<template>
    <div>
        <h5 style="margin-bottom: 15px;">Quảng cáo</h5>
        <div class="wp-news">
            <b-row>
                <div class="d-flex justify-content-between align-items-center flex-wrap" style=" margin-bottom: 20px;">
                    <h5>Quảng cáo của đối tác</h5>
                    <div>
                        <button class="mr-2">Bản nháp đã lưu</button>
                        <button @click="$router.push('/admin/add-ads')">+ Thêm quảng cáo</button>
                    </div>
                </div>
                <b-col cols="md-3" v-for="(item, index) in dataNews" :key="index" style="margin-bottom: 30px;">
                    <div class="item">
                        <img :src="item.img" alt="newsImage" style="width: 100%;">
                        <img src="@/assets/admin/icon/Expired.png" alt="Expired" class="exp-img">
                        <p class="news_ttl">{{ item.ttl }}</p>
                        <p>Ngày đăng ký: {{ item.res_date }}</p>
                        <p class="mt-1">Ngày kết thúc: {{ item.end_date }}</p>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dataNews: [
                {
                    img: require('@/assets/admin/images/ads-1.png'),
                    ttl: 'Công ty luật ABC',
                    res_date: '13/06/2023',
                    end_date: '30/06/2023'
                },
                {
                    img: require('@/assets/admin/images/ads-2.png'),
                    ttl: 'Công ty luật ABC',
                    res_date: '13/06/2023',
                    end_date: '30/06/2023'
                },
                {
                    img: require('@/assets/admin/images/ads-1.png'),
                    ttl: 'Công ty luật ABC',
                    res_date: '13/06/2023',
                    end_date: '30/06/2023'
                },
                {
                    img: require('@/assets/admin/images/ads-2.png'),
                    ttl: 'Công ty luật ABC',
                    res_date: '13/06/2023',
                    end_date: '30/06/2023'
                },
                {
                    img: require('@/assets/admin/images/ads-1.png'),
                    ttl: 'Công ty luật ABC',
                    res_date: '13/06/2023',
                    end_date: '30/06/2023'
                },
            ]
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        font-family: Roboto;
        color: #515151;
        font-size: 22px;
        font-weight: 700;
    }
    .wp-news {
        font-family: Roboto;
        box-shadow: 2px 2px 6px 0px #00000026;
        background: #FFFFFF;
        border-radius: 15px;
        padding: 22px 31px 30px 32px;
        color: #515151;
    }

    .item {
        position: relative;
        cursor: pointer;
        border-radius: 20px;
        background-color: #EEEEEE;
        padding-bottom: 15px;
        height: 100%;
    }

    .exp-img {
        position: absolute;
        top: 5px;
        left: 7px;
    }

    .item p {
        font-size: 16px;
        line-height: 28.8px;
        padding: 0 7px 0 9px;
        margin: 0;
    }

    .item p.news_ttl {
        font-size: 18px;
        font-weight: 600;
        line-height: 32.4px;
        margin: 8px 0 2px;
    }
    
    button {
        border: 1px solid #EE3439;
        background: none;
        color: #EE3439;
        font-size: 15px;
        font-weight: 500;
        border-radius: 15px;
        padding: 4px 39px;
    }

    @media (max-width: 768px) {
        .item {
            width: max-content;
            margin: 0 auto 30px;
        }
    }
</style>