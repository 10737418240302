<template>
    <div class="list_cus_new">
        <h5 style="font-weight: 700; font-size: 22px; color: #515151; margin-bottom: 20px;">Duyệt đăng ký tham gia đấu giá</h5>
        <b-row>
            <b-col cols="lg-5">
                <div class="list">
                    <h5>Danh sách đăng ký tham gia đấu giá</h5>
                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                        <div class="d-flex">
                            <b-form class="search-form mt-2">
                                <b-form-group>
                                    <b-form-input v-model="keySearch" type="text" placeholder="Tìm kiếm" required></b-form-input>
                                </b-form-group>
                                <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                            </b-form>
                            <!-- <button><img src="@/assets/admin/icon/Adjust.png" alt=""></button> -->
                        </div>
                        <button class="btn-approve mt-2" @click="$router.push('/admin/list-joined/' + $route.params.asset_id)">Danh sách tham gia</button>
                    </div>
                    <div class="cus-item" v-for="(item, index) in dataCustomer" :key="index" style="cursor: pointer;" @click="getDetail(item.member_id)">
                        <div class="cus-info d-flex">
                            <img :src="$storage + item.avatar" alt="avatar" style="width: 70px; height: 70px; border-radius: 36px; margin-right: 15px; object-fit: cover;" @error="handleError">
                            <div>
                                <p style="font-size: 18px; font-weight: 500; min-height: 21px;">
                                    <span v-if="item.type == 1">{{ item.member_name }}</span>
                                    <span v-else>{{ item.rep_name }}</span>
                                </p>
                                <p>Mã khách hàng: <span>{{ item.member_code }}</span></p>
                                <p v-if="item.type == 2">Tổ chức: <span>{{ item.member_name }}</span></p>
                                <p v-else>Tài khoản cá nhân</p>
                            </div>
                        </div>
                    </div>
                    <Paginate :page=page @set-page="setPage" style="margin-top: 10px;"/>
                </div>
            </b-col>
            <b-col cols="lg-7" v-if="showDetail">
                <div class="detail-cus">
                    <div class="d-flex justify-content-between align-items-center">
                        <h5 class="mb-3">Thông tin khách hàng đăng ký tham gia đấu giá</h5>
                        <button class="btn-accept" :disabled="!recordFee && downFee || recordFee && !downFee" @click="approveMember(dataDetail.member_id)">Xác nhận chờ duyệt</button>
                    </div>
                    <b-row>
                        <b-col cols="lg-4">
                            <div class="text-center">
                                <img :src="$storage + dataDetail.avatar" @error="handleError" alt="avatar" style=" border-radius: 36px;" class="mb-2">
                                <p class="mb-0" style="font-size: 13px;">Mã khách hàng</p>
                                <p style="font-weight: 500;">{{ dataDetail.member_code }}</p>
                            </div>
                            <div class="file">
                                <p>Giấy tờ liên quan:</p>
                                <b-link :href="$storage + item.url" v-for="(item, index) in dataDetail.required_file" :key="index" target="_blank" v-if="item.url !== ''">{{ item.file_name }}</b-link>
                            </div>
                            <div class="list-access">
                                <p style="font-size: 14px;">Hồ sơ đăng ký đấu giá:</p>
                                <label class="container-label mb-0">
                                    <p style="font-size: 13px;">Đăng ký </p>
                                    <input type="checkbox" class="input-style" checked="checked" disabled>
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container-label mb-0">
                                    <p style="font-size: 13px;">Phí mua hồ sơ</p>
                                    <p class="fee">{{ parseInt(dataDetail.record_fee).toLocaleString('vi') }} VNĐ</p>
                                    <input type="checkbox" class="input-style" v-model="recordFee">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container-label mb-0">
                                    <p style="font-size: 13px;">Phí đặt trước</p>
                                    <p class="fee">{{ parseInt(dataDetail.down_payment).toLocaleString('vi') }} VNĐ</p>
                                    <input type="checkbox" class="input-style" v-model="downFee">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </b-col>
                        <b-col cols="lg-8">
                            <p style="font-size: 18px; font-weight: 600;">Thông tin cá nhân</p>
                            <div class="info"><p>Họ và tên </p>
                                <p v-if="dataDetail.type == 1">{{ dataDetail.member_name }}</p>
                                <p v-else>{{ dataDetail.rep_name }}</p>
                            </div>
                            <div class="info"><p>Loại tài khoản </p>
                                <p v-if="dataDetail.type == 2">Tổ chức</p>
                                <p v-else>Cá nhân</p>
                            </div>
                            <div class="info"><p>Số điện thoại </p><p>{{ dataDetail.phone }}</p></div>
                            <div class="info"><p>Ngày sinh </p><p>{{ formateTime(dataDetail.birthday) }}</p></div>
                            <div class="info"><p>Email </p><p>{{ dataDetail.email }}</p></div>
                            <div class="info"><p>Địa chỉ </p>
                                <p v-if="dataDetail.type == 1">{{ dataDetail.member_address +' - '+ dataDetail.ward_name +' - '+ dataDetail.district_name +' - '+ dataDetail.province_name }}</p>
                                <p v-else>{{ dataDetail.rep_address +' - '+ dataDetail.ward_name +' - '+ dataDetail.district_name +' - '+ dataDetail.province_name }}</p>
                            </div>
                            <div class="info"><p>Giới tính</p>
                                <p v-if="dataDetail.gender == 0">Nam</p>
                                <p v-else>Nữ</p>
                            </div>
                            <div class="info"><p>Số CMT / Thẻ căn cước / Hộ chiếu</p><p>{{ dataDetail.id_number }}</p></div>
                            <div class="info"><p>Ngày cấp</p><p>{{ formateTime(dataDetail.id_date) }}</p></div>
                            <div class="info"><p>Nơi cấp</p><p>{{ dataDetail.id_place }}</p></div>
                            <p>Ảnh CMT / Thẻ căn cước / Hộ chiếu</p>
                            <div class="info">
                                <div class="text-center col-lg-6">
                                    <img :src="$storage + dataDetail.id_front_face" alt="" @error="handleError" style="border-radius: 20px;">
                                    <p style="color: #757575; font-size: 12px; text-align: center; width: 100%;">Mặt trước</p>
                                </div>
                                <div class="text-center col-lg-6">
                                    <img :src="$storage + dataDetail.id_back_side" alt="" @error="handleError" style="border-radius: 20px;">
                                    <p style="color: #757575; font-size: 12px; text-align: center; width: 100%;">Mặt sau</p>
                                </div>
                            </div>
                            <div v-if="dataDetail.type == 2">
                                <p style="font-size: 18px; font-weight: 600;">Thông tin doanh nghiệp</p>
                                <div class="info"><p>Tên doanh nghiệp</p><p>{{ dataDetail.member_name }}</p></div>
                                <div class="info"><p>Mã số doanh nghiệp</p><p>{{ dataDetail.tax_code }}</p></div>
                                <div class="info"><p>Ngày cấp mã số thuế</p><p>{{ formateTime(dataDetail.tax_date) }}</p></div>
                                <div class="info"><p>Nơi cấp</p><p>{{ dataDetail.tax_place }}</p></div>
                                <div class="info"><p>Địa chỉ</p><p>{{ dataDetail.member_address }}</p></div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import search from '../components/search_form.vue'
import Paginate from '../../web-view/components/paginate.vue'
export default {
    components: {
        search,
        Paginate
    },
    data() {
        return {
            keySearch: '',
            page: {
                totalData: 0,
                totalPage: 0,
                currentPage: this.$route.query.page || 1,
                perPage: 10,
                classPrev: 'd-none',
                classNext: 'd-none'
            },
            dataCustomer: [],
            allData: [],
            showDetail: false,
            token: '',
            dataDetail: [],
            recordFee: '',
            downFee: ''
        }
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
        this.getListMemberRegisterAuction()
        this.getAllData()
    },
    methods: {
        formateTime(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY HH:mm:ss")
        },
        async getDetail(id) {
            this.showDetail = true
            const res = await this.$axios.get('/auction/getMemberRegisterAuctionDetailAdmin', {
                params: {
                    asset_id: this.decode(this.$route.params.asset_id),
                    member_id: id
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.dataDetail = res.data
            }
        },
        async getListMemberRegisterAuction() {
            const res = await this.$axios.get('/auction/getListMemberRegisterAuction', {
                params: {
                    asset_id: this.decode(this.$route.params.asset_id),
                    page: this.page.currentPage,
                    per_page: this.page.perPage,
                    approve_status: 0,
                    admin_approve_status: 0
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.page.totalData = res.data.pagination.total_element
                this.page.totalPage = res.data.pagination.total_page
                this.dataCustomer = res.data.re
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if(err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                }
            })
        },
        setPage(val) {
            this.page.currentPage = val;
            this.showDetail = false
            this.getListMemberRegisterAuction();
        },
        async getAllData() {
            const res = await this.$axios.get('/auction/getListMemberRegisterAuction', {
                params: {
                    asset_id: this.decode(this.$route.params.asset_id),
                    page: 1,
                    per_page: '1000',
                    approve_status: 0,
                    admin_approve_status: 0
                },
                headers: {
                    'x-access-token': this.token
                }
            }) 
            if(res.status == 200) {
                this.allData = res.data.re
            }
        },
        async approveMember(id) {
            const data = {
                member_id: id,
                asset_id: this.decode(this.$route.params.asset_id)
            }
            await this.$axios.post('/auction/approveMemberRegisterAdmin', data, {
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.$toast.success('Phê duyệt đăng ký thành công!')
                this.getListMemberRegisterAuction()
                this.showDetail = false
                this.recordFee = ''
                this.downFee = ''
            }).catch(err => {
                this.$toast.error('Phê duyệt đăng ký không thành công!')
            })
        }
    }
}
</script>
<style lang="css" scoped>
    .list, .detail-cus {
        box-shadow: 2px 2px 6px 0px #00000026;
        background: #FFFFFF;
        padding: 10px 19px 24px 14px;
        color: #515151;
    }

    .list {
        border-radius: 15px;
    }

    .detail-cus {
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    button {
        background: none;
        border: none;
    }

    .btn-approve {
        font-size: 15px;
        font-weight: 500;
        color: #EE3439;
        border: 1px solid #EE3439;
        border-radius: 20px;
        max-height: 32px;
        padding: 10px 15px;
        align-items: center;
        display: flex;
    }

    .btn-accept {
        font-size: 15px;
        font-weight: 500;
        color: #FFFFFF;
        background-color: #EE3439;
        border-radius: 20px;
        padding: 4px 29px;
    }

    .list-access p {
        font-weight: 500;
        margin-bottom: 10px;
    }

    .cus-item {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #B4B4B4;
        border-radius: 10px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }

    .cus-item p {
        margin: 0;
        font-size: 13px;
    }

    .cus-item span {
        font-size: 14px;
        font-weight: 500;
    }

    .btn-del img {
        width: 15px;
        height: 15px;
        filter: grayscale(1);
        margin-right: 20px;
    }

    .detail-cus h5 {
        font-size: 20px;
        font-weight: 500;
    }

    .file p {
        font-size: 14px;
        font-weight: 500;
    }

    .file a {
        position: relative;
        display: block;
        color: #515151;
        font-size: 14px;
        margin-bottom: 10px;
        padding-left: 30px;
    }

    .file a::before {
        position: absolute;
        content: '';
        background-image: url('@/assets/icon/Note.png');
        width: 25px;
        height: 25px;
        left: 0;
    }

    .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .info p {
        font-size: 14px;
        color: #757575;
    }

    .info p:last-child {
        font-weight: 500;
        font-size: 15px;
        color: #515151;
        text-align: right;
        width: 50%;
    }

    .container-label {
        color: #515151;
        display: block;
        position: relative;
        padding-left: 25px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
    }

    .container-label input.input-style {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #FFFFFF;
        border: 1px solid #B4B4B4;
        border-radius: 5px;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .container-label input:checked ~ .checkmark:after {
        display: block;
    }

    .container-label .checkmark:after {
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        background-image: url('@/assets/admin/icon/Done_check.png');
    }

    p.fee {
      font-size: 13px;
      color: #515151;
      background: #E5E5E5;
      border-radius: 8px;
      width: 142px;
      height: 32px;
      padding: 5px;
    }

    @media (max-width: 992px) {
        .detail-cus {
            margin-top: 20px;
        }
    }
</style>

<style>
    .list .search-form {
        /* width: 363px; */
    }

    @media (max-width:425px) {
        .list .search-form {
            width: 100%;
        }
    }
</style>