var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"header-home mt-3 w-100"},[_c('div',[_vm._v(" Tài sản đấu giá ")]),_c('div',{staticClass:"view-more"},[_c('b-link',{attrs:{"to":"product-auction"}},[_vm._v("Xem thêm >>")])],1)]),(_vm.listAsset.result)?_c('div',{staticClass:"content-home"},[_c('carousel',{attrs:{"responsive":{
            0: {
                nav: false,
                dots: false,
                items: 1
            },
            675:{
                items: 2,
                nav: false,
                dots: false,
            },
            768: {
                items: 2,
                nav: false,
                dots: false,
            },
            992: {
                items: 3,
                nav: false,
                dots: false,
            },
            1280: {
                items: 4,
                nav: false,
                dots: false,
            },
        },"autoplay":true,"margin":10}},_vm._l((_vm.listAsset.result),function(item,index){return _c('b-link',{key:index},[_c('div',{staticClass:"scroll-mobie d-flex justify-content-center",on:{"click":function($event){_vm.$router.push('/product-detail/' + _vm.encode(item.asset_id))}}},[_c('cardAsset',{attrs:{"item":item}})],1)])}),1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }