<template>
    <div>
        <h5 style="font-size: 22px; font-weight: 700; color: #515151; margin-bottom: 15px;">Duyệt tài sản mới</h5>
        <div class="approve_asset">
            <h5>Danh sách tài sản chờ duyệt</h5>
            <b-row class="mb-3">
                <b-col cols="lg-4" class="d-flex align-items-center">
                    <b-form class="search-form" style="width: 300px;">
                        <b-form-group>
                            <b-form-input v-model="keySearch" type="text" placeholder="Tìm kiếm tên tài sản" required></b-form-input>
                        </b-form-group>
                        <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                    </b-form>
                    <!-- <button><img src="@/assets/admin/icon/Adjust.png" alt=""></button> -->
                </b-col>
                <b-col cols="lg-8" class="d-flex align-items-center flex-wrap">
                    <button class="button-list" :class="{'active' : active_el == 1}" @click="activeBtn(1)">Tất cả</button>
                    <b-dropdown id="list_cate" :text="text_cate" class="m-md-2 button-list" :class="{'active' : active_el == 2}">
                        <b-dropdown-item v-for="(cate, index) in listCate" :key="index" @click="activeBtn(2, cate.name, cate.category_id)">{{ cate.name }}</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown id="list_branch" :text="text_agency" class="m-md-2 button-list" :class="{'active' : active_el == 3}" v-if="role_id == 1">
                        <b-dropdown-item v-for="(agency, index) in listAgency" :key="index" @click="activeBtn(3, agency.agency_name, agency.agency_id)">{{ agency.agency_name }}</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown id="list_branch" :text="text_order" class="m-md-2 button-list" :class="{'active' : active_el == 4}">
                        <b-dropdown-item @click="activeBtn(4, 'Mới nhất', 'latest')">Mới nhất</b-dropdown-item>
                        <b-dropdown-item @click="activeBtn(4, 'Cũ nhất', 'oldest')">Cũ nhất</b-dropdown-item>
                    </b-dropdown>
                </b-col>
            </b-row>
            <div class="wp-asset-item">
                <div class="row asset_item" v-for="(data, index) in filteredItems" :key="index" style="cursor: pointer;">
                    <b-col cols="lg-1" class="pl-0 pr-2 d-flex align-items-center" @click="$router.push('/admin/approve-asset/detail-asset/' + encode(data.asset_id))">
                        <img :src="$storage + data.image[0]" v-if="data.image.length !== 0" alt="" class="asset_item_img" @error="handleError">
                    </b-col>
                    <b-col cols="lg-11 p-0">
                        <h5 class="asset_ttl" @click="$router.push('/admin/approve-asset/detail-asset/' + encode(data.asset_id))">{{ data.asset_name }}</h5>
                        <div class="row align-items-center">
                            <b-col cols="lg-4" class="first-col-item" @click="$router.push('/admin/approve-asset/detail-asset/' + encode(data.asset_id))">
                                <div class="info_asset_item"><p>Mã tài sản:</p> <span style="color: #3FA5FF; text-decoration: underline;">{{ data.asset_code }}</span></div>
                                <div class="info_asset_item"><p>Giá khởi điểm:</p> <span style="color: #EE3439;">{{ parseInt(data.price).toLocaleString("vi-VN")}} VNĐ</span></div>
                                <div class="info_asset_item"><p>Bước giá:</p> <span>{{ parseInt(data.step_price).toLocaleString("vi-VN")}} VNĐ</span></div> 
                            </b-col>
                            <b-col cols="lg-4" @click="$router.push('/admin/approve-asset/detail-asset/' + encode(data.asset_id))">
                                <div class="info_asset_item"><p>Chi nhánh:</p> 
                                    <span v-for="(dataAgency, index) in listAgency" :key="index" v-if="dataAgency.agency_id == data.agency_id">{{ dataAgency.agency_name }}</span>
                                </div>
                                <div class="info_asset_item"><p>Kết thúc đăng ký:</p> <span>{{ formateTime(data.register_end_time) }}</span></div>
                                <div class="info_asset_item"><p>Thời gian đấu giá:</p> <span>{{ formateTime(data.start_time) }}</span></div>
                            </b-col>
                            <b-col cols="lg-4">
                                <div class="d-flex app_btn">
                                    <button class="app_btn_item" @click="approveAsset('cancel', data.asset_id, data.asset_code)">Từ chối</button>
                                    <button class="app_btn_item app_btn_item--approve" @click="approveAsset('approve', data.asset_id, data.asset_code)">Phê duyệt</button>
                                    <button @click="$router.push('edit-asset/' + encode(data.asset_id))" v-if="intListRole.includes(24)"><img src="@/assets/admin/icon/Edit.png" alt=""></button>
                                    <button @click="deleteAssset('one', data.asset_id)" v-if="intListRole.includes(25)"><img src="@/assets/admin/icon/Trash.png" alt=""></button>
                                </div>
                            </b-col>
                        </div>
                    </b-col>
                </div>
            </div>
        </div>
        <Approve ref="approve" @handleCloseModal="listenDialog"/>
        <Delete ref="delete" @handleCloseModal="listenDialog"/>
    </div>
</template>
<script>
import Approve from './modal/approve.vue'
import Delete from './modal/delete.vue'
export default {
    components: {
        Approve,
        Delete
    },
    data() {
        return{
            dataAsset:[],
            token: '',
            keySearch: '',
            listAgency: [],
            listCate: [],
            active_el: 1,
            text_cate: 'Danh mục tài sản',
            text_agency: 'Chi nhánh',
            text_order: 'Sắp xếp theo',
            listRole: [],
            intListRole: [],
            params_agency: '',
            role_id: ''
        }
    },
    computed: {
        filteredItems() {
            return this.dataAsset.filter(dataAsset => dataAsset.asset_name.toLowerCase().includes(this.keySearch.toLowerCase()));
        },
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
            this.role_id = info.roles.role_id
            if(info.roles.role_id == 1) {
                this.params_agency = ''
            } else {
                this.params_agency = info.agency_id
            }
            info.roles.arr_resource.forEach(element => {
                this.listRole.push(element.resource_id)
            });
            this.intListRole = this.listRole.map(Number)
        }
        this.getDataAsset()
        this.getListAgency()
        this.getListCate()
    },
    methods: {
        formateTime(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY HH:mm:ss")
        },
        async activeBtn(num, name, id){
            this.active_el = num
            if(num == 2) {
                this.text_cate = name
                const res = await this.$axios.get('/asset/getListAssetToApprove', {
                    params: {
                        asset_name: '',
                        asset_code: '',
                        category_id: id,
                        owner: '',
                        from_price: '',
                        to_price: '',
                        from_step_price: '',
                        to_step_price: '',
                        order: 'latest',
                        page: '1',
                        per_page: '100000',
                        winner: '',
                        status: '',
                        description: '',
                        auction_id: '',
                        publish_status: '1',
                        agency_id: this.params_agency
                    },
                    headers: {
                        'x-access-token': this.token
                    }
                })
                if(res.status == 200) {
                    this.dataAsset = res.data.result
                }
                this.text_agency = 'Chi nhánh'
                this.text_order = 'Sắp xếp theo'
            } else if(num == 3) {
                this.text_agency = name
                const res = await this.$axios.get('/asset/getListAssetToApprove', {
                    params: {
                        asset_name: '',
                        asset_code: '',
                        category_id: '',
                        owner: '',
                        from_price: '',
                        to_price: '',
                        from_step_price: '',
                        to_step_price: '',
                        order: 'latest',
                        page: '1',
                        per_page: '100000',
                        winner: '',
                        status: '',
                        description: '',
                        auction_id: '',
                        publish_status: '1',
                        agency_id: id
                    },
                    headers: {
                        'x-access-token': this.token
                    }
                })
                if(res.status == 200) {
                    this.dataAsset = res.data.result
                }
                this.text_cate = 'Danh mục tài sản'
                this.text_order = 'Sắp xếp theo'
            } else if(num == 4) {
                this.text_order = name
                const res = await this.$axios.get('/asset/getListAssetToApprove', {
                    params: {
                        asset_name: '',
                        asset_code: '',
                        category_id: '',
                        owner: '',
                        from_price: '',
                        to_price: '',
                        from_step_price: '',
                        to_step_price: '',
                        order: id,
                        page: '1',
                        per_page: '100000',
                        winner: '',
                        status: '',
                        description: '',
                        auction_id: '',
                        publish_status: '1',
                        agency_id: this.params_agency
                    },
                    headers: {
                        'x-access-token': this.token
                    }
                })
                if(res.status == 200) {
                    this.dataAsset = res.data.result
                }
                this.text_cate = 'Danh mục tài sản'
                this.text_agency = 'Chi nhánh'
            } else {
                this.text_cate = 'Danh mục tài sản'
                this.text_agency = 'Chi nhánh'
                this.text_order = 'Sắp xếp theo'
                this.getDataAsset()
            }
        },
        approveAsset(type, id, code) {
            this.$refs.approve.show(type, id, code)
        },
        listenDialog(isClose = false) {
            if(!isClose) {
                this.getDataAsset()
            }
        },
        deleteAssset(type, id) {
            this.$refs.delete.show(type, id)
        },
        async getDataAsset() {
            const res = await this.$axios.get('/asset/getListAssetToApprove', {
                params: {
                    asset_name: '',
                    asset_code: '',
                    category_id: '',
                    owner: '',
                    from_price: '',
                    to_price: '',
                    from_step_price: '',
                    to_step_price: '',
                    order: 'latest',
                    page: '1',
                    per_page: '100000',
                    winner: '',
                    status: '',
                    description: '',
                    auction_id: '',
                    publish_status: '1',
                    agency_id: this.params_agency
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res=> {
                this.dataAsset = res.data.result
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                }else if(err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                }else {
                    this.$toast.error('Vui lòng thử lại!')
                } 
            })
        },
        async getListAgency() {
            const res = await this.$axios.get('/agency/getListAgency', {
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.listAgency = res.data
            }
        },
        async getListCate() {
            const res = await this.$axios.get('/category/getListCategory', {
                params: {
                    code: '',
                    name: '',
                    status: '',
                    parent_id: '',
                    page: '1',
                    per_page: '100000',
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.listCate = res.data.result
            }
        },
    }
}
</script>
<style lang="css" scoped>

    .approve_asset {
        color: #515151;
        background: #FFFFFF;
        padding: 10px 15px 0 16px;
        box-shadow: 2px 2px 6px 0px #00000026;
        border-radius: 15px;
        padding-bottom: 30px;
    }
    .approve_asset button {
        background: none;
        border: none;
    }
    .approve_asset h5 {
        font-size: 20px;
        font-weight: 500;
        padding-top: 6px;
    }

    .approve_asset .button-list.active {
        background: #EE3439;
        color: #FFFFFF;
        border: none;
    }

    .approve_asset .button-list {
        width: 185px;
        height: 31px;
        border: 1px solid #515151;
        border-radius: 20px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
    }
    
    .asset_item {
        border: 1px solid #B4B4B4;
        border-radius: 10px;
        margin: 0 0 20px 0;
    }

    .asset_item p {
        margin: 0;
        color: #929292;
        font-size: 13px;
        width: 135px;
    }
    .asset_item .first-col-item p {
        width: 115px;
    }
    .asset_ttl {
        font-size: 18px;
        font-weight: 500;
    }

    .info_asset_item {
        display: flex;
        align-items: center;
    }

    .info_asset_item span {
        font-size: 14px;
        font-weight: 500;
    }

    .app_btn {
        justify-content: space-evenly;
    }
    .app_btn img  {
        filter: grayscale(1);
        width: 20px;
        height: 20px;
    }
    .approve_asset button.app_btn_item {
        border: 1px solid #EE3439;
        border-radius: 15px;
        box-shadow: 2px 2px 6px 0px #00000026;
        color: #EE3439;
        font-size: 14px;
        width: 106px;
        height: 30px;
    }

    .approve_asset button.app_btn_item--approve {
        background-color: #EE3439;
        color: #FFFFFF;
        border: none;
    }

    .asset_item_img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        max-height: 125px;
        object-fit: cover;
    }

    .wp-asset-item {
        max-height: 700px;
        overflow-y: auto;
    }

    .wp-asset-item::-webkit-scrollbar {
        width: 0;
    }
    @media (max-width: 992px) {
        .asset_item .first-col-item p {
            width: 135px;
        }

        .app_btn {
            margin-top: 20px;
            padding-bottom: 20px;
        }
        .asset_item_img {
            width: unset;
        }
    }

    @media (max-width: 768px) {
        .button-list {
            margin-top: 0.5rem;
            margin-right: 0.5rem;
        }
    }
</style>

<style>
    .button-list .dropdown-toggle {
        position: relative;
        background: none;
        border: none;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button-list .dropdown-toggle::after {
        position: absolute;
        right: 12px;
        border-right-color: #515151 !important;
        border-bottom-color: #515151 !important;
    }

    .button-list .dropdown-toggle:active {
        background: none;
        color: #515151;
    }

    .approve_asset .button-list.active .dropdown-toggle {
        color: #FFF;
    }

    .approve_asset .button-list.active .dropdown-toggle::after {
        border-right-color: #FFF !important;
        border-bottom-color: #FFF !important;
    }

    .button-list .dropdown-menu {
        width: max-content;
        text-align: center;
        box-shadow: 2px 2px 4px 0px #00000040;
        border-radius: 10px;
    }

    .button-list .dropdown-menu li {
        border-bottom: 1px solid #DADADA;
        margin: 0 5px;
    }

    .button-list .dropdown-menu li:last-child {
        border-bottom: none;
    }

    .button-list .dropdown-menu .dropdown-item:hover,
    .button-list .dropdown-menu .dropdown-item:active,
    .button-list .dropdown-menu .dropdown-item:focus {
        background: none;
        color: #515151;
    }
</style>