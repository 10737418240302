<template>
    <div>
        <b-col cols="md-12">
            <b-link class="item-auction">
                <img class="item-auction_img" :src="this.$storage+auctionDetail.thumbnail" alt="" @error="handleError">
                <img v-if="auctionDetail.auction_status_id == 1" class="auction-item_ico auction-item_ico--child" src="@/assets/icon/Auction2.png" alt="">
                <img class="auction-item_ico auction-item_ico--child"
                    v-if="auctionDetail.auction_status_id == 2 || auctionDetail.auction_status_id == 3" src="@/assets/icon/golden sold out.png" alt="">
                <div style="margin-left: 10px; width: 100%;">
                    <label class="item-auction_txt">{{ auctionDetail.auction_name }}</label>

                    <div class="item-auction-listinfo">
                        <ul>
                            <li>Mã cuộc đấu giá: {{ auctionDetail.auction_code }}</li>
                            <li>Đấu giá viên: {{ auctionDetail.full_name }}</li>
                            <li>Thời gian kết thúc: {{ formateTime(auctionDetail.end_time, 'DD/MM/YYYY HH:mm:ss') }} </li>
                            <li>Thời gian bắt đầu: {{ formateTime(auctionDetail.start_time) }}</li>
                            <li>Số tài sản đấu giá: {{ auctionDetail.count_asset }}</li>
                        </ul>
                        <div class="text-center">
                            <div class="status" v-if="auctionDetail.auction_status_id == 0">
                                <img src="@/assets/icon/icon_auction.png" alt="">
                                <p class="status-auction status-auction--pending">Chưa đấu giá</p>
                            </div>
                            <div class="status" v-else-if="auctionDetail.auction_status_id == 1 && getEndTimeStatus(auctionDetail.end_time)">
                                <img src="@/assets/icon/icon_auction.png" alt="">
                                <p class="status-auction status-auction--pending">Đang đấu giá</p>
                            </div>
                            <!-- <div class="status" v-if="auctionDetail.auction_status_id == 2">
                                <img src="@/assets/icon/Ok.png" alt="">
                                <p class="status-auction">Đấu giá xong - chờ xác nhận</p>
                            </div> -->
                            <div class="status" v-else-if="auctionDetail.auction_status_id == 2">
                                <img src="@/assets/icon/Ok.png" alt="">
                                <p class="status-auction">Đấu giá thành</p>
                            </div>
                            <div class="status" v-else>
                                <img src="@/assets/icon/close.png" alt="">
                                <p class="status-auction status-auction--pending">Đấu giá không thành</p>
                            </div>
                        </div>
                    </div>
                </div>
            </b-link>
            <div class="d-flex show-detail">
                <p>Danh sách tài sản trong cuộc đấu giá</p>
            </div>
            <b-row class="row-width">
                <b-col cols="xl-4 col-md-6" v-for="(item, index) in listAsset.result" :key="index"
                @click="$router.push('/product-detail/' + encode(item.asset_id))" >
                    <cardAsset :item="item" />
                </b-col>
            </b-row>
        </b-col>
    </div>
</template>

<script>
import cardAsset from '../components/card-asset.vue';
export default {
    props:['params'],
    components:{
        cardAsset,
    },
    data() {
        return {
            paramsAsset: {
                asset_name: "",
                asset_code: "",
                asset_status: "",
                category_id: "",
                owner: "",
                from_price: "",
                to_price: "",
                from_step_price: "",
                to_step_price: "",
                page: 1,
                per_page: 10,
                order: "latest",
                winner: "",
                status: "",
                description: "",
                auction_id: this.decode(this.$route.params.auction_id),
            },
            listAsset: {},
            auctionDetail: {},
            paramsAuction: {
                page: 1,
                per_page: 100,
                keyword: '',
            auction_type: '',
            },
            listAuction: {},
            pageAsset: {},
        }
    },
    created(){
        this.getAuctionDetailForMember({
            auction_id: this.paramsAsset.auction_id,
        })
        this.getListAssetForMember(this.paramsAsset)
    },
    methods:{
        getEndTimeStatus(end_time){
            let end = this.$moment.utc(end_time).format("x")
            let now = this.$moment.utc().format("x")
            let countdown = end - now
            if (countdown - 25200000 !== 0 && countdown - 25200000 > 0) {
                return true
            }
        }
    }
}
</script>

<style lang="css" scoped>
/* ------ */
.item-auction {
    display: flex;
    color: #515151;
    background: #F8F8F8;
    padding: 11px 11px 15px;
    text-decoration: none;
    border-radius: 9.36px;
    box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.item-auction_txt {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 9px;
    line-height: 23.44px;
    width: 100%;
    -webkit-line-clamp: 3
}

.item-auction ul {
    margin-bottom: 0;
    list-style-type: disc;
    padding-left: 2rem;
}

.item-auction ul li {
    padding-bottom: 5px;
}

.item-auction ul li:last-child {
    padding-bottom: 0;
}

.show-detail {
    justify-content: space-between;
    align-items: center;
}

.show-detail p {
    color: #515151;
    font-size: 18px;
    line-height: 32.4px;
    margin: 10px 0 15px;
    font-weight: 500
}


/* ------- */
.item-in-auction {
    font-size: 14.05px;
    font-weight: 400;
    color: #515151;
    display: block;
    text-decoration: none;
    background-color: #F8F8F8;
    border-radius: 9.36px;
    padding-bottom: 5px;
}

.item-in-auction img {
    width: 100%;
}

.item-in-auction p {
    margin: 0;
    padding: 5px 7px 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.item-in-auction ul {
    border: 1px solid #515151;
    border-radius: 18.73px;
    padding: 5px 0;
    margin: 10px auto;
    color: #929292;
    position: relative;
    max-width: 280px;
}

.item-in-auction ul span {
    color: #515151;
    font-weight: 500;
}

.item-in-auction ul::before {
    content: "";
    position: absolute;
    background-image: url("@/assets/icon/Auction1.png");
    background-repeat: no-repeat;
    width: 44px;
    height: 44px;
    top: 16px;
}

.item-in-auction ul li {
    padding-left: 50px;
    padding-bottom: 3px;
}

.row-width {
    margin-bottom: 15px;
}

.item-auction-listinfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.status-auction {
    margin-bottom: 0;
    color: #00B569;
    font-weight: 500;
    max-width: 126px;
    font-size: 15px;
    margin-top: 5px;
}

.status-auction--pending {
    color: #EE3439;
}

.status img {
    max-width: 50px;
    max-height: 50px;
}

.item-auction_img {
    width: 297px;
    height: 225px;
    border-radius: 10px;
    object-fit: cover;
}

.auction-item_ico {
    position: absolute;
    top: 2%;
    left: 3%
}

@media (max-width: 1024px) {
    .item-auction-listinfo {
        font-size: 14px;
    }

    .item-auction_txt {
        font-size: 17px;
    }
}

@media (max-width: 768px) {
    .row-width .col-md-4 {
        width: 50%;
    }

    .item-auction_img,
    .item-in-auction img {
        width: 100%;
    }

    .item-auction-listinfo {
        display: block;
    }

    .item-auction-listinfo ul {
        margin-bottom: 15px;
    }

    .status-auction {
        max-width: 100%;
    }
}


@media (max-width: 575px) {
    .item-auction {
        flex-wrap: wrap;
    }
}

@media (max-width: 425px) {
    .item-auction {
        display: block;
        font-size: 14px;
    }

    .item-auction_txt,
    .show-detail p {
        font-size: 14px;
    }

    .row-width .col-md-4 {
        width: 100%;
    }

    .auction-item_ico--child {
        left: 9%;
    }
}
</style>
