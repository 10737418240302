<template>
    <div>
        <canvas ref="chartCanvas"></canvas>
    </div>
</template>
<script>
 import Chart from 'chart.js/auto'
export default {
    mounted() {
      this.renderChart();
    },
    methods: {
      renderChart() {
        const ctx = this.$refs.chartCanvas.getContext('2d');
        new Chart(ctx, {
            type: 'line',
            data: {
                labels: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5'],
                datasets: [
                {
                    label: 'Doanh thu A',
                    data: [1200, 1500, 800, 2000, 1800],
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 2,
                    pointRadius: 5,
                    pointHoverRadius: 8,
                },
                {
                    label: 'Doanh thu B',
                    data: [1000, 1300, 700, 1800, 1600],
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 2,
                    pointRadius: 5,
                    pointHoverRadius: 8,
                },
                ],
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom',
                        labels: {
                            usePointStyle: true,
                            pointStyle: 'rectRounded',
                            boxWidth: 15,
                            boxHeight: 15,
                            font: {
                                size: 14
                            }
                        },
                    }
                },
            },
        });
      },
    },
}
</script>
<style scoped>
    canvas {
        width: 100% !important;
        height: auto !important;
    }
</style>