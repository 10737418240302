<template>
    <div>
        <canvas ref="chartCanvas"></canvas>
    </div>
</template>
<script>
 import Chart from 'chart.js/auto'
export default {
    mounted() {
      this.renderBarChart();
    },
    methods: {
      renderBarChart() {
        const ctx = this.$refs.chartCanvas.getContext('2d');
        new Chart(ctx, {
          type: 'bar',
          data: {
            labels: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
            datasets: [
              {
                label: 'Doanh thu',
                data: [1200, 1500, 800, 2000, 1800, 1800, 2000, 1800, 1000, 2000, 1800, 1800],
                backgroundColor: '#3FA5FF',
              },
            ],
          },
          options: {
            plugins: {
                legend: {
                    position: 'bottom',
                    align: 'end',
                    labels: {
                      	usePointStyle: true,
                        pointStyle: 'rectRounded',
                        boxWidth: 15,
                        boxHeight: 15,
						font: {
                            size: 16
                        }
                    },
                },
            },
            responsive: true,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      },
    },
}
</script>
<style scoped>
    canvas {
        width: 100% !important;
        height: auto !important;
    }
</style>