<template>
    <div>
        <div class="nav-breadcrums py-2" style="padding: 0.2rem 0;font-size: .875rem;">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Trang Chủ</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Quản lý tài khoản</li>
                </ol>
            </nav>
        </div>
        <div class="title">Quản lý tài khoản </div>
        <div class="col-12 d-flex justify-content-center">
            <input type="file" accept="image/*" class="image_input_review" hidden ref="inputAvatar" id="inputAvatar" @change="changeAvatar">
            <div class="avatar_img mt-5" onclick="$(this).prev().click()" >
                <img :src="this.$storage + dataMember.avatar" style="cursor: pointer;" alt="" ref="avatar" v-if="dataMember.avatar" @error="handleErrorAvatar">
                <img class="review-img" src="@/assets/front-end/img/blank-avatar-icon-26.png" onclick="$('#inputAvatar').click()" ref="avatar" alt="" v-else>
            </div>
        </div>
        <div style="text-align: center;" v-if="dataMember.type == 2">
            <div class="title">{{ dataMember.rep_name }}</div>
            <div style="font-size: 20px;">( Tổ chức )</div>
            <div style="font-size: 18px; margin: 1rem;">Id : {{ dataMember.member_code }}</div>
        </div>
        <div style="text-align: center;" v-else>
            <div class="title">{{ dataMember.member_name }}</div>
            <div style="font-size: 20px;">( Cá nhân )</div>
            <div style="font-size: 18px; margin: 1rem;">Id : {{ dataMember.member_code }}</div>
        </div>
        <hr>
        <div class="menu mt-1">
            <nav class="nav-link p-0" aria-label="">
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <a class="nav-link col-12"
                        :class="{ 'active': this.$router.currentRoute.path == '/account' || this.$router.currentRoute.path == '/account/banking' || this.$router.currentRoute.path == '/account/file' }"
                        id="account-tab" data-bs-toggle="tab" data-bs-target="#account" type="button" role="tab"
                        @click="changePath('account', 'tài khoản', '/account')" to="/account" aria-controls="account"
                        aria-selected="true">Thông tin tài khoản</a>
                    <a class="nav-link col-12"
                        :class="{ 'active': this.$router.currentRoute.path == '/account/auction' || this.$router.currentRoute.path == '/account/auction/delivery' || this.$router.currentRoute.path == '/account/auction/finished' }"
                        id="auction-tab" data-bs-toggle="tab" data-bs-target="#auction" type="button" role="tab"
                        @click="changePath('account', 'tài khoản', '/account/auction')" aria-controls="auction"
                        aria-selected="true">Cuộc đấu giá tham
                        gia</a>
                    <a class="nav-link col-12" :class="{ 'active': this.$router.currentRoute.path == 'account/asset' }"
                        id="asset-tab" data-bs-toggle="tab" data-bs-target="#asset" type="button" role="tab"
                        @click="changePath('account', 'tài khoản', '/account/asset')"
                        aria-controls="asset" to="/account/asset" aria-selected="true">Tài sản đấu giá</a>
                    <a class="nav-link col-12" style="margin-top: 30px; cursor: pointer; color: #EE3439 !important;"
                        @click="logoutMember()">Đăng xuất</a>
                </div>

            </nav>
        </div>
    </div>
</template>
<script>
export default {
    props:['dataMember'],
    data() {
        return {
            info: JSON.parse(localStorage.getItem('userInfo')),
            Avatar: null
        }
    },
    methods:{
        async changeAvatar() {
            this.$axios.put('/member/updateAvatar',{
                file: event.target.files[0],
                member_id:this.info.id
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-access-token': this.info.accessToken
                }
            }).then(res=>{
                window.location.reload()
                this.$toast.success('Đổi avatar thành công')
            }).catch(err=>{
                this.$toast.error('Bạn không có lỗi, lỗi của chúng tôi')
            })
        },

    },
}
</script>
<style lang="css" scoped>
.avatar_img{
    width: 230px;
    height: 230px;
    border-radius: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    object-fit: cover;
    border: #ffffff 0.25rem solid;
}
.avatar_img img{
    width: 100%;
    height: auto;
    border: none;
    border-radius: unset;
    object-fit: cover;
}
</style>