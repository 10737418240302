import Vue from 'vue'
import App from './App.vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import './pages/web-view/account/account.css'
import './pages/web-view/auth/auth.css'
import './pages/web-view/home/home.css'
import './pages/web-view/countdown/countdown.css'
import './assets/front-end/css/theme.min.css'
import './assets/css-media/smallscreen.css'

import './assets/front-end/css/web.css'
import './assets/css-media/miniscreen.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import moment from 'moment'
import common from "./common";
import router from "./router/index";
import axios from 'axios'
import socket from '@/service/socket';
import baseService from './service/baseService';
import store from './store/store';
import fireBase from '@/service/fireBase';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import './assets/front-end/css/responsive.css'
Vue.prototype.$socket = socket;
axios.defaults.baseURL = "https://api.dgv.com.vn/api";
// axios.defaults.baseURL = "http://localhost:3008/api";
Vue.prototype.$axios = axios;
moment.locale('vi')
Vue.prototype.$moment = moment;
Vue.prototype.$storage = "https://api.dgv.com.vn/storage/";
// Vue.prototype.$storage = "http://localhost:3008/storage/";
Vue.prototype.$report = "https://report.dgv.com.vn";
Vue.prototype.$notification = fireBase;
Vue.mixin(common);
Vue.mixin(baseService);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(CKEditor);
Vue.use(Toast, { timeout: 5000 });
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
