
<template>
    <div>
        <nav aria-label="" class="col-12 p-0">
            <div class="nav nav-tabs nav-content justify-content-around" id="nav-tab-auction" role="tablist">
                <a class="nav-link"
                    :class="{ 'active': this.$router.currentRoute.path == '/account/auction' || this.$router.currentRoute.path == '/account' }"
                    id="nav-happenning-tab" data-bs-toggle="tab" data-bs-target="#nav-happenning" type="button" role="tab"
                    aria-controls="nav-happenning" aria-selected="true">
                    Cuộc đấu giá đang diễn ra
                </a>
                <a class="nav-link" :class="{ 'active': this.$router.currentRoute.path == '/account/auction/delivery' }"
                    id="nav-delivery-tab" data-bs-toggle="tab" data-bs-target="#nav-delivery" type="button" role="tab"
                    aria-controls="nav-delivery" aria-selected="false">
                    Cuộc đấu giá sắp diễn ra
                </a>
                <a class="nav-link" :class="{ 'active': this.$router.currentRoute.path == '/account/auction/finished' }"
                    id="nav-history-tab" data-bs-toggle="tab" data-bs-target="#nav-history" type="button" role="tab"
                    aria-controls="nav-history" aria-selected="false">
                    Cuộc đấu giá đã kết thúc
                </a>
            </div>
        </nav>

        <div class="tab-content px-2" id="nav-tab-auctionContent">

            <auctionHappenning />

            <auctionDelivery />

            <AuctionHistory />
        </div>
    </div>
</template>
<script >
import auctionHappenning from "./auctionHappenning.vue";
import auctionDelivery from "./auctionDelivery.vue";
import AuctionHistory from "./auctionHistory.vue";
export default {
    components: {
        auctionHappenning,
        auctionDelivery,
        AuctionHistory
    }
}
</script>