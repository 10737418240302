<template>
    <div class="PageNotFound"></div>
</template>
<script>
export default {
    name: "PageNotFound",
};
</script>
<style lang="css" scoped>
    .PageNotFound {
        width: 100%;
        height: 100vh;
        position: relative;
    }
    .PageNotFound::after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url(../../assets/images/404.jpg) no-repeat;
        background-position: center;
        background-size: cover;
    }
    

</style>
