<template>
    <div class="col-md-12">
        <p>Copyrighted to DGV AUCTION PARTNERSHIP COMPANY</p>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="css" scoped>
    div {
        background-color: #080808;
        background-color: #080808;
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    p {
        font-family: Libre Bodoni;
        font-weight: 700;
        font-size: 15.95px;
        line-height: 19.93px;
        text-align: center;
        color: #D6B877;
        margin: 0;
    }

    @media (max-width: 575px) {
        p {
            font-size: 12px;
        }
    }
    
    @media (max-width: 320px) {
        p {
            font-size: 10px;
        }
    }
</style>