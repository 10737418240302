<template>
    <b-modal id="edit_banking" ref="editBanking" size="md" centered hide-footer hide-header modal-class="edit_banking">
        <form action="" class="auth" @submit.prevent="updateBankInfo(params)">
            <h5>Chỉnh sửa thông tin ngân hàng</h5>
            <div class="form-group">
                <label for="">Họ và tên chủ tài khoản</label>
                <input type="text" class="form-control" v-model="params.bank_account_name"
                name="bank_account_name" @input="inputTxt">
            </div>
            <div class="form-group">
                <label for="">Ngân hàng</label>
                <select class="form-control form-select" name="" id="" v-model="params.bank_id">
                    <option :value="bank.id" v-for="(bank, index) in listBank">{{ bank.shortName + '-' + bank.name }} </option>
                </select>
            </div>
            <div class="form-group">
                <label for="">Số tài khoản</label>
                <input type="text" class="form-control" v-model="params.bank_account_number"
                @input="inputTxt" name="bank_account_number">
            </div>
            <div class="form-group">
                <label for="">Chi nhánh</label>
                <input type="text" name="branch" class="form-control" v-model="params.branch"
                @input="inputTxt" >
            </div>
            <div class="form-group">
                <div class="d-md-flex flex-wrap align-items-center col-12 p-0">
                    <label class="">Nhập lại mật khẩu</label>
                    <div class="d-md-flex align-items-center w-100">
                    <input ref="inputPass" class="form-control" type="password" v-model="params.password">
                    <a href="#" class="toggel-password" @click="showPass()">
                        <b-icon icon="eye" font-scale="0.5" v-if="iconStt"></b-icon>
                        <b-icon icon="eye-slash" font-scale="0.5" v-else></b-icon>
                    </a>
                    </div>
                </div>
            </div>
            <div class="d-flex" style="text-align: center;">
                <div class="col-6">
                    <b-button class="btnn btn-outline-primary" block @click="hide()">Hủy bỏ</b-button>
                </div>
                <div class="col-6">
                    <b-button class="btnn btn-primary" type="submit" block>Lưu chỉnh sửa</b-button>
                </div>
            </div>
        </form>
        
    </b-modal>
</template>
<script>
    export default {
        props:['formData'],
        data(){
            return {
                iconStt: true,
                listBank:[],
                params:{
                    password:"",
                    bank_account_name:"",
                    bank_id:"",
                    branch:"",
                    bank_account_number:"",
                },
                publicKey: 'MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHvcIWyQskFcrDyzUuVML4OgVCrbOS+g0K5B5ngrMK1623+eBYBVegi6f0fe15xNatSphlBNjaTezEyZVYORd9lRfxMglWXeUtDTQhqmzBU4653USZZqdMGBdPMZRpDXO4+T+WEEXLDA/bW1MZ8E8LIKof2edlwAGX2JBKDqwPfhAgMBAAE=',
                encryptedData: '',
            }
        },
        mounted(){
            this.getListBank();
        },
        methods:{
            inputTxt() {
                let name = event.target.name
                this.params[name] = this.params[name].replace(/[<>\[\]]/g, '')
            },
            show(){
                this.$refs.editBanking.show()
                this.params.bank_account_name = this.formData.bank_account_name
                this.params.bank_id = this.formData.bank_id
                this.params.branch = this.formData.branch
                this.params.bank_account_number = this.formData.bank_account_number
            },
            hide(){
                this.$refs.editBanking.hide()
            },
            showPass() {
                this.$refs.inputPass.type = this.showPassword(this.$refs.inputPass.type)
                this.iconStt = !this.iconStt
            }
            
        }
    }
</script>
<style scoped lang="css">
    .auth .form-group label{
        color: #757575;
        font-size: 14px;
        font-weight: 300;
    }
    .form-group{
        margin-bottom: 0.5rem;
    }
    .btnn.btn-primary:hover, .btnn.btn-primary:active{
        color: #ffffff;
        background-color: #EE3434;
        border: #EE3434 1px solid;
    }
    .btnn.btn-outline-primary:active, .btnn.btn-outline-primary:focus-visible{
        color: #EE3434;
        background-color: #FFFFFF;
        border: #EE3434 1px solid;
    }
    .auth .form-control{
        background-color:  #E8E8E8;   
        color: #8d8d8d; 
        height: 38px;
        filter: unset;
    }
    .auth .form-select{
        filter: unset;
        color: #515151;
    }
    .auth .review-img{
        filter: brightness(0);
    }
    button.btnn {
        border-radius: 12px;
        width: 100%;
        height: 39px;
    }
    .auth input.form-control::placeholder,input {
        color: #8d8d8d;
    }

    .toggel-password {
        top: 30px;
    }

    .btn-outline-primary:active, .btn-outline-primary:focus-visible {
        background-color: #FFF;
        box-shadow: none;
        color: #EE3439;
        border-color: #EE3439;
    }

    .btn-primary:hover, .btn-primary:focus-visible, .btn-primary:active {
        background-color: #EE3439;
        border-color: #EE3439;
        box-shadow: none;
    }
</style>
<style lang="css">
    .edit_banking .modal-content{
        width: 383px;
    }
    .edit_banking .modal-body{
        padding: 1rem 0.75rem;
    }
</style>