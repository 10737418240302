<template>
    <div>
        <Header></Header>
        <router-view></router-view>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/pages/web-view/components/header.vue';
import Footer from '@/pages/web-view/components/footer.vue';
export default {
    components: {
        Header,
        Footer,
    },
    name: "App",
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || "DGV";
            }
        },
    }
};
</script>
