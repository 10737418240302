<template>
    <div>
        <div class="info-pro" :class="{ 'over': this.timeLeft == 0 }">
            <p class="info-pro__ttl">Thông tin tài sản trong cuộc đấu giá</p>
            <SlideImage :list_images=dataProduct.image />
            <div class="infor_propduct">
                <div class="row">
                    <b-col cols="lg-5">
                        <p>Tài sản: </p>
                    </b-col>
                    <b-col cols="lg-7" class="text-right">
                        <p class="text-left">{{ dataProduct.asset_name }}</p>
                    </b-col>
                </div>
                <div class="row">
                    <b-col cols="lg-5">
                        <p>Giá khởi điểm: </p>
                    </b-col>
                    <b-col cols="lg-7" class="text-right">
                        <p class="text-red">{{ parseInt(dataProduct.price).toLocaleString("vi-VN") }} VNĐ</p>
                    </b-col>
                </div>
                <div class="row">
                    <b-col cols="lg-5">
                        <p>Mã tài sản đấu giá: </p>
                    </b-col>
                    <b-col cols="lg-7" class="text-right">
                        <p>{{ dataProduct.asset_code }}</p>
                    </b-col>
                </div>
                <div class="row">
                    <b-col cols="lg-5">
                        <p>Bước giá: </p>
                    </b-col>
                    <b-col cols="lg-7" class="text-right">
                        <p>{{ parseInt(dataProduct.step_price).toLocaleString("vi-VN") }} VNĐ</p>
                    </b-col>
                </div>
                <div class="row">
                    <b-col cols="lg-5">
                        <p>Số bước giá / lần: </p>
                    </b-col>
                    <b-col cols="lg-7" class="text-right">
                        <p>100</p>
                    </b-col>
                </div>
                <div class="row">
                    <b-col cols="lg-5">
                        <p>Tiền mua hồ sơ: </p>
                    </b-col>
                    <b-col cols="lg-7" class="text-right">
                        <p>{{ parseInt(dataProduct.record_fee).toLocaleString("vi-VN") }} VNĐ</p>
                    </b-col>
                </div>
                <div class="row">
                    <b-col cols="lg-5">
                        <p>Tiền đặt trước:</p>
                    </b-col>
                    <b-col cols="lg-7" class="text-right">
                        <p>{{ parseInt(dataProduct.down_payment).toLocaleString("vi-VN") }} VNĐ</p>
                    </b-col>
                </div>
                <div class="row">
                    <b-col cols="lg-5">
                        <p>Tên chủ tài sản: </p>
                    </b-col>
                    <b-col cols="lg-7" class="text-right">
                        <p>{{ dataProduct.owner }}</p>
                    </b-col>
                </div>
                <div class="row">
                    <b-col cols="lg-5">
                        <p>Thời gian bắt đầu trả giá:</p>
                    </b-col>
                    <b-col cols="lg-7" class="text-right">
                        <p>{{ formateTime(dataProduct.auction_start_time) }}</p>
                    </b-col>
                </div>
                <div class="row">
                    <b-col cols="lg-5">
                        <p>Thời gian kết thúc trả giá: </p>
                    </b-col>
                    <b-col cols="lg-7" class="text-right">
                        <p>{{ formateTime(dataProduct.end_time) }}</p>
                    </b-col>
                </div>
                <div class="row">
                    <b-col cols="lg-5">
                        <p>Hình thức đấu: </p>
                    </b-col>
                    <b-col cols="lg-7" class="text-right">
                        <p v-if="dataProduct.type == 1">Đấu giá lên</p>
                        <p v-if="dataProduct.type == 2">Đấu giá xuống</p>
                    </b-col>
                </div>
                <div class="text-center">
                    <b-link :to="'/auctioneer/asset-detail/' + this.encode(dataProduct.asset_id)" class="view-detail">Chi tiết tài sản đấu giá</b-link>
                </div>
            </div>
        </div>
        <!-- <div :class="{ 'confirm_user': this.timeLeft == 0 }" style="display: none;">
            <p class="info-pro__ttl">Duyệt thành viên vào phòng đấu giá</p>
            <div class="user_wait" v-for="(user, index) in getDataRegister" :key="index">
                <div class="col-lg-12 user_info">
                    <p class="user_code" @click="showInfoUser(user.member_id)">{{ user.member_code }}</p>
                    <p class="user_name">{{ user.member_name }}</p>
                </div>
                <div class="col-lg-12 user_opt">
                    <p class="user_status user_status--onl" v-if="user.is_online == 1">Đang online</p>
                    <p class="user_status user_status--off" v-else>Chưa online</p>
                    <div>
                        <button class="btn_cancel"
                            @click="showmodalApprove(0, user.member_id, user.member_socket_id, dataProduct.auction_key)">Từ
                            chối</button>
                        <button class="btn_accept"
                            @click="showmodalApprove(1, user.member_id, user.member_socket_id, dataProduct.auction_key)">Phê
                            duyệt</button>
                    </div>
                </div>
            </div>
        </div> -->
        <infoUser ref="infoUser" />
        <modalApprove ref="modalApprove" />
    </div>
</template>

<script>
import SlideImage from '../../../admin-view/components/slideImage.vue';
import infoUser from '../modal/info_user.vue';
import modalApprove from '../modal/approve.vue';
// import { state } from "@/service/socket";
export default {
    components: {
        SlideImage,
        infoUser,
        modalApprove
    },
    data() {
        return {
            dataProduct: [],
            users: [],
            timeLeft: 0,
            auctionId: '',
            timeLeft: 0,
            token: '',
            asset_id: ''
        }
    },
    // computed: {
    //     getDataRegister() {
    //         return state.dataRegister
    //     }
    // },
    mounted() {
        const storedData = localStorage.getItem('auInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
        this.auctionId = this.decode(this.$route.params.auction_id);
        this.getListAsset()
        // this.getDataProduct()
    },
    methods: {
        showInfoUser(id) {
            this.$refs.infoUser.show(id)
        },
        showmodalApprove(status, member_id, member_socket_id, auction_key) {
            this.$refs.modalApprove.show(status, member_id, member_socket_id, auction_key)
        },
        async getDataProduct() {

            const res = await this.$axios.get('/asset/getAssetDetail?asset_id=' + this.asset_id, {
                headers: {
                    'x-access-token': this.token
                }
            })

            if (res.status == 200) {
                this.dataProduct = res.data
                const data = {
                    title: res.data.asset_name,
                    auction_key: res.data.auction_key,
                    asset_id: res.data.asset_id,
                    auction_id: res.data.auction_id,
                }
                this.$emit('sendDataTitle', data);
                let startTime = new Date(this.dataProduct.auction_start_time).getTime();
                let now = new Date().getTime();
                let countdown = startTime - now
                if (countdown !== 0 && countdown > 0) {
                    this.timeLeft = parseInt(countdown);
                    this.startCountdown(this.timeLeft);
                }
            }
        },
        formateTime(time) {
            return this.$moment.utc(time).format("HH:mm:ss DD/MM/YYYY")
        },
        async getListAsset() {
            const res = await this.$axios.get('/asset/getListAsset', {
                params: {
                    asset_name: '',
                    asset_code: '',
                    category_id: '',
                    owner: '',
                    from_price: '',
                    to_price: '',
                    from_step_price: '',
                    to_step_price: '',
                    order: 'latest',
                    page: '1',
                    per_page: '100000',
                    winner: '',
                    status: '',
                    description: '',
                    auction_id: this.auctionId,
                    publish_status: '',
                    asset_status: ''
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.asset_id = res.data.result[0].asset_id
                this.getDataProduct()
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAuctioneer')
                } else if (err.response.status == 401) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAuctioneer')
                }
            })
        }
    }
}
</script>

<style lang="css" scoped>
.info-pro__ttl {
    color: #515151;
    font-size: 18px;
    font-weight: 500;
    padding-left: 15px;
    position: sticky;
    top: 0;
    background: #FFFFFF;
    z-index: 11;
    padding: 10px 0;
    margin: 0;
}

.infor_propduct {
    font-size: 15px;
    color: #515151;
    font-weight: 400;
}

.infor_propduct .col-lg-7 {
    font-weight: 500;
    color: #444444;
}

.text-red {
    color: #EE3439;
}

.view-detail {
    text-align: center;
    display: inline-block;
    color: #515151;
    font-weight: 500;
    font-size: 17px;
    border: 1px solid #515151;
    max-width: 260px;
    border-radius: 17px;
    padding: 6px 44px;
    margin: 15px 0 25px;
}

.info-pro,
.confirm_user {
    box-shadow: 2px 2px 6px 0px #00000026;
    background-color: #FFFFFF;
    border-radius: 15px;
    padding: 0 10px 10px;
}

.info-pro {
    max-height: 949px;
    overflow-y: auto;
}

.info-pro::-webkit-scrollbar {
    width: 0;
}
/* 
.over {
    max-height: 575px;
    overflow-y: auto;
    overflow-x: hidden;
} */

.over::-webkit-scrollbar,
.confirm_user::-webkit-scrollbar {
    width: 0px;
}

.confirm_user {
    margin-top: 20px;
    height: 354px;
    max-height: 354px;
    overflow-y: auto;
    display: block !important;
}

.user_wait {
    background: #E7E7E7;
    padding: 14px 6px 6px 5px;
    border-radius: 14px;
    margin-bottom: 10px;
}

.user_wait p {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
}

.user_info,
.user_opt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.user_info .user_code {
    color: #3FA5FF;
    text-decoration: underline;
    cursor: pointer;
}

.user_info .user_name {
    color: #515151;
}

.user_status {
    position: relative;
    font-size: 13px;
    padding-left: 15px;
    display: flex;
    align-items: center;
}

.user_status::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 7px;
    border-radius: 20px;
    left: 0;
}

.user_status--onl {
    color: #00B569;
}

.user_status--onl::before {
    background-color: #00B569;
}

.user_status--off {
    color: #EE3439;
}

.user_status--off::before {
    background-color: #EE3439;
}

.user_opt button {
    border: 1px solid #EE3439;
    color: #EE3439;
    border-radius: 14px;
    padding: 2px 31px;
    font-weight: 500;
    font-size: 15px;
    margin-top: 10px;
}

.user_opt .btn_accept {
    margin-left: 5px;
    color: #FFFFFF;
    background-color: #EE3439;
}
</style>
