<template>
    <div>
        <b-modal id="join_auction" ref="join_auction" centered hide-footer hide-header content-class="modal-width"
            style="width: 421;height: 342px;" modal-class="modal-join">
            <div class="join_auction">
                <img src="../../../assets/icon/icon_auction.png" alt="">
                <h5>Tham gia đấu giá</h5>
                <p>Bạn đã thoát ra khỏi phòng đấu giá , để quay lại phòng vui lòng nhập lại mã key để vào điểm danh và đợi
                    đấu giá viên duyệt vào phòng .</p>
                <b-form-input type="text" required placeholder="Nhập mã key" v-model="inputkey"></b-form-input>
                <p style="color: #EE3439; font-weight: 600; margin: 10px 0 0;" v-if="showError">{{ messageError }}
                </p>
                <b-button class="mt-3 cancel_btn" block @click="hide">Hủy bỏ</b-button>
                <b-button class="mt-3 confirm" block @click="checkCode">Vào điểm danh</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    data() {
        return {
            inputkey: '',
            showError: false,
            userCurrent: '',
            auction_id: '',
            asset_id: '',
            messageError: "Vui lòng nhập đúng mã key"
        }
    },
    created() {
        this.userCurrent = localStorage.getItem('member');
    },
    methods: {
        checkCode() {
            const data = {
                member_key: this.inputkey,
                auction_id: this.auction_id
            }

            this.$axios.post('/auction/checkMemberKey', data, {
                headers: {
                    'x-access-token': localStorage.getItem('member')
                }
            })
                .then(res => {
                    if (res.data.result_status == 0) {
                        this.messageError = res.data.message
                        this.showError = true
                    } else {
                        this.showError = false
                        localStorage.setItem('member_key', data.member_key)
                        this.$refs['join_auction'].hide()
                        this.$router.push('/auction-room/' + this.encode(this.asset_id))
                    }
                })
                .catch(error => {
                    this.showError = true
                });

        },
        show(item) {
            this.auction_id = item.auction_id
            this.asset_id = item.asset_id
            this.$refs['join_auction'].show()
        },
        hide() {
            this.$refs['join_auction'].hide()
            this.showError = false
        }
    }
}
</script>
<style lang="css">
.modal-join .modal-width {
    width: 421px;
    min-height: 342px;
    padding: 0 1rem 0 1rem;
}

.join_auction {
    text-align: center;
    color: #444444;
}

.join_auction h5 {
    font-weight: 500;
    font-size: 20.96px;
    line-height: 37.73px;
}

.join_auction button {
    width: 165.06px;
    height: 41.9px;
    border-radius: 32px;
    font-weight: 700;
    font-size: 16px;
    border: none;
}

.join_auction .confirm,
.join_auction .confirm:active {
    background: #EE3439;
}

.join_auction input {
    border-radius: 20px;
    background: #EEEEEE;
    border: none;
    height: 51px;
}

.join_auction input:focus {
    box-shadow: none;
    background: #EEEEEE;
}

.join_auction .cancel_btn,
.join_auction .cancel_btn:active {
    background: #FFFFFF;
    border: 1px solid #EE3439;
    color: #EE3439;
    margin-right: 10px;
}
</style>