<template>
    <div class="bg-transparent login">
        <div class="banner-main first">
            <div class="banner-main second">
                <div class="container d-md-flex pt-1 page-countdown" id="scrollToMe">
                    <div class="col-md-3">
                        <div class="text-center">
                            <img src="@/assets/images/logo_count.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-9 text-center">
                        <div :class="{'d-none' : step2}">
                            <div class="my-2 clock-title">Đếm ngược thời gian</div>
                            <button class="btn_play"><img src="@/assets/front-end/icon/Circled_play.png" alt="" @click="startCountdown"></button>
                            <div class="flex-center divider" id="divider">
                                {{ currentTime }} <span class="mr-4"></span>  {{ this.$moment().format('DD/MM/YYYY') }}
                            </div>
                        </div>

                        <div :class="{'d-none' : !step2  || step3}">
                            <div class="my-2 clock-title">Thời gian đến lúc bắt đầu đấu giá</div>
                            <p class="count">{{ countdownTime }}</p>
                            <div class="flex-center divider" id="divider">
                                {{ currentTime }} <span class="mr-4"></span>  {{ this.$moment().format('DD/MM/YYYY') }}
                            </div>
                        </div>

                        <div :class="{'d-none' : !step3 }">
                            <div class="my-2 clock-title">Đếm ngược thời gian</div>
                            <p id="clock-countdown" class="my-2 clock-countdown">{{ formattedTime }}</p>
                            <div class="flex-center divider" id="divider">
                                {{ currentTime }} <span class="mr-4"></span>  {{ this.$moment().format('DD/MM/YYYY') }}
                            </div>
                            <button class="btn_stop" @click="pauseCountdown" v-if="!isPaused"><img src="@/assets/front-end/icon/Stop_circled.png" alt=""></button>
                            <button class="btn_stop" @click="replayCountdown(timeCount)" v-else><img src="@/assets/front-end/icon/Circled_play.png" alt=""></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script >
export default {
    data() {
        return {
            infomation: {},
            currentTime:null,
            timeLeft:'',
            countdownTime: 10,
            formattedTime: '00:00:00',
            timeCount: 0,
            step2: false,
            step3: false,
            isPaused: false,
            countdownInterval: null,
        }
    },
    created() {
        this.getInformation()
    },
    mounted(){
        setInterval(() => {
            this.currentTime = this.$moment().format('hh:mm');
        });
        this.scrollToBottom()
        if(this.$route.path == '/countdown') {
            document.body.classList.add('fix-height')
        } else {
            document.body.classList.remove('fix-height')
        }
        document.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },
    destroyed () {
        document.body.classList.remove('fix-height')
    },
    computed: {
        countdown() {
            return this.timeLeft ? this.formatTime(this.timeLeft) : '00:00:00';
        },
    },
    methods:{
        handleBeforeUnload(event) {
            event.preventDefault();
            event.returnValue = ''
        },
        handleKeyDown(event) {
            if (event.keyCode === 116) {
                event.preventDefault();
            }
        },
        pad(d) {
            return (d < 10) ? '0' + d.toString() : d.toString();
        },
        setTime(time) {
            if(time) {
                this.timeCount = time
            } else {
                this.timeCount = this.infomation.start_time.value * 60 - 1
            }
            this.countdownInterval = setInterval(() => {
                if (this.timeCount <= 0) {
                    clearInterval(this.countdownInterval);
                    this.formattedTime = '00:00:00'
                } else if(this.timeCount <= 3600) {
                    const minutes = Math.floor(this.timeCount / 60);
                    const seconds = this.timeCount % 60;
                    this.formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                    this.timeCount--;
                } else {
                    const hours = Math.floor(this.timeCount / 3600);
                    const minutes = Math.floor((this.timeCount % 3600) / 60);
                    const seconds = this.timeCount % 60;
                    this.formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                    this.timeCount--;
                }
            }, 1000);
        },
        scrollToBottom() {
            setTimeout(() => {
                document.body.scrollTop = 100;
                document.documentElement.scrollTop = 100;
            }, 20);
        },
        getInformation() {
            let infomation = {
                end_time: {},
                start_time: {},
            }
            this.$axios.get('/common/getInfomation')
            .then(res => {
                res.data.forEach(element => {
                    infomation[element.type] = element
                });
                this.infomation = infomation
            })
        },
        startCountdown() {
            this.step2 = true
            let seconds = 9;

            const countdownInterval = setInterval(() => {
                this.countdownTime = seconds;

                if (seconds === 0) {
                    clearInterval(countdownInterval);
                    this.step3 = true
                    this.setTime()
                } else {
                    seconds--;
                }
            }, 1000);
        },
        pauseCountdown() {
            this.isPaused = true
            clearInterval(this.countdownInterval);
        },
        replayCountdown(timeCount) {
            this.isPaused = false
            this.setTime(timeCount);
        }
    }
}

</script>
<style lang="css" scoped>
.page-countdown{
    height: 100vh;
    display: flex;
    align-items: center;
}

button {
    background: none;
    border: none;
}
.btn_play {
    margin: 40px 0 73px;
}
.btn_stop {
    position: absolute;
    right: 0;
    bottom: -200px;
}

.btn_stop img {
    width: 60px;
    height: 60px;
}
.count {
    color: #D6B877;
    font-family: Times New Roman;
    font-size: 340px;
    font-weight: 700; 
}

.clock-countdown {
    margin: 40px 0;
}
</style>

<style>

/* .fix-height {
    overflow: hidden;
} */
</style>