<template>
    <div>
        <b-container class="auction_room">
            <div class="d-flex justify-content-between align-items-center">
                <b-breadcrumb :items="items"></b-breadcrumb>
                <div class="time text-center mt-2 mb-2">
                    <p>{{ this.$moment(this.server_time).utcOffset(7).format('LTS') }}</p>
                    <p style="text-transform: capitalize;">{{ this.$moment().utcOffset(7).format('dddd') }} - {{
                        this.$moment().utcOffset(7).format('l') }}</p>
                </div>
            </div>
            <b-row>
                <h5 class="title">{{ asset.asset_name }}</h5>
                <p class="room_ttl" v-if="!approve_status || !room_status ">Điểm danh đấu giá</p>
                <div v-else-if=" room_status != 3 || room_status != 4"></div>
                <p class="room_ttl" v-else>Phòng đấu giá </p>
                
                <b-col cols="lg-6">
                    <slideImage :list_images="asset.image" />
                    <img src="@/assets/icon/Auction2.png" alt="" class="image-status"
                        v-if="room_status != 3 || room_status != 4">
                    <img src="@/assets/icon/golden sold out.png" alt="" class="image-status" v-else>
                    <b-row class="file " v-if="!approve_status || !room_status">
                        <b-col cols="md-6">
                            <div class="more-info">
                                <h5 class="more-info__ttl">Tài liệu liên quan</h5>
                                <ul class="list-info">
                                    <li v-for="(file, index) in asset.file" :key="index">
                                        <b-link :href="$storage + file.url" target="_blank">{{ file.file_name }}</b-link>
                                    </li>
                                </ul>
                            </div>
                        </b-col>
                        <b-col cols="md-6" class="mb-2">
                            <div class="more-info">
                                <h5 class="more-info__ttl">Thông tin tổ chức đấu giá</h5>
                                <div class="col-12 d-flex">
                                    <img class="col-2 mr-2" style="width: 35px; height: 25px;"
                                        src="@/assets/icon/Company.png" alt="">
                                    <div>
                                        <span>
                                            Đơn vị tổ chức:
                                        </span> <br>
                                        <label class="text-red">
                                            {{ asset.org }}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 d-flex">
                                    <img class="col-2 mr-2" style="width: 35px; height: 25px;"
                                        src="@/assets/icon/Customer.png" alt="">
                                    <div>
                                        <span>
                                            Đấu giá viên:
                                        </span> <br>
                                        <label class="text-red">
                                            {{ asset.DGV }}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 d-flex">
                                    <img class="col-2 mr-2" style="width: 35px; height: 25px;"
                                        src="@/assets/icon/Location.png" alt="">
                                    <div>
                                        <span>
                                            Địa điểm:
                                        </span> <br>
                                        <span class="text-red">
                                            {{ asset.auction_address }}
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </b-col>
                    </b-row>
                    <div class="more-info m-0" v-else>
                        <h5 class="more-info__ttl">Thông tin tổ chức đấu giá</h5>
                        <ul class="list-info-cus">
                            <li><img src="@/assets/icon/Company.png" alt="">Đơn vị tổ chức: <span v-if="asset.org">
                                    {{ asset.org }}
                                </span>
                                <span v-else>Công ty đấu giá hợp danh DVG</span>
                            </li>
                            <li><img src="@/assets/icon/Customer.png" alt="">Đấu giá viên:
                                <span v-if="asset.DGV"> {{ asset.DGV }} </span>
                                <span v-else>Trần Thanh Tùng</span>
                            </li>
                            <li><img src="@/assets/icon/Location.png" alt="">Địa điểm:
                                <span v-if="asset.address"> {{ asset.address }} </span>
                                <span v-else> Hà Nội </span>
                            </li>
                        </ul>
                    </div>

                </b-col>
                <b-col cols="lg-6" v-if="this.room_status == 4|| room_status == 2">
                    <div class="status_au">
                        <!-- :class="{
                        'status_au--success': asset.asset_status == 2 || asset.asset_status == 4,
                        'status_au--fail': asset.asset_status == 3
                    }" -->
                        <p style="padding-top: 5px;">Cuộc đấu giá kết thúc </p>
                        <p style="color: #EE3439;">Kết thúc</p>
                        <!-- <p v-if="asset.auction_status == 3">Đấu giá không thành</p> -->
                        <!-- <p v-else-if="asset.auction_status == 2">Đấu giá thành</p> -->
                        <!-- <p v-else>Đã đấu giá - Đang xử lý</p> -->
                    </div>
                    <div class="info">
                        <p>Trạng thái tài sản:</p>
                        <!-- <span class="highlight" style="color: #FF9838;" v-if="asset.asset_status == 2">Đã đấu giá - Đang
                            xử lý</span> -->
                        <span class="highlight" style="color: #FF9838;" v-if="asset.asset_status == 2">Đã đấu giá - Đang
                            xử lý</span>
                        <span class="highlight" v-else-if="asset.asset_status == 3">Đấu giá không thành</span>
                        <span class="highlight" style="color: #00B569;" v-else-if="asset.asset_status == 4">Đấu giá thành
                            công</span>
                    </div>
                    <div class="info">
                        <p>Giá trúng đấu giá:</p>
                        <span class="highlight" v-if="asset.highestBid">
                            {{ parseInt(asset.highestBid).toLocaleString("vi-VN") }} VNĐ
                        </span>
                        <span class="highlight" v-else>
                            0 VNĐ
                        </span>
                    </div>
                    <div class="info">
                        <p>Giá khởi điểm:</p> <span class="highlight">
                            {{ parseInt(asset.price).toLocaleString("vi-VN") }} VNĐ</span>
                    </div>
                    <div class="info">
                        <p>Mã tài sản đấu giá:</p> <span>{{ asset.asset_code }}</span>
                    </div>
                    <div class="info">
                        <p>Tiền mua hồ sơ:</p>
                        <span>{{ parseInt(asset.record_fee).toLocaleString("vi-VN") }} VNĐ</span>
                    </div>
                    <div class="info">
                        <p>Bước giá:</p> <span>{{ parseInt(asset.step_price).toLocaleString("vi-VN") }} VNĐ</span>
                    </div>
                    <div class="info">
                        <p>Số bước giá trả tối đa / lần:</p> <span>{{ asset.step }}</span>
                    </div>
                    <div class="info">
                        <p>Tiền đặt trước:</p> <span>
                            {{ parseInt(asset.down_payment).toLocaleString("vi-VN") }} VNĐ
                        </span>
                    </div>
                    <div class="info">
                        <p>Tên chủ tài sản:</p> <span>{{ asset.owner }}</span>
                    </div>
                    <div class="info">
                        <p>Thời gian bắt đầu trả giá:</p> <span>{{ formateTime(asset.start_time) }}</span>
                    </div>
                    <div class="info">
                        <p>Thời gian kết thúc trả giá:</p> <span>{{ formateTime(asset.end_time) }}</span>
                    </div>
                    <div class="group-btn " :class="{ 'justify-content-end': !isClient }">
                        <b-link @click="$router.push('/auction-detail/' + encode(asset.asset_id))" class="btn-link col-6">Chi tiết tài
                            sản đấu giá</b-link>
                    </div>
                    <div class="text-center my-3"
                        v-if="dataCheckRole && dataCheckRole.role && dataCheckRole.role.includes(2)">
                        <button class="btn-complain" @click="showComplain(asset.auction_id, asset.asset_id)">Khiếu
                            nại cuộc đấu giá</button>
                    </div>
                    <div class="group-btn">
                        <b-link class="btn-link"
                            v-if="dataCheckRole && dataCheckRole.role && dataCheckRole.role.includes(3)"
                            @click="showReport(asset.auction_id, asset.asset_id)">
                            Báo cáo
                        </b-link>
                        <b-link class="btn-link btn-report"
                            v-if="dataCheckRole && dataCheckRole.role && dataCheckRole.role.includes(4)" @click="getReport">
                            Xuất file
                        </b-link>
                    </div>
                </b-col>
                <b-col cols="lg-6" v-else>
                    <div class="show-time" :class="{ 'back-status': room_status == 1 && approve_status }">
                        <p v-if="!room_status">Cuộc đấu giá sắp diễn ra</p>
                        <p v-else>Cuộc đấu giá đang diễn ra </p>
                        <p v-if="!room_status">Thời gian đến đấu giá còn:</p>
                        <p v-else>Thời gian trả giá còn:</p>
                        <p v-if="!room_status">{{ countdownStart }}</p>
                        <p v-else-if="room_status == 1">
                            {{ countdownEnd }}
                        </p>
                        <p v-else>Chờ xử lý kết quả</p>
                    </div>
                    <div class="info info--status mb-1" v-if="dataCheckRole.role_name">
                        <span>Bạn được mời tham gia cuộc đấu giá - {{ dataCheckRole.role_name }}</span>
                    </div>
                    <div class="info info--status mb-1" v-else>
                        <span>Đã được duyệt tham gia đấu giá</span>
                    </div>
                    <div class="info" v-if="!room_status">
                        <p>Trạng thái:</p>
                        <span v-if="dataCheckRole.role && dataCheckRole.role.length !== 0"
                            style="font-size: 18px; font-weight: 500; color: #00B569;"> Chờ đấu giá
                        </span>
                        <span v-else-if="approve_status" style="font-size: 18px; font-weight: 500; color: #00B569;">
                            Đã điểm danh
                        </span>
                        <span v-else style="font-size: 18px; font-weight: 500; color: #EE3439;">
                            Chờ đấu giá viên điểm danh
                        </span>
                    </div>
                    <div class="info" v-if="dataCheckRole.role_name">
                        <p>Chức năng của bạn:</p> <span>{{ dataCheckRole.role_name }}</span>
                    </div>
                    <div class="info">
                        <p>Giá khởi điểm:</p>
                        <span v-if="asset.price" :class="{ 'in-room': approve_status && room_status }">
                            {{ this.asset.price.toLocaleString("vi-VN") }} VNĐ
                        </span>
                    </div>
                    <div class="info">
                        <p>Mã tài sản đấu giá:</p> <span>{{ asset.asset_code }}</span>
                    </div>
                    <div class="info" v-if="!room_status">
                        <p>Thời gian bắt đầu đăng ký tham gia đấu giá:</p> <span>{{ formateTime(this.asset.register_start_time) }}</span>
                    </div>

                    <div class="info" v-if="!room_status">
                        <p>Thời gian kết thúc đăng ký:</p> <span>
                            {{ formateTime(this.asset.register_end_time) }}</span>
                    </div>
                    <div class="info" v-if="!room_status">
                        <p>Tiền mua hồ sơ:</p> <span>{{ parseInt(asset.record_fee).toLocaleString("vi-VN") }} VNĐ</span>
                    </div>
                    <div class="info" v-show="room_status">
                        <p>Thời gian kết thúc trả giá:</p> <span>
                            {{ formateTime(this.asset.end_time) }}</span>
                    </div>
                    <div class="info">
                        <p>Bước giá:</p>
                        <span v-if="asset.step_price">
                            {{ asset.step_price.toLocaleString("vi-VN") }} VNĐ
                        </span>
                    </div>
                    <div class="info">
                        <p>Số bước giá trả tối đa / lần:</p>
                        <span>{{ asset.step }}</span>
                    </div>
                    <div class="info" v-if="!room_status">
                        <p>Tiền đặt trước:</p> <span v-if="asset.down_payment">{{ parseInt(asset.down_payment).toLocaleString("vi-VN") }} VNĐ</span>
                    </div>
                    <div class="info" v-if="!room_status">
                        <p>Tên chủ tài sản:</p> <span>{{ this.asset.owner }}</span>
                    </div>
                    <div class="info" v-if="!room_status">
                        <p>Thời gian bắt đầu trả giá:</p> <span>
                            {{ formateTime(this.asset.start_time) }}</span>
                    </div>
                    <div class="info">
                        <p>Hình thức trả giá:</p>
                        <span v-if="asset.type == 1">Đấu giá lên - Trả liên tục</span>
                        <span v-if="asset.type == 2">Đấu giá xuống - Trả liên tục</span>
                        <span v-if="asset.type == 3">Đấu giá theo vòng - Trả liên tục</span>
                        <span v-if="asset.type == 4">Đấu giá lên - Trả tự do</span>
                        <span v-if="asset.type == 5">Đấu giá xuống - Trả tự do</span>
                        <span v-if="asset.type == 6">Đấu giá theo vòng - Trả tự do</span>
                    </div>
                    <!-- ---GUEST--- -->
                    <div v-if="room_status && isClient || room_status && asset.autherity_role == 2">
                        <div class="guest-show my-2">
                            <div class="info">
                                <p>Mã cuộc đấu giá:</p> <span>{{ asset.auction_code }}</span>
                            </div>
                            <div class="info" v-if="isClient">
                                <p>Chức năng của bạn:</p> <span>{{ dataCheckRole.role_name }}</span>
                            </div>
                            <div class="info" v-else>
                                <p>Chức năng của bạn:</p> <span> Ủy quyền cho người khác tham gia </span>
                            </div>
                            <div class="info">
                                <p>Số lượt trả giá: </p> <span>{{ countBid }}</span>
                            </div>
                            <div class="info">
                                <p>Số người tham gia:</p> <span>{{ this.countMemberJoined }}</span>
                            </div>
                            <div class="info">
                                <p>Thời gian giá trả cao nhất hiện tại:</p>
                                <span v-if="dataHighest.result_status == 1 && dataHighest.highest_bid.member_id">{{
                                    formateTimeGuest(dataHighest.highest_bid.time) }}</span>
                            </div>
                            <div class="info">
                                <p>Người trả cao nhất hiện tại: </p>
                                <span v-if="dataHighest.result_status == 1 && dataHighest.highest_bid.member_id">{{
                                    dataHighest.highest_bid.member_code }}</span>
                            </div>
                            <div class="info">
                                <p>Giá cao nhất hiện tại:</p>
                                <span style="font-size: 22px;"
                                    v-if="dataHighest.result_status == 1 && dataHighest.highest_bid.member_id">{{
                                        parseInt(this.highest_price).toLocaleString("vi-VN") }} VNĐ</span>
                            </div>
                        </div>

                        <div v-if="room_status == 3">
                            <div class="group-btn"
                                v-if="dataCheckRole.role && dataCheckRole.role.includes(2) && dataCheckRole.role.includes(3)">
                                <b-link class="btn-link mr-3" v-if="dataCheckRole.role && dataCheckRole.role.includes(2)"
                                    @click="showComplain(asset.auction_id, asset.asset_id)">
                                    Khiếu nại cuộc đấu giá
                                </b-link>
                                <b-link class="btn-link" v-if="dataCheckRole.role && dataCheckRole.role.includes(3)"
                                    @click="showReport(asset.auction_id, asset.asset_id)">
                                    Báo cáo
                                </b-link>
                            </div>
                            <div v-else>
                                <div class="text-center my-3" v-if="dataCheckRole.role && dataCheckRole.role.includes(2)">
                                    <button class="btn-complain"
                                        @click="showComplain(asset.auction_id, asset.asset_id)">Khiếu nại
                                        cuộc đấu giá</button>
                                </div>
                                <div class="text-center my-3" v-if="dataCheckRole.role && dataCheckRole.role.includes(3)">
                                    <button class="btn-complain" @click="showReport(asset.auction_id, asset.asset_id)">Báo
                                        cáo</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ---/GUEST--- -->
                    <!-- <div class="text-right mb-3" v-show="!approve_status || !room_status || timeCountStart">
                        <b-link :to="'/auction-detail/' + encode(asset.asset_id)" class="btn-link btn-detail">
                            Chi tiết tài sản đấu giá</b-link>
                    </div> -->
                    <div class="auction-box" :class="{ 'auction-box--return': !bid_status }"
                        v-if="room_status && !isClient && asset.autherity_role != 2">
                        <p>
                            <span class="auction-box_txt">Mã đấu giá của bạn:</span>
                            <span class="text-red">{{ member_code }}</span>
                        </p>
                        <p class="d-flex" v-if="my_highest_price == ''">
                            <span style="min-width: 90px; display: inline-block;" class="price-at">Đặt giá:</span>
                            <span class="text-red">Nhập số tiền bạn đặt vào ô trả giá , nhấn cộng / trừ để thêm / bớt một
                                bước giá</span>
                        </p>
                        <p v-show="my_highest_price">
                            <span class="auction-box_txt">Giá đặt cao nhất của bạn: </span>
                            <span class="text-red num-red" style="font-size: 20px;padding-left: 0.25rem;">{{
                                this.my_highest_price.toLocaleString("vi-VN") }} VNĐ</span>
                        </p>
                        <p v-if="highest_price">
                            <span class="auction-box_txt">Giá cao nhất hiện tại: </span>
                            <span class="text-red num-red" style="font-size: 20px;">
                                {{ parseInt(this.highest_price).toLocaleString("vi-VN") }} VNĐ
                            </span>
                        </p>
                        <p class="text-red num-red text-center" style=" font-size: 24px;" v-show="!bid_status">
                            Bạn đã rút lại giá đã trả và bị truất quyền tham gia đấu giá</p>
                        <b-form style="position: relative;" v-show="bid_status" @submit="modalConfirmPay()">
                            <b-form-group id="input-group-1" label-for="input-1" style="margin-bottom: 10px;">
                                <b-button :disabled="!timeCount || !approve_status" @click="subStep" class="btn-sum"><img
                                        src="@/assets/icon/Sub.png" alt=""></b-button>

                                <input type="text" placeholder="Nhập số tiền bạn trả" class="input-price" @change="formatInput"
                                    v-model="inputData" @input="formatInput" maxlength="15" :disabled="!timeCount || !approve_status" />

                                <b-button :disabled="!timeCount || !approve_status" @click="sumStep" class="btn-sub"><img
                                        src="@/assets/icon/Sum.png" alt=""></b-button>
                            </b-form-group>
                            <p class="num-to-text" v-show="inputData">{{ numberInWords }} Đồng</p>
                            <p style="text-align: center; color: #EE3439; font-weight: 500;" v-show="text_error">
                                {{ text_error }} </p>
                            <b-button type="button" @click="modalReturn" class="btn btn-outline-primary"
                                v-if="my_highest_price == highest_price && dataHighest.highest_bid.member_id == member_id" :disabled="!timeCount || !approve_status">Rút lại giá đã trả</b-button>
                            <b-button type="submit" class="auction-btn" :disabled="!timeCount"
                                :class="{ 'w-100': my_highest_price != highest_price || !my_highest_price || dataHighest.highest_bid.member_id != member_id, 'disable': !timeCount || !approve_status }">
                                <img src="@/assets/icon/Auction3.png" alt="">
                                Trả giá</b-button>
                        </b-form>
                    </div>
                    <div class="text-right mb-3" v-show="!approve_status || !room_status">
                        <b-link :to="'/auction-detail/' + encode(asset.asset_id)" class="btn-link btn-detail">
                            Chi tiết tài sản đấu giá</b-link>
                    </div>
                </b-col>
            </b-row>

            <Tab v-show="approve_status && room_status" :asset="asset" :top5AllBid="all_bid_client" :all_bid="all_bid"
                :countMemberJoined="countMemberJoined" :all_my_bid="all_my_bid" :highest_price="highest_price"
                :propisClient="isClient" :dataClient="dataCheckRole" :dataHighest="dataHighest"/>
            <returnPrice ref="child" @save-data="withdrawPrice()" />
            <b-modal ref="notifi-modal" id="notifi-modal" centered hide-footer hide-header @hide="hideModal()">
                <div class="notifi-modal">
                    <img src="@/assets/icon/Notice_ico.png" alt="">
                    <h5 class="notifi-modal__ttl">Thông báo</h5>
                    <h5>Bạn đã bị kick khỏi phòng đấu giá!</h5>
                    <span>Do vi phạm quy chế đấu giá , bạn đã bị đấu giá viên kích khỏi phòng và bị mất phí đăng ký , phí
                        đặt trước .</span>
                    <b-button class="mt-3" block @click="$bvModal.hide('notifi-modal')">Xác nhận</b-button>
                </div>
            </b-modal>
            <b-modal id="confirm_pay" ref="confirmPay" centered hide-footer hide-header>
                <div class="confirm_pay">
                    <img src="@/assets/icon/Auction_modal.png" alt="">
                    <h5>Trả giá tài sản với: {{ formattedValue }} VNĐ</h5>
                    <p>Giá trả của bạn: {{ numberInWords }} đồng</p>
                    <p class="important">Lưu ý: Khi bạn đã xác nhận giá trả sẽ không thể chỉnh sửa giá được nữa</p>
                    <b-button class="mt-3 btn-cancel" block @click="$bvModal.hide('confirm_pay')">Hủy bỏ</b-button>
                    <b-button class="mt-3" block @click="bidPrice">Xác nhận giá trả</b-button>
                </div>
            </b-modal>
            <b-modal ref="statusComplain" centered hide-footer hide-header content-class="modal-width" modal-class="statusComplain">
                <div class="statusComplain text-center">
                    <div v-if="this.inviteComplain == 1">
                        <img src="@/assets/icon/Ok.png" alt="">
                        <p style="font-weight: 500; margin-top: 15px;" class="success">Thành công</p>
                    </div>
                    <div v-else>
                        <img src="@/assets/front-end/icon/Group 831.png" alt="">
                        <p style="font-weight: 500; margin-top: 15px; color: #EE3439;">{{ this.message }}</p>
                    </div>
                </div>
            </b-modal>
            <b-modal id="success_pay" ref="success_pay" centered hide-footer hide-header>
                <div class="success_pay text-center" v-if="message.result_status == 1">
                    <img src="@/assets/icon/Ok.png" alt="">
                    <p style="color: #00B569; font-weight: 500; font-size: 23.33px;">Trả giá thành công</p>
                </div>
                <div class="warning text-center" v-else>
                    <img src="@/assets/icon/close.png" alt="">
                    <p style="color: #EE3439; font-weight: 500; font-size: 23.33px;">{{ this.message.message }}</p>
                </div>
            </b-modal>
            <!-- <modalStart ref="modalStart" :dataProp=startModal
                v-if="timeCountStart == 0 && this.startModal && room_status == 1" /> -->
            <modalEnd ref="endRoom" />
            <endSuccessAuction ref="endAuction" :asset="asset" :resultAuction=resultAuction />
            <!-- <modalWel ref="modalWel" :room_status="room_status" :approve_status="approve_status"
                :timeCountStart="timeCountStart" /> -->
            <modalComplain ref="modalComplain" @complain="complain"/>
            <modalReport ref="modalReport" />
        </b-container>
    </div>
</template>
<script>
// import modalStart from "@/pages/web-view/auctionRoom/modal/modalStart.vue";
import modalEnd from "@/pages/web-view/auctionRoom/modal/modalEnd.vue";
// import modalWel from "./modal/welcome";
import modalComplain from "./modal/complain.vue";
import modalReport from "./modal/report.vue";
import endSuccessAuction from "./modal/end_success_auction.vue";
import Tab from "./tab_product_detail.vue";
import slideImage from "../components/slideImage.vue"
import returnPrice from "./modal/return_price"
import confirmPay from "./modal/confirm_pay"
// import VNnum2words from 'vn-num2words';
import number2vn from 'number2vn';
import router from "@/router/index";

export default {
    components: {
        slideImage,
        Tab, 
        modalReport,
        returnPrice,
        // modalWel,
        // modalStart,
        modalEnd,
        endSuccessAuction,
        modalComplain,
    },
    data() {
        return {
            items: [
                {
                    text: 'Trang chủ',
                    to: '/',
                },
                {
                    text: 'Phòng đấu giá',
                    active: true
                },
            ],
            inviteComplain:'',
            timeLeft: 0,
            isShow: true,
            inputData: '',
            return_price: '',
            highest_price: 0,
            my_highest_price: 0,
            error_price: true,
            text_error: '',
            dataConfirm: {
                price: ''
            },
            currentTime: '',
            asset_id: 0,
            asset: {
                autherity_role: 0
            },
            key: localStorage.getItem('member_key'),
            token: JSON.parse(localStorage.getItem('userInfo')).accessToken,
            member_code: JSON.parse(localStorage.getItem('userInfo')).member_code,
            room_status: '',
            socket: null,
            approve_status: 0,
            timeCount: null,
            timeCountStart: 0,
            auction_start: false,
            showForTime: true,
            all_bid_client: [],
            countMemberJoined: 0,
            all_my_bid: [],
            all_bid: [],
            message: {
                result_status: '',
                message: ''
            },
            startModal: false,
            showModal: true,
            resultAuction: {
                destroyRoom: false,
                auction_status: "",
                note: ""
            },
            bid_status: 1,
            dataCheckRole: [],
            checkdata: '',
            countBid: '',
            dataHighest: {
                highest_bid: {
                    member_id: {}
                }
            },
            register_room_status: 1,
            isClient: false,
            member_id: JSON.parse(localStorage.getItem('userInfo')).id,
            server_time: Date.now(),
            routerRedirect: false,
        }
    },
    computed: {
        countdownStart() {
            return this.timeCountStart ? this.formatCountdown(this.timeCountStart, 'hour') : '00 giờ      00 phút      00 giây';
        },
        countdownEnd() {
            return this.timeCount ? this.formatCountdown(this.timeCount, 'hour') : '00 giờ      00 phút      00 giây';
        },
        numberInWords() {
            return number2vn(this.inputData.replace(/[^\d]/g, ''));
        },
        formattedValue() {
            const cleanedValue = parseInt(this.inputData);
            this.inputData = cleanedValue.toLocaleString("vi-VN");
            if (this.inputData === "NaN") {
                this.inputData = ''
            }
            return this.inputData
        },
    },
    mounted() {
        // this.getData();
        this.runMethods();
        // document.addEventListener('keydown', this.handleKeyDown);
        // window.addEventListener('beforeunload', this.handleBeforeUnload);
        setInterval(() => {
            this.currentTime = this.$moment().format('LTS');
        });
        window.addEventListener("beforeunload", this.handleBeforeUnload);
        // this.$refs.modalWel.show()
    },
    // beforeUpdate() {
        
    //     window.addEventListener('beforeunload', function (event) {
    //         event.preventDefault();
    //     });
    // },
    beforeDestroy() {
        console.log('disconnect...');
        this.routerRedirect = true
        this.socket.disconnect()
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    },

    watch: {
        "$route.path": function (url) {
            console.log('Route is updating...',url);
        }
    },
    methods: {
        handleBeforeUnload(event) {            
            // event.preventDefault();
            // event.returnValue = ''
        },
        // handleKeyDown(event) {
        //     // Kiểm tra nếu mã phím là 116 (F5), chặn sự kiện mặc định
        //     if (event.keyCode === 116) {
        //         event.preventDefault();
        //     }
        // },
        modalConfirmPay() {
            event.preventDefault();
            let input = parseInt(this.inputData.replace(/[^\d]/g, ''))
            if (!input) {
                this.text_error = 'Vui lòng nhập giá';
            } 
            // else if ((input-this.asset.price) % this.asset.step_price != 0) {
            //     this.text_error = 'Giá trả của bạn không đúng đề nghị trả giá lại';
            // } else if (input - this.highest_price > 0 && this.asset.type == 2) {
            //     this.text_error = 'Giá trả của bạn không đúng đề nghị trả giá lại';
            // } else if (input - this.highest_price < 0 && this.asset.type == 1) {
            //     this.text_error = 'Giá trả của bạn không đúng đề nghị trả giá lại';
            // } 
            else {
                this.text_error = ''
                if (this.$refs.confirmPay) {
                    this.$refs.confirmPay.show()
                }
            }
        },
        bidPrice() {
            this.dataConfirm.price = parseInt(this.inputData.replace(/[^\d]/g, ''));
            this.socket.emit('bid', { price: this.dataConfirm.price })
            this.socket.on('response_bid', (res) => {
                this.message.result_status = parseInt(res.result_status)
                this.message.message = res.message
                if (this.$refs.confirmPay) {
                    this.$refs.confirmPay.hide()
                }
                if (this.$refs.success_pay) {
                    this.$refs.success_pay.show()
                }
                if (res.result_status) {
                    this.my_highest_price = parseInt(this.dataConfirm.price);
                }
                setTimeout(() => {
                    if (this.$refs.success_pay) {
                        this.$refs.success_pay.hide()
                    }
                }, 3000);
            })
        },
        hideModal() {
            this.$router.push('/account/auction')
        },
        formateTime(time) {
            return new Date(time).toLocaleString('en-GB', { timeZone: 'UTC' }).replace(',', "")
        },
        formateTimeGuest(time) {
            return this.$moment.utc(time).format('HH:mm:ss (DD/MM/YYYY)')
        },
        formatInput(event) {
            this.inputData = this.formatNumber(event.target.value)
        },
        modalReturn() {
            if (this.$refs['child']) {
                this.$refs['child'].show()
            }
        },
        formatCountdown(time) {
            // const duration = this.$moment.duration(time, 'seconds');
            // const hours = Math.floor(duration.asHours());
            // const minutes = duration.minutes();
            // const seconds = Math.floor(time % 60);
            const hours = parseInt(time / 3600); // Tính số giờ
            const minutes = parseInt((time % 3600) / 60); // Tính số phút
            const seconds =parseInt(time % 60); // Tính số giây còn lại

           return (
                (hours < 10 ? '0' + hours : hours) + ' giờ       ' +
                (minutes < 10 ? '0' + minutes : minutes) + ' phút       ' +
                (seconds < 10 ? '0' + seconds : seconds) + ' giây '
            );; // Trả về chuỗi định dạng
        },
        sumStep() {
            let input = parseInt(this.inputData.replace(/[^\d]/g, ''))
            if (!input && !this.highest_price) {
                input = input + this.asset.step_price
            } else if (this.highest_price && this.asset.type == 2) {
                if (input == 0) {
                    input = this.highest_price
                }
                input = parseInt(input) + this.asset.step_price
            } else {
                input = parseInt(input) + this.asset.step_price
            }
            this.inputData = input.toString()
        },
        showReport(auction_id, asset_id) {
            if (this.$refs.modalReport) {
                this.$refs.modalReport.show(auction_id, asset_id)
            }
        },
        getReport() {
            const links = [
                this.$report + "/report/exportPDF?path=template/bien_ban_dau_gia&asset_id=" + this.decode(this.$route.params.id),
                this.$report + "/report/exportPDF?path=template/danh_sach_diem_danh&asset_id=" + this.decode(this.$route.params.id),
                this.$report + "/report/exportPDF?path=template/dien_bien_tra_gia&asset_id=" + this.decode(this.$route.params.id),
                this.$report + '/report/exportPDF?path=template/trich_xuat_dau_gia&asset_id=' + this.decode(this.$route.params.id),
                this.$report + '/report/exportPDF?path=template/danh_sach_dang_ky&asset_id=' + this.decode(this.$route.params.id)
            ];

            links.forEach((link) => {
                window.open(link, '_blank');
            });
        },
        subStep() {
            let input = parseInt(this.inputData.replace(/[^\d]/g, ''))
            if (!input && !this.highest_price) {
                input = this.asset.price
                this.highest_price = this.asset.price
            } else if (this.highest_price && this.asset.type == 1) {
                input = this.highest_price
            } else {
                input = parseInt(input) - this.asset.step_price
            }
            this.inputData = input.toString()
        },
        withdrawPrice() {
            this.socket.emit('withdraw_price', {})
            this.socket.on('response_withdraw_price', (res) => {
            })
        },
        async runMethods() {
            try {
                await this.getData();
            } catch (error) { }
        },
        async getAssetDetail(){
            await this.$axios('/asset/getAssetDetail?asset_id=' + this.asset_id, {
                headers: {
                    'x-access-token': this.token
                }
            }).then((res) => {
                res.data.auction_fee = parseInt(res.data.auction_fee)
                res.data.price = parseInt(res.data.price)
                res.data.register_auction_fee = parseInt(res.data.register_auction_fee)
                res.data.step_price = parseInt(res.data.step_price)
                this.asset = res.data
            })
        },
        async getData() {
            this.asset_id = this.decode(this.$route.params.id)
            await this.$axios('/asset/getAssetDetail?asset_id=' + this.asset_id, {
                headers: {
                    'x-access-token': this.token
                }
            }).then((res) => {
                res.data.auction_fee = parseInt(res.data.auction_fee)
                res.data.price = parseInt(res.data.price)
                res.data.register_auction_fee = parseInt(res.data.register_auction_fee)
                res.data.step_price = parseInt(res.data.step_price)
                this.asset = res.data
                this.checkClientRole()
            })
            this.socket = this.$socket(this.asset.auction_key, this.key, this.token)
            this.socket.connect()
            this.socket.on("response_approveMember", (res) => {
                this.approve_status = res.result_status
            })
            this.socket.on("register_room_status", (res) => {
                this.register_room_status = res.register_room_status
                if (this.register_room_status == 2) {
                    this.approve_status = 1
                }
                if (this.register_room_status == 3) {
                    this.$router.back()
                }
                if (this.register_room_status == 4) {
                    this.bid_status = 0
                }
                if (this.register_room_status == 5) {
                    if (this.$refs['notifi-modal']) {
                        this.$refs['notifi-modal'].show()
                    }
                }
            })
            this.socket.on('room_status', (res) => {
                this.room_status = res.room_status
                if (this.room_status == 3) {
                    this.$nextTick(() => {
                        if (this.$refs['endRoom']) {
                            this.$refs['endRoom'].show()
                        }
                    })
                }
            })
            this.socket.on('kick', (res) => {
                if (this.$refs['notifi-modal']) {
                    this.$refs['notifi-modal'].show()
                }
                this.room_status = "kick"
            })
            this.socket.on('response_withdraw_price', (res) => {
                this.return_price = res.result_status
            })
            this.socket.on('autherity_role', (res) => {
                this.asset.autherity_role = res.autherity_role
            })
            this.socket.on('highest_bid', (res) => {
                this.highest_price = res.highest_bid.price
                if (this.asset.type != 2 && this.asset.type != 5) {
                    this.inputData = res.highest_bid.price
                }
                this.dataHighest = res
            })
            this.socket.on('countBid', (res) => {
                this.countBid = res.countBid
            })
            this.socket.on('countdown', (res) => {
                this.timeCount = res.countdown
                if (res.countdown > 0) {
                    this.showForTime = false
                    this.auction_start = true
                } else {
                    this.timeCount = 0
                }
            })
            this.socket.on('countdownStart', (res) => {
                if (res.countdownStart == 0) {
                    this.startModal = true
                }
                this.timeCountStart = res.countdownStart
                if (res.countdownStart > 0) {
                    this.showForTime = true
                } else {
                    this.timeCountStart = 0
                }
            })
            this.socket.on('server_time', (res) =>{
                this.server_time = res.server_time
            })
            this.socket.on('listMemberJoined', res => {
                this.listMemberJoined = res.listMember
                //lấy số người đang tham gia đấu giá (online)
                this.countMemberJoined = 0
              
                for (const element of res.listMember) {
                    if (element.is_online == 1) {
                        this.countMemberJoined ++
                    }
                }
            })
            this.socket.on("top5AllBid", (res) => {
                this.top5AllBid = res.top5AllBid
            })
            this.socket.on('all_bid', (res) => {
                this.all_bid = res.all_bid
            })
            this.socket.on("all_bid_client", (res) => {
                this.all_bid_client = res.all_bid_client
            })
            this.socket.on("countMemberJoined", (res) => {
                // this.countMemberJoined = res.countMember
            })
            this.socket.on("all_my_bid", (res) => {
                if (res.all_my_bid[0]) {
                    this.my_highest_price = parseInt(res.all_my_bid[0].price)
                }
                this.all_my_bid = res.all_my_bid
                this.all_my_bid.price = this.my_highest_price
                this.all_my_bid.forEach(bid => {
                    if (bid.action == 2) {
                        this.return_price = true
                    }
                });
            })
            this.socket.on('destroyRoom', (res) => {
                this.resultAuction = res
                this.resultAuction.destroyRoom = true
                this.getAssetDetail()
                if (this.$refs.endAuction) {
                    this.$refs.endAuction.show()
                }
            })
            this.socket.on('confirmRoom', (res) => {
                this.resultAuction = res
                this.getAssetDetail()
                if (this.$refs.endAuction) {
                    this.$refs.endAuction.show()
                }
            })
            this.socket.on('bid_status', (res) => {
                this.bid_status = res.bid_status
            })
            this.socket.on('disconnect', (res) => {
                if (this.room_status == "kick") {
                    if (this.$refs['notifi-modal']) {
                        this.$refs['notifi-modal'].show()
                    }
                }
                else if(!this.routerRedirect){
                    this.socket.disconnect()
                    this.$router.push('/account/auction')
                }

            })
            this.socket.on('checkLogout', res => {
                 if (res.logout_status == 1) {
                    this.socket.disconnect()
                    this.logoutMember()
                    this.$toast.warning("Tài khoản của bạn bị đăng nhập ở nơi khác!")
                }
            })
            this.socket.allBid()
            let startTime = new Date(this.asset.end_time).getTime();
            let now = new Date().getTime();
            let countdown = startTime - now
            if (countdown !== 0 && countdown > 0) {
                this.timeLeft = parseInt(countdown);
                this.startCountdown(parseInt(countdown));
            }
            
        },
        async checkClientRole() {
            await this.$axios.post('/member/checkClientRole', {
                member_id: JSON.parse(localStorage.getItem('userInfo')).id,
                auction_id: this.asset.auction_id
            }, {
                headers: {
                    'x-access-token': this.token
                }
            }).then((res) => {
                this.dataCheckRole = res.data.client_role
                if (res.data.client_role.role.length > 0) {
                    this.isClient = true
                }
            }).catch((err) => {
                this.dataCheckRole = []
            })
        },
        showComplain(auction_id, asset_id) {
            if (this.$refs.modalComplain) {
                this.$refs.modalComplain.show(auction_id, asset_id)
            }
        },
        complain(data) {
            this.socket.emit('complain', data)
            this.socket.on('complain_status', res => {
                if (res.result_status == 1) {
                    this.message = res.message
                    this.inviteComplain = 1
                    if (this.$refs.statusComplain) {
                        this.$refs.statusComplain.show()
                    }
                    if (this.$refs.modalComplain) {
                        this.$refs.modalComplain.show(auction_id, asset_id)
                    }
                } else {
                    this.message = "Gửi khiếu nại không thành công. Vui lòng thử lại!"
                    this.inviteComplain = 0
                    if (this.$refs.statusComplain) {
                        this.$refs.statusComplain.show()
                    }
                }
            })
        }
    },
}
</script>
<style lang="css" scoped>
.disable {
    filter: brightness(2.5);
}

.in-room {
    font-size: 20px;
    font-weight: 500;
}

.notifi-modal {
    text-align: center;
    color: #444444;
}
.status_au {
    box-shadow: 0px 2px 10px 0px #0000001A;
    border-radius: 40px;
    text-align: center;
    position: relative;
    margin-bottom: 15px;
}

.status_au::before {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    width: 59px;
    height: 59px;
    left: 3%;
    top: 23%;
    background-image: url('@/assets/icon/Auction_modal.png');
    background-size: cover;
}

.status_au p {
    color: #515151;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0;
}

.status_au p:last-child {
    font-family: Libre Bodoni;
    font-weight: 700;
    font-size: 26px;
    padding-bottom: 10px;
}

.status_au--success::before {
    background-image: url('@/assets/icon/Ok.png');
}

.status_au--fail::before {
    background-image: url('@/assets/front-end/icon/Group\ 831.png');
    width: 52px;
    height: 51px;
}

.status_au--success p:last-child {
    color: #00B569;
}

.status_au--fail p:last-child {
    color: #EE3439;
}

.btn-report {
    border-color: #EE3439;
    color: #EE3439;
    margin-left: 15px;
}

.image-status {
    position: absolute;
    z-index: 90;
    top: 5%;
    left: 5%;
}

.btn-complain {
    background: none;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #515151;
    border-radius: 16px;
    width: 100%;
    padding: 10px 0;
    color: #515151;
}

.happenning {
    border: 1px solid #EE3439;
    background-color: #FFFFFF !important;
}

.end {
    border: unset;
}

.notifi-modal__ttl {
    font-size: 20.96px;
    line-height: 37.73px;
    font-weight: 500;
}

.notifi-modal button {
    background: #EE3439;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14.47px;
    width: 165px;
    height: 42px;
    font-family: Roboto;
    border-radius: 9.65px
}

.notifi-modal button:hover {
    background: #EE3439;
}

.auction_room {
    color: #515151;
    background-image: url('@/assets/images/logo_moreinfo.png');
    background-repeat: no-repeat;
    background-position: top;
}

.info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.info p {
    min-width: 330px;
    margin: 0;
}

.info span {
    font-weight: 500;
    max-width: 50vw;
}

.info span.highlight {
    color: #EE3439;
    font-size: 24px;
}

.info--status {
    flex-wrap: wrap;
}

.info--status span {
    position: relative;
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
    color: #00B569;
    padding-left: 17px;
    margin-right: 16px;
}

.info--status span::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-image: url(@/assets/icon/Ok.png);
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 28%;
}

span.auction--success {
    color: #00B569;
    font-size: 20px;
    font-weight: 500;
}

.btn-link {
    border: 1px solid #515151;
    color: #515151;
    padding: 12px 32px;
    text-decoration: none;
    border-radius: 16px;
    font-weight: 500;
    display: inline-block;
}

.auction_room .more-info__ttl {
    font-weight: 500;
    font-size: 22px;
    line-height: 39.6px;
}

.auction_room .more-info__ttl label {
    display: -webkit-box;
}

.auction_room .more-info li {
    padding: 5px 0;
}

.auction_room .list-info li {
    position: relative;
    margin-left: 33px;
}

.auction_room .list-info li::before {
    content: " ";
    background: url('@/assets/icon/Note.png');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    position: absolute;
    left: -33px;
    top: 3px;
}

.auction_room .list-info li a {
    text-decoration: none;
    color: #515151;
}

.list-info,
.list-info-cus {
    padding-left: 5px;
}

.auction_room .list-info-cus img {
    padding-right: 14px;
}

.auction_room .text-red {
    color: #EE3439;
    font-size: 16px;
    font-weight: 500;
    line-height: 28.8px;
    font-family: Roboto;
}

.show-time {
    position: relative;
    text-align: center;
    font-weight: 500;
    color: #EE3439;
    background-color: #f7f7f7;
    box-shadow: 2px 2px 2px 0px #00000040;
    border-radius: 40px;
    margin-bottom: 5px;
}

.show-time::before {
    content: "";
    position: absolute;
    background-image: url('@/assets/icon/clock.png');
    width: 75px;
    height: 61px;
    background-repeat: no-repeat;
    left: 0;
    top: 20%;
}

.show-time p:first-child {
    font-size: 22px;
}

.show-time p:last-child {
    font-size: 30px;
    font-family: Libre Bodoni;
    white-space: break-spaces;
}

.show-time p:nth-child(2) {
    font-size: 18px;
}

.show-time p {
    margin: 0;
}

/* ---Auction Box------- */
.auction-box {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 9px 17px 16px 14px;
    margin-top: 10px;
}

.auction-box--return {
    border: none;
    background: #EEEEEE;
}

.auction-box p {
    margin-bottom: 10px;
}

.auction-box .auction-box_txt {
    min-width: 290px;
    display: inline-block;
}

.auction-box .btn-sum,
.btn-sub {
    border: none;
    padding: 0;
    background: none;
}

.btn-sub:active {
    background: none;
}

.input-price {
    width: 468px;
    height: 52px;
    border-radius: 39px;
    border: 1px solid #9c9c9c;
    color: #515151;
    padding-left: 17px;
    font-size: 25px;
    font-weight: 500;
    caret-color: #515151;
}

.input-price:focus-visible {
    outline: none;
}

::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #BBBBBB;
}

.num-format {
    margin: 0;
    position: absolute;
    top: 10px;
    left: 14%;
    font-size: 22px;
    font-weight: 500;
    color: #515151;
}

.form-group input:focus {
    box-shadow: none;
}

.num-to-text {
    padding-left: 75px;
    line-height: 28.8px;
    text-transform: capitalize;
    text-align: justify;
}

.auction-btn {
    background: #EE3439;
    border: none;
    width: 431px;
    height: 52px;
    border-radius: 16px;
    font-size: 22px;
    font-weight: 500;
}

.auction-btn:active {
    background: #EE3439;
}

.btn-cancel:active {
    background: none;
    color: #EE3439;
    border-color: #EE3439;
}

.btn-outline-primary {
    background: none;
    color: #EE3439;
    border-color: #EE3439;
    min-height: 52px;
    margin-right: 0.5rem;
    font-size: 16px;
    font-weight: 500;
    border-radius: 16px;
}



.room_ttl {
    color: #515151;
    font-family: Libre Bodoni;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}

.time p {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    font-family: Libre Bodoni;
    color: #DB282C;
    line-height: 96%;
}

.time p:last-child {
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto;
    margin: 0;
}

/* ----- */
.guest-show {
    box-shadow: 0px 0px 10px 0px #0000001A;
    border-radius: 16px;
    padding: 9px 11px 17px 14px;
}

.guest-show span {
    color: #EE3439;
    font-weight: 500;
}

.group-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.group-btn .btn-link {
    border: 1px solid #515151;
    color: #515151;
    text-decoration: none;
    border-radius: 16px;
    font-weight: 500;
    height: 46px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 1366px) {
    .info {
        margin-bottom: 10px;
    }
}

@media (max-width: 1024px) {
    .info p {
        min-width: 240px;
    }

    .show-time::before {
        display: none;
    }

    .btn-link {
        padding: 12px 16px;
    }

    .num-to-text {
        padding-left: 25px;
    }

    .input-price {
        width: 324px;
    }

    .num-format {
        padding-left: 9px;
    }

    .auction-btn {
        width: 258px;
    }

    .auction-box .auction-box_txt {
        min-width: 190px;
    }
}
@media (max-width:992px) {
    .num-format{
        left: 35%;
    }
}
@media (max-width: 768px) {
    .show-time::before {
        display: none;
    }

    .auction_room .title {
        font-size: 20px;
    }

    .auction_room .more-info,
    .auction_room .text-red {
        font-size: 14px;
    }

    .auction_room .more-info__ttl {
        font-size: 16px;
    }

    .info p {
        min-width: 50vw;
    }

    .info span.highlight {
        font-size: 16px;
    }

    .btn-link {
        width: 100%;
    }

    .file .col-md-4 {
        width: 50%;
    }

    .auction-box .auction-box_txt {
        min-width: 290px;
    }

    .auction-btn {
        width: 490px;
    }

    .input-price {
        width: 495px;
    }
}

@media (max-width: 425px) {
    .file .col-md-4 {
        width: 100%;
        }
    .num-format{
        left: 25%;
    }
    .info span.highlight {
        font-size: 18px;
    }

    .info p {
        min-width: 210px;
    }

    .info {
        font-size: 14px;
    }

    .auction-box form button {
        width: 100%;
    }

    .btn-cancel {
        margin-bottom: 15px;
    }

    .auction-box {
        font-size: 14px;
    }

    .auction-box .auction-box_txt {
        min-width: 170px;
    }

    .auction-box .num-red {
        font-size: 16px !important;
    }

    .input-price {
        width: 280px;
    }

    .info--status {
        justify-content: space-between;
    }

    .show-time::before {
        display: none;
    }

    .show-time p:first-child {
        font-size: 18px;
    }

    .show-time p:nth-child(2) {
        font-size: 16px;
    }

    .show-time p:last-child {
        font-size: 22px;
    }

    .auction_room .title {
        font-size: 18px;
    }
}

@media (max-width: 375px) {
    .input-price {
        width: 240px;
    }

    .info span.highlight {
        font-size: 16px;
    }
}

@media (max-width: 320px) {
    .info p {
        min-width: 200px;
    }

    .info span.highlight,

    .info,
    .auction-box,
    .auction_room .more-info,
    .auction_room .text-red {
        font-size: 14px;
    }

    .price-at {
        min-width: 60px !important;
    }

    .auction-box .auction-box_txt {
        min-width: 120px;
    }

    .auction-box .num-red {
        font-size: 14px !important;
    }

    .input-price {
        font-size: 21px;
        width: 205px;
    }

    .num-format {
        font-size: 19px;
    }

    span.auction--success {
        font-size: 16px;
    }

    .time p:last-child {
        display: none;
    }
}
</style>

<style lang="css">
.auction-box .form-group>div {
    display: flex;
    margin-top: 13px;
    justify-content: space-around;
}
.auction_room .cz-preview-item.active{
    min-height: unset;
    height: auto;
}
@media (min-width: 992px) {
    .auction_room .cz-preview-item.active{
        min-height: 415px;
    }
}
</style>