<template>
    <div :class="{'overlay' : isLoading}">
        <h5>Danh sách tài khoản khách mời</h5>
        <b-row>
            <b-col cols="lg-5">
                <div class="list_employ" style="cursor: pointer;">
                    <div class="d-flex justify-content-between mb-3">
                        <p style="margin: 0; font-weight: 500; font-size: 20px;">Tài khoản khách mời</p>
                        <button class="btn-add-emp" @click="createBlank">+ Thêm tài khoản mới</button>
                    </div>
                    <div class="d-flex flex-wrap">
                        <div class="d-flex">
                            <b-form class="search-form">
                                <b-form-group>
                                    <b-form-input v-model="keySearch" type="text" placeholder="Tìm kiếm"></b-form-input>
                                </b-form-group>
                                <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                            </b-form>
                            <!-- <button><img src="@/assets/admin/icon/Adjust.png" alt=""></button> -->
                        </div>
                    </div>
                    <div v-if="keySearch == ''">
                        <div class="employ-item" v-for="(member, index) in dataMember" :key="index">
                            <div class="info-employ align-items-center d-flex" @click="showDetail(member)">
                                <img :src="$storage + member.avatar" alt="" style="max-width: 70px; height: 70px; border-radius: 36px;" v-if="member.avatar" @error="handleError">
                                <div class="ml-3">
                                    <p style="font-size: 18px; font-weight: 500;">{{ member.email }}</p>
                                    <p>Mã thành viên: <span>{{ member.member_code }}</span></p>
                                </div>
                            </div>
                            <div>
                                <button @click="deleteMember(member.member_id)"><img src="@/assets/admin/icon/Trash.png" alt=""></button>
                            </div>
                        </div>
                        <Paginate :page=page @set-page="setPage" style="margin-top: 10px;"/>
                    </div>
                    <div v-else>
                        <div class="employ-item" v-for="(member, index) in filteredItems" :key="index">
                            <div class="info-employ align-items-center d-flex" @click="showDetail(member)">
                                <img :src="$storage + member.avatar" alt="" style="max-width: 70px; height: 70px; border-radius: 36px;" v-if="member.avatar" @error="handleError">
                                <div class="ml-3">
                                    <p style="font-size: 18px; font-weight: 500;">{{ member.email }}</p>
                                    <p>Mã thành viên: <span>{{ member.member_code }}</span></p>
                                </div>
                            </div>
                            <div>
                                <button @click="deleteMember(member.member_id)"><img src="@/assets/admin/icon/Trash.png" alt=""></button>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col cols="lg-7" v-if="this.show_detail == true">
                <div class="detail_employ">
                    <div class="d-flex align-items-center mb-3 justify-content-between">
                        <p style="font-size: 20px; font-weight: 500;" class="mb-0">Thông tin tài khoản</p>
                        <button class="btn-reset" @click="resetPass(dataDetail.member_id)" v-if="dataDetail.member_email" :disabled="isLoading">Reset password to default</button>
                    </div>
                    <b-row>
                        <b-col cols="lg-2">
                            <div class="ava-member text-center">
                                <img :src="$storage + dataDetail.avatar" alt="" @error="handleError">
                            </div>
                        </b-col>
                        <b-col cols="lg-9">
                            <b-row class="info-detail align-items-center">
                                <b-col cols="md-5"><p>Mã khách mời:</p></b-col>
                                <b-col cols="md-7"><p>{{ dataDetail.member_code }}</p></b-col>
                                <b-col cols="md-5"><p>Tên đăng nhập hệ thống:</p></b-col>
                                <b-col cols="md-7"><p>{{ dataDetail.email }}</p></b-col>
                                <b-col cols="md-5"><p>Pass đăng nhập:</p></b-col>
                                <b-col cols="md-7"><p>*******</p></b-col>
                                <b-col cols="md-5"><p>Email được gán:</p></b-col>
                                <b-col cols="md-7">
                                    <p v-if="dataDetail.member_email">{{ dataDetail.member_email }}</p>
                                    <p v-else>Không</p>
                                </b-col>
                                <b-col cols="md-5"><p>Cuộc đấu giá tham gia:</p></b-col>
                                <b-col cols="md-7">
                                    <p v-if="dataDetail.auction_code">{{ dataDetail.auction_code }}</p>
                                    <p v-else>Không</p>
                                </b-col>
                                <b-col cols="md-5"><p>Chức năng: </p></b-col>
                                <b-col cols="md-7">
                                    <p v-if="dataDetail.client_role">{{ dataDetail.client_role }}</p>
                                    <p v-else>Không</p>
                                </b-col>
                                <b-col cols="md-5"><p>Phân quyền: </p></b-col>
                                <b-col cols="md-7">
                                    <div class="d-flex justify-content-between list-role">
                                        <p :class="{'active' : dataDetail.role.includes(1)}">Khiếu nại</p>
                                        <p :class="{'active' : dataDetail.role.includes(2)}">Báo cáo</p>
                                        <p :class="{'active' : dataDetail.role.includes(3)}">Xuất file</p>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
        <Delete ref="delete" :del_id="del_id" @handleCloseModal="listenDialog"/>
        <div class="loader" v-if="isLoading"></div>
    </div>
</template>
<script>
import search from '../components/search_form.vue'
import Paginate from '../../web-view/components/paginate.vue'
import Delete from "./modal/delete.vue"
export default {
    components: {
        search,
        Paginate,
        Delete
    },
    data() {
        return {
            keySearch: '',
            page: {
                totalData: 0,
                totalPage: 0,
                currentPage: this.$route.query.page || 1,
                perPage: 10,
                classPrev: 'd-none',
                classNext: 'd-none'
            },
            dataMember: [],
            dataAllMember: [],
            show_detail: false,
            token: '',
            dataDetail: [],
            del_id: '',
            isLoading: false
        }
    },
    computed: {
        filteredItems() {
            return this.dataAllMember.filter(member => member.email.toLowerCase().includes(this.keySearch.toLowerCase()));
        }
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
        this.getListBlank()
        this.getAllListBlank()
    },
    methods: {
        editMember() {
            this.status_edit = !this.status_edit
        },
        showDetail(data) {
            this.dataDetail = data
            this.show_detail = true
        },
        deleteMember (id) {
            this.del_id = id
            this.$refs.delete.show();
        },
        setPage(val) {
            this.page.currentPage = val;
            this.getListBlank();
        },
        listenDialog(isClose = false) {
            if(!isClose) {
                this.getListBlank()
                this.show_detail = false
            }
        },
        async getListBlank() {
            const data = {
                page: this.page.currentPage,
                per_page: this.page.perPage
            };
            await this.$axios.get('/member/getListBlankMember',{
                params: {
                    page: data.page,
                    per_page: data.per_page,
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res=> {
                this.page.totalData = res.data.pagination.total_element
                this.page.totalPage = res.data.pagination.total_page
                this.dataMember = res.data.result;
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if(err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                } else {
                    this.$toast.error('Vui lòng thử lại!')
                }
            })
        },
        async getAllListBlank() {
            const res = await this.$axios.get('/member/getListBlankMember',{
                params: {
                    page: '1',
                    per_page: '10000',
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.dataAllMember = res.data.result;
            }
        },
        async createBlank() {
            await this.$axios.post('/auction/createBlankMember','',{
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.$toast.success("Thêm tài khoản khách mời thành công")
                this.getListBlank()
            }).catch(res => {
                this.$toast.error("Thêm tài khoản khách không mời thành công")
            })
        },
        async resetPass(id) {
            this.isLoading = true
            const data = {
                "member_id": id
            }
            await this.$axios.put('/member/resetPassToDefault',data, {
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.$toast.success(res.data.message)
                this.isLoading = false
            }).catch(res => {
                this.$toast.error(res.data.message)
                this.isLoading = false
            })
        }
    }
}
</script>
<style lang="css" scoped>
    button {
        background: none;
        border: none;
    }

    .list_employ, .detail_employ {
        background: #ffffff;
        box-shadow: 2px 2px 6px 0px #00000026;
        padding: 10px 18px 15px 14px;
        color: #515151;
    }
    .list_employ {
        border-radius: 15px;
    }
    .detail_employ {
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .btn-add-emp {
        color: #EE3439;
        font-weight: 500;
    }

    .list_employ .button-list {
        width: 185px;
        height: 31px;
        border: 1px solid #515151;
        border-radius: 20px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
    }

    .employ-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #B4B4B4;
        border-radius: 10px;
        padding: 0 26px 0 0;
        border-top-left-radius: 56px;
        border-bottom-left-radius: 56px;
        margin-top: 15px;
    }

    .employ-item button img {
        width: 15px;
        height: 15px;
        filter: grayscale(1);
    }
    
    .info-employ {
        flex-basis: 100%;
        align-items: center;
    }

    .info-employ p {
        margin: 0;
        font-size: 13px;
    }
    .info-employ span {
        font-size: 14px;
        font-weight: 500;
    }

    .ava-member img {
        max-width: 100%;
        height: auto;
        border-radius: 130px;
        margin-bottom: 10px;
    }
    .ava-member p {
        margin: 0;
    }
    .info-detail p {
        margin-bottom: 10px;
        font-size: 14px;
    }
    .info-detail .col-md-7 p {
        font-size: 15px;
        font-weight: 500;
    }

    /* ------ */

    label {
        font-size: 14px;
    }

    .btn-reset {
        color: #EE3439;
        border: 1px solid #EE3439;
        border-radius: 20px;
        padding: 4px 14px;
        font-size: 13px;
    }

    .list-role p {
        position: relative;
        padding-left: 23px;
        display: flex;
        align-items: center;
    }
    .list-role p::before  {
        content: '';
        background: #FFFFFF;
        border: 1px solid #B4B4B4;
        width: 16px;
        height: 16px;
        position: absolute;
        border-radius: 5px;
        left: 0;
    }

    .list-role p.active::before {
        background: #515151;
        border: none;
    }

    @media (max-width: 992px) {
        .detail_employ, .info-detail {
            margin-top: 20px;
        }
    }
</style>

<style>
    .list_employ .search-form {
        width: 365px;
    }
</style>