<template>
    <div>
        <b-container class="">
            <b-breadcrumb :items="items"></b-breadcrumb>
            <b-row>
                <b-col>
                    <h5 class="more_ttl">{{infomation[param.infomation].title }}</h5>
                    <div class="content-details" v-html="infomation[param.infomation].value"></div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>

export default {
    data() {
        return {
            items: [
                {
                    text: 'Trang chủ',
                    to: '/',
                },
                {
                    text: 'Hồ sơ',
                    active: true
                },
            ],
            param: this.$route.params,
            infomation:{},
        }
    },
    created() {
        this.infomation = this.getInformation()
    },
    watch: {
        "$route.params": function (url) {
            this.param = url
        }
    },
}
</script>
<style lang="css" >
.breadcrumb {
    margin-top: 20px;
}
figure.image{
    text-align: center;
}
.content-details img{
    text-align: center;
}
.breadcrumb-item a {
    font-size: 16px;
    font-family: Roboto;
    line-height: 28.8px;
    color: #9B9B9B;
    text-decoration: none;
}

.breadcrumb-item span {
    font-size: 16px;
    font-family: Roboto;
    line-height: 28.8px;
    color: #515151;
}

.more_ttl {
    font-size: 24px;
    line-height: 30px;
    font-family: Libre Bodoni;
    color: #515151;
    padding-bottom: 16px;
    border-bottom: 1px solid #E1E1E1;
}

.more_txt {
    color: #515151;
    font-family: Roboto;
    line-height: 30.4px;
    text-align: justify;
    background-image: url('@/assets/images/logo_moreinfo.png');
    background-repeat: no-repeat;
    background-position: top;
}
</style>