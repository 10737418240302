
<template>
    <div class="container account d-xl-flex">
        <div class="col-xl-3 col-6 p-0 d-flex justify-content-end">


            <div class="px-xl-3 side-bar d-none d-xl-block">
                <accountSidebar :dataMember="dataMember" />
            </div>
            <b-sidebar class="d-flex" id="sidebar-1" shadow hide-header backdrop>
                <div class="px-3 side-bar d-block">
                    <accountSidebar :dataMember="dataMember" />
                </div>
            </b-sidebar>
            <div class="btn-open-sidebar d-xl-none">
                <b-button v-b-toggle.sidebar-1>></b-button>
            </div>
        </div>
        <div class="col-12 col-xl-9 p-0 tab-bar">
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade"
                    :class="{ 'show active': this.$router.currentRoute.path == '/account' || this.$router.currentRoute.path == '/account/banking' || this.$router.currentRoute.path == '/account/file' }"
                    id="account" role="tabpanel" aria-labelledby="account-tab">
                    <accountInformation :dataProp="dataMember" />
                </div>
                <div class="tab-pane fade"
                    :class="{ 'show active': this.$router.currentRoute.path == '/account/auction' || this.$router.currentRoute.path == '/account/auction/delivery' || this.$router.currentRoute.path == '/account/auction/finished' }"
                    id="auction" role="tabpanel" aria-labelledby="auction-tab">
                    <accountAution />
                </div>
                <div class="tab-pane fade" :class="{ 'show active': this.$router.currentRoute.path == 'account/asset' }"
                    id="asset" role="tabpanel" aria-labelledby="asset-tab">
                    <accountAsset />
                </div>
            </div>
        </div>
    </div>
</template>
<script >
import accountInformation from './accountInformation.vue'
import accountAution from './accountAution.vue'
import accountAsset from "./accountAsset.vue";
import accountSidebar from './accountSidebar.vue';
import Vue from 'vue'
import accountJs from "./account";
Vue.mixin(accountJs)
export default {
    components: {
        accountInformation,
        accountAution,
        accountAsset,
        accountSidebar
    },
    data() {
        return {
            dataMember: [],
            info: JSON.parse(localStorage.getItem('userInfo')),
        }
    },
    mounted() {
        this.getDataMember()
    },

}
</script>

<style lang="css">
.account .b-sidebar-body {
    color: #ffffff;
    font-family: Libre Bodoni;
    font-weight: 500;
    height: 100%;
    background-image: url('../../../assets/front-end/img/Group887.png');
}

#account .tab-content .tab-pane::-webkit-scrollbar {
    width: 2px;
}

#account .tab-content .tab-pane::-webkit-scrollbar-thumb {
    background: #D6B877;
}
</style>
