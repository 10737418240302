<template>
    <div>
        <b-modal id="modal-notice-register" ref="modal-notice-register" centered hide-footer hide-header content-class="modal-width"
            modal-class="modal-notice-register" @hide="hide">
            <div class="notice-modal">
                <img src="../../../assets/icon/clock.png" alt="">
                <h5 class="notice-modal__ttl">Bạn đã gửi đăng ký tham gia đấu giá</h5>
                <p>Cuộc đấu giá sẽ được hiển thị trong phần Quản lý tài khoản . <br>
                    Hãy nộp đủ hồ sơ yêu cầu và hoàn thành các khoản phí phải đóng . Chúng tôi sẽ phê duyệt và gửi thông báo đến bạn .
                </p>
            </div>
        </b-modal>
    </div>
</template>
<script>

export default {
    props:['asset_id'],
    data() {
        return {
            params: {
                file1: null,
                file2: null,
                auction_fee: false,
                register_auction_fee: false,
            },
            auction_id:''
        }
    },
    created() {
        this.userCurrent = localStorage.getItem('member');
    },
    methods: {
        show(id, auction_key) {
            this.auction_id = id
            this.auction_key = auction_key
            this.$refs['modal-notice-register'].show()
        },
        hide() {
            this.$refs['modal-notice-register'].hide()
            this.$router.push('/product-detail/'+this.encode(this.asset_id))
            this.showError = false
        }
    }
}
</script>
<style lang="css" scoped>

.notice-modal {
    text-align: center;
    color: #444444;
}
.notice-modal img{
    width: 74px;
    height: 63px;
}
.notice-modal ul {
    text-align: left;
    list-style: disc;
    font-weight: 500;
    color: #EE3439;
    width: 263px;
    margin: 0 auto 10px;
}

.notice-modal__ttl {
    font-size: 20px;
    font-weight: 500;
    margin: 0 1rem;
    color: #EE3439;
}

.notice-modal button {
    background: #EE3439;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14.47px;
    width: 165px;
    height: 42px;
    font-family: Roboto;
    border-radius: 9.65px
}

.notice-modal button:hover {
    background: #EE3439;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input {
    color: #515151;
    background: #EEEEEE;
    border-radius: 15px;
    padding: 3px 0;
    margin-left: 35px;
    width: -webkit-fill-available;
}

.custom-file-input::after {
    display: none;
}

.custom-file-input::before {
    position: absolute;
    content: '';
    background-image: url('@/assets/admin/icon/Import Pdf.png');
    width: 32px;
    height: 32px;
    left: 35px;
}</style>
<style lang="css">
.modal-notice-register .modal-width {
    width: 362px;
}
</style>