<template>
    <div>
        <nav aria-label="" class="col-12 p-0">
            <div class="nav nav-tabs nav-content justify-content-around" id="nav-tab-account" role="tablist">
                <a class="nav-link active" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                    type="button" role="tab" aria-controls="nav-profile" aria-selected="true">
                    Thông tin tài khoản cá nhân
                </a>
                <a class="nav-link" :class="{ 'active': this.$router.currentRoute.path == '/account/banking' }"
                    id="nav-bank-tab" data-bs-toggle="tab" data-bs-target="#nav-bank" type="button" role="tab"
                    aria-controls="nav-bank" aria-selected="false">
                    Thông tin tài khoản ngân hàng
                </a>
                <a class="nav-link" :class="{ 'active': this.$router.currentRoute.path == '/account/file' }"
                    id="nav-file-tab" data-bs-toggle="tab" data-bs-target="#nav-file" type="button" role="tab"
                    aria-controls="nav-file" aria-selected="false">
                    Tài liệu của bạn
                </a>
            </div>
        </nav>
        <div class="tab-content" id="nav-tab-accountContent">
            <div class="tab-pane fade pb-2 show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                <div class="col-12 pl-4 pr-2">
                    <div class="col-12 d-md-flex justify-content-between align-items-center p-2">
                        <p class="m-0">Thông tin cá nhân</p>
                        <div class="d-flex">
                            <button href="#" @click="showModal()" class="btnn btn-outline">
                                Đổi mật khẩu
                            </button>
                            <button href="#" @click="editAccount()" class="btnn btn-outline">Chỉnh sửa</button>
                        </div>
                    </div>
                    <div class="col-12 p-xl-2 mx-xl-2 row">
                        <div class="col-xl-6 row">
                            <h6 class=" col-6" :class="{'col-xl-3':dataProp.type==1}">Họ và tên </h6>
                            <label class="col-6" v-if="dataProp.type == 1">{{ dataProp.member_name }}</label>
                            <label class="col-6" v-else>{{ dataProp.rep_name }}</label>
                        </div>
                        <div class="col-xl-6 row" v-if="dataProp.type == 2">
                            <h6 class="col-xl-3 col-6">Chức vụ</h6>
                            <label class="col-xl-9 col-6">{{ dataProp.position }}</label>
                        </div>
                        <div class="col-xl-6 row">
                            <h6 class="col-6">Loại tài khoản </h6>
                            <label class="col-6" v-if="dataProp.type == 2">Tổ chức</label>
                            <label class="col-6" v-else>Cá nhân</label>
                        </div>
                        <div class="col-xl-6 row">
                            <h6 class="col-xl-3 col-6">Email </h6>
                            <label class="col-xl-9 col-6">{{ dataProp.email }}</label>
                        </div>
                        <div class="col-xl-6 row">
                            <h6 class="col-6">Số điện thoại </h6>
                            <label class="col-6">{{ dataProp.phone }}</label>
                            <h6 class="col-6">Ngày sinh </h6>
                            <label class="col-6">{{ formateTime(dataProp.birthday) }}</label>
                        </div>
                        <div class="col-xl-6 row">
                            <h6 class="col-xl-3 col-6">Địa chỉ </h6>
                            <label class="col-xl-9 col-6" v-if="dataProp.province_name">
                                {{ dataProp.member_address+' - '+dataProp.ward_name +' - '+ dataProp.district_name +' - '+dataProp.province_name }}
                            </label>
                        </div>

                        <div class="col-xl-6 row">
                            <h6 class="col-6">Số CMT / Thẻ căn cước / Hộ chiếu</h6>
                            <label class="col-6">{{ dataProp.id_number }}</label>
                        </div>
                        <div class="col-xl-6 row">
                            <h6 class="col-xl-3 col-6">Giới tính</h6>
                            <label class="col-xl-9 col-6" v-if="!dataProp.gender">Nam</label>
                            <label class="col-xl-9 col-6" v-else>Nữ</label>
                            <h6 class="col-xl-3 col-6">Ngày cấp</h6>
                            <label class="col-xl-9 col-6">{{ formateTime(dataProp.id_date) }}</label>
                        </div>
                        <div class="col-xl-6 row">
                            <h6 class="col-6">Nơi cấp</h6>
                            <label class="col-6">{{ dataProp.id_place }}</label>
                        </div>
                        <div class="col-12 row">
                            <h6 class="col-xl-3">Ảnh CMT / Thẻ căn cước / Hộ chiếu</h6>
                            <div class="col-xl-3 col-6" style="text-align: center;">
                                <img :src="$storage + dataProp.id_front_face" alt=""
                                    v-if="dataProp.id_front_face" @error="handleError">
                                <div>Mặt trước</div>
                            </div>
                            <div class="col-xl-3 col-6" style="text-align: center;">
                                <img :src="$storage + dataProp.id_back_side" alt=""
                                    v-if="dataProp.id_back_side" @error="handleError">
                                <div>Mặt sau</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="dataProp.type == 2">
                        <div class="col-12 d-flex justify-content-between align-items-center p-2">
                            <p class="m-0">Thông tin Doanh nghiệp</p>
                        </div>
                        <div class="col-12 p-xl-2 mx-xl-2 row">
                            <h6 class="col-xl-3 col-6">Tên doanh nghiệp</h6>
                            <label class="col-xl-9 col-6">{{ dataProp.member_name }}</label>
                            <h6 class="col-xl-3 col-6">Mã số doanh nghiệp</h6>
                            <label class="col-xl-9 col-6">{{ dataProp.tax_code }}</label>
                            <h6 class="col-xl-3 col-6">Ngày cấp mã số thuế</h6>
                            <label class="col-xl-9 col-6">{{ formateTime(dataProp.tax_date) }}</label>
                            <h6 class="col-xl-3 col-6">Nơi cấp</h6>
                            <label class="col-xl-9 col-6">{{ dataProp.tax_place }}</label>
                            <h6 class="col-xl-3 col-6">Địa chỉ</h6>
                            <label class="col-xl-9 col-6">{{ dataProp.rep_address }}</label>
                            <h6 class="col-xl-3 col-6">Giấy chứng nhận đăng ký kinh doanh</h6>
                            <div class="col-xl-9 col-6">
                                <a href="#" class="note list-file" @click="viewFilePDF">Giấy chứng nhận đăng ký kinh doanh . PDF</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" :class="{ 'show active': this.$router.currentRoute.path == '/account/banking' }"
                id="nav-bank" role="tabpanel" aria-labelledby="nav-bank-tab">
                <div class="col-12 pl-4 pr-2">
                    <div class="col-12 d-md-flex justify-content-between align-items-center p-2">
                        <p class="m-0">Tài khoản ngân hàng</p>
                        <div class="d-flex">
                            <button href="#" @click="editBanking" class="btnn btn-outline">Chỉnh sửa</button>
                        </div>
                    </div>
                    <div class="col-12 mx-xl-2 row">
                        <h6 class="col-xl-4">Họ và tên chủ tài khoản </h6>
                        <label class="col-xl-8">{{dataProp.bank_account_name }}</label>
                        <h6 class="col-xl-4">Ngân hàng</h6>
                        <label class="col-xl-8">{{dataProp.bank_name }}</label>
                        <h6 class="col-xl-4">Số tài khoản</h6>
                        <label class="col-xl-8">{{dataProp.bank_account_number}}</label>
                        <h6 class="col-xl-4">Chinh nhánh</h6>
                        <label class="col-xl-8">{{dataProp.branch}}</label>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" :class="{ 'show active': this.$router.currentRoute.path == '/account/file' }"
                id="nav-file" role="tabpanel" aria-labelledby="nav-file-tab">
                <div class="col-12 pl-0 pr-xl-2">
                    <div class="col-12 d-flex justify-content-between align-items-center m-3 pl-3">
                        <p class="m-0">Tài liệu , hồ sơ của bạn</p>
                    </div>
                    <div class="col-12 p-2 mx-2 row">
                        <h6 class="warning col-auto"><img src="@/assets/front-end/icon/Important.png" alt="">Lưu ý : </h6>
                        <h6 class="col-auto warning"> 
                            Tài liệu , hồ sơ này sẽ được dùng để xét duyệt đăng ký tham gia đấu giá <br>
                            Nếu đăng ký tham gia đấu giá mà thiếu hồ sơ , chúng tôi sẽ gửi thông báo tới bạn
                        </h6>
                        <div class="col-12" v-for="(file, index) in dataProp.file" :key="index">
                            <b-link :href="$storage + file.url" target="_blank">
                                <img src="@/assets/front-end/icon/Note.png" alt="">
                                <span>{{ file.file_name }}</span>
                            </b-link>
                        </div>

                    </div>
                    <!-- <a href="#" class="col-12 p-2 mx-2 row" style="width: 323px;"
                        >
                        <img src="@/assets/front-end/img/Group 501.png" alt="">
                    </a>
                    <input type="file" name="profile" id="profile-input" hidden> -->
                </div>
            </div>
        </div>
        <changePassword ref="changePassword" />
        <editAccount ref="editAccount" :formData="dataProp"/>
        <editBanking ref="editBanking" :formData="dataProp"/>

        <b-modal id="" ref="viewFileModal" centered hide-footer hide-header size="lg" content-class="modal-pdf ">
            <div>
                <pdf :src="pdfdata" v-for="i in numPages" :key="i" :id="i" :page="i" :scale.sync="scale"></pdf>
            </div>
        </b-modal>
    </div>
</template>

<script>
import pdfvuer from 'pdfvuer'
import changePassword from "../modal/changePassword.vue"
import editAccount from "./modal/editAccount.vue"
import editBanking from "./modal/editBanking.vue"
export default {
    props: ['dataProp'],
    components: {
        changePassword,
        editAccount,
        editBanking,
        pdf: pdfvuer
    },
    data() {
        return {
            pdfdata: '',
            numPages: 0,
        }
    },
    mounted(){
        
    },
    methods: {
        getPdf() {
            var self = this;
            self.pdfdata = pdfvuer.createLoadingTask(this.pdfdata);
            self.pdfdata.then(pdf => {
                self.numPages = pdf.numPages;
            })
        },
        showModal() {
            this.$refs.changePassword.show()
            
        },
        formateTime(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY")
        },
        editAccount(){
            this.$refs.editAccount.show()    
        },
        editBanking(){
            this.$refs.editBanking.show()
        },
        viewFilePDF(){
            this.$refs.viewFileModal.show()
            this.pdfdata = this.$storage + this.dataProp.business_registration
            this.getPdf()
        }
    }
}
</script>
<style lang="css">
    .modal-pdf .modal-body{
        background-color: #ffffff;
        content: 'loadding...';
        width: fit-content;
    }
</style>
<style scoped lang="css">
.form-group {
    display: flex;
    flex-wrap: wrap;
}
.note.list-file::before {
    content: " ";
    background: url('@/assets/icon/Note.png');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    position: absolute;
    left: 10px;
    top: 0px;
}
.note.list-file{
    margin-left: 1.5rem;
}
</style>
