<template>
    <div>
        <div class="about-us">
            <b-container class="bg-2">
                <b-row>
                    <b-col cols="md-6 flex-colum justify-content-center align-items-center">
                        <img class="logo" src="@/assets/images/Logo.png" alt="">
                        <p class="quote">Lấy chữ tín làm đầu , sự hài lòng, tín nhiệm của quý khách hàng chính là sự thành
                            công của chúng tôi .</p>
                    </b-col>
                    <b-col cols="md-6 mt-3 mb-5">
                        <b-card class="contact mt-3 mb-5">
                            <b-card-text>
                                <h3>Liên hệ</h3>
                                <p>Thông tin liên hệ</p>
                                <ul class="list-info">
                                    <li>{{ infomation.company_name.value }}</li>
                                    <li>Địa chỉ văn phòng: {{ infomation.address.value }}</li>
                                    <li>Email: <b-link href="mailto:daugiadgv@gmail.com">{{ infomation.email.value }}</b-link></li>
                                    <li>Điện thoại: <b-link href="tel: +84964611011" class="phone">{{ infomation.phone.value }}</b-link>
                                    </li>
                                </ul>
                            </b-card-text>
                            <!-- <div>
                                <p>Gửi liên hệ</p>
                                <b-form class="form-contact" autocomplete="off">
                                    <b-form-group>
                                        <b-form-input type="text" placeholder="Tên của bạn" required></b-form-input>
                                    </b-form-group>
                                    <b-form-group>
                                        <input class="form-control" type="text" placeholder="Số điện thoại liên hệ" required
                                            v-model="inputPhone" @input="checkInputPhone" maxlength="14">
                                    </b-form-group>
                                    <b-form-group>
                                        <b-form-input type="email" placeholder="Email của bạn" required></b-form-input>
                                    </b-form-group>
                                    <b-form-group>
                                        <b-form-textarea placeholder="Viết bình luận , nội dung của bạn" rows="5"
                                            required></b-form-textarea>
                                    </b-form-group>
                                    <b-button type="submit" class="viewmore">Gửi liên hệ đến DGV</b-button>
                                </b-form>
                            </div> -->
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>
<script>
export default {
    components: {
    },
    data() {
        return {
            inputPhone: '',
            infomation: {}
        };
    },
    created() {
        this.infomation = this.getInformation()
    },
    methods: {
        checkInputPhone(event) {
            this.inputPhone = this.formatNumber(event.target.value)
        },
    },
}
</script>

<style lang="css" scoped>
.contact h3 {
    font-size: 30px;
    font-weight: 700;
    line-height: 35.16px;
    margin-bottom: 17px;
}

.contact p {
    font-size: 20px;
    line-height: 23.44px;
    font-weight: 500;
}

.list-info {
    list-style: disc;
    font-size: 18px;
    line-height: 24.96px;
    padding-left: 2rem;
}

.list-info a {
    color: #FFFFFF;
}

.phone {
    text-decoration: none;
}

.form-contact {
    text-align: right;
}

.form-contact input,
textarea {
    border-radius: 11px;
    background: #C0C0C080;
    border: none;
    color: #FFFFFF;
    padding: 7px 0.75rem;
}

.form-control:focus {
    box-shadow: none;
    background: #C0C0C080;
    color: #FFFFFF;
}

::placeholder {
    color: #B3B3B3;
}
</style>