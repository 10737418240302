<template>
    <div>
        <Header />
        <div class="personal">
            <div class="container">
                <b-row>
                    <b-col cols="lg-3">
                        <div class="wp-side">
                            <div class="d-flex bread">
                                <b-link :to="routeLink">Trang chủ </b-link>
                                <p> / Quản lý</p>
                            </div>
                            <h5>Trang cá nhân</h5>
                            <div class="text-center">
                                <p style="font-size: 29.647px;">{{ dataDetail.full_name }}</p>
                                <p style="font-size: 22.235px;" v-for="role in listRole" v-if="role.role_id == dataDetail.role_id">{{ role.role_name }}</p>
                                <img v-if="dataDetail.avatar" :src="$storage + dataDetail.avatar" alt="" class="mt-3" @error="handleError" style="border-radius: 50%; width: 200px; height: 200px;">
                                <img v-else src="@/assets/images/th.jpg" alt="" style="border-radius: 50%; width: 200px; height: 200px;">
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="lg-9">
                        <h5 style="font-size: 22px; font-weight: 700; font-family: Libre Bodoni; margin: 7px 0 10px;">Thông tin cá nhân</h5>
                        <div class="wp-infor">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="infor_ttl">Thông tin chung</p>
                                <button class="change-pass" @click="showChangePass(token)">Đổi mật khẩu</button>
                            </div>
                            <b-row>
                                <b-col cols="lg-4"><p>Họ và tên:</p></b-col>
                                <b-col cols="lg-8"><p>{{ dataDetail.full_name }}</p></b-col>
                                <b-col cols="lg-4"><p>Giới tính:</p></b-col>
                                <b-col cols="lg-8">
                                    <p v-if="dataDetail.gender == 0">Nam</p>
                                    <p v-else>Nữ</p>
                                </b-col>
                                <b-col cols="lg-4"><p>Ngày sinh:</p></b-col>
                                <b-col cols="lg-8"><p>{{ formateTime(dataDetail.birthday) }}</p></b-col>
                                <b-col cols="lg-4"><p>Email:</p></b-col>
                                <b-col cols="lg-8"><p>{{ dataDetail.email }}</p></b-col>
                                <b-col cols="lg-4"><p>Địa chỉ:</p></b-col>
                                <b-col cols="lg-8"><p>{{ dataDetail.address }}</p></b-col>
                                <b-col cols="lg-4"><p>Chức vụ:</p></b-col>
                                <b-col cols="lg-8"><p>{{ dataDetail.position }}</p></b-col>
                                <b-col cols="lg-4"><p>Chi nhánh làm việc:</p></b-col>
                                <b-col cols="lg-8"><p v-for="agency in listAgency" v-if="agency.agency_id == dataDetail.agency_id">{{ agency.agency_name }}</p></b-col>
                                <b-col cols="lg-4"><p>Số CMT/CCCD/Hộ chiếu:</p></b-col>
                                <b-col cols="lg-8"><p>{{ dataDetail.cccd }}</p></b-col>
                                <b-row>
                                    <b-col cols="lg-4" class="d-flex">
                                        <p>Ngày cấp:</p>
                                        <p style="font-weight: 500; margin-left: 25px;">{{ formateTime(dataDetail.ngayCapCC) }}</p>
                                    </b-col>
                                    <b-col cols="lg-8" class="d-flex">
                                        <p style="font-weight: 400; margin-right: 25px;">Nơi cấp:</p>
                                        <p>{{ dataDetail.noiCapCC }}</p>
                                    </b-col>
                                </b-row>
                                <b-col cols="lg-4"><p>Ảnh CMT / Thẻ căn cước / Hộ chiếu</p></b-col>
                                <b-col cols="lg-8" class="d-flex">
                                    <div class="text-center">
                                        <img v-if="dataDetail.id_front_face" :src="$storage + dataDetail.id_front_face" alt="" @error="handleError">
                                        <p style="font-size: 13px; color: #757575;">Mặt trước</p>
                                    </div>
                                    <div class="text-center" style="margin-left: 60px;">
                                        <img v-if="dataDetail.id_back_side" :src="$storage + dataDetail.id_back_side" alt="" @error="handleError">
                                        <p style="font-size: 13px; color: #757575;">Mặt sau</p>
                                    </div>
                                </b-col>
                                <div class="row mt-3" v-if="dataDetail.role_id == 3">
                                    <b-col cols="lg-4"><p>Số thẻ đấu giá viên:</p></b-col>
                                    <b-col cols="lg-8"><p>{{ dataDetail.soTheDGV }}</p></b-col>
                                    <b-col cols="lg-4"><p>Ngày cấp:</p></b-col>
                                    <b-col cols="lg-8"><p>{{ formateTime(dataDetail.ngayCap) }}</p></b-col>
                                    <b-col cols="lg-4"><p>Nơi cấp:</p></b-col>
                                    <b-col cols="lg-8"><p>{{ dataDetail.noiCap }}</p></b-col>
                                </div>
                                <ul v-if="dataDetail.role_id == 3">
                                    <p class="infor_ttl">Giấy tờ liên quan</p>
                                    <li><b-link class="file_lnk" target="_blank" :href="$storage + dataDetail.ccHanhNghe">Chứng chỉ hành nghề đấu giá</b-link></li>
                                    <li><b-link class="file_lnk" target="_blank" :href="$storage + dataDetail.theDGV">Thẻ đấu giá viên </b-link></li>
                                </ul>
                            </b-row>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
        <Footer />
        <ChangePass ref="changePass"/>
    </div>
</template>
<script>
import Header from '@/pages/admin-view/components/header.vue';
import Footer from '@/pages/admin-view/components/copyright.vue';
import ChangePass from './changePass.vue'
export default {
    components: {
        Header,
        Footer,
        ChangePass
    },
    data() {
        return {
            routeLink: '',
            id: '',
            token: '',
            dataDetail: [],
            listRole: [],
            listAgency: [],
        }
    },
    mounted() {
        const currentPath = this.$route.path;
        if (currentPath.search('auctioneer') == true) {
            this.routeLink = '/auctioneer'
            const storedData = localStorage.getItem('auInfo')
            if(storedData) {
                var data = JSON.parse(storedData)
                this.id = data.id
                this.token = data.accessToken
            }
        } else {
            this.routeLink = '/admin'
            const storedData = localStorage.getItem('admInfo')
            if(storedData) {
                var data = JSON.parse(storedData)
                this.id = data.id
                this.token = data.accessToken
            }
        }
        this.getDataDetail()
        this.getListRole()
        this.getListAgency()
    },
    methods: {
        formateTime(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY")
        },
        showChangePass(token) {
            this.$refs.changePass.show(token)
        },
        async getDataDetail() {
            const res = await this.$axios.get('/auth/getUserDetail?user_id=' + this.id, {
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.dataDetail = res.data
            }
        },
        async getListRole() {
            const res = await this.$axios.get('/permission/getListRole', {
                headers: {
                    'x-access-token': this.token
                }
            })

            if(res.status == 200) {
                this.listRole = res.data;
            }
        },
        async getListAgency() {
            const res = await this.$axios.get('/agency/getListAgency', {
                headers: {
                    'x-access-token': this.token
                }
            })

            if(res.status == 200) {
                this.listAgency = res.data;
            }
        },
    }
}
</script>
<style lang="css" scoped>
    .personal {
        background: #EEE;
        padding-bottom: 20px;
        min-height: 850px;
    }
    .wp-side {
        color: #EEE;
        font-family: Libre Bodoni;
        font-weight: 500;
        background-color: #080808;
        padding: 10px 7px 40px 11px;
        border-radius: 0px 0px 200px 200px;
    }
    .wp-side h5 {
        font-size: 24px;
        margin-bottom: 32px;
    }

    .wp-side p {
        margin: 0;
    }

    .wp-infor {
        background-color: #FFF;
        box-shadow: 2px 2px 6px 0px #00000026;
        border-radius: 15px;
        padding: 18px 20px 20px 30px;
        color: #515151;
    }

    .wp-infor .col-lg-8 p {
        font-weight: 500;
    }

    .infor_ttl {
        font-size: 20px;
        font-weight: 500;
    }

    .bread {
        font-family: Roboto;
        margin-bottom: 20px;
    }

    .bread a {
        color: #DBDBDB;
        padding-right: 5px;
    }

    .change-pass {
        background: none;
        border: 1px solid #EE3439;
        border-radius: 15px;
        color: #EE3439;
        font-weight: 500;
        height: 30px;
        width: 150px;
    }
    
    .file_lnk {
        position: relative;
        display: block;
        padding-left: 35px;
        color: #515151;
        font-weight: 500;
        margin-bottom: 10px;
    }
    
    .file_lnk::before {
        content: '';
        position: absolute;
        left: 0;
        background-image: url('@/assets/icon/Note.png');
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
    }
</style>