<template>
    <div>
        <b-modal ref="selectWinner" id="selectWinner" centered hide-footer hide-header content-class="modal-width" modal-class="modal-notice" @hide="clearInput" size="xl">
            <div class="status-modal text-center">
                <img src="@/assets/icon/icon_auction.png" alt="">
                <div>
                    <p style="font-size: 20px; font-weight: 500;">Chọn người trúng đầu giá</p>
                    <p style="text-align: center;">Đấu giá viên kiểm tra lại quá trình đấu giá, căn cứ vào kết quả đấu giá để chọn ra người trúng đấu giá cuối cùng và ghi chú lý do trúng đấu giá bên dưới để gửi thông báo đến tất cả khách hàng tham gia cuộc đấu giá</p>
                    <b-table responsive="md" :fields="table_bid.fields" :items="table_bid.items" tbody-tr-class="my-2" class="setHeight">
                        <template #cell(price)="row">
                            <span :class="{'return_price': row.item.withdraw_status == 1}" v-if="row.item.price == null">
                                0 VNĐ
                            </span>
                            <span :class="{'return_price': row.item.withdraw_status == 1}" v-else>
                                {{ parseInt(row.item.price).toLocaleString("vi-VN") }} VNĐ
                            </span>
                            <span v-show="row.item.withdraw_status == 1" class="text-red">
                                Rút giá
                            </span>
                        </template>

                        <template #cell(time)="row">
                            <span style="font-weight: 700; opacity: 0.7; color: #111111;">{{ formateTimeTb(row.item.time) }}</span>
                            <span style="font-weight: 500; display: block;">{{ formateDate(row.item.time) }}</span>
                        </template>

                        <template #cell(button)="row">
                            <button @click="selectedData(row.item)" v-if="row.item === selectedRecord" style="background-color: #B4B4B4 !important;">Đã chọn</button>
                            <button @click="selectedData(row.item)" v-else>Chọn</button>
                        </template>
                    </b-table>
                    <p style="font-weight: 500; text-align: left; margin-bottom: 5px;">Ghi chú lý do cho kết quả đấu giá:</p>
                    <b-form-textarea
                        placeholder="Nhập lí do"
                        rows="2"
                        max-rows="5"
                        v-model="noteSuccess"
                    ></b-form-textarea>
                    <div class="text-right">
                        <button class="btn-cancel mr-3" @click="$refs.selectWinner.hide()">Hủy bỏ</button>
                        <button @click="showConfirm('success')" :disabled="selectedRecord == null">Xác nhận kết quả</button>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal ref="reasonFail" id="reasonFail" centered hide-footer hide-header content-class="modal-width" modal-class="modal-notice" @hide="clearInput">
            <div class="reason-fail text-center">
                <img src="@/assets/icon/Auction_modal.png" alt="">
                <h5 style="margin-top: 10px;">Cuộc đấu giá đã kết thúc không thành</h5>
                <p>Tài sản đấu giá: <span>{{title}}</span></p>
                <b-form class="text-left">
                    <!-- <b-form-group>
                        <b-form-radio-group v-model="selected">
                            <b-form-radio value="Lỗi hệ thống">Lỗi hệ thống</b-form-radio>
                            <b-form-radio value="Không có người trả giá">Không có người trả giá</b-form-radio>
                            <b-form-radio value="Chỉ có một người trả giá">Chỉ có một người trả giá</b-form-radio>
                            <b-form-radio value="Cuộc đấu giá vi phạm quy chế ">Cuộc đấu giá vi phạm quy chế </b-form-radio>
                            <b-form-radio value="Lỗi mạng">Lỗi mạng</b-form-radio>
                            <b-form-radio value="Lỗi khác">Lỗi khác</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group> -->

                    <b-form-textarea
                        placeholder="Ghi chú"
                        rows="5"
                        max-rows="7"
                        v-model="noteFail"
                    ></b-form-textarea>
                </b-form>
                <p>Thời điểm kết thúc đấu giá: <span>{{ formateTime(time) }}</span></p>
                <p class="p-notice">Đã gửi thông báo tới tất cả khách hàng tham gia đấu giá ! </p>
                <b-button block style="border-radius: 10px; height: 42px;" @click="showConfirm('failed')">Xác nhận</b-button>
            </div>
        </b-modal>

        <b-modal ref="modalDif" id="modalDif" centered hide-footer hide-header content-class="modal-width" modal-class="modal-notice" @hide="clearInput">
            <div class="reason-fail text-center">
                <img src="@/assets/icon/Auction_modal.png" alt="">
                <h5 style="margin-top: 10px;">Cuộc đấu giá đã kết thúc</h5>
                <p>Tài sản đấu giá: <span>{{title}}</span></p>
                <p style="margin-bottom: 0; text-align: left; font-size: 15px; color: #515151;">Đấu giá viên nhập lý do</p>
                <b-form class="text-left">
                    <b-form-textarea
                        placeholder="Lý do"
                        rows="3"
                        max-rows="7"
                        v-model="noteFail"
                    ></b-form-textarea>
                </b-form>
                <p>Thời điểm kết thúc đấu giá: <span>{{ formateTime(time) }}</span></p>
                <p class="p-notice">Đã gửi thông báo tới tất cả khách hàng tham gia đấu giá ! </p>
                <b-button block style="border-radius: 10px; height: 42px;" @click="showConfirm('dif')" :disabled="!noteFail">Xác nhận</b-button>
            </div>
        </b-modal>

        <b-modal ref="confirmAuction" id="confirmAuction" centered hide-footer hide-header content-class="modal-width" modal-class="modal-notice" @hide="$router.push('/auctioneer/auctiondetail-auction/'+ encode(asset_id))">
            <div class="confirm-auction text-center">
                <div v-if="this.status == 'success'">
                    <img src="@/assets/icon/Auction_modal.png" alt="">
                    <h5 style="margin-top: 10px;">Cuộc đấu giá đã kết thúc thành</h5>
                    <p>Tài sản đấu giá: <span>{{ title }}</span></p>
                    <p style="font-weight: 500;">Chúc mừng quý khách {{ selectedRecord.member_code }} đã là người trúng đấu giá</p>
                    <p>Giá trúng đấu giá:<span style="font-weight: 600; color: #EE3439;"> {{ parseInt(selectedRecord.price).toLocaleString("vi-VN") }} VNĐ</span></p>
                    <p>Thời điểm trả giá: <span>{{ formateTime(selectedRecord.time) }}</span></p>
                    <p style="font-weight: 500;">{{ this.dataNoteSuccess }}</p>
                    <p class="p-notice">Đã gửi thông báo tới tất cả khách hàng tham gia đấu giá!</p>
                </div>
                <div v-else-if="this.status == 'failed'">
                    <img src="@/assets/icon/Auction_modal.png" alt="">
                    <h5 style="margin-top: 10px;">Cuộc đấu giá đã kết thúc không thành</h5>
                    <p>Tài sản đấu giá: <span>{{ title }}</span></p>
                    <p style="font-weight: 500;">Không có người trúng đấu giá <br/>Cuộc đấu giá bị hủy bỏ</p>
                    <p>Giá trúng đấu giá:<span style="font-weight: 600; color: #EE3439;"> 0 VNĐ</span></p>
                    <p style="font-weight: 500;">{{ this.dataNoteFail }} <br/> Tài sản {{ code }} sẽ được đấu giá vào cuộc đấu giá khác.</p>
                    <p>Thời điểm kết thúc đấu giá: <span>{{ formateTime(time) }}</span></p>
                    <p class="p-notice">Đã gửi thông báo tới tất cả khách hàng tham gia đấu giá!</p>
                </div>
                <div v-else>
                    <img src="@/assets/icon/Auction_modal.png" alt="">
                    <h5 style="margin-top: 10px;">Cuộc đấu giá đã kết thúc</h5>
                    <p>Tài sản đấu giá: <span>{{ title }}</span></p>
                    <p style="font-weight: 500;">Lý do: {{ this.dataNoteFail }}</p>
                    <p>Thời điểm kết thúc đấu giá: <span>{{ formateTime(time) }}</span></p>
                    <p class="p-notice">Đã gửi thông báo tới tất cả khách hàng tham gia đấu giá!</p>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    data() {
        return {
            status: '',
            title: '',
            time:'',
            code: '',
            price: '',
            auction_key: '',
            asset_id: '',
            showErr: false,
            table_bid: {
                fields: [
                    { key: 'member_code', label: 'Mã khách hàng', thClass: 'text-center col-md-2', tdClass: 'text-center col-md-2' },
                    { key: 'member_name', label: 'Họ và tên', thClass: 'text-center col-md-3', tdClass: 'text-center col-md-3' },
                    { key: 'time', label: 'Thời gian', thClass: 'text-center col-md-2', tdClass: 'text-center col-md-2' },
                    { key: 'price', label: 'Giá trả', thClass: 'text-center col-md-2', tdClass: 'text-center col-md-2' },
                    { key: 'button', label: 'Chọn người trúng đấu giá', thClass: 'text-center col-md-3', tdClass: 'text-center col-md-3' },
                ],
                items: [],
            },
            // selected: 'Lỗi hệ thống',
            selectedRecord: null,
            noteSuccess: '',
            noteFail: '',
            dataNoteSuccess: '',
            dataNoteFail: ''
        }
    },
    methods: {
        async showSelectWinner(title, id, auction_key) {
            this.title = title
            this.asset_id = id
            this.auction_key = auction_key
            // ---API---
            const storedData = localStorage.getItem('auInfo')
            if(storedData) {
                var info = JSON.parse(storedData)
            }
            const res = await this.$axios.get('/auction/getListHighestBid?asset_id=' + this.asset_id, {
                headers: {
                    'x-access-token': info.accessToken
                }
            })
            if (res.status == 200) {
                this.table_bid.items = res.data
            }
            this.$refs.selectWinner.show()
        },
        showDif(title, time, code, auction_key, id) {
            this.title = title
            this.time = time
            this.code = code
            this.auction_key = auction_key
            this.asset_id = id
            this.$refs.modalDif.show()
        },
        showReasonFail(title, time, code, auction_key, id) {
            this.title = title
            this.time = time
            this.code = code
            this.auction_key = auction_key
            this.asset_id = id
            this.$refs.reasonFail.show()
        },
        showConfirm(status) {
            this.status = status
            this.dataNoteSuccess = this.noteSuccess
            this.dataNoteFail = this.noteFail
            const key = '';
            const token = JSON.parse(localStorage.getItem('auInfo')).accessToken;

            if(status == 'success') {
                const dataSuccess = {
                    "auction_status": 2,
                    "member_id": this.selectedRecord.member_id,
                    "member_code": this.selectedRecord.member_code,
                    "price": this.selectedRecord.price,
                    "time": this.selectedRecord.time,
                    "note": this.noteSuccess
                }
                // this.$socket(this.auction_key, key, token).emit("confirmRoom", dataSuccess)
                this.$emit("confirmRoom", dataSuccess);
            } else if (status == 'failed') {
                const dataFail = {
                    "auction_status": 3,
                    "reason": this.noteFail,
                    "note": this.noteFail
                }
                // this.$socket(this.auction_key, key, token).emit("confirmRoom", dataFail)
                this.$emit("confirmRoom", dataFail);
            } else {
                const dataDif = {
                    "auction_status": 4,
                    "reason": this.noteFail,
                    "note": this.noteFail
                }
                // this.$socket(this.auction_key, key, token).emit("confirmRoom", dataFail)
                this.$emit("confirmRoom", dataDif);
            }
            this.$socket(this.auction_key, key, token).on('room_status', (res) => {
                if(res.room_status == 4) {
                    // this.$refs.confirmAuction.show()
                    // this.$refs.reasonFail.hide()
                    // this.$refs.selectWinner.hide()
                    this.$bvModal.show('confirmAuction')
                    this.$bvModal.hide('reasonFail')
                    this.$bvModal.hide('selectWinner')
                    this.$bvModal.hide('modalDif')
                    setTimeout(() => {
                        this.$bvModal.hide('confirmAuction')
                    }, 10000);
                }
            })
        },
        selectedData(data) {
            this.selectedRecord = data;
        },
        formateTime(time) {
            return this.$moment.utc(time).format("HH:mm:ss DD/MM/YYYY")
        },
        formateTimeTb(time) {
            return this.$moment.utc(time).format("HH:mm:ss:SSS")
        },
        formateDate(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY")
        },
        clearInput () {
            this.noteFail = '',
            this.noteSuccess = ''
        },
    }
}
</script>
<style lang="css">
    .modal-notice .modal-width {
        /* max-width: 492px; */
        border-radius: 17.47px;
        box-shadow: 0px 0px 38px 0px #00000040;
    }

    .status-modal th, .status-modal td{
        color: #515151;
        font-weight: 500;
        vertical-align: middle;
    }
    .status-modal thead {
        position: sticky;
        top: 0;
        z-index: 99;
        height: 55px;
        background: #FFFFFF;
    }
    .reason-fail .custom-radio {
        align-items: center;
        display: flex; 
    }

    .reason-fail .custom-control-label {
        margin-left: 10px;
    }

    .status-modal .table > :not(caption) > * > * {
        border: none;
    }

    .status-modal tr{
        border-bottom: 1px solid #8a8383;
    }

    .status-modal tr:last-child {
        border-bottom: none;
    }

    .status-modal tbody tr {
        background: #F8F8F8;
        border-radius: 14px;
    }

    .status-modal tbody td {
        font-size: 15px;
        color: #515151;
        font-weight: 500;
    }
</style>
<style lang="css" scoped>

    textarea {
        overflow-y: auto !important;
        background: #EEEEEE;
        border-radius: 10px;
        border: 1px solid #CCCCCC;
        margin-bottom: 10px;
    }

    textarea:focus {
        background: #EEEEEE;
        border: 1px solid #CCCCCC;
        box-shadow: none;
    }

    .status-modal {
        color: #444444;
    }
    .status-modal h5 {
        font-size: 21px;
        font-weight: 500;
    }
    
    .p-notice {
        font-size: 14px;
        color: #EE3439;
        position: relative;
        text-align: left;
        padding-left: 25px;
        display: flex;
        align-items: center;
    }
    .p-notice::before {
        content: '';
        position: absolute;
        background-image: url('@/assets/icon/Important.png');
        background-repeat: no-repeat;
        width: 24px;
        height: 20px;
        left: 0;
    }
    button {
        background-color: #EE3439;
        color: #ffffff;
        font-weight: 700;
        border-radius: 20px;
        border: none;
        min-width: 160px;
        min-height: 34px;
        font-size: 15px;
    }

    button:active,
    button:focus {
        background-color: #EE3439 !important;
    }

    button:hover {
        background-color: #EE3439;
    }

    .btn-cancel {
        color: #EE3439;
        border: 1px solid #EE3439;
        background: #ffffff;
    }

    .btn-cancel:hover,
    .btn-cancel:focus,
    .btn-cancel:active {
        background: #ffffff !important;
        color: #EE3439;
        border: 1px solid #EE3439;
    }

    .error {
        font-size: 14px;
        text-align: center;
        display: block;
        margin-bottom: 20px;
        color: #EE3439;
        font-weight: 600;
    }

    .setHeight {
        max-height: 400px;
        overflow-y: auto;
    }
    .table-responsive-md::-webkit-scrollbar {
        width: 0px;
    }

    .confirm-auction p {
        margin-bottom: 5px;
    }
</style>