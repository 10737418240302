<template>
    <b-link class="d-flex flex-center">
        <b-card v-if="item">
            <div v-if="item.image.length !== 0" >
                <img class="card-img" v-for="(image, index) in item.image" :src="$storage + image" 
                alt="" @error="handleError" v-if="index == 0">
            </div>
            <img src="@/assets/images/th.jpg" alt="" v-else class="card-img">
            <div v-if="item.asset_status !== 0">
                <img src="@/assets/icon/Auction2.png" alt="" class="image-status image-status--list"
                    v-if="item.asset_status == 1" style="width: unset;">
                <img src="@/assets/icon/golden sold out.png" alt="" class="image-status image-status--list"
                    v-else style="width: unset;">
            </div>
            <p class="text">{{ item.asset_name }}</p>
            <div class="col-12 px-2" to="">
                <b-button class="d-flex col-12 align-items-center p-0">
                    <img class="icon-auction" src="@/assets/icon/Auction1.png" alt="">
                    <div class="list-btn ">
                        <div>
                            <span>Thời gian đấu giá: </span> 
                            {{ formateDate(item.start_time) }}
                        </div>
                        <div>
                            <span>Giá khởi điểm: </span>
                            {{ parseInt(item.price).toLocaleString("vi-VN") }} VNĐ
                        </div>
                        <div class="text-red">
                            <span>Trạng thái: </span>
                            {{ getStatus(item.asset_status) }}
                        </div>
                    </div>
                </b-button>
            </div>
        </b-card>
    </b-link>
</template>
<script>
export default {
    props:['item'],
    methods:{
        getStatus(status){
            if (status == 0) {
                return "Chưa đấu giá"
            }else if(status == 1){
                return "Đang đấu giá"
            } else if (status == 2) {
                return "Đã đấu giá"
            } else if (status == 3) {
                return "Đấu giá không thành"
            } else{
                return "Đấu giá thành"
            }
        },
    }
}
</script>
<style scoped lang="css">
.icon-auction{
    width: 42px !important;
    height: 43px;
    margin-right: 0.5rem;
}
.text-red{
    color: #EE3439;
}
.card {
    border: none;
    text-align: center;
    min-width: 285px;
    max-width: 308px;
}
.list-btn span{
    color: #929292;
    font-family: Roboto;
    font-size: 14.047px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
}
.card-body {
    padding: 0 0 10px;
    margin-bottom: 11px;
    background: #F8F8F8;
    border-radius: 7px;
}

.card-body .card-text {
    font-size: 18px;
    color: #515151;
    line-height: 32.4px;
    font-weight: 600;
    margin: 8px 0 0;
    padding-left: 9px;
}
.card-img{
    width: 308px !important;
    height: 205px !important;
    object-fit: cover;
}
.card-body .text-detail,
.card-body .text {
    font-size: 15px;
    font-weight: 400;
    line-height: 27px;
    color: #515151;
    margin-bottom: 10px;
    padding-left: 9px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    min-height: 81px;
}

.card-body .text {
    text-align: left;
}

.card-body .btn {
    background: none;
    color: #515151;
    font-size: 14.05px;
    border: 1px solid #515151;
    font-weight: 500;
    line-height: 25.29px;
    border-radius: 20px;
}

.list-btn {
    margin: 0;
    text-align: left;
    padding-left: 0;
}

.image-status {
    position: absolute;
    z-index: 90;
    top: 3%;
    left: 3%;
}

.image-status--list {
    left: 7%;
}
@media (max-width: 1200px) {
    .card{
        width: 309px;
    }
}
@media (max-width: 425px) {
    .image-status {
        position: absolute;
        z-index: 90;
        top: 3%;
        left: 8%;
    }

    .image-status--list {
        left: 7%;
    }
}
</style>