<template>
    <div class="container-fluid">
         <div class="row">
             <Header></Header>
         </div>
         <div class="row">
            <router-view></router-view>
         </div>
         <div class="row">
            <Footer></Footer>
         </div>
     </div>
 </template>
 <script>
import Header from '@/pages/admin-view/components/header.vue'
import Footer from '@/pages/admin-view/components/copyright.vue'
export default {
     components: {
         Header,
         Footer
     }
 }
 </script>
 <style lang="css" scoped>
     
 </style>