<template>
    <div>
        <b-modal id="modal-notice" ref="modal-notice" centered hide-footer hide-header 
            content-class="modal-width" modal-class="modal-notice">
            <div class="notice-modal">
                    <img src="../../../assets/icon/Notice_ico.png" alt="">
                    <h5 class="notice-modal__ttl">Thông báo bổ sung hồ sơ</h5>
                    <p class="m-0">Bạn đang cần bổ sung những điều kiện sau :</p>
                    <form action="" class="text-left form-group form-check">
                        <div>
                            <div v-for="file in listMissingRequiredFile">
                                <label for=""  class="text-red">{{file.required_file_name}}</label>
                                <input class="custom-file-input mb-2" :name="file.required_file_id"
                                type="file" accept="application/pdf" @change="uploadFile">
                            </div>
                        </div>
                    </form>

                    <p>Bạn cần bổ sung đầy đủ hồ sơ và phí trước <br>
                        thời điểm đấu giá để được tham gia đấu giá , nếu đến <br> thời điểm đấu giá bạn không đủ các điều kiện <br>
                        sẽ <strong>không được</strong> tham gia đấu giá
                    </p>
                </div>
                <div class="button-footer col-12">
                    <b-button class="btnn btn-outline-primary col-6" block @click="$bvModal.hide('modal-notice')">BỎ QUA</b-button>
                    <b-button class="btnn btn-primary col-6" @click="updateMissingRequiedFile" block>BỔ SUNG</b-button>
                </div>
        </b-modal>
    </div>
</template>
<script>

export default {
    data() {
        return {
            params:{
                file1:null,
                file2:null,
                auction_fee:false,
                register_auction_fee:false,
            },
            listMissingRequiredFile:[],
            auction_id:'',
            asset_id:"",
            userCurrent:'',
            listFile:[],
        }
    },
    created() {
        this.userCurrent = localStorage.getItem('member');
    },
    mounted(){
        
    },
    methods: {
        show(asset_id,auction_id,) {
            this.auction_id = auction_id
            this.asset_id = asset_id
            this.$refs['modal-notice'].show()
            this.getListMissingRequiredFile({ asset_id: this.asset_id })
            
        },
        hide() {
            this.$refs['modal-notice'].hide()
            this.showError = false
        },
        async uploadFile(){
            let name = event.target.name
            await this.$axios.post('/common/uploadFile', {
                file: event.target.files[0],
                path: 'member/required_file/'
            }, {
                headers: {
                    'x-access-token': localStorage.getItem('member'),
                    'Content-Type': 'multipart/form-data',
                }
            }).then((res) => {
                this.listMissingRequiredFile.forEach(element => {
                    if (element.required_file_id == name) {
                        element.url = res.data.list_result[0].path
                    }
                });
            })
        },
        async updateMissingRequiedFile(){
            await this.$axios.post('/auction/updateMissingRequiedFile', {
                asset_id:this.asset_id,
                auction_id:this.auction_id,
                member_file_required:this.listMissingRequiredFile
            }, {
                headers: {
                    'x-access-token': localStorage.getItem('member'),
                    'Content-Type': 'application/json',
                },
            }).then((res) => {
                this.$toast.success('Thành công!')
                this.$refs['modal-notice'].hide()
            }).catch(err => {
                if (err.code == "ERR_NETWORK") {
                    this.$toast.error('Lỗi hệ thống')
                } else {
                    this.$toast.error(err.response.data.error)
                }
            })
        }
    }
}
</script>
<style lang="css" scoped>
.form-check{
    padding: 0 1.5rem;
}
.button-footer{
    display: flex;
}
.btnn.btn-outline-primary, .btnn.btn-primary{
    border-radius: 10px;
    font-size: 15px;
    font-weight: 700;
    width: 100%;
    height: 42px;
}
.modal-notice .modal-width{
    width: 455px;
}
.text-red{
    color: #EE3439;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    display: flex !important;
    position: relative;
    align-items: center;
    padding: 0 10px;
}
.notice-modal .text-red::before{
    content: "";
    background-color: #EE3439;
    border-radius: 25px;
    padding: 2px;
    position: absolute;
    width: 5px;
    height: 5px;
    left: 0;
}
.check_form{
    color: #515151;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
}
.check_form input{
    width: 17px;
    filter: hue-rotate(134deg);
}
.notice-modal  {
    text-align: center;
    color: #444444;
}
.notice-modal ul{
    text-align: left;
    list-style: disc;
    font-weight: 500;
    color: #EE3439;
    width: 263px;
    margin: 0 auto 10px;
}
.notice-modal__ttl {
    font-size: 20.96px;
    line-height: 37.73px;
    font-weight: 500;
    margin-bottom: 0;
}
.notice-modal button {
    background: #EE3439;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14.47px;
    width: 165px;
    height: 42px;
    font-family: Roboto;
    border-radius: 9.65px
}
.notice-modal button:hover {
    background: #EE3439;
}
    .custom-file-input::-webkit-file-upload-button {
        visibility: hidden;
    }

    .custom-file-input {
        color: #515151;
        background: #EEEEEE;
        border-radius: 15px;
        padding: 3px 0;
        margin-left: 35px;
        width: -webkit-fill-available;
    }

    .custom-file-input::after {
        display: none;
    }

    .custom-file-input::before {
        position: absolute;
        content: '';
        background-image: url('@/assets/admin/icon/Import Pdf.png');
        width: 32px;
        height: 32px;
        left: 35px;
    }
</style>