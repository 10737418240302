<template>
    <div class="au-finished">
        <b-row>
            <b-col cols="lg-6">
                <div class="list_auction">
                    <h5>Danh sách cuộc đấu giá đã diễn ra</h5>
                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                        <div class="d-flex">
                            <b-form class="search-form">
                                <b-form-group>
                                    <b-form-input v-model="keySearch" type="text" placeholder="Tìm kiếm" required></b-form-input>
                                </b-form-group>
                                <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                            </b-form>
                            <!-- <button><img src="@/assets/admin/icon/Adjust.png" alt=""></button> -->
                        </div>
                    </div>
                    <p class="total-num">Số cuộc đấu giá trong danh sách: <span>{{ filteredItems.length }}</span></p>
                    <div class="list-auction-item">
                        <div class="auction-item" v-for="(item, index) in filteredItems" :key="index" style="cursor: pointer;" @click="showDetail(item.auction_id)">
                            <img :src="$storage + item.thumbnail" style="max-width: 192px; height: 198px; border-radius: 25px; width: 100%; object-fit: cover;" alt="Auction Image" @error="handleError">
                            <div class="auction-item-info">
                                <p class="au_ttl">{{ item.auction_name }}</p>
                                <div class="d-flex justify-content-between flex-wrap">
                                    <div class="info">
                                        <p>Mã cuộc đấu giá: <span>{{ item.auction_code }}</span></p>
                                        <p>Số tài sản đấu: <span>{{ item.count_asset }}</span></p>
                                        <p>Đấu giá viên: <span>{{ item.full_name }}</span></p>
                                        <p>Thời gian bắt đầu: <span>{{ formateTime(item.start_time) }}</span></p>
                                        <p>Thời gian kết thúc: <span>{{ formateTime(item.end_time) }}</span></p>
                                        <p>{{ item.value }}</p>
                                    </div>
                                    <div class="action">
                                        <div class="text-center" v-if="item.auction_status_id == 2">
                                            <img src="@/assets/admin/icon/Ok.png" alt="">
                                            <p style="color: #00B569; font-weight: 500; font-size: 15px; max-width: 130px;">Cuộc đấu giá thành</p>
                                        </div>
                                        <div class="text-center" v-if="item.auction_status_id == 3">
                                            <img src="@/assets/icon/close.png" alt="">
                                            <p style="color: #EE3439; font-weight: 500; font-size: 15px; max-width: 130px;">Cuộc đấu giá không thành</p>
                                        </div>
                                        <div class="text-center" v-if="item.auction_status_id == 4">
                                            <img src="@/assets/icon/Auction_modal.png" alt="">
                                            <p style="color: #EE3439; font-weight: 500; font-size: 15px; max-width: 130px;">Cuộc đấu giá kết thúc</p>
                                        </div>
                                        <button style="margin-top: 20px;" @click="deleteAuction(item.auction_id)" @click.stop><img src="@/assets/admin/icon/Trash.png" alt="" style="width: 20px;"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col cols="lg-6" v-if="showAsset">
                <div class="list_asset">
                    <h5>Danh sách tài sản trong cuộc đấu giá</h5>
                    <div class="d-flex">
                        <b-form class="search-form">
                            <b-form-group>
                                <b-form-input v-model="keySearchAsset" type="text" placeholder="Tìm kiếm" required></b-form-input>
                            </b-form-group>
                            <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                        </b-form>
                        <button><img src="@/assets/admin/icon/Adjust.png" alt=""></button>
                    </div>
                    <p class="total-num">Số tài sản trong cuộc đấu giá: <span>{{ filteredItemsAsset.length }}</span></p>
                    <div class="asset_item" v-for="(item, index) in filteredItemsAsset" :key="index">
                        <img v-if="item.image" :src="$storage + item.image[0]" alt="" style="border-radius: 20px; width: 188px; height: 188px; object-fit: cover;" @click="$router.push('/admin/list-auction/detail-asset/' + encode(item.asset_id))" @error="handleError">
                        <div class="asset-info">
                            <p class="au_ttl" style="line-height: 19px;" @click="$router.push('/admin/list-auction/detail-asset/' + encode(item.asset_id))">{{ item.asset_name }}</p>
                            <div class="d-flex align-items-end wp-button">
                                <div class="info" style="width: 100%;" @click="$router.push('/admin/list-auction/detail-asset/' + encode(item.asset_id))">
                                    <p>Mã tài sản: <span style="color: #3FA5FF; text-decoration: underline;">{{ item.asset_code }}</span></p>
                                    <p>Giá khởi điểm: <span style="color: #EE3439;">{{ parseInt(item.price).toLocaleString('vi') }} VNĐ</span></p>
                                    <p>Giá trúng đấu giá: <span style="color: #EE3439;">{{ parseInt(item.highest_price).toLocaleString('vi') }} VNĐ</span></p>
                                    <p>Chi nhánh: <span>{{ item.agency_name }}</span></p>
                                    <p>Thời gian đấu giá: <span>{{ formateTime(item.start_time) }}</span></p>
                                    <p>Phương thức đấu giá: <span>{{ item.auction_type }}</span></p>
                                </div>
                                <div class="text-left">
                                    <p style="font-size: 13px;">Trạng thài tài sản:</p>
                                    <p style="font-size: 14px; color: #00B569; font-weight: 600; text-align: center;" v-if="item.asset_status == 4">Đấu giá thành</p>
                                    <p style="font-size: 14px; color: #EE3439; font-weight: 600; text-align: center;" v-if="item.asset_status == 3">Đấu giá không thành</p>
                                    <p style="font-size: 14px; color: #FF9838; font-weight: 600; text-align: center;" v-if="item.asset_status == 2">Đã đấu giá - chờ xử lý</p>
                                    <button class="history" @click="$router.push('/admin/list-history/' + encode(item.asset_id))">Lịch sử trả giá</button>
                                    <button @click="getReport(item.asset_id)">Biên bản đấu giá</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <DeleteAuction ref="deleteAuction" @handleCloseModal="listenDialog"/>
    </div>
</template>
<script>
import search from '../../components/search_form.vue'
import DeleteAuction from '../modal/delete.vue'
export default {
    components: {
        search,
        DeleteAuction
    },
    data() {
        return{
            keySearch: '',
            keySearchAsset: '',
            dataAuction: [],
            dataDetail: [],
            token: '',
            showAsset: false,
            params_agency: ''
        }
    },
    computed: {
        filteredItems() {
            return this.dataAuction.filter(dataAuction => dataAuction.auction_name.toLowerCase().includes(this.keySearch.toLowerCase()));
        },
        filteredItemsAsset() {
            return this.dataDetail.filter(dataDetail => dataDetail.asset_name.toLowerCase().includes(this.keySearchAsset.toLowerCase()));
        },
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken

            if(info.roles.role_id == 1) {
                this.params_agency = ''
            } else {
                this.params_agency = info.agency_id
            }
        }
        this.getDataAuction()
    },
    methods: {
        formateTime(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY HH:mm:ss")
        },
        async getDataAuction() {
            const res = await this.$axios.get('/auction/getListAuctionEndingForAdmin', {
                params: {
                    page: '1',
                    per_page: '10000',
                    approve_status: '1',
                    publish_status: '1',
                    agency_id: this.params_agency
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.dataAuction = res.data.re
            }
        },
        async showDetail(id){
            const res = await this.$axios.get('/asset/getListAsset', {
                params: {
                    asset_name: '',
                    asset_code: '',
                    category_id: '',
                    owner: '',
                    from_price: '',
                    to_price: '',
                    from_step_price: '',
                    to_step_price: '',
                    order: 'latest',
                    winner: '',
                    status: '',
                    description: '',
                    auction_id: id,
                    publish_status: '',
                    page: '1',
                    per_page: '100000',
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.showAsset = true
                this.dataDetail = res.data.result
            }
        },
        getReport(id) {
            const links = [
                this.$report + "/report/exportPDF?path=template/bien_ban_dau_gia&asset_id=" + id,
                this.$report + "/report/exportPDF?path=template/danh_sach_diem_danh&asset_id=" + id,
                this.$report + "/report/exportPDF?path=template/dien_bien_tra_gia&asset_id=" + id,
                this.$report + '/report/exportPDF?path=template/trich_xuat_dau_gia&asset_id=' + id,
                this.$report + '/report/exportPDF?path=template/danh_sach_dang_ky&asset_id=' + id
            ];

            links.forEach((link) => {
                window.open(link, '_blank');
            });
        },
        deleteAuction(id) {
            this.$refs.deleteAuction.show(id)
        }, 
        listenDialog(isClose = false) {
            if(!isClose) {
                this.showDetail = false
                this.getDataAuction()
            }
        },
    }
}
</script>
<style lang="css" scoped>

    .au-finished {
        color: #515151;
        font-family: Roboto;
    }
    .list_auction, .list_asset {
        box-shadow: 2px 2px 6px 0px #00000026;
        background: #FFFFFF;
        padding: 9px 12px 13px 13px;
        border-radius: 15px;
    }
    button {
        background: none;
        border: none;
    }

    .list_auction h5, .list_asset h5 {
        font-size: 20px;
        font-weight: 500;
        line-height: 36px;
    }

    p.total-num {
        font-size: 15px;
        font-weight: 500;
        margin: 10px 0;
    }

    .list-auction-item {
        overflow-y: auto;
        max-height: 750px;
    }

    .list-auction-item::-webkit-scrollbar {
        width: 0;
    }

    .auction-item {
        display: flex;
        align-items: center;
        box-shadow: 0px 0px 5px 0px #00000021;
        border: 1px solid #EAEAEA;
        border-radius: 30px;
        padding: 5px 13px 5px 5px;
        margin-bottom: 10px;
    }

    .auction-item-info {
        margin-left: 10px;
        width: 100%;
    }

    .auction-item-info p {
        margin-bottom: 0;
    }
    p.au_ttl {
        font-weight: 600;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .info p {
        font-size: 14px;
        color: #929292;
        margin-bottom: 2px;
    }

    .info p span {
        font-weight: 500;
        color: #515151;
        padding-left: 5px;
    }

    .info p:last-child {
        color: #515151;
        font-weight: 500;
    }

    .action {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .action img {
        width: 40px;
    }


    .asset_item {
        border: 1px solid #B4B4B4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .asset_item button {
        font-size: 15px;
        font-weight: 500;
        border: 1px solid #515151;   
        border-radius: 20px;
        padding: 4px 32px;
        margin-top: 7px;
    }

    .asset_item button.history {
        padding: 4px 40px;
    }

    .asset-info p {
        margin-bottom: 2px;
    }

    .asset-info {
        margin-left: 10px;
        padding: 5px 0;
    }

    button.approve {
        border: 1px solid #EE3439;
        border-radius: 20px;
        color: #EE3439;
        font-weight: 500;
        padding: 3px 10px;
        margin-bottom: 10px;
        font-size: 15px;
    }

    button.list-cus {
        border-radius: 20px;
        color: #FFFFFF;
        font-weight: 500;
        padding: 4px 11px;
        background: #EE3439;
        font-size: 15px;
    }

    @media (max-width: 1366px) {
        .wp-button {
            flex-wrap: wrap;
        }
    }
    
    @media (max-width: 1024px) {

        .wp-button {
            flex-wrap: wrap;
        }
        .list_auction {
            margin-bottom: 15px;
        }
        
    }
    @media (max-width: 768px) {
        .list_asset {
            margin-top: 20px;
        }

        .wp-button {
            flex-wrap: nowrap;
        }
    }

    @media (max-width: 576px) {
        .wp-button {
            flex-wrap: wrap;
        }
    }

    @media (max-width: 425px) {
        .auction-item, .asset_item, .wp-button {
            flex-wrap: wrap;
        }
    }
</style>

<style>
    .au-finished .search-form {
        width: 363px;
    }

    @media (max-width: 425px) {
        .au-finished .search-form {
            width: max-content;
        }
    }
</style>