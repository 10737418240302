<template>
    <div  class="d-none d-xl-block">
        <section class="call-buton" v-if="phone.value">
            <a class="cc-calto-action-ripple" :href="'tel:'+ phone.value"><img src="../../../assets/icon/phone_ico.png" alt=""></a>
        </section>
    </div>
</template>

<script>
export default {
    props:['phone']
}
</script>

<style lang="css" scoped>
.call-buton .cc-calto-action-ripple {
    z-index: 99999;
    position: fixed;
    left: 3rem;
    bottom: 8rem;
    border-radius: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #ffffff;
    -webkit-animation: cc-calto-action-ripple 0.6s linear infinite;
    animation: cc-calto-action-ripple 0.6s linear infinite;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
}

.call-buton .cc-calto-action-ripple:hover img {
    -webkit-transform: rotate(70deg);
    transform: rotate(70deg);
}

@media (max-width: 768px) {
    .call-buton .cc-calto-action-ripple {
        left: 5px;
        bottom: 5px;
    }
}

@keyframes cc-calto-action-ripple {
    0% {
        -webkit-box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2), 0 0 0 0 rgba(236, 139, 0, 0.2), 0 0 0 5px rgba(236, 139, 0, 0.2), 0 0 0 10px rgba(236, 139, 0, 0.2);
        box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2), 0 0 0 0 rgba(236, 139, 0, 0.2), 0 0 0 5px rgba(236, 139, 0, 0.2), 0 0 0 10px rgba(236, 139, 0, 0.2);
    }

    100% {
        -webkit-box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2), 0 0 0 5px rgba(236, 139, 0, 0.2), 0 0 0 10px rgba(236, 139, 0, 0.2), 0 0 0 20px rgba(236, 139, 0, 0);
        box-shadow: 0 4px 10px rgba(236, 139, 0, 0.2), 0 0 0 5px rgba(236, 139, 0, 0.2), 0 0 0 10px rgba(236, 139, 0, 0.2), 0 0 0 20px rgba(236, 139, 0, 0);
    }
}
</style>
