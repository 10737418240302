<template>
  <div class="pagination" v-if="data.totalPage > 1">
    <p :class="data.classPrev" class="paginate_txt">Đang xem trang {{ data.currentPage }} trên tổng {{ data.totalPage }}
      trang kết quả</p>
    <b-pagination v-model=data.currentPage :per-page=data.perPage :total-rows=data.totalData prev-text="Trước"
      next-text="Sau" :prev-class=data.classPrev :next-class=data.classNext first-class="d-none" last-class="d-none"
      @change="setPage">
    </b-pagination>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ["page", "rows"],
  data() {
    return {
      data: this.page,
    }
  },

  methods: {
    setPage(val) {
      this.$emit("set-page", val);
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style lang="css">
.pagination {
  justify-content: center;
}

.paginate_txt {
  margin: 0;
  color: #515151;
  font-weight: 500;
}

.pagination .active>.page-link {
  background-color: #EE3439;
  border-color: unset;
  color: #FFFFFF;
}

.pagination .page-link:focus {
  box-shadow: none;
}

.pagination .page-link {
  padding: 0;
  color: #515151;
  font-weight: 500;
  line-height: 25.29px;
  width: 27px;
  height: 27px;
  border-radius: 20px;
  border: 1px solid #515151;
  margin-right: 10px;
  text-align: center;
}

.prev .page-link,
.next .page-link {
  width: 100%;
  border: none;
}

.prev .page-link:hover,
.next .page-link:hover,
.prev .page-link:active,
.next .page-link:active {
  background: none !important;
}
</style>