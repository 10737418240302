<template>
    <div>
        <nav aria-label="" class="col-12 p-0">
            <div class="nav nav-tabs nav-content" id="nav-tab-asset" role="tablist">
                <a class="nav-link active" id="nav-your-asset-tab" data-bs-toggle="tab" data-bs-target="#nav-your-asset"
                    type="button" role="tab" aria-controls="nav-your-asset" aria-selected="true">
                    Tài sản đấu giá của bạn
                </a>
            </div>
        </nav>
        <div class="tab-content px-3" id="nav-tab-assetContent">
            <div class="tab-pane fade show active" id="nav-your-asset" role="tabpanel" aria-labelledby="nav-your-asset-tab">
                <div class="d-flex mt-3">
                    <img class="mx-2" style="width: 25px;height: 20px;" src="@/assets/front-end/icon/Important.png" alt="">
                    <h6 class="warning" style="font-size: 19px;font-weight: 500;font-family: Libre Bodoni;">
                        Lưu ý: Sau khi bạn trúng đấu giá , chúng tôi
                        sẽ liên hệ trực tiếp qua email và số điện thoại để hoàn tất các thủ tục và thanh toán khoản tiền còn
                        lại của tài sản
                    </h6>
                </div>

                <div class="col-12 p-2 row">
                    <div class="col-xl-12 col-xs-12 col-md-6 mb-2" v-for="(accountAsset, index) in listAsset.result">
                        <div class="d-xl-flex card px-1 col-12 p-0" @click="$router.push('/result-auction/'+encode(accountAsset.asset_id))" style="cursor: pointer;">
                            <div v-if="accountAsset.image.length !== 0" class="col-auto">
                                <img class="card-img" v-for="(image, index) in accountAsset.image" :src="$storage + image" 
                                alt="" @error="handleError" v-if="index == 0">
                            </div>
                            <div class="row w-100">
                                <div class="">
                                    <label class="col-12">{{ accountAsset.asset_name }}</label>
                                </div>
                                <div class="col-xl-8">
                                    <div class="col-12 d-flex align-items-center">
                                        <h6>Mã tài sản :</h6>
                                        <span>{{ accountAsset.asset_code }}</span>
                                    </div>
                                    <div class="col-12 d-flex align-items-center">
                                        <h6>Thời gian bắt đầu trả giá :</h6>
                                        <span>{{formateTime(accountAsset.start_time)}}</span>
                                    </div>
                                    <div class="col-12 d-flex align-items-center">
                                        <h6>Mã của bạn :</h6>
                                        <span>{{ info.member_code}}</span>
                                    </div>
                                    <div class="col-12 d-xl-flex align-items-center ">
                                        <h6>Số tiền trúng đấu giá :</h6>
                                        <div class="ml-2 warning"
                                            style="font-size: 20px;font-weight: 500;font-family: Roboto;">
                                            {{ parseInt(accountAsset.highest_price).toLocaleString("vi-VN") }} VNĐ
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-end col-xl-4 col-6 align-items-end pr-3 view-detail">
                                    <b-link :to="'/product-detail/'+ encode(accountAsset.asset_id)"
                                        style="font-weight: 400;font-size: 15px; font-family: Roboto;" class="warning">
                                        Xem chi tiết >></b-link>
                                </div>
                            </div>
                        </div>
                        <div class="sub-content" style="right: 5%;">
                            <div class="">
                                <div class="success" style="color: #515151 !important;">
                                    <img style="width: 35px;height: 35px;" src="@/assets/front-end/icon/Ok.png" alt="">
                                    Trúng đấu giá
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            listAsset:{},
            info:{},
            params: {
                asset_name: '',
                asset_code: '',
                category_id: '',
                owner: '',
                to_price: '',
                from_price: '',
                from_step_price: '',
                to_step_price: '',
                winner: JSON.parse(localStorage.getItem('userInfo')).id,
                status: '',
                description: '',
                page: 1,
                per_page: 10,
                order: 'latest',
                auction_id: "",
            },
        }
    },
    mounted(){
        this.info = JSON.parse(localStorage.getItem('userInfo'))
        this.getListAssetForMember(this.params)
    }
}
</script>
<style scoped lang="css">
a.warning {
    text-decoration: none;
}
</style>