<template>
    <div>
        <b-modal id="" ref="approve" centered hide-footer hide-header modal-class="approve" content-class="modal-width">
            <div class="approve text-center" :class="{'overlay' : isLoading}">
                <div v-if="this.type == 'approve'">
                    <img src="@/assets/admin/icon/warning.png" alt="" class="mb-3">
                    <h5>Phê duyệt đăng tài sản</h5>
                    <p>Bạn đang phê duyệt đăng tài sản mới <br/>
                        Mã tài sản: <span style="font-weight: 500;">{{ this.code }}</span>
                    </p>
                    <b-button class="mt-3" block @click="approveAsset('approve')" :disabled="isLoading">Xác nhận</b-button>
                </div>
                
                <div v-else>
                    <img src="@/assets/admin/icon/warning.png" alt="" class="mb-3">
                    <h5>Từ chối yêu cầu phê duyệt tài sản</h5>
                    <p>Bạn đã từ chối yêu cầu duyệt bài đăng tài sản mới <br/>
                        Hãy tích chọn hoặc nhập lý do từ chối để gửi thông báo đến nhân viên
                    </p>
                    <b-form class="text-left form-cancel-asset">
                        <p>Lý do từ chối: </p>
                        <b-form-group >
                            <b-form-radio-group v-model="selected" @input="checkDisabled">
                                <b-form-radio value="Thiếu thông tin tài sản">Thiếu thông tin tài sản</b-form-radio>
                                <b-form-radio value="Sai thông tin tài sản">Sai thông tin tài sản</b-form-radio>
                                <b-form-radio value="Thiếu giấy tờ liên quan">Thiếu giấy tờ liên quan</b-form-radio>
                                <b-form-radio value="Sai chính tả">Sai chính tả</b-form-radio>
                                <b-form-radio value="Ảnh tài sản không đúng">Ảnh tài sản không đúng</b-form-radio>
                                <b-form-radio value="Lý do khác">Lý do khác</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-textarea
                            placeholder="Nhập lí do"
                            rows="3"
                            max-rows="6"
                            style="overflow-y: auto !important;"
                            v-model="text"
                            :disabled="disabled"
                        ></b-form-textarea>
                        <div class="text-center">
                            <b-button class="mt-3" block @click="approveAsset('reject')" :disabled="isLoading">Xác nhận</b-button>
                        </div>
                    </b-form>
                </div>
            </div>
            <div class="loader" v-if="isLoading"></div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            type: '',
            id: '',
            code: '',
            selected: 'Thiếu thông tin tài sản',
            text: '',
            disabled: true,
            isLoading: false
        }
    },
    computed: {

    },
    methods: {
        show(type, id, code) {
            this.type = type
            this.id = id
            this.code = code
            this.$refs['approve'].show()
        },
        hideModal() {
            this.$refs['approve'].hide()
        },
        emitModalVisible(isClose = false) {
            this.$emit("handleCloseModal", isClose);
        },
        checkDisabled() {
            if(this.selected == "Lý do khác") {
                this.disabled = false
            } else {
                this.disabled = true
            }
        },
        async approveAsset(type) {
            this.isLoading = true
            var note = this.selected
            if(this.selected == "Lý do khác") {
                note = this.text
            }
            const storedData = localStorage.getItem('admInfo')
            if (storedData) {
                var info = JSON.parse(storedData)
            }

            if(type == 'approve') {
                const data = {
                    "asset_id": this.id
                }
                await this.$axios.put('/asset/approveAsset', data, {
                    headers: {
                        'x-access-token': info.accessToken,
                    } 
                }).then(res => {
                    this.$toast.success('Phê duyệt tài sản thành công!')
                    this.$refs['approve'].hide()
                    this.emitModalVisible(false)
                    this.isLoading = false
                }).catch(err => {
                    this.$toast.error('Phê duyệt tài sản không thành công!')
                    this.isLoading = false
                })
            } else {
                const data = {
                    "asset_id": this.id,
                    "note" : note
                }
                await this.$axios.put('/asset/rejectAsset', data, {
                    headers: {
                        'x-access-token': info.accessToken,
                    } 
                }).then(res => {
                    this.$toast.success('Từ chối phê duyệt tài sản thành công!')
                    this.$refs['approve'].hide()
                    this.emitModalVisible(false)
                    this.isLoading = false
                }).catch(err => {
                    this.$toast.error('Từ chối phê duyệt tài sản không thành công!')
                    this.isLoading = false
                })
            }
        }
    }
}
</script>

<style>
    .approve .modal-width {
        width: 455px;
    }

    .approve .form-cancel-asset .custom-radio {
        display: flex;
    }

    .approve .form-cancel-asset .custom-radio label{
        margin-left: 10px;
    }

    .approve .form-cancel-asset textarea {
        background: #E6E6E6;
        border: 1px solid #CCCCCC;
        border-radius: 10px;
    }

    .approve .form-cancel-asset textarea:focus {
        box-shadow: none;
    }
</style>
<style lang="css" scoped>

    .approve h5,
    .approve p {
        color: #444444;
    }
    .approve button {
        background-color: #EE3439;
        color: #ffffff;
        font-weight: 500;
        border-radius: 10px;
        border: none;
        min-width: 163px;
        min-height: 42px;
    }

    .approve button:active,
    .approve button:focus {
        background-color: #EE3439 !important;
    }

    .approve button:hover {
        background-color: #EE3439;
    }

    .form-cancel-asset p {
        font-size: 15px;
        font-weight: 500;
    }

    @media (max-width: 375px) {
        .approve .btn-cancel {
            margin-right: 0 !important;
        }

        .approve p {
            font-size: 15px;
        }
    }
</style>