<template>
    <div class="list-member-aution">
        <div>
            <label>
                Kết quả cuộc đấu giá : Quyền sử dụng đất tại 151/62B Trần Hoàng Ra, phường Hưng Dan, Quận Ninh Bắc, Thành
                phố Cần Nội (lần bán 11)
            </label>
            <div class="flex-end mr-xl-5">
                <a href="#" class="btnn btn-auction px-5" @click="downloadPDF(formData)">
                    Xuất file
                </a>
            </div>
        </div>
        <div class="container p-5">
            <div class="text-center head">
                <h4>
                    (Ban hành kèm theo Thông tư số 06/2017/TT-BTP) <br>
                    CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM<br>
                    Độc lập – Tự do – Hạnh phúc<br>
                    _ _ _ _ _
                </h4>
                <h3>
                    DANH SÁCH KHÁCH HÀNG THAM GIA ĐẤU GIÁ
                </h3>
            </div>
            <div class="report-content">
                <table class='table compact table-border cell-border'>
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Họ và tên</th>
                            <th>Số CCCD/ hộ chiếu</th>
                            <th>Địa chỉ</th>
                            <th>Tham gia</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(mem, index) in member">
                            <td>{{ index + 1 }}</td>
                            <td>{{ mem.member_name }}</td>
                            <td>{{ mem.id_number }}</td>
                            <td>{{ mem.address }}</td>
                            <td>
                                <div v-if="mem.join_status"> <img src="@/assets/admin/icon/Done.png" alt=""></div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            member: [
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 0
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 0
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                },
                {
                    "member_name": "Nguyễn Văn A",
                    "id_number": "112310315311",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "join_status": 1
                }
            ],
            formData: {
                "path": "template/listMemberAuction"
            }
        }
    }
}
</script>
<style lang="css" scoped>
.head {
    color: #515151
}

label {
    font-size: 20px;
    font-weight: 500;
}

h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 39px;
}

.report-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
}

td,
th {
    text-align: center;
    border: 1px solid black;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0;
    padding: 0;
    border: 1px solid black;
    vertical-align: baseline
}

.btn-auction {
    margin-left: 20px;
    background-color: #EE3439;
    color: #FFFFFF !important;
    border-radius: 30px;
}
</style>