<template>
    <div :class="{ 'overlay': isLoading }">
        <div class="add_asset">
            <div class="d-flex align-items-center justify-content-between mb-3">
                <h5 style="font-weight: 700; margin: 0;">Tạo tài sản mới</h5>
                <button class="draft" @click="$router.push('/admin/draft-asset')">Bản nháp đã lưu</button>
            </div>
            <b-form class="form-add" @submit="addAsset('add')">
                <b-row>
                    <b-col cols="lg-7">
                        <h5>Thông tin chi tiết tài sản</h5>
                        <b-row style="align-items: center;">
                            <b-col cols="lg-4">
                                <label for="asset_name">Tên tài sản:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-form-input id="asset_name" class="mb-2 mr-sm-2 mb-sm-0"
                                    placeholder="Nhập tên, mô tả tài sản" v-model="dataAdd.asset_name"
                                    @input="formatTxt('asset_name')" required></b-form-input>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="price">Giá khởi điểm:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <input id="price" class="mb-2 mr-sm-2 mb-sm-0 form-control" placeholder="Nhập số tiền"
                                    v-model="dataAdd.price" maxlength="15" @input="formatInput('price')" required>
                            </b-col>

                            <!-- <b-col cols="lg-4">
                                <p>Mã tài sản đấu giá:</p>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <p style="color: #444444; font-weight: 500; margin-bottom: 0;">MTS-HQ6EV3</p>
                            </b-col> -->

                            <b-col cols="lg-4">
                                <label for="time_start_regis">Thời gian bắt đầu đăng ký tham gia đấu giá:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-row>
                                    <b-col cols="lg-6">
                                        <div>
                                            <b-form-datepicker id="time_start_regis" start-weekday=1 placeholder="Chọn ngày"
                                                locale="vi" v-model="date_start_time_res"></b-form-datepicker>
                                        </div>
                                    </b-col>
                                    <b-col cols="lg-6">
                                        <!-- <b-form-timepicker locale="vi" placeholder="Chọn thời gian" v-model="time_start_time_res"></b-form-timepicker> -->
                                        <b-form-input v-model="time_start_time_res" type="time" locale="vi"
                                            required></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="time_end_regis">Thời gian kết thúc đăng ký:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-row>
                                    <b-col cols="lg-6">
                                        <div>
                                            <b-form-datepicker id="time_end_regis" start-weekday=1 placeholder="Chọn ngày"
                                                locale="vi" v-model="date_end_time_res"></b-form-datepicker>
                                        </div>
                                    </b-col>
                                    <b-col cols="lg-6">
                                        <!-- <b-form-timepicker locale="vi" placeholder="Chọn thời gian" v-model="time_end_time_res"></b-form-timepicker> -->
                                        <b-form-input v-model="time_end_time_res" type="time" locale="vi"
                                            required></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="price_hs">Tiền mua hồ sơ:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <input id="price_hs" class="mb-2 mr-sm-2 mb-sm-0 form-control" placeholder="Nhập số tiền"
                                    v-model="dataAdd.record_fee" maxlength="15" @input="formatInput('record_fee')" required>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="step">Bước giá:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <input id="step" class="mb-2 mr-sm-2 mb-sm-0 form-control" placeholder="Nhập bước giá"
                                    v-model="dataAdd.step_price" maxlength="15" @input="formatInput('step_price')" required>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="max_step">Số bước giá trả tối đa / lần:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <input id="max_step" class="mb-2 mr-sm-2 mb-sm-0 form-control"
                                    placeholder="Nhập số lần bước giá" v-model="dataAdd.step" maxlength="5"
                                    @input="formatInput('step')" required>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="deposit">Tiền đặt trước:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <input id="deposit" class="mb-2 mr-sm-2 mb-sm-0 form-control"
                                    placeholder="Nhập số tiền đặt trước cho tài sản" v-model="dataAdd.down_payment"
                                    maxlength="15" @input="formatInput('down_payment')" required>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="owner_name">Tên chủ tài sản:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-form-input id="owner_name" class="mb-2 mr-sm-2 mb-sm-0"
                                    placeholder="Nhập tên chủ tài sản" v-model="dataAdd.owner" @input="formatTxt('owner')"
                                    required></b-form-input>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="owner_address">Địa chỉ chủ tài sản:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-form-input id="owner_address" class="mb-2 mr-sm-2 mb-sm-0"
                                    placeholder="Nhập địa chỉ chủ tài sản" v-model="dataAdd.owner_address" @input="formatTxt('owner_address')"
                                    required></b-form-input>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="rep_owner">Người đại diện chủ tài sản:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-form-input id="rep_owner" class="mb-2 mr-sm-2 mb-sm-0"
                                    placeholder="Nhập người đại diện chủ tài sản" v-model="dataAdd.rep_owner" @input="formatTxt('rep_owner')"
                                    required></b-form-input>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="owner_position">Chức vụ người đại diện:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-form-input id="owner_position" class="mb-2 mr-sm-2 mb-sm-0"
                                    placeholder="Nhập chức vụ người đại diện" v-model="dataAdd.owner_position" @input="formatTxt('owner_position')"
                                    required></b-form-input>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="address">Nơi xem tài sản:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-form-input id="address" class="mb-2 mr-sm-2 mb-sm-0"
                                    placeholder="Nhập địa chỉ, nới xem tài sản" v-model="dataAdd.view_address"
                                    @input="formatTxt('view_address')" required></b-form-input>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="time_start">Thời gian bắt đầu trả giá:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-row>
                                    <b-col cols="lg-6">
                                        <div>
                                            <b-form-datepicker id="time_start" start-weekday=1 placeholder="Chọn ngày"
                                                locale="vi" v-model="date_start_time"></b-form-datepicker>
                                        </div>
                                    </b-col>
                                    <b-col cols="lg-6">
                                        <!-- <b-form-timepicker locale="vi" placeholder="Chọn thời gian" v-model="time_start_time"></b-form-timepicker> -->
                                        <b-form-input v-model="time_start_time" type="time" locale="vi"
                                            required></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="time_end">Thời gian kết thúc trả giá:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-row>
                                    <b-col cols="lg-6">
                                        <div>
                                            <b-form-datepicker id="time_end" start-weekday=1 placeholder="Chọn ngày"
                                                locale="vi" v-model="date_end_time"></b-form-datepicker>
                                        </div>
                                    </b-col>
                                    <b-col cols="lg-6">
                                        <!-- <b-form-timepicker locale="vi" placeholder="Chọn thời gian" v-model="time_end_time"></b-form-timepicker> -->
                                        <b-form-input v-model="time_end_time" type="time" locale="vi"
                                            required></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="cate">Danh mục tài sản:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-form-select id="cate" class="form-control form-select" v-model="dataAdd.category_id"
                                    required>
                                    <b-form-select-option :value="null">Chọn danh mục</b-form-select-option>
                                    <b-form-select-option v-for="item in listCate" :key="item.category_id"
                                        :value="item.category_id">{{ item.name }}</b-form-select-option>
                                </b-form-select>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="branch">Chi nhánh tổ chức đấu giá:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-form-select id="branch" class="form-control form-select" v-model="dataAdd.agency_id"
                                    required>
                                    <b-form-select-option :value="null">Chọn chi nhánh tổ chức đấu
                                        giá</b-form-select-option>
                                    <b-form-select-option v-for="item in listAgency" :key="item.agency_id"
                                        :value="item.agency_id">{{ item.agency_name }}</b-form-select-option>
                                </b-form-select>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="contractually">Số hợp đồng đấu giá:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-form-input id="contractually" class="mb-2 mr-sm-2 mb-sm-0"
                                    placeholder="Số hợp đồng đấu giá" v-model="dataAdd.contractually" @input="formatTxt('contractually')"
                                    required></b-form-input>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="contractually_date">Ngày ký hợp đồng đấu giá:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-form-datepicker id="contractually_date" start-weekday=1 placeholder="Chọn ngày"
                                                locale="vi" v-model="dataAdd.contractually_date"></b-form-datepicker>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="contract_annexes">Phụ lục hợp đồng đấu giá:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-form-input id="contract_annexes" class="mb-2 mr-sm-2 mb-sm-0"
                                    placeholder="Số hợp đồng đấu giá" v-model="dataAdd.contract_annexes" @input="formatTxt('contract_annexes')"
                                    required></b-form-input>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="contract_annexes_date">Ngày ký phụ lục hợp đồng:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-form-datepicker id="contract_annexes_date" start-weekday=1 placeholder="Chọn ngày"
                                                locale="vi" v-model="dataAdd.contract_annexes_date"></b-form-datepicker>
                            </b-col>
                        </b-row>

                        <h5 style="font-size: 20px; font-weight: 600;">THÔNG BÁO ĐẤU GIÁ</h5>
                        <ckeditor :editor="editor" v-model="dataAdd.auction_noti" :config="editorConfig" @ready="onReady">
                        </ckeditor>
                    </b-col>
                    <b-col cols="lg-5">
                        <h5>Hình ảnh tài sản:</h5>
                        <b-row>
                            <b-col cols="lg-3" class="mb-3" v-for="(data, index) in dataImg" :key="index">
                                <img :src="data.result" alt="" style="border-radius: 11px; width: 137px; height: 103px; object-fit: cover;">
                            </b-col>
                        </b-row>
                        <b-form-file class="mt-3 d-none" multiple id="file_img" ref="fileInput" @change="handleImgChange"
                            accept="image/png, image/gif, image/jpeg, image/jpg"></b-form-file>
                        <img src="@/assets/admin/icon/icon_add.png" onclick="$('#file_img').click()">
                        <h5>Thông tin tổ chức đấu giá </h5>
                        <b-row>
                            <b-col cols="lg-4">
                                <label for="unit"><img src="@/assets/icon/Company.png" alt=""
                                        style="padding-right: 14px;">Đơn vị tổ chức:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-form-input id="unit" class="mb-2 mr-sm-2 mb-sm-0"
                                    placeholder="Nhập đơn vị tổ chức đấu giá" v-model="dataAdd.org"
                                    @input="formatTxt('org')" required></b-form-input>
                            </b-col>

                            <b-col cols="lg-4">
                                <label><img src="@/assets/icon/Customer.png" alt="" style="padding-right: 14px;">Đấu giá
                                    viên:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-dropdown :text="text_dgv" class="button-list">
                                    <b-dropdown-item v-for="(dgv, index) in listDGV" :key="index"
                                        @click="getNameDGV(dgv.full_name, dgv.user_id)">
                                        <div class="d-flex align-items-center">
                                            <img :src="$storage + dgv.avatar" alt=""
                                                style="width: 45px; height: 45px; border-radius: 31px;"
                                                @error="handleError">
                                            <div class="ml-3">
                                                <p style="font-weight: 500; margin-bottom: 0;">{{ dgv.full_name }}</p>
                                                <p class="m-0">Đấu giá viên - {{ dgv.agency_name }}</p>
                                            </div>
                                        </div>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-col>

                            <b-col cols="lg-4">
                                <label for="add"><img src="@/assets/icon/Location.png" alt=""
                                        style="padding-right: 14px;">Địa điểm:</label>
                            </b-col>
                            <b-col cols="lg-8" class="input-form">
                                <b-form-input id="add" class="mb-2 mr-sm-2 mb-sm-0"
                                    placeholder="Địa điểm tổ chức, trụ sở công ty" v-model="dataAdd.address"
                                    @input="formatTxt('address')" required></b-form-input>
                            </b-col>
                        </b-row>

                        <h5>Tài liệu liên quan</h5>
                        <b-row>
                            <b-col>
                                <input class="custom-file-input mb-2" multiple type="file" @change="handleFileChange"
                                    accept="application/pdf" required>
                                <div v-for="(data, index) in dataFile" :key="index" class="mb-2">
                                    <img src="@/assets/icon/Note.png" alt="">
                                    {{ data.name }}
                                </div>
                            </b-col>
                        </b-row>

                        <h5 style="margin-top: 28px;">Quy chế đấu giá</h5>
                        <b-row>
                            <b-col>
                                <input class="custom-file-input mb-2" type="file"
                                    accept="application/pdf" @change="handleFile2Change" required>
                                <div v-for="(data, index) in dataFile2" :key="index" class="mb-2">
                                    <img src="@/assets/icon/Note.png" alt="">
                                    {{ data.name }}
                                </div>
                            </b-col>
                        </b-row>

                        <h5 style="margin-top: 28px;">Số quy chế đấu giá</h5>
                        <b-row>
                            <b-col>
                                <b-form-input id="add" class="mb-2 mr-sm-2 mb-sm-0"
                                    placeholder="Số quy chế đấu giá" v-model="dataAdd.statute"
                                    @input="formatTxt('statute')" required></b-form-input>
                            </b-col>
                        </b-row>

                        <h5 style="margin-top: 28px;">Ngày cấp quy chế đấu giá</h5>
                        <b-row>
                            <b-col>
                                <b-form-datepicker start-weekday=1 placeholder="Chọn ngày"
                                            locale="vi" v-model="dataAdd.statute_date"></b-form-datepicker>
                            </b-col>
                        </b-row>

                        <h5 style="margin-top: 28px;">Quy chế riêng</h5>
                        <b-row>
                            <b-col>
                                <b-form-textarea placeholder="Nhập quy chế riêng cho tài sản" rows="5" max-rows="8"
                                    v-model="dataAdd.own_regulation" @input="formatTxt('own_regulation')"
                                    required></b-form-textarea>
                            </b-col>
                        </b-row>

                        <h5 style="margin-top: 18px;">Hồ sơ yêu cầu đăng ký tham gia</h5>
                        <div class="btn-bg" :class="{ 'set-height': dataList.length !== 0 }">
                            <button class="btn-add-req" @click="$refs.listFile.show()" type="button">+ Thêm yêu cầu</button>
                            <p v-for="(data, index) in dataList" :key="index" class="pl-3">{{ data }}</p>
                        </div>

                        <div>
                            <button class="add_asset_btn" type="button" @click="$router.push('/admin/approve-asset')"
                                :disabled="isLoading">Hủy bỏ</button>
                            <button class="add_asset_btn" type="button" @click="addAsset('draft')" :disabled="isLoading">Lưu
                                bản nháp</button>
                            <button class="add_asset_btn add_asset_btn--red" type="submit" :disabled="isLoading">Tạo tài
                                sản</button>
                        </div>
                    </b-col>
                </b-row>
            </b-form>
            <listFile ref="listFile" @sendDataList="getDataList" />
        </div>
        <div class="loader" v-if="isLoading"></div>
    </div>
</template>
<script>
import listFile from "./modal/list_file.vue";
import Editor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-classic/build/translations/vi';
import axios from 'axios'
export default {
    components: { listFile },
    data() {
        function uploader(editor) {
            editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                return uploadImage(loader);
            };
        }

        function uploadImage(loader) {
            return {
                upload: () => {
                    return new Promise((resolve, reject) => {
                        loader.file.then(async (file) => {
                            try {
                                if (!file) return

                                var data = {
                                    'file': file,
                                    'path': 'asset/image/'
                                }

                                await axios.post('https://api.dgv.com.vn/api/common/uploadFile', data, {
                                    headers: {
                                        'x-access-token': '',
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }).then(res => {
                                    const url = 'https://api.dgv.com.vn/storage/' + res.data.list_result[0].path_tam
                                    resolve({ default: url })
                                })
                            } catch (error) {
                                reject(error)
                            }
                        })
                    })
                }
            }
        }
        return {
            text_dgv: 'Tên đấu giá viên',
            date_start_time_res: '',
            time_start_time_res: '',
            date_end_time_res: '',
            time_end_time_res: '',
            date_start_time: '',
            time_start_time: '',
            date_end_time: '',
            time_end_time: '',
            dataImg: [],
            dataFile: [],
            dataFile2: [],
            dataList: [],
            token: '',
            listAgency: [],
            listCate: [],
            listDGV: [],
            dataAdd: {
                asset_name: '',
                price: '',
                register_start_time: null,
                register_end_time: null,
                record_fee: '',
                step_price: '',
                step: '',
                down_payment: '',
                owner: '',
                view_address: '',
                start_time: null,
                end_time: null,
                category_id: null,
                agency_id: null,
                auction_noti: '',
                image: [],
                org: '',
                dgv: '',
                address: '',
                regulation: '',
                own_regulation: '',
                file: [],
                required_file: [],
                publish_status: '',
                contractually: '',
                contractually_date: null,
                contract_annexes: '',
                contract_annexes_date: '',
                statute: '',
                statute_date: null,
                owner_address: '',
                rep_owner: '',
                owner_position: '',
            },
            number: 0,
            isLoading: false,
            editor: Editor,
            editorConfig: {
                extraPlugins: [uploader],
                language: 'vi',
                // mediaEmbed: {
                //     previewsInData: true
                // }
                toolbar: {
                    items: [
                        'undo',
                        'redo',
                        '|',
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'strikethrough',
                        'underline',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'imageUpload',
                        'link',
                        '|',
                        'blockQuote',
                        'insertTable',
                        'alignment',
                        '|',
                        'fontFamily',
                        'fontSize',
                        'fontColor',
                        'fontBackgroundColor',
                    ]
                },
            }
        }
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
        this.getListAgency()
        this.getListCate()
        this.getListDGV()
    },
    methods: {
        handleImgChange(event) {
            this.dataImg = []
            const files = Array.from(event.target.files);
            files.forEach(file => {
                if (file) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        this.dataImg.push(reader)
                        this.dataAdd.image = []
                        this.upload('image', file)
                    };
                    reader.readAsDataURL(file);
                }
            });
        },
        handleFileChange(event) {
            this.dataFile = []
            const files = Array.from(event.target.files);
            files.forEach(file => {
                if (file) {
                    this.dataFile.push(file)
                    this.dataAdd.file = []
                    this.upload('file', file)
                }
            });
        },
        handleFile2Change(event) {
            this.dataFile2 = []
            const file = event.target.files[0];
            if (file) {
                this.dataFile2.push(file)
                this.upload('regulation', file)
            }
        },
        getDataList(data) {
            this.dataList = []
            this.dataAdd.required_file = []
            data.forEach(element => {
                this.dataList.push(element.required_file_name)
                this.dataAdd.required_file.push(element.id)
            });
        },
        formatInput(field) {
            if (this.dataAdd[field]) {
                const cleanedValue = this.dataAdd[field].replace(/[^\d]/g, '');
                this.number = parseInt(cleanedValue, 10);
                this.dataAdd[field] = this.number.toLocaleString();
            }
        },
        async upload(type, file) {
            if (type == 'image') {
                const data = {
                    'file': file,
                    'path': 'asset/image/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    this.dataAdd.image.push(res.data.list_result[0].path)
                })
            } else if (type == 'file') {
                const data = {
                    'file': file,
                    'path': 'asset/file/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    this.dataAdd.file.push(res.data.list_result[0].path)
                })
            } else {
                const data = {
                    'file': file,
                    'path': 'asset/file/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    this.dataAdd.regulation = res.data.list_result[0].path
                })
            }
        },
        async getListAgency() {
            const res = await this.$axios.get('/agency/getListAgency', {
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.listAgency = res.data
            }).catch(err => {
                if (err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if (err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                }
            })
        },
        async getListCate() {
            const res = await this.$axios.get('/category/getListCategory', {
                params: {
                    code: '',
                    name: '',
                    status: '',
                    parent_id: '',
                    page: '1',
                    per_page: '10000',
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if (res.status == 200) {
                this.listCate = res.data.result
            }
        },
        async getListDGV() {
            const res = await this.$axios.get('/auth/getListUser', {
                params: {
                    user_name: '',
                    user_code: '',
                    role_id: '3',
                    agency_id: '',
                    phone: '',
                    address: '',
                    email: '',
                    full_name: '',
                    gender: '',
                    birthday: '',
                    soTheDGV: '',
                    cccd: '',
                    page: '1',
                    per_page: '10000',
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if (res.status == 200) {
                this.listDGV = res.data.result
            }
        },
        getNameDGV(name, id) {
            this.text_dgv = name
            this.dataAdd.dgv = id
        },
        async addAsset(type) {
            event.preventDefault()
            if (this.date_start_time_res && this.date_start_time_res) {
                this.dataAdd.register_start_time = this.date_start_time_res + " " + this.time_start_time_res
            }
            if (this.date_end_time_res && this.time_end_time_res) {
                this.dataAdd.register_end_time = this.date_end_time_res + " " + this.time_end_time_res
            }
            if (this.date_start_time && this.time_start_time) {
                this.dataAdd.start_time = this.date_start_time + " " + this.time_start_time
            }
            if (this.date_end_time && this.time_end_time) {
                this.dataAdd.end_time = this.date_end_time + " " + this.time_end_time
            }
            this.dataAdd.price = this.dataAdd.price.replace(/[^\d]/g, '')
            this.dataAdd.record_fee = this.dataAdd.record_fee.replace(/[^\d]/g, '')
            this.dataAdd.step_price = this.dataAdd.step_price.replace(/[^\d]/g, '')
            this.dataAdd.step = this.dataAdd.step.replace(/[^\d]/g, '')
            this.dataAdd.down_payment = this.dataAdd.down_payment.replace(/[^\d]/g, '')

            if (type == 'add') {
                if (!this.dataAdd.register_start_time || !this.dataAdd.register_end_time || !this.dataAdd.start_time || !this.dataAdd.end_time || this.dataAdd.image.length == 0 || this.dataAdd.required_file.length == 0 || !this.dataAdd.dgv) {
                    this.$toast.error('Vui lòng nhập đầy đủ thông tin!')
                } else {
                    this.dataAdd.publish_status = "1"
                    this.isLoading = true
                    await this.$axios.post('/asset/createAsset', this.dataAdd, {
                        headers: {
                            'x-access-token': this.token,
                        }
                    }).then(res => {
                        this.$toast.success('Thêm tài sản thành công!')
                        this.$router.push('/admin/approve-asset')
                        this.isLoading = false
                    }).catch(err => {
                        this.$toast.error('Thêm tài sản không thành công!')
                        this.isLoading = false
                    })
                }
            } else {
                this.dataAdd.publish_status = "0"
                this.isLoading = true
                await this.$axios.post('/asset/createAsset', this.dataAdd, {
                    headers: {
                        'x-access-token': this.token,
                    }
                }).then(res => {
                    this.$toast.success('Lưu bản nháp tài sản thành công!')
                    this.$router.push('/admin/draft-asset')
                    this.isLoading = false
                }).catch(err => {
                    this.$toast.error('Lưu bản nháp tài sản không thành công!')
                    this.isLoading = false
                })
            }
        },
        onReady(editor) {
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formatTxt(item) {
            this.dataAdd[item] = this.dataAdd[item].replace(/[<>\[\]]/g, '')
        }
    }
}
</script>
<style lang="css" scoped>
.set-height {
    height: fit-content !important;
    padding-bottom: 20px;
}

.add_asset {
    color: #515151;
    font-family: Roboto;
}

.add_asset h5 {
    font-size: 20px;
    font-weight: 500;
    line-height: 36px;
}

.form-add {
    box-shadow: 2px 2px 6px 0px #00000026;
    background-color: #FFFFFF;
    border-radius: 15px;
    padding: 19px 10px 32px 30px;
}

.form-add .col-lg-8 {
    margin-bottom: 15px;
}

.form-add input.form-control,
.form-add .form-select,
.form-add textarea.form-control {
    background-color: #EEEEEE;
    border: none;
    border-radius: 15px;
    padding: 11px 12px;
}

.form-add textarea.form-control {
    overflow-y: auto !important;
}

.form-add input.form-control:focus,
.form-add .form-select:focus,
.form-add textarea.form-control {
    box-shadow: none;
}

.form-add input.form-control::placeholder {
    color: #B4B4B4;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input {
    color: #515151;
    margin-left: 100px;
    background: #EEEEEE;
    border-radius: 15px;
    padding: 3px 0;
}

.custom-file-input::before {
    position: absolute;
    left: 10px;
    content: 'Chọn file';
    display: inline-block;
    background: #444444;
    border-radius: 15px;
    padding: 6px 13px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
}

.add_asset button {
    background: none;
    border: none;
}

.add_asset .add_asset_btn {
    color: #EE3439;
    border: 1px solid #EE3439;
    border-radius: 15px;
    box-shadow: 2px 2px 6px 0px #00000026;
    width: 160px;
    height: 42px;
    border-radius: 20px;
    margin-right: 9px;
    margin-top: 20px;
}

.add_asset .add_asset_btn--red {
    color: #FFFFFF;
    background: #EE3439;
    width: 204px;
    height: 45px;
}

.btn-bg {
    background: #EEEEEE;
    border-radius: 15px;
    height: 105px;
}

.btn-add-req {
    color: #EE3439;
    font-weight: 500;
    padding: 10px 0 10px 15px;
}

.draft {
    color: #EE3439;
    border: 1px solid #EE3439 !important;
    border-radius: 20px;
    font-weight: 500;
    padding: 4px 38px;
}

.button-list {
    width: 100%;
}

@media (max-width: 1280px) {

    .custom-file-input {
        margin-left: 0;
    }
}

@media (max-width: 992px) {
    .add_asset_btn--red {
        margin-top: 0;
    }

    .b-form-datepicker {
        margin-bottom: 20px;
    }
}

@media (max-width: 575px) {
    .add_asset_btn--red {
        margin-top: 30px;
    }
}
</style>

<style>
.form-add button.h-auto {
    opacity: 0;
}

.form-add .no-gutters {
    margin: 0;
}

.form-add .b-calendar-grid-help {
    display: none;
}

.form-add .b-calendar .form-control:focus,
.form-add .b-form-datepicker.form-control.focus,
.form-add .form-control.focus {
    box-shadow: none;
    border: 1px solid #ced4da6e;
}

.form-add .text-truncate {
    padding: 0;
}

.form-add .b-form-datepicker,
.form-add .b-form-timepicker {
    background-color: #EEEEEE;
    border-radius: 12px;
    padding: 2px 12px;
}

.form-add .text-muted {
    color: #B4B4B4 !important;
}

.form-add .button-list .dropdown-toggle {
    justify-content: flex-start;
    background-color: #EEEEEE;
    border-radius: 15px;
    padding: 12.5px 12px;
}
</style>