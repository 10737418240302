<template>
    <div class="row">
        <div class="col-12 cate_name">
            {{ cate.name }}
        </div>
        <b-col cols="xl-3">
            <div class="d-xl-flex d-none sidebar">
                <img class="sidebar_img" :src="this.$storage+cate.image" alt="" @error="handleError">
                <div class="sidebar_txt">
                    <b-link :to="'/cate-detail/'+cate.category_id">Xem tất cả</b-link>
                </div>
            </div>
        </b-col>
        <b-col cols="xl-9">
            <b-row class="m-b">
                <b-col cols="md-6 col-sm-6" v-for="(product, index) in listAsset.result" :key="index">
                    <b-link :to="'/product-detail/'+encode(product.asset_id)" class="category-item">
                        <div v-if="product.image.length !== 0" >
                            <img v-for="(image, index) in product.image" :src="$storage + image" 
                                alt="" class="card-img" @error="handleError"  v-if="index == 0">
                        </div>
                        <img src="@/assets/images/th.jpg" alt="" v-else class="card-img" @error="handleError">
                        <div class="category-item_txt" >
                            <p class="category-item_quote">{{ product.asset_name }}</p>
                            <p>Chủ tài sản: <span>{{ product.owner }}</span></p>
                            <p>Giá khởi điểm: <span class="text-red">
                                {{ parseInt(product.price).toLocaleString("vi-VN") }} VNĐ
                            </span></p>
                            <p>Thời gian đấu giá: <span>{{ formateTime(product.start_time, "DD/MM/YYYY HH:mm") }}</span></p>
                        </div>
                    </b-link>
                </b-col>
            </b-row>
        </b-col>
    </div>
</template>
<script>
export default{
    props:['cate'],
    data(){
        return {
            params:{
                asset_name: "",
                asset_code: "",
                asset_status: "",
                category_id: "",
                owner: "",
                from_price: "",
                to_price: "",
                from_step_price: "",
                to_step_price: "",
                page: 1,
                per_page: 4,
                order: "latest",
                winner: "",
                status: "",
                description: "",
                auction_id: "",
            },
            listAsset:{}
        }
    },
    mounted(){
        this.params.category_id = this.cate.category_id
        this.getListAssetForMember(this.params)
    },
}
</script>
<style lang="css" scoped>
.cate_name{
    color: #515151;
    font-family: Libre Bodoni;
    font-size: 22px;
    font-weight: 500;
}
.card-img{
    max-width: unset;
    width: 192px;
    height: 192px;
    border-radius: 10px;
    object-fit: cover;
}
.sidebar_img{
    width: 308px;
    height: 422px;
}
.product-auction {
    color: #515151;
}

.product-auction .head {
    justify-content: space-between;
    margin-bottom: 20px;
}

h5 {
    font-family: Libre Bodoni;
    font-size: 24px;
    font-weight: 500;
    color: #515151;
    align-items: flex-end;
    display: flex;
}


.product-auction .head .title {
    font-size: 24px;
    line-height: 30px;
    font-family: Libre Bodoni;
    border: none;
}

.product-auction .form-search {
    position: relative;
}

.product-auction .form-search .form-control {
    border: 1px solid #B3B3B3;
    border-radius: 39px;
    width: 358px;
    height: 47px;
    padding-right: 80px;
}

.product-auction .form-search .form-control:focus {
    box-shadow: none;
}

.product-auction .form-search .search_ico {
    position: absolute;
    background: #515151;
    top: 2px;
    right: 2px;
    padding: 7px 21px;
    border-radius: 39px;
    cursor: pointer;
}

.product-auction .slide .slide-item {
    border: none;
    padding: 0;
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    min-height: 390px;
    border-radius: 10px;
}

.product-auction .slide .slide-item .btn {
    background-color: transparent;
    border-radius: 19px;
    color: #929292;
    text-align: left;
    font-size: 14px;
}

.product-auction .slide .slide-item__ttl {
    text-align: left;
    margin: 0;
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.product-auction .slide .slide-item .list-btn {
    margin: 0;
    padding-left: 0;
}

.product-auction .slide .slide-item .list-btn span {
    color: #515151;
    font-weight: 500;
}

.product-auction .category {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    margin: 24px 0 14px;
    font-family: Libre Bodoni;
}

.product-auction .sidebar_txt {
    position: absolute;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
    text-align: center;
    font-family: Libre Bodoni;
    font-weight: 500;
    width: 93%;
}

.sidebar {
    align-items: center;
    height: 100%;
}

.product-auction .sidebar_txt a {
    color: #FFFFFF;
    font-size: 22.48px;
    line-height: 28.09px;
    text-decoration: none;
    border: 1px solid #FFFFFF;
    border-radius: 30px;
    padding: 7px 34px
}

.product-auction .category-item {
    display: flex;
    align-items: center;
    border: 1px solid #BEBEBE;
    border-radius: 10px;
    padding: 7px;
    text-decoration: none;
}

.m-b .col-md-6:first-child {
    margin-bottom: 10px;
}

.product-auction .category-item_txt {
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto;
    line-height: 25.2px;
    padding-left: 9px;
    text-align: left;
}

.product-auction .category-item_txt .category-item_quote {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #515151;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    min-height: 73px;
}

.product-auction .category-item_txt p {
    margin-bottom: 9px;
    color: #929292;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.product-auction .category-item_txt span {
    font-weight: 600;
    color: #515151;
    font-family: Roboto;
}

.product-auction .text-red {
    color: #EE3439 !important;
}

@media (max-width: 1024px) {
    .sidebar {
        display: none !important;
    }

    .product-auction .col-md-9 {
        width: 100%;
    }

    .product-auction .list-cate {
        display: block !important;
    }
}

@media (max-width: 768px) {

    .product-auction .category-item {
        display: block;
        text-align: center;
        margin-bottom: 15px;
    }

    .product-auction .category-item_txt {
        padding-top: 15px;
    }

    .m-b .col-md-6:first-child {
        margin-bottom: 0;
    }
}

@media (max-width: 576px) {
    .product-auction .head {
        display: block !important;
    }

    .product-auction .form-search .form-control {
        width: 100%;
    }
}
</style>