<template>
    <div class="report">
        <div class="header">
            <Header />
        </div>
        <div class="p-3">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import Header from '../components/header.vue';
export default {
    components: {
        Header,
    }
}
</script>