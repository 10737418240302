<template>
    <div>
        <b-modal id="" ref="modalKickUser" centered hide-footer hide-header content-class="modal-style" modal-class="modal-kick" @hide="clearInput">
            <div class="kick-modal text-center">
                <img src="@/assets/admin/icon/warning.png" alt="">
                <h5 v-if="this.code == 'stop'">Dừng cuộc đấu giá</h5>
                <h5 v-else>Yêu cầu kick thành viên</h5>
                <p v-if="this.code == 'stop'">Bạn đã yêu cầu dừng cuộc đấu giá , hãy nhập lý do dừng để gửi thông báo đến những người tham gia . Sau khi cuộc đấu giá bị dừng sẽ chuyển trạng thái <span style="color: #EE3439; font-weight: 700;">Cuộc đấu giá không thành</span> và sẽ đấu giá lại vào một ngày khác</p>
                <p v-else>Bạn đã yêu cầu truất quyền tham gia đấu giá của thành viên <span style="font-weight: 500;">{{ this.code }}</span></p>
                <div class="text-left" style="color: #515151;">
                    <p style="font-weight: 500; margin: 0;">Lý do từ chối:</p>
                    <b-form-group v-if="this.code == 'stop'">
                        <b-form-radio-group v-model="selected">
                            <b-form-radio value="Lỗi hệ thống">Lỗi hệ thống</b-form-radio>
                            <b-form-radio value="Lỗi khác" @input="submitError">Lỗi khác</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                    <b-form-group v-else>
                        <b-form-radio-group v-model="selectedKick">
                            <b-form-radio value="Vi phạm quy chế đấu giá">Vi phạm quy chế đấu giá</b-form-radio>
                            <b-form-radio value="Lỗi khác" @input="submitError">Lỗi khác</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                    <b-form-textarea
                        placeholder="Nhập lí do"
                        rows="3"
                        max-rows="6"
                        v-model="text"
                        :disabled="isDisabled"
                    ></b-form-textarea>
                    <span v-if="showErr" class="error">Vui lòng nhập lí do</span>
                </div>
                <b-button class="mt-3 mr-3 btn-cancel" @click="hideModal()" block>Hủy bỏ</b-button>
                <b-button class="mt-3" block  @click="endAuction" v-if="this.code == 'stop'">Xác nhận</b-button>
                <b-button class="mt-3" block  @click="kickMember" v-else>Xác nhận</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            code: '',
            id: '',
            socket_id: '',
            auction_key: '',
            value_kick: '',
            value_stop: '',
            text: '',
            isDisabled: true,
            selected: 'Lỗi hệ thống',
            selectedKick: 'Vi phạm quy chế đấu giá',
            showErr: false,
            auction_id: '',
            asset_id: '',
            token: ''
        }
    },
    mounted() {
        const storedData = localStorage.getItem('auInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
        this.auction_id = this.decode(this.$route.params.auction_id);
        this.getAssetId()
    },
    methods: {
        submitError() {
            this.isDisabled = !this.isDisabled
            this.text = ''
        },
        clearInput() {
            this.text = ''
        },
        show(code, id, socket_id, auction_key) {
            this.code = code
            this.id = id
            this.socket_id = socket_id
            this.auction_key = auction_key
            this.$refs['modalKickUser'].show()
        },
        hideModal() {
            this.$refs['modalKickUser'].hide()
        },
        kickMember() {
            //SOCKET
            const key = ''
            const token = JSON.parse(localStorage.getItem('auInfo')).accessToken;
            const note = this.selectedKick
            if (note == 'Lỗi khác') {
                if (this.text == '')
                {
                    this.showErr = true
                } else {
                    // this.$socket(this.auction_key, key, token).emit("kickMember", {member_id: this.id, client_id: this.socket_id, note: this.text})
                    this.$emit("kickMember", this.id, this.socket_id, this.text);
                    this.$refs['modalKickUser'].hide()
                }
            } else {
                // this.$socket(this.auction_key, key, token).emit("kickMember", {member_id: this.id, client_id: this.socket_id, note: note})
                this.$emit("kickMember", this.id, this.socket_id, note);
                this.$refs['modalKickUser'].hide()
            }
        },
        endAuction() {
            const key = ''
            const token = JSON.parse(localStorage.getItem('auInfo')).accessToken;
            var note = this.selected
            if (note == 'Lỗi khác') {
                note = this.text
            }
            // this.$socket(this.auction_key, key, token).emit("destroyRoom", {note: note})
            this.$emit("destroyRoom", note);
            this.$socket(this.auction_key, key, token).on("destroyRoom", res => {
                if(res.result_status == 1) {
                    // this.$refs['modalKickUser'].hide()
                    this.$router.push('/auctioneer/auctiondetail-auction/'+ this.encode(this.asset_id))
                }
            })
        },
        async getAssetId() {
            const res = await this.$axios.get('/asset/getListAsset', {
                params:{
                    asset_name:"",
                    asset_code:"",
                    category_id: "",
                    owner: "",
                    from_price: "",
                    to_price: "",
                    from_step_price: "",
                    to_step_price: "",
                    page: 1,
                    per_page: 10,
                    order: "latest",
                    winner: "",
                    status: "",
                    description: "",
                    auction_id: this.auction_id
                },
                headers: {
                    'x-access-token': this.token
                }
            })

            if (res.status == 200) {
                this.asset_id = res.data.result[0].asset_id
            }
        }
    }
}
</script>

<style lang="css" scoped>

    .kick-modal {
        color: #444444;
    }
    button {
        text-transform: uppercase;
        background-color: #EE3439;
        color: #ffffff;
        font-weight: 700;
        border-radius: 10px;
        border: none;
        min-width: 163px;
        min-height: 42px;
    }

    button:active,
    button:focus {
        background-color: #EE3439 !important;
    }

    button:hover {
        background-color: #EE3439;
    }

    .btn-cancel {
        color: #EE3439;
        border: 1px solid #EE3439;
        background: #ffffff;
    }

    .btn-cancel:hover,
    .btn-cancel:focus,
    .btn-cancel:active {
        background: #ffffff !important;
        color: #EE3439;
        border: 1px solid #EE3439;
    }
    .error {
        font-size: 14px;
        text-align: center;
        display: block;
        margin-bottom: 20px;
        color: #EE3439;
        font-weight: 600;
    }
</style>

<style lang="css">
    .modal-kick .modal-style {
        border-radius: 17.47px;
        box-shadow: 0px 0px 38px 0px #00000040;
    }

    .kick-modal .custom-radio {
        align-items: center;
        display: flex; 
    }

    .kick-modal .custom-control-label {
        margin-left: 10px;
    }
    .kick-modal textarea {
        border-radius: 10px;
        overflow-y: auto !important;
        background-color: #E6E6E6;
        border: 1px solid #CCCCCC;
        max-width: 400px;
        margin: 0 auto;
    }

    .kick-modal textarea:focus {
        box-shadow: none;
        border: none;
        border: 1px solid #CCCCCC;
        background-color: #E6E6E6;
    }
</style>