<template>
    <div>
        <b-modal ref="modalReport" centered hide-footer hide-header content-class="modal-width" modal-class="modal-report" @hide="clearData">
            <div class="accept-modal text-center">
                <img src="@/assets/icon/Notice_ico.png" alt="">
                <h5 style="margin-top: 10px;">Báo cáo về cuộc đấu giá</h5>
                <p>Bạn đang yêu cầu báo cáo cuộc đấu giá với đấu giá viên<br/> Hãy nhập chi tiết lý do để gửi đến đấu giá viên.<br/> Đấu giá viên sẽ liên hệ với bạn</p>
                <b-form class="form-complain">
                    <b-form-group
                        label="Nội dung báo cáo:"
                        label-for="reason"
                    >
                        <b-form-textarea
                            id="reason"
                            v-model="reason"
                            type="text"
                            placeholder="Nhập báo cáo"
                            required
                            rows="5"
                            max-rows="10"
                            style="overflow-y: auto !important;"
                        ></b-form-textarea>
                        <span style="text-align: center; font-size: 13px; font-weight: 500; color: #EE3439;" v-if="showErr">Vui lòng nhập lý do khiếu nại</span>
                    </b-form-group>
                </b-form>
                <b-button class="mt-3 btn-cancel" block @click="hideModalReport()">HỦY BỎ</b-button>
                <b-button class="mt-3" block @click="sendComplain">GỬI BÁO CÁO</b-button>
            </div>
        </b-modal>

        <b-modal ref="statusComplain" centered hide-footer hide-header content-class="modal-width" modal-class="statusComplain">
            <div class="statusComplain text-center">
                <div v-if="this.inviteComplain == 1">
                    <img src="@/assets/icon/Ok.png" alt="">
                    <p style="font-weight: 500; margin-top: 15px;" class="success">{{ this.message }}</p>
                </div>
                <div v-else>
                    <img src="@/assets/front-end/icon/Group 831.png" alt="">
                    <p style="font-weight: 500; margin-top: 15px; color: #EE3439;">{{ this.message }}</p>
                </div>
            </div>
        </b-modal>

    </div>
</template>
<script>
export default {
    data() {
        return {
            reason: '',
            showErr: false,
            auction_id: '',
            asset_id: '',
            message: '',
            inviteComplain: ''
        }
    },
    methods: {
        show(auction_id, asset_id) {
            this.auction_id = auction_id
            this.asset_id = asset_id
            this.$refs.modalReport.show()
        },
        hideModalReport() {
            this.$refs.modalReport.hide()
        },
        clearData() {
            this.reason = ''
        },
        async sendComplain() {
            if (this.reason == '') {
                this.showErr = true
            } else {
                this.showErr = false
                const data = {
                    'complain' : this.reason,
                    'auction_id' : this.auction_id,
                    'asset_id' : this.asset_id
                }
                const storedData = localStorage.getItem('userInfo')
                if (storedData) {
                    var info = JSON.parse(storedData)
                }

                await this.$axios.post('/auction/complain', data, {
                    headers: {
                        'x-access-token': info.accessToken
                    }
                }).then((res) => {
                    this.message = "Gửi báo cáo thành công!"
                    this.inviteComplain = 1
                    this.$refs.statusComplain.show()
                    this.$refs.modalReport.hide()
                }).catch((err) => {
                    this.message = "Gửi báo cáo không thành công. Vui lòng thử lại!"
                    this.inviteComplain = 0
                    this.$refs.statusComplain.show()
                })
            }
        }
   }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #444;
        font-size: 20px;
        font-family: Roboto;
        font-weight: 500;
    }
    p {
        color: #444;
    }
    textarea {
        border-radius: 10px;
        border: 1px solid #CCC;
        background: #E6E6E6;
        overflow-y: auto !important;
    }
    textarea:focus {
        border: 1px solid #CCC;
        background: #E6E6E6;
        box-shadow: none;
    }
    button {
        border-radius: 9.646px;
        background-color: #EE3439;
        color: #FFF;
        text-align: center;
        font-size: 14.47px;
        font-family: Roboto;
        font-weight: 700;
        text-transform: capitalize;
        border: none;
        width: 155.004px;
        height: 42.079px;
    }

    button:hover, button:active {
        background-color: #EE3439 !important;
    }

    button.btn-cancel {
        margin-right: 10px;
        border: 1px solid #EE3439;
        background-color: #FFF;
        color: #EE3439;
    }

    button.btn-cancel:hover, button.btn-cancel:active {
        background-color: #FFF !important;
        color: #EE3439;
        border: 1px solid #EE3439;
    }
</style>

<style>
    .statusComplain .modal-width {
        width: 350px;
    }
    .form-complain label {
        color: #515151;
        font-size: 15px;
        font-family: Roboto;
        font-weight: 500;
        text-align: left;
    }
</style>