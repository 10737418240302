<template>
    <div>
        <h5 style="margin-bottom: 15px;">Duyệt tin tức mới</h5>
        <div class="wp-news">
            <b-row>
                <h5 style=" margin-bottom: 20px;">Tin tức chung</h5>
                <b-col cols="md-3" v-for="(item, index) in dataNews" :key="index" @click="$router.push('/admin/detail-news/' + encode(item.news_id))" style="margin-bottom: 30px;">
                    <div class="item">
                        <img :src="$storage + item.image" alt="newsImage" style="width: 100%; height: 206px; border-radius: 20px; object-fit: cover;" @error="handleError">
                        <p class="news_ttl">{{ item.title }}</p>
                        <p>Công ty đấu giá hợp danh DGV thông báo: <br>{{ item.title }}</p>
                        <div class="wp-btn">
                            <button>Từ chối</button>
                            <button>Phê duyệt</button>
                        </div>
                    </div>
                </b-col>
                <Paginate :page=page @set-page="setPage" style="margin-top: 10px;"/>
            </b-row>
        </div>
    </div>
</template>
<script>
import Paginate from '../../web-view/components/paginate.vue'
export default {
    components: {
        Paginate
    },
    data() {
        return {
            dataNews: [],
            token: '',
            page: {
                totalData: 0,
                totalPage: 0,
                currentPage: this.$route.query.page || 1,
                perPage: 12,
                classPrev: 'd-none',
                classNext: 'd-none'
            },
        }
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
        this.getDataNews()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getDataNews();
        },
        async getDataNews() {
            await this.$axios.get('/news/getListNewsForAdmin', {
                params: {
                    page: this.page.currentPage,
                    per_page: this.page.perPage,
                    approve_status: 0,
                    publish_status: 1
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.page.totalData = res.data.pagination.total_element
                this.page.totalPage = res.data.pagination.total_page
                this.dataNews = res.data.result
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if(err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                }
            })
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        font-family: Roboto;
        color: #515151;
        font-size: 22px;
        font-weight: 700;
    }
    .wp-news {
        font-family: Roboto;
        box-shadow: 2px 2px 6px 0px #00000026;
        background: #FFFFFF;
        border-radius: 15px;
        padding: 22px 31px 30px 32px;
        color: #515151;
    }

    .item {
        cursor: pointer;
        border-radius: 20px;
        background-color: #EEEEEE;
        padding-bottom: 15px;
        height: 100%;
    }

    .item p {
        font-size: 15px;
        line-height: 27px;
        padding: 0 7px 0 9px;
        margin: 0;
    }

    .item p.news_ttl {
        font-size: 18px;
        font-weight: 600;
        line-height: 32.4px;
        text-transform: uppercase;
        margin: 8px 0 2px;
    }

    .wp-btn {
        text-align: center;
        margin-top: 25px;
    }

    .wp-btn button:first-child {
        color: #EE3439;
        background-color: #FFFFFF;
        margin-right: 7px;
        margin-bottom: 10px;
    }

    button {
        background-color: #EE3439;
        border: 1px solid #EE3439;
        border-radius: 20px;
        color: #FFFFFF;
        padding: 3px 25px;
        width: 138px;
    }

    @media (max-width: 768px) {
        .item {
            width: max-content;
            margin: 0 auto 30px;
        }
        img {
            width: 100%;
        }
    }
    @media (max-width: 575px) {
        .item {
            width: 100%;
        }
    }
</style>