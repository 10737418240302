<template>
    <div class="InternetNotFound">
        <img src="@/assets/images/network_error.png" alt="">
    </div>
</template>
<script>
document.addEventListener("click", ()=>{console.log("jj");})
export default {
    name: "InternetNotFound",
    mounted(){
        
        window.addEventListener('online', () => 
        {
            console.log('online');
            this.$router.push('/')
            window.location.reload()
        }
    );
    }
};
</script>
<style lang="css" scoped>
    .InternetNotFound {
        width: 100%;
        position: relative;
    }
    .InternetNotFound img{
        width: 100%;
        height: 100vh;
        object-fit: contain;
    }

</style>
