<template>
    <div>
        <b-modal id="" ref="modalAccept" centered hide-footer hide-header content-class="modal-width" modal-class="modal-accept">
            <div class="accept-modal text-center">
                <img src="@/assets/admin/icon/warning.png" alt="">
                <h5 v-if="this.code == 'all'">Điểm danh tất cả thành viên tham gia</h5>
                <h5 v-else="">Điểm danh thành viên tham gia</h5>
                <p v-if="this.code == 'all'">Bạn đã điểm danh tất cả thành viên hiện trong điểm danh</p>
                <p v-else>Bạn muốn phê duyệt thành viên {{ this.code }} được quyền tham gia đấu giá tài sản ? </p>
                <b-button class="mt-3 mr-3 btn-cancel" @click="hideModal()" block>Hủy bỏ</b-button>
                <b-button class="mt-3" block  @click="confirmUser('all')" v-if="this.code == 'all'">Xác nhận</b-button>
                <b-button class="mt-3" block  @click="confirmUser()" v-else>Xác nhận</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            code: '',
            id: '',
            socket_id: '',
            auction_key:''
        }
    },
    methods: {
        show(code, id, socket_id, auction_key) {
            this.code = code
            this.id = id
            this.socket_id = socket_id
            this.auction_key = auction_key
            this.$refs['modalAccept'].show()
        },
        hideModal() {
            this.$refs['modalAccept'].hide()
        },
        confirmUser(type) {
            //SOCKET
            const key = ''
            const token = JSON.parse(localStorage.getItem('auInfo')).accessToken;
            // if(type == 'all') {
            //     this.$socket(this.auction_key, key, token).emit("approveMember", {member_id: "", member_socket_id: ""})
            // } else {
            //     this.$socket(this.auction_key, key, token).emit("approveMember", {member_id: this.id, member_socket_id: this.socket_id})
            // }
            this.$emit("approveMember", type, this.id, this.socket_id);
            this.$refs['modalAccept'].hide()
        }
    }
}
</script>
<style>
    .modal-accept .modal-width {
        width: 380px;
        border-radius: 17.47px;
        box-shadow: 0px 0px 38px 0px #00000040;
    }
</style>
<style lang="css" scoped>
    button {
        background-color: #EE3439;
        color: #ffffff;
        font-weight: 700;
        border-radius: 10px;
        border: none;
        min-width: 163px;
        min-height: 42px;
    }

    button:active,
    button:focus {
        background-color: #EE3439 !important;
    }

    button:hover {
        background-color: #EE3439;
    }

    .btn-cancel {
        color: #EE3439;
        border: 1px solid #EE3439;
        background: #ffffff;
    }

    .btn-cancel:hover,
    .btn-cancel:focus,
    .btn-cancel:active {
        background: #ffffff !important;
        color: #EE3439;
        border: 1px solid #EE3439;
    }
</style>