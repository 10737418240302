<template>
    <div class="bg" :class="{'overlay' : isLoading}">
        <div class="container">
            <b-row class="login_au justify-content-center">
                <b-col cols="md-6 w-auto">
                    <img src="@/assets/front-end/img/Group 874.png" alt="">
                </b-col>
                <b-col cols="md-6" style="max-width: 500px;">
                    <h5>Trang đăng nhập đấu giá viên</h5>
                    <b-form class="form-login" @submit="loginAuctioneer">
                        <b-form-group
                            label="Tên đăng nhập / Email:"
                            label-for="username"
                        >
                            <b-form-input
                                id="username"
                                v-model="form.user_name"
                                type="text"
                                placeholder="Nhập tên đăng nhập"
                                required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            label="Mật khẩu:" 
                            label-for="password"
                            style="position: relative;"
                        >
                            <b-form-input
                                id="password"
                                v-model="form.password"
                                :type="showPass ? 'text' : 'password'"
                                placeholder="Nhập mật khẩu"
                                required
                            ></b-form-input>
                            <a class="toggle-pass" @click="togglePasswordVisibility">
                                <b-icon icon="eye" font-scale="0.5" v-if="iconStt"></b-icon>
                                <b-icon icon="eye-slash" font-scale="0.5" v-else></b-icon>
                            </a>
                        </b-form-group>
                       <b-form-group style="text-align: center;">
                            <div class="d-flex align-items-center mb-3" style="height: 65px;">
                                <div id="svgCapcha" class="captcha"></div>
                                <p style="font-size: 35px; margin: 0 0 0 30px; cursor: pointer; color: #EE3439;" @click="renderCaptcha('refesh')"
                                v-if="reloadCapcha">
                                    <b-icon icon="arrow-counterclockwise" aria-hidden="true"></b-icon>
                                </p>
                            </div>
                            <b-form-input
                                id="captcha"
                                v-model="inputCaptcha"
                                placeholder="Nhập captcha"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <b-form-invalid-feedback :state="showErr">{{ message }}</b-form-invalid-feedback>
                        <b-button type="submit" class="btn-login">Đăng nhập</b-button>
                    </b-form>
                </b-col>
            </b-row>
        </div>
        <div class="loader" v-if="isLoading"></div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import {notification} from '@/service/fireBase'
export default {
    data() {
        return {
            reloadCapcha: false,
            iconStt: true,
            showPass: false,
            inputCaptcha: '',
            form: {
                user_name: '',
                password: '',
                device_token: null,
                device_name: ''
            },
            captcha: {
                image: null,
                text: '',
            },
            showErr: true,
            message:"",
            isLoading: false
        }
    },
    computed: {
        getDeviceToken() {
            return notification
        }
    },
    mounted(){
        this.renderCaptcha('new')
        if (window.innerWidth < 768) {
            this.form.device_name = "Mobile"
        } else if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
            this.form.device_name = "Tablet"
        } else if (window.innerWidth >= 1024 && window.innerWidth <= 1600) {
            this.form.device_name = "Laptop"
        } else {
            this.form.device_name = "PC"
        }
    },
    methods: {
        ...mapActions(['loginAu']),
        togglePasswordVisibility() {
            this.iconStt = !this.iconStt
            this.showPass = !this.showPass;
        },
        async loginAuctioneer() {
            event.preventDefault()
            if (this.getDeviceToken.token) {
                this.form.device_token = this.getDeviceToken.token
            }
            if (this.inputCaptcha == this.captcha.text) {
                this.isLoading = true
                await this.$axios.post('/auth/signin', this.form).then((res) => {
                    this.loginAu(res.data.userData);
                    this.$router.push('/auctioneer')
                    this.isLoading = false
                }).catch((err) => {
                    if (err.code == "ERR_NETWORK") {
                        this.$toast.error('Lỗi hệ thống')
                    } else {
                        this.message = err.response.data.error
                        this.$toast.error(err.response.data.error)
                    }
                    this.showErr = false
                    this.isLoading = false
                })
            } else {
                this.showErr = false
                this.$toast.error('Mã captcha không đúng')
                this.message = 'Mã captcha không đúng'
            }
        },
        renderCaptcha(type) {
            this.reloadCapcha = false
            if(type == 'refesh') {
                $('#svgCapcha svg').remove()
                this.reloadCapcha=false
            }
            this.$axios.get('/auth/generateCaptcha')
            .then(res=>{
                this.captcha.text = res.data.text
                this.captcha.image = res.data.image 
                $('#svgCapcha').append(res.data.image)
                this.reloadCapcha = true
            })
        }
    },
}
</script>
<style lang="css" scoped>

    .bg {
        background-image: url('@/assets/images/background.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .login_au {
        height: 100vh;
        align-items: center;
        justify-content: center;
    }

    h5 {
        color: #D6B877;
        font-family: Libre Bodoni;
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .captcha{
        background-color: #FFFFFF;
        width: 50%;
        border-radius: 10px;
        margin-bottom: 15px;
    }
    .btn-login {
        background-color: #D6B877;
        border: none;
        color: #333333;
        font-weight: 700;
        font-family: Libre Bodoni;
        width: 100%;
        height: 44px;
        text-transform: uppercase;
        box-shadow: 0px 0px 3.745985269546509px 0px #00000080;
        border-radius: 15px;
        margin-top: 30px;
    }
    .btn-login:focus {
        background-color: #D6B877;
        color: #333333;
    }

    .toggle-pass {
        position: absolute;
        color: #D6B877;
        font-size: 20px;
        top: 54%;
        right: 3%;
        cursor: pointer;
    }
    @media (max-width: 768px) {
        h5 {
            font-size: 24px;
        }

        .login_au {
            overflow-y: scroll;
        }
    }
</style>

<style>
    .login_au .form-login label {
        color: #FFFFFF;
        font-size: 18px;
        font-family: Roboto;
        margin-bottom: 10px;
    }

    .login_au .form-login input {
        background: #FFFFFF52;
        border: none;
        color: #FFFFFF80;
        border-radius: 37px;
        height: 46px;
    }

    .login_au .form-login input::placeholder {
        color: #FFFFFF80;
    }

    .login_au .form-login input:focus {
        box-shadow: none;
    }

    .login_au .toggle-pass svg {
        font-size: 24px !important;
    }
</style>