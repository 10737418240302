<template>
    <div class="row mb-4">
        <b-col cols="lg-8" class="list_price">
            <p class="ttl">Giá trả cao nhất:</p>
            <div class="highest" v-for="(data, index) in dataHighestBid" v-if="index == 0 && data.price !== null && data.action == 1">
                <p>{{ data.member_code }}</p>
                <p>{{ data.member_name }}</p>
                <p>{{ formateTime(data.time) }}</p>
                <p class="text-red">{{ parseInt(data.price).toLocaleString("vi-VN")}}VNĐ</p>
            </div>
            <p class="ttl">Lịch sử trả giá:</p>
            <b-table responsive="md" :fields="list_bid.fields" :items="list_bid.items" tbody-tr-class="my-2">
                <template #cell(member_code)="row">
                    <span :class="{'return_price':  row.item.kick == 1}" >
                        {{ row.item.member_code }}
                    </span>
                </template>
                <template #cell(member_name)="row">
                    <span :class="{'return_price':  row.item.kick == 1}" >
                        {{ row.item.member_name }}
                    </span>
                </template>
                <template #cell(asset_code)="row">
                    <span :class="{'return_price':  row.item.kick == 1}" >
                        {{ row.item.asset_code }}
                    </span>
                </template>
                <template #cell(price)="row">
                    <span :class="{'return_price': row.item.withdraw_status == 1 || row.item.kick == 1}" v-if="row.item.price == null">
                        0 VNĐ
                    </span>
                    <span :class="{'return_price': row.item.withdraw_status == 1 || row.item.kick == 1}" v-else>
                        {{ parseInt(row.item.price).toLocaleString("vi-VN") }} VNĐ
                    </span>
                    <span v-show="row.item.withdraw_status == 1" class="text-red">
                        Rút giá
                    </span>
                </template>

                <template #cell(time)="row">
                    <span style="font-weight: 700; opacity: 0.7; color: #111111;" :class="{'return_price': row.item.kick == 1}">{{ formateTime(row.item.time) }}</span>
                    <span style="font-weight: 500; display: block;" :class="{'return_price': row.item.kick == 1}">{{ formateDate(row.item.time) }}</span>
                </template>
            </b-table>
            <Paginate :page=page @set-page="setPage" />
        </b-col>

        <b-col cols="lg-4" class="user-join">
            <h5>Thành viên đã duyệt tham gia đấu giá</h5>
            <div class="d-flex align-items-center auction_search">
                <b-form class="search-form" style="width: 100%;">
                    <b-form-group>
                        <b-form-input v-model="keySearch" type="text" placeholder="Tìm kiếm thành viên" required></b-form-input>
                    </b-form-group>
                    <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                </b-form>
                <button><img src="@/assets/admin/icon/Adjust.png"></button>
            </div>
            <p class="mt-3">Số thành viên tham gia: <span style="font-weight: 500;">{{ this.user_join.length }}</span></p>
            <div class="user-join-item">
                <div class="info-user info-user--user" v-for="user in filteredItems" :class="{'info_client' : user.is_client}" :style="{ backgroundColor: getRandomColor(user.is_client) }">
                    <p class="user-code">{{ user.member_code }}</p>
                    <p class="user-name">{{ user.member_name }}</p>
                    <p>{{ user.client_role }}</p>
                </div>
            </div>
        </b-col>
    </div>
</template>
<script>
import Paginate from '../../../web-view/components/paginate.vue'
export default {
    components:{
        Paginate
    },
    data() {
        return {
            asset_id: '',
            dataHistory: [],
            page: {
                totalData: 0,
                totalPage: 0,
                currentPage: this.$route.query.page || 1,
                perPage: 10,
                classPrev: 'd-none',
                classNext: 'd-none'
            },
            list_bid: {
                fields: [
                    { key: 'member_code', label: 'Mã khách hàng', thClass: 'text-center col-md-2', tdClass: 'text-center col-md-2' },
                    { key: 'member_name', label: 'Họ và tên', thClass: 'text-center col-md-4', tdClass: 'text-center col-md-4' },
                    { key: 'time', label: 'Thời gian', thClass: 'text-center col-md-3', tdClass: 'text-center col-md-3' },
                    { key: 'price', label: 'Giá trả', thClass: 'text-center col-md-3', tdClass: 'text-center col-md-3' },
                ],
                items: [],
            },
            user_join: [],
            keySearch: '',
            colors: [
                '#84D677;',
                '#D67777',
                '#3FA5FF',
                '#16a085',
                '#3498db',
                '#6ab04c',
                '#f0932b',
                '#30336b',
                '#A3CB38',
                '#9980FA',
            ]
        }
    },
    computed: {
        filteredItems() {
            return this.user_join.filter(user => user.member_name.toLowerCase().includes(this.keySearch.toLowerCase()));
        },
        dataHighestBid() {
            return this.dataHistory.filter(data => data.action == 1 && data.price !== null);
        }
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getAuHistory();
        },
        async getAuHistory() {
            const data = {
                page : this.page.currentPage,
            };
            const storedData = localStorage.getItem('auInfo')
            if(storedData) {
                var info = JSON.parse(storedData)
            }
            const res = await this.$axios.get('/auction/getAuctionHistoryForDGV?page=' +data.page +'&per_page=10&asset_id=' + this.asset_id, {
                headers: {
                    'x-access-token': info.accessToken
                }
            })

            if(res.status == 200) {
                this.page.totalData = res.data.pagination.total_element
                this.page.totalPage = res.data.pagination.total_page

                this.list_bid.items = res.data.re
                this.dataHistory = [...this.dataHistory, ...res.data.re];
                for (let page = 2; page <= res.data.pagination.total_page; page++) {
                    this.$axios.get('/auction/getAuctionHistoryForDGV?page=' +page +'&per_page=10&asset_id=' + this.asset_id, {
                        headers: {
                            'x-access-token': info.accessToken
                        }
                    })
                    .then(response => {
                        const pageData = response.data.re;
                        this.dataHistory = [...this.dataHistory, ...pageData];
                    })
                }
            }
        },
        async getMemberJoined() {
            const storedData = localStorage.getItem('auInfo')
            if(storedData) {
                var info = JSON.parse(storedData)
            }

            await this.$axios.get('/auction/getListMemberJoin?asset_id=' + this.asset_id, {
                headers: {
                    'x-access-token': info.accessToken
                }
            }).then(res => {
                this.user_join = res.data
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAuctioneer')
                } else if (err.response.status == 401) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAuctioneer')
                }
            })
        },
        formateTime(time) {
            return this.$moment.utc(time).format("HH:mm:ss:SSS")
        },
        formateDate(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY")
        },
        getRandomColor(data) {
            const randomIndex = Math.floor(Math.random() * this.colors.length);
            if (data == 1) {
                return this.colors[randomIndex];
            }
        },
    },
    mounted() {
        this.asset_id = this.decode(this.$route.params.asset_id);
        this.getAuHistory();
        this.getMemberJoined();
    }
}
</script>
<style lang="css" scoped>

    .text-red {
        color: #EE3439;
        font-weight: 500;
        display: block;
    }
    .highest {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        background: #E7E7E7;
        border-radius: 15px;
        padding: 14px;
        color: #515151;
        font-weight: 500;
        flex-wrap: wrap;
    }
    .highest p {
        margin: 0;
    }

    p.ttl {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
    }

    .return_price {
        color: #EE3439 !important;
        text-decoration: line-through;
        opacity: 1 !important;
    }

    /* USER JOIN */
    .user-join {
        box-shadow: 2px 2px 6px 0px #00000026;
        padding: 8px 11px 24px 10px;
        border-radius: 15px;
        max-height: 850px;
        overflow-y: auto;
    }

    .user-join::-webkit-scrollbar {
        width: 2px;
    }
    .user-join::-webkit-scrollbar-thumb {
        background: #929292;
    }

    .user-join h5 {
        color: #515151;
        font-weight: 500;
        font-size: 17px;
        margin-bottom: 20px;
    }
    .user-join button {
        background: none;
        border: none;
    }

    .info-user {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-size: 14.92px;
        font-weight: 500;
        align-items: center;
        border-radius: 14px;
        background-color: #F8F8F8;
        padding: 10px 15px;
        margin-bottom: 10px;
        color: #FFFFFF;
    }

    .info-user p {
        margin-bottom: 7px;
    }
    .info-user .user-code {
        color: #FFFFFF;
        text-decoration: underline;
        cursor: pointer;
        margin-right: 20px;
    }
    .info-user--user .user-code {
        color: #3FA5FF;
    }
    .info-user .user-name {
        width: 65%;
    }
    .info-user--user {
        color: #515151;
    }
    .auction_search > div {
        width: 100%;
    }

    .info_client p{
        color: #FFF !important;
    }

    @media (max-width: 425px) {
        .highest {
            display: block;
        }
    }
</style>

<style lang="css">
  
    .list_price thead {
        position: sticky;
        top: 0;
        z-index: 99;
        height: 55px;
        background: #FFFFFF;
    }

    .list_price thead tr {
        font-size: 15px;
        color: #515151;
    }

    .list_price thead tr th {
        font-weight: 500;
    }

    .list_price .table > :not(caption) > * > * {
        border: none;
    }

    .list_price  tr {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 55px;
    }

    .list_price tbody tr {
        background: #F8F8F8;
        border-radius: 14px;
    }

    .list_price tbody td {
        font-size: 15px;
        color: #515151;
        font-weight: 500;
    }

    .list_price .th-btn{
        max-width: 117px;
    }

    @media (max-width: 425px) {
        .list_price  tr {
            display: table-row;
        }
    }

</style>