<template>
    <div class="tab-pane fade" :class="{ 'show active': this.$router.currentRoute.path == '/account/auction/delivery' }" 
     id="nav-delivery" role="tabpanel" aria-labelledby="nav-delivery-tab">
        <div class="d-flex mt-3 mb-3" >
            <img class="mx-2" style="width: 24px;height: 20px;" src="@/assets/front-end/icon/Important.png" alt="">
            <h6 class="warning" style="font-size: 19px;font-weight: 500;font-family: Libre Bodoni;">Lưu ý: Quý khách hàng
                phải nộp đầy đủ các
                khoản phí trước khi thời gian đấu giá bắt đầu
                mới có thể tham gia đấu giá tài sản.</h6>
        </div>
        <div class="row justify-content-around">
            <div class="col-xl-12 col-md-6 col-xs-12 p-2 d-xl-flex xl-border" v-for="(item, index) in dataAuction"
                :key="index" style="cursor: pointer;">
                <div class="col-xl-12 card" style="border-radius: 30px; box-shadow: 2px 2px 4px 0px #00000021;">
                    <img :src="$storage + item.thumbnail" alt="" style="border-radius: 30px;"
                        @click="getListAssetAuction(item)" @error="handleError">
                    <div class="row" @click="getListAssetAuction(item)">
                        <div>
                            <label class="col-12">{{ item.auction_name }}</label>
                        </div>
                        <div class="col-xl-auto">
                            <div class="col-12 d-flex align-items-center">
                                <h6>Mã cuộc đấu giá:</h6>
                                <span>{{ item.auction_code }}</span>
                            </div>
                            <div class="col-12 d-flex align-items-center">
                                <h6>Số lượng tài sản:</h6>
                                <span>{{ item.count_asset }}</span>
                            </div>
                            <div class="col-12 d-flex align-items-center">
                                <h6>Đấu giá viên:</h6>
                                <span>{{ item.full_name }}</span>
                            </div>
                        </div>
                        <div class="col-xl-auto">
                            <div class="col-12 d-flex align-items-center">
                                <h6>Thời gian bắt đầu:</h6>
                                <span>{{ formateTime(item.start_time) }}</span>
                            </div>
                            <div class="col-12 d-flex align-items-center">
                                <h6>Thời gian kết thúc:</h6>
                                <span>{{ formateTime(item.end_time) }}</span>
                            </div>
                            <div class="col-12 align-items-center">
                                <h6></h6>
                                <span style="margin-left: 0; display: inline-block;">{{ item.value }} </span>
                            </div>
                        </div>
                        <div class="col-12 d-flex align-items-center notificattion-btn" v-if="item.is_client">
                            <p class="success m-0" style="font-size: 14px; font-weight: 600;">
                                <img src="../../../assets/front-end/icon/imp.png" alt=""
                                    style="width: 18px; height: 18px;"> Bạn được mời tham gia cuộc đấu giá
                            </p>
                        </div>
                        <div class="col-12 d-flex align-items-center notificattion-btn" v-else>
                            <p class="success m-0" style="font-size: 14px;" v-if="item.approve_status == 1">
                                <img src="../../../assets/front-end/icon/imp.png" alt="" style="width: 18px;height: 18px;">
                                Đã được duyệt tham gia đấu giá
                            </p>
                            <p class="warning m-0" style="font-size: 14px; font-weight: 600;"
                            @click="$refs.noticeModal.show(item.asset_id, item.auction_id)" v-else>
                                <img src="../../../assets/front-end/icon/Important.png" alt=""
                                    style="width: 24px; height: 20px;"> Chưa được duyệt tham gia đấu giá
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-2 col-xlg-auto" style="text-align: center;">
                        <img src="@/assets/front-end/icon/Group1370.png" alt="" style="width: 70px; height: 70px">
                        <span class="warning" style="margin: 0; display: block;">
                            Thời gian đến đấu giá còn:
                            <countdown :duration="item.start_time" :key="item.auction_id"/>
                        </span>

                        <button class="btnn btn-primari btn-countdown" @click="showModal(item)" 
                        :disabled="item.kick_status == 1">Tham gia</button>
                    </div>
                </div>
            </div>
            <Paginate :page=page @set-page="setPage" class="mt-2" />
        </div>
        <joinAuction ref="joinAuction" />
        <notice_modal ref="noticeModal"/>
    </div>
</template>
<script>
import countdown from './countdown.vue'
import joinAuction from '../modal/join_auction.vue'
import Paginate from '../components/paginate.vue'
import notice_modal from '../modal/notice_modal.vue'
export default {
    components: {
        joinAuction,
        Paginate,
        countdown,
        notice_modal
    },
    data() {
        return {
            dataAuction: [],
            page: {
                totalData: 0,
                totalPage: 0,
                currentPage: this.$route.query.page || 1,
                perPage: 4,
                classPrev: 'd-none',
                classNext: 'd-none'
            },
            timeLeft: 0,
            timeStart: '',
        }
    },
    computed: {
        countdown() {
            return this.timeLeft ? this.formatTime(this.timeLeft) : '00:00:00';
        },
    },
    created() {
        this.getDataAuction()
    },
    methods: {
        formateTime(time) {
            return this.$moment.utc(time).format("HH:mm:ss DD/MM/YYYY")
        },
        showModal(item) {
             this.$refs.joinAuction.show(item)
             // tắt nhập key để vào lịa phòng
            // if (!item.attendance_status) {
            //     this.$refs.joinAuction.show(item)
            // }
            // else {
            //     this.$router.push('/auction-room/' + this.encode(item.asset_id))
            // }
        },
        async getDataAuction() {
            const res = await this.$axios.get('/auction/getListAuctionUpcoming', {
                params: {
                    page: this.page.currentPage,
                    per_page: this.page.perPage
                },
                headers: {
                    'x-access-token': localStorage.getItem('member')
                }
            })
            if (res.status == 200) {
                this.dataAuction = res.data.re
                this.page.totalData = res.data.pagination.total_element
                this.page.totalPage = res.data.pagination.total_page

                this.dataAuction.forEach(ele => {
                    this.timeStart = ele.start_time
                });

                //SET START TIME
                let startTime = this.$moment.utc(this.timeStart).format("x")
                let now = this.$moment.utc().format("x")
                let countdown = startTime - now
                if (countdown - 25200000 !== 0 && countdown - 25200000 > 0) {
                    this.timeLeft = parseInt(countdown);
                    this.startCountdown(this.timeLeft);
                }
            }
        },
        setDisableButton(end_time) {
            let now = new Date().getTime();
            let endTime = new Date(end_time).getTime();
            let countdown = endTime - now - 25200000
            if (countdown > 1800000 || countdown <= 0) {
                return true
            }
        },
        setPage(val) {
            this.page.currentPage = val;
            this.getDataAuction();
        },
        getListAssetAuction(item){
            this.$router.push({
                path: '/auction/auction_asset/' + item.auction_id,
            })
        }
    },

}
</script>