<template>
    <div class="time text-center mt-2 mb-2">
        <p>{{ currentTime }}</p>
        <p style="text-transform: capitalize;">{{ this.$moment().utcOffset(7).format('dddd') }} - {{
            this.$moment().utcOffset(7).format('l') }}</p>
    </div>
</template>
<script>
export default {
    data () {
        return {
            currentTime: null,
            server_time: null,
            delay: 0,
        }
    },
    mounted (){
        this.getServerTime()
    },
    methods:{
        async getServerTime() {
            await this.$axios('/common/getServerTime').then((res) => {
                this.delay = this.$moment(res.data.timeServer).utcOffset(7) - this.$moment()
                let timecount = setInterval(() => {
                    this.currentTime = this.$moment(Date.now() + this.delay).utcOffset(7).format('LTS');
                });
            })
        }
    }
}
</script>
<style scoped>
.time{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.time p {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    font-family: Libre Bodoni;
    color: #DB282C;
    line-height: 96%;
}

.time p:last-child {
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto;
    margin: 0;
}
</style>