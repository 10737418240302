<template>
    <div>
        <!-- item-detail -->
        <div class="au-auction">
            <b-tabs content-class="mt-3">
                <b-tab title="Cuộc đấu giá đang diễn ra" :active="dataAuctions.auction_status == 1"
                    @click="$router.push('/auctioneer/auction')"></b-tab>
                <b-tab title="Cuộc đấu giá sắp diễn ra" :active="dataAuctions.auction_status == 0"
                    @click="$router.push('/auctioneer/auction')"></b-tab>
                <b-tab title="Cuộc đấu giá đã kết thúc"
                    :active="dataAuctions.auction_status == 2 || dataAuctions.auction_status == 3"
                    @click="$router.push('/auctioneer/auction')"></b-tab>
            </b-tabs>
        </div>

        <b-col cols="md-12">
            <b-link class="item-auction">
                <img v-if="dataAuctions.thumbnail" :src="$storage + dataAuctions.thumbnail" alt="" width="290" height="auto"
                    style="border-radius: 20px; max-height: 225px; object-fit: cover;" class="item-auction_img" @error="handleError">
                <div v-if="dataAuctions.auction_status_id !== 0">
                    <img src="@/assets/icon/Auction2.png" alt="" class="image-status"
                        v-if="dataAuctions.auction_status_id == 1">
                    <img src="@/assets/icon/golden sold out.png" alt="" class="image-status" v-else>
                </div>
                <div style="margin-left: 10px; width: 100%;">
                    <p class="item-auction_txt">{{ dataAuctions.auction_name }}</p>

                    <div class="item-auction-listinfo">
                        <ul>
                            <li>Mã cuộc đấu giá: {{ dataAuctions.auction_code }}</li>
                            <li v-for="(dgv, index) in listDGV" :key="index" v-if="dgv.user_id == dataAuctions.dgv">Đấu giá viên: {{ dgv.full_name }}</li>
                            <!-- <li>Thời gian kết thúc đăng ký: {{ formateTime(dataAuctions.registration_end_time) }} </li> -->
                            <li>Thời gian bắt đầu: {{ formateTime(dataAuctions.start_time) }}</li>
                            <li>Thời gian kết thúc: {{ formateTime(dataAuctions.end_time) }}</li>
                            <li>Số tài sản đấu giá: {{ dataList.length }}</li>
                        </ul>
                        <div class="text-center">
                            <div class="status" v-if="dataAuctions.auction_status_id == 0">
                                <img src="@/assets/icon/icon_auction.png" alt="">
                                <p class="status-auction status-auction--pending">Chưa đấu giá</p>
                            </div>
                            <div class="status" v-if="dataAuctions.auction_status_id == 1">
                                <img src="@/assets/icon/icon_auction.png" alt="">
                                <p class="status-auction status-auction--pending">Đang đấu giá</p>
                            </div>
                            <!-- <div class="status" v-if="dataAuctions.auction_status_id == 2">
                                <img src="@/assets/icon/Ok.png" alt="">
                                <p class="status-auction">Đấu giá xong - chờ xác nhận</p>
                            </div> -->
                            <div class="status" v-if="dataAuctions.auction_status_id == 3">
                                <img src="@/assets/icon/close.png" alt="">
                                <p class="status-auction status-auction--pending">Đấu giá không thành</p>
                            </div>
                            <div class="status" v-if="dataAuctions.auction_status_id == 2">
                                <img src="@/assets/icon/Ok.png" alt="">
                                <p class="status-auction">Đấu giá thành</p>
                            </div>
                        </div>
                    </div>
                </div>
            </b-link>
            <div class="d-flex show-detail flex-wrap">
                <p>Danh sách tài sản trong cuộc đấu giá</p>
                <!-- <b-dropdown id="dropdown-1" text="Sắp xếp theo" class="m-md-2 sort">
                    <b-dropdown-item>Giá tăng dần</b-dropdown-item>
                    <b-dropdown-item>Giá giảm dần</b-dropdown-item>
                    <b-dropdown-item>Mới nhất</b-dropdown-item>
                    <b-dropdown-item>Cũ nhất</b-dropdown-item>
                </b-dropdown> -->
            </div>
            <b-row class="row-width">
                <b-col cols="xl-4 col-lg-4 col-md-6" v-for="(data, index) in dataList" :key="index" @click="pushRoute">
                    <b-link class="item-in-auction">
                        <img v-for="(photo, index) in data.image" v-if="index == 0"
                            :src="$storage + photo" @error="handleError" style="max-height: 225px; object-fit: cover;">
                        <img v-if="data.image.length == 0" src="@/assets/images/th.jpg" alt="">
                        <div v-if="data.asset_status !== 0">
                            <img src="@/assets/icon/Auction2.png" alt="" class="image-status image-status--list"
                                v-if="data.asset_status == 1" style="width: unset;">
                            <img src="@/assets/icon/golden sold out.png" alt="" class="image-status image-status--list"
                                v-else style="width: unset;">
                        </div>

                        <div>
                            <p>{{ data.asset_name }}</p>
                            <ul>
                                <li>Thời gian đấu giá: <span>{{ formatTime(data.start_time) }}</span></li>
                                <li>Giá khởi điểm: <span style="color: #EE3439;">{{
                                    parseInt(data.price).toLocaleString("vi-VN") }} VNĐ</span></li>
                                <li>Trạng thái:
                                    <span style="color: #EE3439;" v-if="data.asset_status == 0">Chưa đấu giá</span>
                                    <span style="color: #EE3439;" v-if="data.asset_status == 1">Đang đấu giá</span>
                                    <span style="color: #EE3439;" v-if="data.asset_status == 2">
                                        Đấu giá xong - chờ xác nhận</span>
                                    <span style="color: #EE3439;" v-if="data.asset_status == 3">Đấu giá không thành</span>
                                    <span style="color: #EE3439;" v-if="data.asset_status == 4">Đấu giá thành</span>
                                </li>
                            </ul>
                        </div>
                    </b-link>
                </b-col>
            </b-row>
        </b-col>
    </div>
</template>
    
<script>
export default {
    data() {
        return {
            auctionId: '',
            dataList: [],
            dataAuctions: [],
            token: '',
            listDGV: [],
        }
    },
    created() {
        const storedData = localStorage.getItem('auInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
        this.auctionId = this.decode(this.$route.params.auction_id);
        this.getDataListAsset();
        this.getDataAuction()
        this.getListDGV()
    },
    methods: {
        async getDataListAsset() {
            const res = await this.$axios.get('/asset/getListAsset', {
                params:{
                    asset_name:"",
                    asset_code:"",
                    category_id: "",
                    owner: "",
                    from_price: "",
                    to_price: "",
                    from_step_price: "",
                    to_step_price: "",
                    page: 1,
                    per_page: 10,
                    order: "latest",
                    winner: "",
                    status: "",
                    description: "",
                    auction_id: this.auctionId
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.dataList = res.data.result
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAuctioneer')
                } else if (err.response.status == 401) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAuctioneer')
                }
            })
        },
        async getDataAuction() {
            const res = await this.$axios.get('/auction/getAuctionDetail?auction_id=' + this.auctionId, {
                headers: {
                    'x-access-token': this.token
                }
            })

            if (res.status == 200) {
                this.dataAuctions = res.data
            }
        },
        formateTime(time) {
            return this.$moment.utc(time).format("HH:mm:ss DD/MM/YYYY")
        },
        formatTime(time) {
            return this.$moment.utc(time).format(" DD/MM/YYYY")
        },
        pushRoute() {
            if (this.dataList[0].asset_status !== 0 && this.dataList[0].asset_status !== 1) {
                this.$router.push('/auctioneer/auctiondetail-auction/' + this.encode(this.dataList[0].asset_id))
            } else {
                this.$router.push('/auctioneer/wait-room/' + this.encode(this.dataList[0].asset_id))
            }
        },
        async getListDGV() {
            const res = await this.$axios.get('/auth/getListUser', {
                params: {
                    user_name: '',
                    user_code: '',
                    role_id: '3',
                    agency_id: '',
                    phone: '',
                    address: '',
                    email: '',
                    full_name: '',
                    gender: '',
                    birthday: '',
                    soTheDGV: '',
                    cccd: '',
                    page: '1',
                    per_page: '10000',
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.listDGV = res.data.result
            }
        },
    }
}
</script>
    
<style lang="css" scoped>
/* ------ */
.item-auction {
    display: flex;
    color: #515151;
    background: #F8F8F8;
    padding: 11px 11px 15px;
    text-decoration: none;
    border-radius: 35px;
    box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.item-auction_txt {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 9px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.item-auction ul {
    margin-bottom: 0;
    list-style-type: disc;
    padding-left: 2rem;
}

.item-auction ul li {
    padding-bottom: 5px;
}

.item-auction ul li:last-child {
    padding-bottom: 0;
}

.show-detail {
    justify-content: space-between;
}

.show-detail p {
    color: #515151;
    font-size: 18px;
    line-height: 32.4px;
    margin: 10px 0 15px;
    font-weight: 500
}


/* ------- */
.item-in-auction {
    font-size: 14.05px;
    font-weight: 400;
    color: #515151;
    display: block;
    text-decoration: none;
    background-color: #F8F8F8;
    border-radius: 20px;
    padding-bottom: 5px;
}

.item-in-auction img {
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.item-in-auction p {
    margin: 0;
    padding: 5px 7px 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.item-in-auction ul {
    border: 1px solid #515151;
    border-radius: 18.73px;
    padding: 5px 0;
    margin: 10px auto;
    color: #929292;
    position: relative;
    max-width: 280px;
}

.item-in-auction ul span {
    color: #515151;
    font-weight: 500;
}

.item-in-auction ul::before {
    content: "";
    position: absolute;
    background-image: url("@/assets/icon/Auction1.png");
    background-repeat: no-repeat;
    width: 44px;
    height: 44px;
    top: 16px;
}

.item-in-auction ul li {
    padding-left: 50px;
    padding-bottom: 3px;
}

.row-width {
    margin-bottom: 15px;
}

.item-auction-listinfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.status-auction {
    margin-bottom: 0;
    color: #00B569;
    font-weight: 500;
    max-width: 126px;
    font-size: 15px;
    margin-top: 5px;
}

.status-auction--pending {
    color: #EE3439;
}

.status img {
    max-width: 50px;
    max-height: 50px;
}

.auction-item_ico {
    position: absolute;
    top: 2%;
    left: 3%
}

.image-status {
    position: absolute;
    z-index: 90;
    top: 3%;
    left: 3%;
}

.image-status--list {
    left: 7%;
}

@media (max-width: 1024px) {
    .item-auction-listinfo {
        font-size: 14px;
    }

    .item-auction_txt {
        font-size: 17px;
    }
}

@media (max-width: 768px) {
    .row-width .col-md-4 {
        width: 50%;
    }

    .item-auction_img,
    .item-in-auction img {
        width: 100%;
    }

    .item-auction-listinfo {
        display: block;
    }

    .item-auction-listinfo ul {
        margin-bottom: 15px;
    }

    .status-auction {
        max-width: 100%;
    }
}

@media (max-width: 425px) {
    .item-auction {
        display: block;
        font-size: 14px;
    }

    .item-auction_txt,
    .show-detail p {
        font-size: 14px;
    }

    .image-status {
        position: absolute;
        z-index: 90;
        top: 3%;
        left: 8%;
    }

    .image-status--list {
        left: 7%;
    }
}

@media (max-width: 1024px) {
    .item-auction-listinfo {
        font-size: 14px;
    }

    .item-auction_txt {
        font-size: 17px;
    }
}

@media (max-width: 768px) {

    .item-auction {
        display: block;
    }

    .row-width .col-md-4 {
        width: 50%;
    }

    .item-auction_img,
    .item-in-auction img {
        width: 100%;
    }

    .item-auction-listinfo {
        display: block;
    }

    .item-auction-listinfo ul {
        margin-bottom: 15px;
    }

    .status-auction {
        max-width: 100%;
    }
}

@media (max-width: 425px) {
    .item-auction {
        display: block;
        font-size: 14px;
    }

    .item-auction_txt,
    .show-detail p {
        font-size: 14px;
    }

    .row-width .col-md-4 {
        width: 100%;
    }

}
</style>
    