<template>
    <div class="au-delivery">
        <b-row>
            <b-col cols="lg-6">
                <div class="list_auction">
                    <h5 class="mb-0">Danh sách cuộc đấu giá sắp diễn ra</h5>
                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                        <div class="d-flex" style="margin-top: 15px;">
                            <b-form class="search-form">
                                <b-form-group>
                                    <b-form-input v-model="keySearch" type="text" placeholder="Tìm kiếm" required></b-form-input>
                                </b-form-group>
                                <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                            </b-form>
                            <!-- <button><img src="@/assets/admin/icon/Adjust.png" alt=""></button> -->
                        </div>
                        <button class="add_auction" @click="$router.push('/admin/add-auction')" v-if="intListRole.includes(29)">+ Tạo cuộc đấu giá mới</button>
                    </div>
                    <p class="total-num">Số cuộc đấu giá trong danh sách: <span>{{ filteredItems.length }}</span></p>
                    <div class="list-auction-item">
                        <div class="auction-item" v-for="(item, index) in filteredItems" :key="index" style="cursor: pointer;">
                            <img v-if="item.thumbnail" :src="$storage + item.thumbnail" alt="Auction Image" style="max-width: 192px; height: 198px; border-radius: 25px; width: 100%; object-fit: cover;" 
                                @error="handleError"
                                @click="showAssetDetail(item.auction_id)"
                            >
                            <div class="auction-item-info">
                                <p class="au_ttl" @click="showAssetDetail(item.auction_id)">{{ item.auction_name }}</p>
                                <div class="d-flex justify-content-between flex-wrap">
                                    <div class="info" @click="showAssetDetail(item.auction_id)">
                                        <p>Mã cuộc đấu giá: <span>{{ item.auction_code }}</span></p>
                                        <p>Số tài sản đấu: <span>{{ item.count_asset }}</span></p>
                                        <p>Đấu giá viên: <span>{{ item.full_name }}</span></p>
                                        <p>Thời gian bắt đầu: <span>{{ formateTime(item.start_time) }}</span></p>
                                        <p>Thời gian kết thúc: <span>{{ formateTime(item.end_time) }}</span></p>
                                        <p>{{ item.value }}</p>
                                    </div>
                                    <!-- <div class="d-flex align-items-end" @click="showAssetDetail(item.auction_id)">
                                        <p style="color: #00B569; font-size: 15px; font-weight: 500;" v-if="item.public_status == 1"><img src="@/assets/admin/icon/Eye.png" alt=""> Public</p>
                                        <p style="color: #EE3439; font-size: 15px; font-weight: 500;" v-else><img src="@/assets/admin/icon/Hide.png" alt=""> Private</p>
                                    </div> -->
                                    <div class="action">
                                        <p>Thời gian đến đấu giá còn:</p>
                                        <countdown :duration="item.start_time" />
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center">
                                                <p style="color: #00B569; font-size: 15px; font-weight: 500;" v-if="item.public_status == 1"><img src="@/assets/admin/icon/Eye.png" alt=""> Public</p>
                                                <p style="color: #EE3439; font-size: 15px; font-weight: 500;" v-else><img src="@/assets/admin/icon/Hide.png" alt=""> Private</p>
                                                <div class="ml-3">
                                                    <button @click="$router.push('/admin/edit-auction/' + encode(item.auction_id))" v-if="intListRole.includes(30)"><img src="@/assets/admin/icon/Edit.png" alt=""></button>
                                                    <button @click="deleteAuction(item.auction_id)" v-if="intListRole.includes(31)"><img src="@/assets/admin/icon/Trash.png" alt=""></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col cols="lg-6" v-if="showDetail">
                <div class="list_asset">
                    <h5>Danh sách tài sản trong cuộc đấu giá</h5>
                    <div class="d-flex">
                        <b-form class="search-form">
                            <b-form-group>
                                <b-form-input v-model="keySearchAsset" type="text" placeholder="Tìm kiếm" required></b-form-input>
                            </b-form-group>
                            <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                        </b-form>
                        <button><img src="@/assets/admin/icon/Adjust.png" alt=""></button>
                    </div>
                    <p class="total-num">Số tài sản trong cuộc đấu giá: <span>{{ filteredItemsAsset.length }}</span></p>
                    <div class="asset_item" v-for="(item, index) in filteredItemsAsset" :key="index">
                        <img :src="$storage + item.image[0]" alt="" style="border-radius: 20px; width: 188px; height: 188px; object-fit: cover;" @click="$router.push('/admin/list-auction/detail-asset/' + encode(item.asset_id))" @error="handleError">
                        <div class="asset-info">
                            <p class="au_ttl" style="line-height: 19px;" @click="$router.push('/admin/list-auction/detail-asset/' + encode(item.asset_id))">{{ item.asset_name }}</p>
                            <div class="d-flex align-items-end wp-button">
                                <div class="info" style="width: 100%;" @click="$router.push('/admin/list-auction/detail-asset/' + encode(item.asset_id))">
                                    <p>Mã tài sản: <span style="color: #3FA5FF; text-decoration: underline;">{{ item.asset_code }}</span></p>
                                    <p>Giá khởi điểm: <span style="color: #EE3439;">{{ parseInt(item.price).toLocaleString('vi') }} VNĐ</span></p>
                                    <p>Thời gian đấu giá: <span>{{ formateTime(item.start_time) }}</span></p>
                                    <p>Chi nhánh: <span>{{ item.agency_name }}</span></p>
                                    <p>Kết thúc đăng ký: <span>{{ formateTime(item.register_end_time) }}</span></p>
                                    <p>Phương thức đấu giá: <span>{{ item.auction_type }}</span></p>
                                </div>
                                <div class="text-right mr-3">
                                    <div class="mb-2">
                                        <button @click="$router.push('/admin/edit-asset/' + encode(item.asset_id))" v-if="intListRole.includes(30)"><img src="@/assets/admin/icon/Edit.png" alt=""></button>
                                        <button @click="deleteAssset('one', item.asset_id)" v-if="intListRole.includes(31)"><img src="@/assets/admin/icon/Trash.png" alt=""></button>
                                    </div>
                                    <button class="approve" @click="$router.push('app-joined/' + encode(item.asset_id))" v-if="intListRole.includes(27)">Duyệt đăng ký tham gia</button>
                                    <button class="list-cus" @click="$router.push('list-joined/' + encode(item.asset_id))" v-if="intListRole.includes(27)">Danh sách tham gia ĐG</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <Delete ref="delete" @handleCloseModal="listenDialog"/>
        <DeleteAuction ref="deleteAuction" @handleCloseModal="listenDialog"/>
    </div>
</template>
<script>
import search from '../../components/search_form.vue'
import countdown from '../../../web-view/account/countdown.vue'
import Delete from '../../assetManager/modal/delete.vue'
import DeleteAuction from '../modal/delete.vue'
export default {
    components: {
        search,
        countdown,
        Delete,
        DeleteAuction
    },
    data() {
        return{
            keySearch: '',
            keySearchAsset: '',
            dataAuction: [],
            token: '',
            showDetail: false,
            dataDetail: [],
            listRole: [],
            intListRole: [],
            params_agency: ''
        }
    },
    computed: {
        filteredItems() {
            return this.dataAuction.filter(dataAuction => dataAuction.auction_name.toLowerCase().includes(this.keySearch.toLowerCase()));
        },
        filteredItemsAsset() {
            return this.dataDetail.filter(dataDetail => dataDetail.asset_name.toLowerCase().includes(this.keySearchAsset.toLowerCase()));
        },
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
            info.roles.arr_resource.forEach(element => {
                this.listRole.push(element.resource_id)
            });
            this.intListRole = this.listRole.map(Number)

            if(info.roles.role_id == 1) {
                this.params_agency = ''
            } else {
                this.params_agency = info.agency_id
            }
        }
        this.getDataAuction()
    },
    methods: {
        formateTime(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY HH:mm:ss")
        },
        listenDialog(isClose = false) {
            if(!isClose) {
                this.showDetail = false
                this.getDataAuction()
            }
        },
        deleteAuction(id) {
            this.$refs.deleteAuction.show(id)
        },  
        deleteAssset(type, id) {
            this.$refs.delete.show(type, id)
        },
        async getDataAuction() {
            const res = await this.$axios.get('/auction/getListAuctionUpcomingForAdmin', {
                params: {
                    page: '1',
                    per_page: '10000',
                    approve_status: '1',
                    publish_status: '1',
                    agency_id: this.params_agency
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.dataAuction = res.data.re
            }
        },
        async showAssetDetail(id){
            const res = await this.$axios.get('/asset/getListAsset', {
                params: {
                    asset_name: '',
                    asset_code: '',
                    category_id: '',
                    owner: '',
                    from_price: '',
                    to_price: '',
                    from_step_price: '',
                    to_step_price: '',
                    order: 'latest',
                    winner: '',
                    status: '',
                    description: '',
                    auction_id: id,
                    publish_status: '',
                    page: '1',
                    per_page: '100000',
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.showDetail = true
                this.dataDetail = res.data.result
            }
        }
    }
}
</script>
<style lang="css" scoped>

    .au-delivery {
        color: #515151;
        font-family: Roboto;
    }
    .list_auction, .list_asset {
        box-shadow: 2px 2px 6px 0px #00000026;
        background: #FFFFFF;
        padding: 9px 12px 13px 13px;
        border-radius: 15px;
    }
    button {
        background: none;
        border: none;
    }

    .list_auction h5, .list_asset h5 {
        font-size: 20px;
        font-weight: 500;
        line-height: 36px;
    }

    p.total-num {
        font-size: 15px;
        font-weight: 500;
        margin: 10px 0;
    }

    .list-auction-item {
        overflow-y: auto;
        max-height: 750px;
    }

    .list-auction-item::-webkit-scrollbar {
        width: 0;
    }

    .auction-item {
        display: flex;
        align-items: center;
        box-shadow: 0px 0px 5px 0px #00000021;
        border: 1px solid #EAEAEA;
        border-radius: 30px;
        padding: 5px 13px 5px 5px;
        margin-bottom: 10px;
    }

    .auction-item-info {
        margin-left: 10px;
        width: 100%;
    }

    .auction-item-info p {
        margin-bottom: 0;
    }
    p.au_ttl {
        font-weight: 600;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .info p {
        font-size: 14px;
        color: #929292;
        margin-bottom: 2px;
    }

    .info p span {
        font-weight: 500;
        color: #515151;
        padding-left: 5px;
    }

    .info p:last-child {
        color: #515151;
        font-weight: 500;
    }

    .action {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-end;
    }

    .action p {
        color: #EE3439;
        font-size: 22px;
        font-weight: 600;
    }

    .action p:first-child {
        font-size: 12px;
    }
    .action button img,
    .asset-info button img{
        width: 15px;
        height: 15px;
        filter: grayscale(1);
    }

    .add_auction {
        color: #EE3439;
        font-weight: 500;
        border: 1px solid #EE3439;
        border-radius: 20px;
        padding: 6px 15px;
        margin-top: 15px;
    }

    .asset_item {
        border: 1px solid #B4B4B4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .asset-info p {
        margin-bottom: 2px;
    }

    .asset-info {
        margin-left: 10px;
        padding: 5px 0;
    }

    .asset-info .info p {
        font-size: 13px;
    }
    .asset-info .info p span {
        font-size: 14px;
    }

    button.approve {
        border: 1px solid #EE3439;
        border-radius: 20px;
        color: #EE3439;
        font-weight: 500;
        padding: 3px 10px;
        margin-bottom: 10px;
        font-size: 15px;
    }

    button.list-cus {
        border-radius: 20px;
        color: #FFFFFF;
        font-weight: 500;
        padding: 4px 11px;
        background: #EE3439;
        font-size: 15px;
    }

    @media (max-width: 1366px) {
        .wp-button {
            flex-wrap: wrap;
        }
    }
    @media (max-width: 1024px) {
        .add_auction {
            margin-top: 15px;
        }

        .wp-button {
            flex-wrap: wrap;
        }

        .list_auction {
            margin-bottom: 15px;
        }
    }
    @media (max-width: 768px) {
        .list_asset {
            margin-top: 20px;
        }

        .wp-button {
            flex-wrap: nowrap;
        }
    }

    @media (max-width: 425px) {
        .auction-item, .asset_item, .wp-button {
            flex-wrap: wrap;
        }
    }
</style>

<style>
    .au-delivery .search-form {
        width: 363px;
    }

    @media (max-width: 425px) {
        .au-delivery .search-form {
            width: max-content;
        }
    }
</style>