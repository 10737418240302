<template>
    <div :class="{'overlay' : isLoading}">
        <div class="approve_auction">
            <h5 style="font-size: 22px; font-weight: 700;" class="mb-3">Danh sách cuộc đấu giá cần duyệt</h5>
            <b-row>
                <b-col cols="lg-6">
                    <div class="list_auction">
                        <h5>Danh sách cuộc đấu giá cần duyệt</h5>
                        <div class="d-flex">
                            <b-form class="search-form">
                                <b-form-group>
                                    <b-form-input v-model="keySearch" type="text" placeholder="Tìm kiếm" required></b-form-input>
                                </b-form-group>
                                <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                            </b-form>
                            <button><img src="@/assets/admin/icon/Adjust.png" alt=""></button>
                        </div>
                        <p class="total-num">Số cuộc đấu giá trong danh sách: <span>{{ filteredItems.length }}</span></p>
                        <div class="list-auction-item">
                            <div class="auction-item" v-for="(item, index) in filteredItems" :key="index" style="cursor: pointer;">
                                <img :src="$storage + item.thumbnail" alt="Auction Image" @error="handleError" @click="showDetail(item.auction_id)" style="max-width: 192px; height: 192px; border-radius: 25px; width: 100%; object-fit: cover;">
                                <div class="auction-item-info">
                                    <p class="au_ttl" @click="showDetail(item.auction_id)">{{ item.auction_name }}</p>
                                    <div class="d-flex justify-content-between flex-wrap">
                                        <div class="info" @click="showDetail(item.auction_id)">
                                            <p>Mã cuộc đấu giá: <span>{{ item.auction_code }}</span></p>
                                            <p>Số tài sản đấu: <span>{{ item.count_asset }}</span></p>
                                            <p>Đấu giá viên: <span>{{ item.full_name }}</span></p>
                                            <p>Thời gian bắt đầu: <span>{{ formateTime(item.start_time) }}</span></p>
                                            <p>Thời gian kết thúc: <span>{{ formateTime(item.end_time) }}</span></p>
                                            <p>{{ item.value }}</p>
                                        </div>
                                        <div class="action">
                                            <div class="mb-3">
                                                <button @click="$router.push('/admin/edit-auction/' + encode(item.auction_id))"><img src="@/assets/admin/icon/Edit.png" alt=""></button>
                                                <button @click="deleteAuction(item.auction_id)"><img src="@/assets/admin/icon/Trash.png" alt=""></button>
                                            </div>
                                            <button class="btn-approve" @click="approveAuction('approve', item.auction_id, token, item.auction_code)" :disabled="isLoading">Phê duyệt</button>
                                            <button class="btn-cancel" @click="approveAuction('cancel', item.auction_id, token, item.auction_code)" :disabled="isLoading">Từ chối</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="lg-6" class="mt-md-3 mt-lg-0">
                    <div class="list_asset" v-if="showAsset">
                        <h5>Danh sách tài sản trong cuộc đấu giá</h5>
                        <div class="d-flex">
                            <b-form class="search-form">
                                <b-form-group>
                                    <b-form-input v-model="keySearchAsset" type="text" placeholder="Tìm kiếm" required></b-form-input>
                                </b-form-group>
                                <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                            </b-form>
                            <button><img src="@/assets/admin/icon/Adjust.png" alt=""></button>
                        </div>
                        <p class="total-num">Số tài sản trong cuộc đấu giá: <span>{{ filteredItemsAsset.length }}</span></p>
                        <div class="asset_item" v-for="(item, index) in filteredItemsAsset" :key="index" @click="$router.push('/admin/list-auction/detail-asset/' + encode(item.asset_id))">
                            <img v-if="item.image" :src="$storage + item.image[0]" alt="" style="border-radius: 20px; width: 188px; height: 188px; object-fit: cover;" @error="handleError">
                            <div class="asset-info">
                                <p class="au_ttl">{{ item.asset_name }}</p>
                                <div class="info">
                                    <p>Mã tài sản: <span style="color: #3FA5FF; text-decoration: underline;">{{ item.asset_code }}</span></p>
                                    <p>Giá khởi điểm: <span style="color: #EE3439;">{{ parseInt(item.price).toLocaleString('vi') }} VNĐ</span></p>
                                    <p>Thời gian đấu giá: <span>{{ formateTime(item.start_time) }}</span></p>
                                    <p>Chi nhánh: <span>{{ item.agency_name }}</span></p>
                                    <p>Kết thúc đăng ký: <span>{{ formateTime(item.register_end_time) }}</span></p>
                                    <p>Phương thức đấu giá: <span>{{ item.auction_type }}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <Approve ref="approve" @handleCloseModal="listenDialog"/>
            <Delete ref="delete" @handleCloseModal="listenDialog"/>
        </div>
        <div class="loader" v-if="isLoading"></div>
    </div>
</template>
<script>
import search from '../components/search_form.vue'
import Approve from './modal/approve.vue'
import Delete from './modal/delete.vue'
export default {
    components: {
        search,
        Approve,
        Delete
    },
    data() {
        return{
            keySearch: '',
            keySearchAsset: '',
            dataAuction: [],
            dataDetail: [],
            showAsset: false,
            token: '',
            params_agency: '',
            isLoading: false
        }
    },
    computed: {
        filteredItems() {
            return this.dataAuction.filter(auction => {
                const name = auction.auction_name ? auction.auction_name.toLowerCase() : '';
                return name.includes(this.keySearch.toLowerCase());
            });
        },
        filteredItemsAsset() {
            return this.dataDetail.filter(dataDetail => dataDetail.asset_name.toLowerCase().includes(this.keySearchAsset.toLowerCase()));
        },
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken

            if(info.roles.role_id == 1) {
                this.params_agency = ''
            } else {
                this.params_agency = info.agency_id
            }
        }
        this.getDataAuction()
    },
    methods: {
        async approveAuction(type, id, token, code) {
            if(type == 'approve') {
                const data = {
                    auction_id: id
                }
                this.isLoading = true
                await this.$axios.put('/auction/approveAuction', data, {
                    headers: {
                        'x-access-token': this.token,
                    } 
                }).then(res => {
                    this.$refs.approve.show(type, id, token, code)
                    this.showAsset = false
                    this.isLoading = false
                }).catch(err => {
                    this.$toast.error('Duyệt cuộc đấu giá không thành công!')
                    this.showAsset = false
                    this.isLoading = false
                })
            } else {
                this.$refs.approve.show(type, id, token, code)
            }
        },
        listenDialog(isClose = false) {
            if(!isClose) {
                this.getDataAuction()
            }
        },
        deleteAuction(id) {
            this.$refs.delete.show(id)
        },
        async showDetail(id){
            const res = await this.$axios.get('/asset/getListAsset', {
                params: {
                    asset_name: '',
                    asset_code: '',
                    category_id: '',
                    owner: '',
                    from_price: '',
                    to_price: '',
                    from_step_price: '',
                    to_step_price: '',
                    order: 'latest',
                    winner: '',
                    status: '',
                    description: '',
                    auction_id: id,
                    publish_status: '',
                    page: '1',
                    per_page: '100000',
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.showAsset = true
                this.dataDetail = res.data.result
            }
        },
        formateTime(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY HH:mm:ss")
        },
        async getDataAuction() {
            await this.$axios.get('/auction/getListAuctionToApprove', {
                params: {
                    page: '1',
                    per_page: '10000',
                    approve_status: '0',
                    publish_status: '1',
                    agency_id: this.params_agency
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res=> {
                this.dataAuction = res.data.re
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if(err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                } else {
                    this.$toast.error('Vui lòng thử lại!')
                }
            })
        },
    }
}
</script>
<style lang="css" scoped>

    .approve_auction {
        color: #515151;
        font-family: Roboto;
    }
    .list_auction, .list_asset {
        box-shadow: 2px 2px 6px 0px #00000026;
        background: #FFFFFF;
        padding: 9px 12px 13px 13px;
        border-radius: 15px;
    }
    button {
        background: none;
        border: none;
    }

    .list_auction h5, .list_asset h5 {
        font-size: 20px;
        font-weight: 500;
        line-height: 36px;
    }

    p.total-num {
        font-size: 15px;
        font-weight: 500;
        margin: 10px 0;
    }

    .list-auction-item {
        overflow-y: auto;
        max-height: 750px;
    }

    .list-auction-item::-webkit-scrollbar {
        width: 0;
    }

    .auction-item {
        display: flex;
        align-items: center;
        box-shadow: 0px 0px 5px 0px #00000021;
        border: 1px solid #EAEAEA;
        border-radius: 30px;
        padding: 0 13px 0 5px;
        margin-bottom: 20px;
    }

    .auction-item-info {
        margin-left: 10px;
        width: 100%;
    }

    .auction-item-info p {
        margin-bottom: 0;
    }
    p.au_ttl {
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .info p {
        font-size: 14px;
        color: #929292;
        margin-bottom: 5px;
    }

    .info p span {
        font-weight: 500;
        color: #515151;
        padding-left: 5px;
    }

    .info p:last-child {
        color: #515151;
        font-weight: 500;
    }

    .action {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 10px;
    }

    .action button img{
        width: 20px;
        height: 20px;
        filter: grayscale(1);
    }

    .action button.btn-approve {
        background: #EE3439;
        border: 1px solid #EE3439;
        color: #FFFFFF;
        font-weight: 500;
        border-radius: 20px;
        padding: 3px 17px;
        margin-bottom: 10px;
    }

    .action button.btn-cancel {
        border: 1px solid #EE3439;
        color: #EE3439;
        font-weight: 500;
        border-radius: 20px;
        padding: 3px 25px;
    }

    .asset_item {
        border: 1px solid #B4B4B4;
        border-radius: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .asset-info p {
        margin-bottom: 2px;
    }

    .asset-info {
        margin-left: 10px;
    }

    @media (max-width: 768px) {
        .list_asset {
            margin-top: 20px;
        }
    }

    @media (max-width: 425px) {
        .auction-item, .asset_item {
            flex-wrap: wrap;
        }
    }
</style>

<style>
    .approve_auction .search-form {
        width: 363px;
    }

    @media (max-width: 425px) {
        .approve_auction .search-form {
            width: max-content;
        }
    }
</style>