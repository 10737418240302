<template>
    <div class="news">
        <div class="header-home mt-2 w-100">
            <div>
                Tin tức
            </div>
            <div class="view-more">
                <b-link href="/news">Xem thêm >></b-link>
            </div>
        </div>
        <div class="col-12 cart">
            <b-link :to="'/news-detail/'+item.news_id" v-for="(item, index) in listNews.result" :key="index">
                <div class="p-2 d-md-flex" :class="{ 'border-bottom': index < listNews.result.length - 1 }">
                    <div class="col-md-9 col-xs-12">
                        <label class="">
                            {{ item.title }}
                        </label>
                    </div>
                    <div class="col-md-3">
                        <div class="col-12 flex-end align-items-center h-100">
                            <span class="mr-2">Ngày công khai:</span> {{ formateTime(item.approve_at) }}
                        </div>
                    </div>
                </div>
            </b-link>
        </div>
    </div>
</template>
<script>
export default {
    mounted(){
        this.getListNews(this.params)
    },
    data() {
        return {
            params:{
                page:1,
                per_page:7
            },
            listNews:{},
            
        }
    }
}
</script>