import { mapActions } from 'vuex';
export default {
    methods: {
        ...mapActions(['logoutUser']),
        async logout() {
            await this.$axios.post('/member/signoutMember', this.formData, {
                headers: {
                    'x-access-token': localStorage.getItem('member'),
                },
            }).then(res=>{
                localStorage.removeItem('userInfo')
                localStorage.removeItem('member')
                this.logoutUser();
                this.$router.push('/')
                window.location.reload();
            })          
        },
        
        setDisableButton(end_time) {
            let now = new Date().getTime();
            let endTime = new Date(end_time).getTime();
            let countdown = endTime - now - 25200000
            if (countdown > 1800000 || countdown <= 0) {
                return true
            }
        },
        

        
    }
}