<template>
    <div class="auction-session">
        <div>
            <label>
                Kết quả cuộc đấu giá : Quyền sử dụng đất tại 151/62B Trần Hoàng Ra, phường Hưng Dan, Quận Ninh Bắc, Thành
                phố Cần Nội (lần bán 11)
            </label>
            <div class="flex-end pr-xl-5">
                <b-link to="/report/listMemberAuction" class="btnn btn-outline px-2">
                    List thành viên tham gia đấu giá
                </b-link>
                <a href="#" class="btnn btn-auction px-5" @click="downloadPDF(formData)">
                    Xuất file
                </a>
            </div>
        </div>
        <div class="container p-5">
            <div class="text-center head">
                <h4>
                    (Ban hành kèm theo Thông tư số 06/2017/TT-BTP) <br>
                    CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM<br>
                    Độc lập – Tự do – Hạnh phúc<br>
                    _ _ _ _ _
                </h4>
                <h3>
                    BIÊN BẢN ĐẤU GIÁ TÀI SẢN
                </h3>
            </div>
            <div class="report-content">
                – Căn cứ quy định của Luật đấu giá tài sản;<br>
                – Thực hiện Hợp đồng dịch vụ đấu giá tài sản số ………………..………… ngày ………/….…/..…… giữa ………………………………………
                và………………………………………….<br>
                Hôm nay, vào hồi <strong>{{ report.time.hour }}</strong> giờ <strong>{{ report.time.minute
                }}</strong> phút
                ngày
                <strong>{{ report.time.date }}</strong>, tại
                <strong>{{ report.agency }}</strong> ,<br>
                – Tài sản đấu giá: <strong>{{ report.auction.auction_name }}</strong><br>
                – Mã cuộc đấu giá: <strong>{{ report.auction.auction_code }}</strong><br>
                – Mã tài sản: <strong>{{ report.auction.asset_code }}</strong><br>
                – Giá khởi điểm của tài sản: <strong>{{ report.auction.start_price }} VNĐ</strong><br>
                <strong>I. Thành phần tham dự:</strong><br>
                * Khách mời chứng kiến (Họ và tên, chức vụ, nơi công tác):<br>
                1. <strong>{{ report.auction.owner }} - Chủ tài sản</strong><br>
                2. <strong>{{ report.auction.auction_manager }} - Quản lý đấu giá</strong><br>
                3. <strong>{{ report.auction.auction_secretary }} - Thư ký đấu giá</strong><br>
                * Đấu giá viên (Họ và tên, số Thẻ đấu giá viên):<br>
                <strong>{{ report.aution_manager.name }} - số thẻ đấu giá viên :
                    {{ report.aution_manager.code }}</strong><br>
                * Người tham gia đấu giá (Họ và tên, số CMND/Hộ chiếu/số Thẻ căn cước công dân, địa chỉ liên lạc, nếu nhiều
                người
                thì có danh sách kèm theo):<br>
                Được liệt kê trong file PDF đính kèm<br>
                <strong>II. Diễn biến cụ thể của cuộc đấu giá:</strong><br>
                …………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………<br>
                …………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………<br>
                …………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………<br>
                …………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………<br>
                …………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………<br>
                …………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………<br>
                …………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………………<br>
                Sau <strong>{{ report.auction.count }}</strong> lần trả giá, người trả giá cao nhất là Ông/Bà
                <strong>{{ report.winner.name }}</strong>, địa chỉ
                <strong>{{ report.winner.address }}</strong><br>
                Chứng minh nhân dân /Hộ chiếu/Thẻ Căn cước công dân số: <strong>{{ report.winner.id_number }}</strong>
                ngày cấp: <strong>{{ report.winner.id_date }}</strong> nơi cấp:
                <strong>{{ report.winner.id_place }}</strong><br>
                với giá đã trả là <strong>{{ report.auction.end_price }}</strong> đồng (viết bằng chữ:
                <strong>{{ report.auction.end_price_to_str }}
                    Việt Nam đồng</strong>).<br>
                Cuộc đấu giá kết thúc vào lúc <strong>{{ report.auction.end_time }}</strong> giờ cùng ngày, các bên
                cùng thống
                nhất ký tên<br>
            </div>
            <table>
                <tr>
                    <td>
                        Đại diện người tham gia đấu giá <br>
                        (Ký, ghi rõ họ, tên)
                    </td>
                    <td>
                        Người có tài sản đấu giá<br>
                        (Ký, ghi rõ họ, tên)
                    </td>
                    <td>
                        Người trúng đấu giá<br>
                        (Ký, ghi rõ họ, tên)
                    </td>
                </tr>
                <tr class="signature">
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        Đấu giá viên<br>
                        (Ký, ghi rõ họ, tên)
                    </td>
                    <td></td>
                    <td>
                        Người trúng đấu giá<br>
                        (Ký, ghi rõ họ, tên)
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            report: {
                "time": {
                    "date": "14/05/2023",
                    "hour": 20,
                    "minute": 50
                },
                "agency": "Công ty đấu giá hợp danh DGV",
                "auction": {
                    "auction_name": "Quyền sử dụng đất tại 151/62B Trần Hoàng Ra, phường Hưng Dan, Quận Ninh Bắc, Thành phố Cần Nội (lần bán 11)",
                    "auction_code": "AHIHI-2020",
                    "start_price": "7.000.000.000 ",
                    "owner": "Ông Nguyễn Văn S",
                    "auction_manager": "Ông Nguyễn Văn D",
                    "auction_secretary": "Bà Nguyễn Thị B",
                    "surveyor": "Ông Nguyễn Văn N",
                    "count": 78,
                    "end_price": "7.580.000.000",
                    "end_price_to_str": "Bẩy tỷ năm trăm tám mươi triệu",
                    "end_time": "21h30"
                },
                "aution_manager": {
                    "name": "Ông Trần Thanh Tùng",
                    "code": "123454"
                },
                "winner": {
                    "name": "Nguyễn Văn Lương A",
                    "code": "123454",
                    "address": "Số 9 ngõ 11 Duy Tân , Dịch Vọng Hầu, Cầu Giấy , Hà Nội",
                    "id_number": "012345678901",
                    "id_date": "23/07/2021",
                    "id_place": "Hà Nội"
                }
            },
            formData: {
                "path": "template/auctionSession"
            }
        }
    },
    methods: {
        download(data) {
            const url = URL.createObjectURL(data);
            const a = document.createElement('a');
            a.download = 'test.pdf';
            a.href = url;
            a.target = '_self';

            a.click();

            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                a.remove();
                URL.revokeObjectURL(url);
            }, 100);
        },

    }
}
</script>
<style lang="css" scoped>
.head {
    font-size: 16px;
    font-weight: 600;
    line-height: 36px;
    color: #515151;
}

label {
    font-size: 20px;
    font-weight: 500;
}

h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 39px;
}

.report-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
}

table {
    width: 100%;
}

td {
    font-weight: 700;
    font-size: 14px;
    text-align: center;
}

.signature td {
    height: 75px;
}

.auction-session .btnn {
    border-radius: 14px;
    height: 32px;
    border: 1px solid #EE3439;
    color: #EE3439;
    font-weight: 500;
}
.btn-auction {
    margin-left: 20px;
    background-color: #EE3439;
    color: #FFFFFF !important;
}
</style>