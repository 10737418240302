<template>
    <div class="branch-manager">
        <h3>Quản lý chi nhánh</h3>
        <b-row class="justify-content-between mt-3">
            <b-col cols="lg-3 col-md-6">
                <b-form class="search-form" style="width: 100%;">
                    <b-form-group>
                        <b-form-input v-model="keySearch" type="text" placeholder="Tìm kiếm chi nhánh" required></b-form-input>
                    </b-form-group>
                    <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                </b-form>
            </b-col>
            <b-col cols="lg-3 col-md-6" class="text-right mt-xs-3 mt-sm-3 mt-md-0">
                <button class="btn-add" @click="showModal()" v-if="intListRole.includes(3)">+ Thêm chi nhánh mới</button>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col cols="md-6" v-for="dataBranch in filteredItems" :key="dataBranch.id">
                <div class="branch-item d-flex">
                    <img v-if="dataBranch.image" :src="$storage + dataBranch.image" alt="" class="branch-item-img" @error="handleError">
                    <img v-else src="@/assets/images/th.jpg" alt="" class="branch-item-img">
                    <div class="info-branch">
                        <div class="d-flex justify-content-between">
                            <h5>{{ dataBranch.agency_name }}</h5>
                            <div>
                                <img src="@/assets/admin/icon/Edit.png" v-if="intListRole.includes(4)" @click="editBranch(dataBranch.agency_id)" alt="" style="padding-right: 15px; filter: brightness(0);">
                                <img src="@/assets/admin/icon/Trash.png" v-if="intListRole.includes(5)" @click="showModalDel(dataBranch.agency_name, dataBranch.agency_id )" alt="">
                            </div>
                        </div>
                        <div class="d-flex item-info">
                            <p>Mã chi nhánh:</p>
                            <p>{{ dataBranch.agency_code }}</p>
                        </div>
                        <div class="d-flex item-info">
                            <p style="width: 70%;">Địa chỉ:</p>
                            <p class="text-right">{{ dataBranch.address +' - '+ dataBranch.ward_name +' - '+ dataBranch.district_name +' - '+ dataBranch.province_name }}</p>
                        </div>
                        <div class="d-flex item-info">
                            <p>Giám đốc chi nhánh:</p>
                            <p>{{ dataBranch.agency_manage }}</p>
                        </div>
                        <div class="d-flex item-info">
                            <p>Số điện thoại liên hệ:</p>
                            <p>{{ dataBranch.phone }}</p>
                        </div>
                        <div class="d-flex item-info">
                            <p>Email liên hệ:</p>
                            <p>{{ dataBranch.email }}</p>
                        </div>
                        <div class="d-flex item-info">
                            <p>Số lượng nhân viên:</p>
                            <p>{{ dataBranch.count_staff }}</p>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <addBranch ref="add" @handleCloseModal="listenDialog"/>
        <editBranch ref="edit" @handleCloseModal="listenDialog" />
        <Delete ref="delete" :del="del" @handleCloseModal="listenDialog"/>
    </div>
</template>
<script>
import addBranch from "./modal/add_branch.vue"
import editBranch from "./modal/edit_branch.vue"
import Delete from "./modal/delete.vue"
export default {
    components: {
        addBranch,
        editBranch,
        Delete
    },
    data() {
        return{
            del: {name: '', id: ''},
            keySearch: '',
            dataBranchs: [],
            listRole: [],
            intListRole: [],
        }
    },
    computed: {
        filteredItems() {
            return this.dataBranchs.filter(dataBranch => dataBranch.agency_name.toLowerCase().includes(this.keySearch.toLowerCase()));
        },
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            info.roles.arr_resource.forEach(element => {
                this.listRole.push(element.resource_id)
            });
            this.intListRole = this.listRole.map(Number)
        }
        this.getDataBranch()
    },
    methods: {
        showModal() {
            this.$refs.add.show()
        },
        editBranch(id) {
            this.$refs.edit.show(id)
        },
        showModalDel(name, id) {
            this.del.name = name
            this.del.id = id
            this.$refs.delete.show()
        },
        listenDialog(isClose = false) {
            if(!isClose) {
                this.getDataBranch()
            }
        },
        async getDataBranch() {
            const storedData = localStorage.getItem('admInfo')
            if (storedData) {
                var info = JSON.parse(storedData)
            }

            await this.$axios.get('/agency/getListAgency', {
                headers: {
                    'x-access-token': info.accessToken
                }
            }).then(res=> {
                this.dataBranchs = res.data
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if(err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                } else {
                    this.$toast.error('Vui lòng thử lại!')
                }
            })
        }
    }
}
</script>
<style lang="css" scoped>
    .branch-manager .btn-add {
        background-color: #EE3439;
        border: none;
        outline: none;
        border-radius: 10px;
        width: 195px;
        height: 41px;
        font-size: 15px;
        font-weight: 500;
        color: #FFFFFF;
    }

    .info-branch {
        width: 100%;
        padding-left: 15px;
    }

    .info-branch img {
        cursor: pointer;
    }

    .info-branch h5 {
        color: #515151;
        font-weight: 700;
        font-size: 22px;
        line-height: 39.6px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .branch-item {
        align-items: center;
        background-color: #FFFFFF;
        padding: 7px 14px 8px 8px;
        border-radius: 30px;
        box-shadow: 0px 0px 10px 0px #00000026;
        margin-bottom: 20px;
    }
    .branch-item .branch-item-img{
        max-width: 230px;
        height: 230px;
        border-radius: 20px;
        width: 100%;
        object-fit: cover;
    }

    .item-info {
        justify-content: space-between;
        text-align: left;
    }

    .item-info p {
        margin-bottom: 5px;
        color: #515151;
        font-weight: 500;
        font-size: 15px;
    }
    .item-info p:first-child {
        font-weight: 400;
    }

    @media (max-width: 1024px) {
        .branch-item {
            display: block !important;
            text-align: center;
        }

        .info-branch {
            padding-top: 20px;
        }
    }
    @media (max-width: 425px) {
        .branch-manager .btn-add {
            margin-bottom: 20px;
        }
    }
    @media (max-width: 320px) {
        .info-branch h5 {
            font-size: 16px;
        }
    }
</style>