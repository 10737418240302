import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed');

class CustomVideoBlot extends BlockEmbed {
  static create(value) {
    const node = super.create();
    const video = document.createElement('video');
    video.setAttribute('controls', 'true');
    video.setAttribute('width', '100%');
    video.src = value;
    node.appendChild(video);
    return node;
  }

  static value(node) {
    return node.querySelector('video').src;
  }
}

CustomVideoBlot.blotName = 'video';
CustomVideoBlot.className = 'ql-video';
CustomVideoBlot.tagName = 'div';

Quill.register(CustomVideoBlot, true);