<template>
    <div>
        <h5 style="font-size: 22px; font-weight: 700; color: #515151; margin-bottom: 20px;">Quản lý tài sản</h5>
        <b-row class="asset-manager">
            <b-col cols="xl-4">
                <div class="cate-list" style="height: 100%;">
                    <h5 class="mb-3">Danh mục tài sản</h5>
                    <p v-if="intListRole.includes(7) == false" style="font-weight: 500; color: #EE3439;">Bạn không có quyền xem danh mục</p>
                    <div v-if="intListRole.includes(7)">
                        <div class="d-flex mb-3 justify-content-between">
                            <b-form class="search-form" style="width: 250px;">
                                <b-form-group>
                                    <b-form-input v-model="keySearchCate" type="text" placeholder="Tìm kiếm" required></b-form-input>
                                </b-form-group>
                                <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                            </b-form>
                            <!-- <button><img src="@/assets/admin/icon/Adjust.png" alt=""></button> -->
                            <button class="add-cate" @click="addCate" v-if="intListRole.includes(8)">+ Tạo danh mục mới</button>
                        </div>
                        <label class="container">
                            <h5 style="font-size: 18px;">Chọn tất cả danh mục</h5>
                            <input type="checkbox" class="input-style" @input="getAssetByCate('', -1)" :checked="checked == -1">
                            <span class="checkmark"></span>
                        </label>
                        <!-- ---Item Cate--- -->
                        <div class="wp-item-cate">
                            <label class="container" v-for="(item, index) in filteredItemsCate" :key="index">
                                <input type="checkbox" class="input-style" @input="getAssetByCate(item.category_id, index)" :checked="checked == index">
                                <span class="checkmark"></span>
    
                                <div class="d-flex item-cate">
                                    <img :src="$storage + item.image" alt="" style="border-radius: 10px; max-width: 100px;" @error="handleError">
                                    <div class="item-cate-name">
                                        <p>{{ item.name }}</p>
                                        <p>Số lượng tài sản: {{ item.count_asset }}</p>
                                    </div>
                                    <div>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" v-model="item.view_status" @click="updateStatusCate(item.view_status, item.category_id)">
                                        </div>
                                        <button @click="editCate(item)" v-if="intListRole.includes(9)"><img src="@/assets/admin/icon/Edit.png" alt="" class="ico"></button>
                                        <button @click="deleteCate(item.category_id)" v-if="intListRole.includes(10)"><img src="@/assets/admin/icon/Trash.png" alt="" class="ico"></button>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col cols="xl-8" class="mt-xs-3 mt-sm-3 mt-xl-0">
                <div class="asset-list">
                    <h5 class="mb-3">Danh sách tài sản</h5>
                    <div class="d-flex justify-content-between flex-wrap">
                        <b-form class="search-form" style="width: 300px;">
                            <b-form-group>
                                <b-form-input v-model="keySearchAsset" type="text" placeholder="Tìm kiếm" required></b-form-input>
                            </b-form-group>
                            <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                        </b-form>
                        <!-- <button><img src="@/assets/admin/icon/Adjust.png" alt=""></button> -->
                        <button class="button-list m-sm-2 m-xs-2" :class="{'active' : active_el == 1}" @click="activeBtn(1)">Tất cả</button>
                        <!-- <b-dropdown id="list_cate" :text="text_cate" class="m-sm-2 m-xs-2 button-list" :class="{'active' : active_el == 2}">
                            <b-dropdown-item v-for="(cate, index) in dataCate" :key="index" @click="activeBtn(2, cate.name, cate.category_id)">{{ cate.name }}</b-dropdown-item>
                        </b-dropdown> -->

                        <b-dropdown id="list_branch" :text="text_agency" class="m-md-2 button-list" :class="{'active' : active_el == 2}" v-if="role_id == 1">
                            <b-dropdown-item v-for="(agency, index) in listAgency" :key="index" @click="activeBtn(2, agency.agency_name, agency.agency_id)">{{ agency.agency_name }}</b-dropdown-item>
                        </b-dropdown>

                        <b-dropdown id="list_branch" :text="text_order" class="m-sm-2 m-xs-2 button-list" :class="{'active' : active_el == 3}">
                            <b-dropdown-item @click="activeBtn(3, 'Mới nhất', 'latest')">Mới nhất</b-dropdown-item>
                            <b-dropdown-item @click="activeBtn(3, 'Cũ nhất', 'oldest')">Cũ nhất</b-dropdown-item>
                        </b-dropdown>

                        <b-dropdown id="list_branch" :text="text_status" class="m-sm-2 m-xs-2 button-list" :class="{'active' : active_el == 4}">
                            <b-dropdown-item @click="activeBtn(4, 'Chưa đấu giá', 0)">Chưa đấu giá</b-dropdown-item>
                            <b-dropdown-item @click="activeBtn(4, 'Đang đấu giá', 1)">Đang đấu giá</b-dropdown-item>
                            <b-dropdown-item @click="activeBtn(4, 'Chờ xác nhận', 2)">Chờ xác nhận</b-dropdown-item>
                            <b-dropdown-item @click="activeBtn(4, 'Không thành', 3)">Không thành</b-dropdown-item>
                            <b-dropdown-item @click="activeBtn(4, 'Thành', 4)">Thành</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <!-- ---ITEM ASSET--- -->
                    <div class="wp-item-asset">
                        <div class="mt-3 item-asset d-flex" v-for="(data, index) in filteredItemsAsset" :key="index" style="cursor: pointer;">
                            <b-col cols="auto pl-0 pr-0" class="d-flex align-items-center" @click="$router.push('/admin/list-asset/detail-asset/' + encode(data.asset_id))">
                                <img :src="$storage + data.image[0]" alt="" v-if="data.image.length !== 0" @error="handleError" style="max-width: 100px; width: 100px; height: 100px; border-radius: 10px; object-fit: cover;">
                                <img src="@/assets/images/th.jpg" alt="" v-else style="max-width: 100px; height: 100px; border-radius: 10px;">
                            </b-col>

                            <b-col class="p-0">
                                <div class="row align-items-center m-0">
                                    <div class="d-flex justify-content-between">
                                        <h5 class="asset_ttl" style="margin-bottom: 5px; width: 78%;" @click="$router.push('/admin/list-asset/detail-asset/' + encode(data.asset_id))">{{ data.asset_name }}</h5>
                                        <p class="status status--0" v-if="data.asset_status == 0">Chưa đấu giá</p>
                                        <p class="status" v-else-if="data.asset_status == 1">Đang đấu giá</p>
                                        <p class="status" v-else-if="data.asset_status == 2">Đã đấu giá</p>
                                        <p class="status" v-else-if="data.asset_status == 3">Đấu giá không thành</p>
                                        <p class="status status--4" v-else>Đấu giá thành</p>
                                    </div>
                                    <b-col cols="lg-5" class="first-col-item" @click="$router.push('/admin/list-asset/detail-asset/' + encode(data.asset_id))">
                                        <div class="info_asset_item"><p>Mã tài sản:</p> <span style="color: #3FA5FF; text-decoration: underline;">{{ data.asset_code }}</span></div>
                                        <div class="info_asset_item"><p>Giá khởi điểm:</p> <span style="color: #EE3439;">{{ parseInt(data.price).toLocaleString("vi-VN")}} VNĐ</span></div>
                                        <div class="info_asset_item"><p>Bước giá:</p> <span>{{ parseInt(data.step_price).toLocaleString("vi-VN")}} VNĐ</span></div> 
                                    </b-col>
                                    <b-col cols="lg-5" @click="$router.push('/admin/list-asset/detail-asset/' + encode(data.asset_id))">
                                        <div class="info_asset_item"><p>Chi nhánh:</p> 
                                            <span v-for="(dataAgency, index) in listAgency" :key="index" v-if="dataAgency.agency_id == data.agency_id">{{ dataAgency.agency_name }}</span>
                                        </div>
                                        <div class="info_asset_item"><p>Kết thúc đăng ký:</p> <span>{{ formateTime(data.register_end_time) }}</span></div>
                                        <div class="info_asset_item"><p>Thời gian đấu giá:</p> <span>{{ formateTime(data.start_time) }}</span></div>
                                    </b-col>
                                    <b-col cols="lg-2" class="text-center">
                                        <!-- <p class="status status--0" v-if="data.asset_status == 0">Chưa đấu giá</p>
                                        <p class="status" v-else-if="data.asset_status == 1">Đang đấu giá</p>
                                        <p class="status" v-else-if="data.asset_status == 2">Đã đấu giá</p>
                                        <p class="status" v-else-if="data.asset_status == 3">Đã đấu giá - Thất bại</p>
                                        <p class="status status--4" v-else>Đã đấu giá - Thành công</p> -->
                                        <div class="d-flex justify-content-center">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" role="switch" v-model="data.view_status" @click="updateViewStatus(data.view_status, data.asset_id)">
                                            </div>
                                            <button @click="$router.push('edit-asset/' + encode(data.asset_id))" v-if="intListRole.includes(24)"><img src="@/assets/admin/icon/Edit.png" alt="" class="ico"></button>
                                            <button @click="deleteAssset('one', data.asset_id)" v-if="intListRole.includes(25)"><img src="@/assets/admin/icon/Trash.png" alt="" class="ico"></button>
                                        </div>
                                    </b-col>
                                </div>
                            </b-col>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <addCate ref="addCate" @handleCloseModal="listenDialog"/>
        <editCate ref="editCate" @handleCloseModal="listenDialog"/>
        <delCate ref="delCate" @handleCloseModal="listenDialog"/>
        <delAsset ref="delete" @handleCloseModal="listenDialog"/>
    </div>
</template>
<script>
import addCate from './modal/add_cate.vue'
import editCate from './modal/edit_cate.vue'
import delCate from './modal/delete_cate.vue'
import delAsset from './modal/delete.vue'
export default {
    components: {
        addCate,
        editCate,
        delCate,
        delAsset
    },
    data() {
        return {
            dataCate: [],
            dataAsset: [],
            listAgency: [],
            token: '',
            keySearchCate: '',
            keySearchAsset: '',
            active_el: 1,
            text_agency: 'Chi nhánh',
            text_order: 'Sắp xếp theo',
            text_status: 'Trạng thái',
            checked: -1,
            listRole: [],
            intListRole: [],
            params_agency: '',
            role_id: ''
        }
    },
    computed: {
        filteredItemsCate() {
            return this.dataCate.filter(dataCate => dataCate.name.toLowerCase().includes(this.keySearchCate.toLowerCase()));
        },
        filteredItemsAsset() {
            return this.dataAsset.filter(asset => asset.asset_name.toLowerCase().includes(this.keySearchAsset.toLowerCase()));
        },
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
            this.role_id = info.roles.role_id
            info.roles.arr_resource.forEach(element => {
                this.listRole.push(element.resource_id)
            });
            this.intListRole = this.listRole.map(Number)

            if(info.roles.role_id == 1) {
                this.params_agency = null
            } else {
                this.params_agency = info.agency_id
            }
        }
        this.getListAsset()
        this.getListCate()
        this.getListAgency()
    },
    methods: {
        addCate () {
            this.$refs.addCate.show()
        },
        editCate (data) {
            this.$refs.editCate.show(data)
        },
        deleteCate (id) {
            this.$refs.delCate.show(id)
        },
        listenDialog(isClose = false) {
            if(!isClose) {
                this.getListCate()
                this.getListAsset()
            }
        },
        formateTime(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY HH:mm:ss")
        },
        async getListAsset() {
            await this.$axios.get('/asset/getListAsset', {
                params: {
                    asset_name: '',
                    asset_code: '',
                    category_id: '',
                    owner: '',
                    from_price: '',
                    to_price: '',
                    from_step_price: '',
                    to_step_price: '',
                    order: 'latest',
                    page: '1',
                    per_page: '10000',
                    winner: '',
                    status: '',
                    description: '',
                    auction_id: '',
                    publish_status: '',
                    asset_status: '',
                    agency_id: this.params_agency
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res=> {
                this.dataAsset = res.data.result
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if(err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                } else {
                    this.$toast.error('Vui lòng thử lại!')
                }
            })
        },
        async getListCate() {
            const res = await this.$axios.get('/category/getListCategory', {
                params: {
                    code: '',
                    name: '',
                    status: '',
                    parent_id: '',
                    page: '1',
                    per_page: '10000',
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.dataCate = res.data.result
            }
        },
        async getListAgency() {
            const res = await this.$axios.get('/agency/getListAgency', {
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.listAgency = res.data
            }
        },
        async updateStatusCate(data, id) {
            if(data == 1) {
                var params = {
                    "view_status": "0",
                    "category_id": id
                }
            } else {
                var params = {
                    "view_status": "1",
                    "category_id": id
                }
            }
            await this.$axios.put('/category/updateCategoryViewStatus', params, {
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.$toast.success('Thay đổi trạng thái thành công!')
                this.getListCate()
            }).catch(err => {
                this.$toast.error('Thay đổi trạng thái không thành công!')
            })
        },
        async updateViewStatus(data, id) {
            if(data == 1) {
                var params = {
                    "view_status": "0",
                    "asset_id": id
                }
            } else {
                var params = {
                    "view_status": "1",
                    "asset_id": id
                }
            }
            await this.$axios.put('/asset/updateAssetViewStatus', params, {
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.$toast.success('Thay đổi trạng thái thành công!')
                this.getListAsset()
            }).catch(err => {
                this.$toast.error('Thay đổi trạng thái không thành công!')
            })
        },
        deleteAssset(type, id) {
            this.$refs.delete.show(type, id)
        },
        async activeBtn(num, name, id){
            this.active_el = num
            if(num == 2) {
                this.text_agency = name
                const res = await this.$axios.get('/asset/getListAsset', {
                    params: {
                        asset_name: '',
                        asset_code: '',
                        category_id: '',
                        owner: '',
                        from_price: '',
                        to_price: '',
                        from_step_price: '',
                        to_step_price: '',
                        order: 'latest',
                        page: '1',
                        per_page: '10000',
                        winner: '',
                        status: '',
                        description: '',
                        auction_id: '',
                        publish_status: '1',
                        agency_id: id
                    },
                    headers: {
                        'x-access-token': this.token
                    }
                })
                if(res.status == 200) {
                    this.dataAsset = res.data.result
                }
                this.text_order = 'Sắp xếp theo'
                this.text_status = 'Trạng thái'
            } else if(num == 3) {
                this.text_order = name
                const res = await this.$axios.get('/asset/getListAsset', {
                    params: {
                        asset_name: '',
                        asset_code: '',
                        category_id: '',
                        owner: '',
                        from_price: '',
                        to_price: '',
                        from_step_price: '',
                        to_step_price: '',
                        order: id,
                        page: '1',
                        per_page: '10000',
                        winner: '',
                        status: '',
                        description: '',
                        auction_id: '',
                        publish_status: '1',
                        agency_id: this.params_agency
                    },
                    headers: {
                        'x-access-token': this.token
                    }
                })
                if(res.status == 200) {
                    this.dataAsset = res.data.result
                }
                this.text_agency = 'Chi nhánh'
                this.text_status = 'Trạng thái'
            }else if(num == 4) {
                this.text_status = name
                const res = await this.$axios.get('/asset/getListAsset', {
                    params: {
                        asset_name: '',
                        asset_code: '',
                        category_id: '',
                        owner: '',
                        from_price: '',
                        to_price: '',
                        from_step_price: '',
                        to_step_price: '',
                        order: 'latest',
                        page: '1',
                        per_page: '10000',
                        winner: '',
                        status: '',
                        description: '',
                        auction_id: '',
                        publish_status: '1',
                        agency_id: this.params_agency,
                        asset_status: id,
                    },
                    headers: {
                        'x-access-token': this.token
                    }
                })
                if(res.status == 200) {
                    this.dataAsset = res.data.result
                }
                this.text_agency = 'Chi nhánh'
                this.text_order = 'Sắp xếp theo'
            } else {
                this.text_agency = 'Chi nhánh'
                this.text_order = 'Sắp xếp theo'
                this.text_status = 'Trạng thái'
                this.getListAsset()
            }
        },
        async getAssetByCate(id, key) {
            this.checked = key
            const res = await this.$axios.get('/asset/getListAsset', {
                params: {
                    asset_name: '',
                    asset_code: '',
                    category_id: id,
                    owner: '',
                    from_price: '',
                    to_price: '',
                    from_step_price: '',
                    to_step_price: '',
                    order: 'latest',
                    page: '1',
                    per_page: '10000',
                    winner: '',
                    status: '',
                    description: '',
                    auction_id: '',
                    publish_status: '',
                    asset_status: '',
                    agency_id: this.params_agency
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.dataAsset = res.data.result
            }
        }
    }
}
</script>
<style lang="css" scoped>
    .container {
        color: #515151;
        display: block;
        position: relative;
        padding-left: 25px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
    }

    .container input.input-style {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #FFFFFF;
        border: 1px solid #B4B4B4;
        border-radius: 5px;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .container input:checked ~ .checkmark:after {
        display: block;
    }

    .container .checkmark:after {
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        background-image: url('@/assets/admin/icon/Done_check.png');
    }

    .form-switch .form-check-input {
        width: 41px;
        height: 20px;
    }

    .form-switch .form-check-input:checked {
        background-color: #EE3439;
        border-color: #EE3439;
    }

    .form-switch .form-check-input:focus {
        box-shadow: none;
    }

    .asset-manager {
        color: #515151;
    }
    .cate-list, .asset-list {
        background: #FFFFFF;
        box-shadow: 2px 2px 6px 0px #00000026;
        border-radius: 15px;
        padding: 10px 12px 25px 13px;
    }
    button {
        background: none;
        border: none;
    }
    .add-cate {
        color: #EE3439;
        font-weight: 500;
        font-size: 15px;
    }
    .item-cate {
        border: 1px solid #B4B4B4;
        border-radius: 10px;
        align-items: center;
    }

    .item-cate-name {
        margin: 0 15px;
        width: 258px;
    }
    .item-cate-name p {
        margin: 0;
        font-size: 13px;
    }
    .item-cate-name p:first-child {
        font-weight: 500;
        font-size: 16px;
    }

    button img.ico {
        width: 15px;
        height: 15px;
        filter: grayscale(1);
    }

    .info_asset_item {
        display: flex;
        flex-wrap: wrap;
    }
    .info_asset_item p {
        margin: 0;
        width: 165px;
    }
    .info_asset_item span {
        font-weight: 500;
    }
    .asset_ttl {
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .first-col-item p {
        width: 135px;
    }

    p.status {
        color: #EE3439;
        font-weight: 500;
        margin-bottom: 5px;
        text-align: center;
    }

    p.status--0 {
        color: #FF9838;
    }

    p.status--4 {
        color: #00B569;
    }

    .wp-item-asset, .wp-item-cate {
        max-height: 700px;
        overflow-y: auto;
    }

    .wp-item-asset::-webkit-scrollbar,
    .wp-item-cate::-webkit-scrollbar {
        width: 0;
    }

    .item-asset {
        margin: 0 0 20px 0;
        border: 1px solid #B4B4B4;
        border-radius: 10px;
        /* flex-wrap: wrap; */
    }

    .button-list.active {
        background: #EE3439;
        color: #FFFFFF;
        border: none;
    }

    .button-list.active .dropdown-toggle {
        color: #FFF;
    }

    .button-list.active .dropdown-toggle::after {
        border-right-color: #FFF !important;
        border-bottom-color: #FFF !important;
    }
    .button-list {
        width: 160px;
        height: 31px;
        border: 1px solid #515151;
        border-radius: 20px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
    }

    @media (max-width: 1440px) {
        .info_asset_item {
            display: block;
        }
    }
    @media (max-width: 1200px) {
        .cate-list {
            margin-bottom: 20px;
        }
        .item-cate {
            width: max-content;
        }
    }
    @media (max-width: 1024px) {
        .info_asset_item {
            display: flex;
        }

        .cate-list {
            margin-bottom: 30px;
        }
    }
</style>

<style>
    .asset-list .button-list.active .dropdown-toggle {
        color: #FFF;
    }

    .asset-list .button-list.active .dropdown-toggle::after {
        border-right-color: #FFF !important;
        border-bottom-color: #FFF !important;
    }
</style>