import io from 'socket.io-client';
import { reactive } from "vue";

export const state = reactive({
    dataRegister: [],
    dataJoined: [],
    listClientJoined: [],
    dataAllBid: [],
    dataHighestBid: [],
    dataComplain: [],
});

export default function socket(auction_key, member_key, accessToken) {
    const socket = io('https://api.dgv.com.vn', {
        query: { auction_key: auction_key, member_key: member_key },
        extraHeaders: { 'x-access-token': accessToken }
    });

    socket.on('listMemberRegister', res => {
        state.dataRegister = res.listMember
    })

    socket.on('listMemberJoined', res => {
        state.dataJoined = res.listMember
    })

    socket.on('listClientJoined', res => {
        state.listClientJoined = res.listClient
    })

    socket.on('all_bid', res => {
        state.dataAllBid = res.all_bid
    })

    socket.on('highest_bid', res => {
        state.dataHighestBid = res
    })

    socket.on('complain', res => {
        state.dataComplain = res
    })

    return socket;
}