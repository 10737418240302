var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sameproduct"},[_c('div',{staticClass:"d-flex same_ttl"},[_c('h5',[_vm._v("Tài sản tương tự")]),_c('b-link',{attrs:{"to":'/cate-detail/'+_vm.category_id}},[_vm._v("Xem thêm >>")])],1),(_vm.listAsset.result)?_c('b-row',[_c('div',{staticClass:"slide"},[_c('carousel',{attrs:{"responsive":{
                0: {
                    nav: false,
                    dots: false,
                    items: 1
                },
                575: {
                    items: 2,
                    nav: false,
                    dots: false,
                },
                768: {
                    items: 2,
                    nav: false,
                    dots: false,
                },
                992: {
                    items: 3,
                    nav: false,
                    dots: false,
                },
                1200: {
                    items: 4,
                    nav: false,
                    dots: false,
                },
            },"autoplay":true,"margin":10}},_vm._l((Array.from(_vm.listAsset.result)),function(itemcarousel,index){return _c('b-link',{key:index,staticClass:"slide-item text-center",on:{"click":function($event){_vm.$router.push('/product-detail/'+ _vm.encode(itemcarousel.asset_id))}}},[_c('cardAsset',{attrs:{"item":itemcarousel}})],1)}),1)],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }