<template>
    <div class="home-cate">
        <div class="header-home mt-2 w-100">
            <div>
                Sản phẩm , danh mục đấu giá đa dạng
            </div>
        </div>
        <div class="col-12 my-2 mx-md-4 content-home">
            <div class="col-12 px-2">
                Công ty Đấu giá hợp danh DGV có nhiều năm kinh nghiệm trong lĩnh vực tổ chức hoạt động đấu giá tài
                sản,
                tư vấn pháp luật và cung cấp các dịch vụ làm thủ tục chuyển quyền sở hữu, quyền sử dụng tài sản;
                quản lý
                tài sản và các dịch vụ khác liên quan đến tài sản đấu giá. Trong lĩnh vực đấu giá , chúng tôi đẩy
                mạnh
                đa dạng những nguồn sản phẩm và danh mục đấu giá cả về tài sản công cũng như tài sản cá nhân , đảm
                bảo
                uy tín , đa dạng , thuận tiện cho người có tài sản và người đấu giá
            </div>
        </div>
        <!-- <div class="row px-3 mt-3">
            <div class="col-lg-6 col-12 d-md-flex justify-content-center pl-0 pr-1 pb-1"
                v-for="(category, index) in listCategory" v-if="index < 3">
                <div class="w-100">
                    <img :src="$storage+category.image" class="main-image" alt="" @error="handleError">
                    <div class="category_content">
                        <label class="title">{{ category.name }}</label>
                        <router-link class="btn" :to="'/cate-detail/' + category.category_id">Xem thêm</router-link>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-6 d-md-flex justify-content-center pl-0 pr-1 pb-1"
                v-for="(category, index) in listCategory" v-if="index >= 3 && index < 4">
                <div class="w-100">
                    <img :src="$storage + category.image" class="second-image" alt="">
                    <div class="category_content">
                        <label class="title">{{ category.name }}</label>
                        <b-link class="btn" :to="'/cate-detail/'+category.category_id">Xem thêm</b-link>
                    </div>
                </div>
            </div>

        </div> -->
        <div class="col-12 d-md-flex mt-3">
            <div class="col-md-6 p-1">
                <img src="@/assets/front-end/img/group-trucks-parked-row.png" alt="">
            </div>
            <div class="col-xs-12 d-none-mobie p-1 pr-2">
                <img src="@/assets/front-end/img/mumbai-skyline-skyscrapers-construction.png" alt="">
            </div>
        </div>
        <div class="col-12 d-md-none">
            <div class="col-xs-12 p-1">
                <img src="@/assets/front-end/img/mumbai-skyline-skyscrapers-construction.png" alt="">
            </div>
        </div>
        <div class="col-12 d-md-flex">
            <div class="col-md-6 p-1">
                <img src="@/assets/front-end/img/land-plot-with-nature-landscape-location-pin.png" alt="">
            </div>
            <div class="d-flex">
                <div class="col-6 p-1">
                    <img src="@/assets/front-end/img/photo-automobile-production-line-welding-car-body.png" alt="">
                </div>
                <div class="col-6 p-1">
                    <img src="@/assets/front-end/img/wooden-gavel.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            params: {
                code: "",
                name: "",
                status: "",
                parent_id: "",
                page: 1,
                per_page: 6,
            },
            listCategory: ['','','','','',''],
        }
    },
    mounted(){
        this.getListCategoryForMember(this.params)
    },
}
</script>
<style lang="css" scoped>
img{
    max-height: 252px;
    width: inherit; height: 100%;
}

</style>