<template>
    <div>
        <Home></Home>
    </div>
</template>

<script>
import Home from "./home/homeView.vue";
export default {
    components: {
        Home
    },
}

</script>

