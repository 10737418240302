<template>
    <div class="manager">
        <div class="container">
            <b-row>
                <b-sidebar class="d-flex" id="sidebar-2" no-header shadow backdrop>
                    <b-col class="sidebar p-0">
                        <div class="info_manager">
                            <b-breadcrumb :items="items"></b-breadcrumb>
                            <h5>Quản lý của đấu giá viên</h5>
                            <img :src="$storage + data.avatar" alt="" @error="handleError">
                            <div style="text-align: center;">
                                <p style="font-size: 24px;">{{ data.full_name }}</p>
                                <p>Đấu giá viên</p>
                                <p class="mt-4">Chi nhánh: {{ data.agency_name }}</p>
                            </div>
                        </div>
                        <div class="menu mt-4 px-2">
                            <b-link to="/auctioneer/asset"
                                :class="{ 'active': this.$router.currentRoute.path == '/auctioneer/asset' }"><img
                                    src="@/assets/front-end/icon/Clipboard List.png" alt="">
                                Quản lý tài sản</b-link>
                            <b-link to="/auctioneer/auction"
                                :class="{ 'active': this.$router.currentRoute.path == '/auctioneer/auction' }"><img
                                    src="@/assets/front-end/icon/auction3.png" alt="">Cuộc đấu giá của bạn</b-link>
                        </div>
                    </b-col>
                </b-sidebar>
                <b-col cols="md-3 p-0" class="sidebar d-none d-xl-block">
                    <div class="info_manager">
                        <b-breadcrumb :items="items"></b-breadcrumb>
                        <h5>Quản lý của đấu giá viên</h5>
                        <img class="" :src="$storage + data.avatar" alt="" @error="handleError">
                        <div style="text-align: center;">
                            <p style="font-size: 24px;">{{ data.full_name }}</p>
                            <p>Đấu giá viên</p>
                            <p class="mt-4">Chi nhánh: {{ data.agency_name }}</p>
                        </div>
                    </div>
                    <div class="menu mt-4">
                        <b-link to="/auctioneer/asset"
                            :class="{ 'active': this.$router.currentRoute.path == '/auctioneer/asset' }"><img
                                src="@/assets/front-end/icon/Clipboard List.png" alt="">
                            Quản lý tài sản</b-link>
                        <b-link to="/auctioneer/auction"
                            :class="{ 'active': this.$router.currentRoute.path == '/auctioneer/auction' }"><img
                                src="@/assets/front-end/icon/auction3.png" alt="">Cuộc đấu giá của bạn</b-link>
                    </div>
                </b-col>
                <div class="btn-open-sidebar d-xl-none">
                    <b-button v-b-toggle.sidebar-2>></b-button>
                </div>
                <b-col cols="xl-9 " class="bg">
                    <router-view></router-view>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            items: [
                {
                    text: 'Trang chủ',
                    to: '/auctioneer',
                },
                {
                    text: 'Quản lý',
                    active: true
                },
            ],
            data: []
        }
    },
    name: "App",
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || "Admin-DGV";
            }
        },
    },
    mounted() {
        const storedData = localStorage.getItem('auInfo')
        if (storedData) {
            this.data = JSON.parse(storedData)
        }
    }
}
</script>
<style lang="css" scoped>
.breadcrumb-item a {
    color: #DBDBDB;
    font-weight: 400;
}

.sidebar {
    position: sticky;
    top: 0;
    height: 100%;
}

.breadcrumb-item span {
    color: #EEEEEE;
    font-weight: 400;
}

.manager {
    background: #EEEEEE;
    min-height: 900px;
}

.info_manager {
    background: #080808;
    border-radius: 0px 0px 50px 50px;
    padding: 10px 7px 20px 11px;
}

.info_manager img {
    /* border: 6px solid #EEEEEE; */
    border-radius: 171px;
    width: 230px;
    height: 230px;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
    /* width: 100%; */
}

.info_manager h5 {
    font-family: Libre Bodoni;
    font-size: 24px;
    font-weight: 500;
    color: #EEEEEE;
}

.info_manager>div {
    font-family: Libre Bodoni;
    color: #EEEEEE;
}

.info_manager>div p {
    font-size: 18px;
    margin: 0;
}

.menu a {
    color: #515151;
    font-family: Libre Bodoni;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    height: 46px;
    margin-bottom: 10px;
    padding: 0 15px;
}

.menu a img {
    margin-right: 10px;
}

.menu a.active {
    background-color: #4E4E4E;
    border-radius: 32px;
    color: #EEEEEE;
}

.menu a.active img {
    filter: brightness(3);
}

.bg {
    background-image: url('../../../../assets/images/logo_moreinfo.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.btn-open-sidebar {
    position: fixed;
    top: 50%;
    left: 0;
    padding-left: 0;
    z-index: 1000;
}

@media (max-width: 1024px) {
    .info_manager h5 {
        font-size: 18px;
    }

    .menu a {
        font-size: 15px;
    }
}

@media (max-width: 767px) {
    .sidebar {
        position: unset;
    }
}
</style>