<template>
    <div>
        <b-modal id="" ref="delete" centered hide-footer hide-header modal-class="del-branch" content-class="modal-width" @hide="emitModalVisible">
            <div class="delete-branch text-center">
                <img src="@/assets/admin/icon/warning.png" alt="">
                <h5>Xóa chi nhánh</h5>
                <p>Bạn đang yêu cầu xóa bỏ {{ this.del.name }}<br/>Sau khi xác nhận xóa bỏ sẽ không thể hoàn tác</p>
                <b-button class="mt-3 mr-3 btn-cancel" @click="hideModal()" block>Hủy bỏ</b-button>
                <b-button class="mt-3" block @click="deleteAgency">Xác nhận</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    props:['del'],
    data() {
        return {
            data: this.del
        }
    },
    computed: {

    },
    methods: {
        show() {
            this.$refs['delete'].show()
        },
        hideModal() {
            this.$refs['delete'].hide()
        },
        emitModalVisible(isClose = false) {
            this.$emit("handleCloseModal", isClose);
        },
        async deleteAgency() {
            const data = {
                'agency_id': this.del.id
            }
            const storedData = localStorage.getItem('admInfo')
            if (storedData) {
                var info = JSON.parse(storedData)
            }

            await this.$axios.put('/agency/deleteAgency', data, {
                headers: {
                    'x-access-token': info.accessToken
                } 
            }).then((res) => {
                this.emitModalVisible(false)
                this.$toast.success('Xóa chi nhánh thành công!')
                this.$refs['delete'].hide()
            }).catch((err) => {
                this.$toast.error('Xóa chi nhánh không thành công!')
            })
        }
    }
}
</script>

<style>
    .del-branch .modal-width {
        width: 380px;
    }
</style>
<style lang="css" scoped>

    .delete-branch h5,
    .delete-branch p {
        color: #444444;
    }
    .delete-branch button {
        background-color: #EE3439;
        color: #ffffff;
        font-weight: 500;
        border-radius: 10px;
        border: none;
        min-width: 163px;
        min-height: 42px;
    }

    .delete-branch button:active,
    .delete-branch button:focus {
        background-color: #EE3439 !important;
    }

    .delete-branch button:hover {
        background-color: #EE3439;
    }

    .delete-branch .btn-cancel {
        color: #EE3439;
        border: 1px solid #EE3439;
        background: #ffffff;
    }

    .delete-branch .btn-cancel:hover,
    .delete-branch .btn-cancel:focus,
    .delete-branch .btn-cancel:active {
        background: #ffffff !important;
        color: #EE3439;
        border: 1px solid #EE3439;
    }
    @media (max-width: 375px) {
        .delete-branch .btn-cancel {
            margin-right: 0 !important;
        }

        .delete-branch p {
            font-size: 15px;
        }
    }
</style>