<template>
    <div>
        <b-modal id="" ref="add-member" size="lg" centered hide-footer hide-header content-class="wp-add-member" @hide="clearData">
            <div class="add-member" :class="{'overlay' : isLoading}">
               <h5>Thêm nhân viên mới</h5>
               <b-row>
                <b-col cols="lg-4 text-center">
                    <img src="@/assets/admin/images/add_member.png" alt="" onclick="$('#file_img').click()" v-if="!avatar">
                    <img onclick="$('#file_img').click()" :src="avatar" v-else>
                    <b-form-file class="d-none" ref="fileInput" id="file_img" @change="handleImgChange" accept="image/png, image/gif, image/jpeg, image/jpg"></b-form-file>
                    <!-- <p class="mb-0 mt-3" style="font-size: 13px;">Mã nhân viên :</p> -->
                    <!-- <p class="mb-0" style="font-weight: 500;">MNV-2541</p> -->
                </b-col>
                <b-col cols="lg-8">
                    <b-form @submit="addMember" autocomplete="off">
                        <b-row class="align-items-center">
                            <b-col cols="lg-5"><label for="name">Tên nhân viên:</label></b-col>
                            <b-col cols="lg-7"><b-form-input id="name" v-model="dataFormAdd.full_name" required @input="formatTxt('full_name')"></b-form-input></b-col>
                            <b-col cols="lg-5"><label for="birth">Ngày sinh:</label></b-col>
                            <b-col cols="lg-7"><b-form-datepicker v-model="dataFormAdd.birthday" id="birth" :show-decade-nav="true" :max="max" start-weekday=1 locale="vi"></b-form-datepicker></b-col>
                            <b-col cols="lg-5"><label for="gender">Giới tính:</label></b-col>
                            <b-col cols="lg-7">
                                <b-form-radio-group class="d-flex" style="height: 41px;" v-model="dataFormAdd.gender" required>
                                    <b-form-radio value="0" id="gender">Nam</b-form-radio>
                                    <b-form-radio value="1">Nữ</b-form-radio>
                                </b-form-radio-group>
                            </b-col>
                            <b-col cols="lg-5"><label for="phone">Số điện thoại:</label></b-col>
                            <b-col cols="lg-7"><input id="phone" v-model="dataFormAdd.phone" @input="checkInputNumber('phone')" class="form-control" maxlength="10" required></b-col>
                            <b-col cols="lg-5"><label for="email">Email:</label></b-col>
                            <b-col cols="lg-7"><b-form-input id="email" type="email" v-model="dataFormAdd.email" required @input="formatTxt('email')"></b-form-input></b-col>
                            <b-col cols="lg-5"><label for="address">Địa chỉ:</label></b-col>
                            <b-col cols="lg-7"><b-form-input id="address" v-model="dataFormAdd.address" required @input="formatTxt('address')"></b-form-input></b-col>
                            <b-col cols="lg-5"><label for="position">Chức danh trong công ty:</label></b-col>
                            <b-col cols="lg-7"><b-form-input id="position" v-model="dataFormAdd.position" required @input="formatTxt('position')"></b-form-input></b-col>
                            <b-col cols="lg-5"><label for="auth">Phân quyền chức năng:</label></b-col>
                            <b-col cols="lg-7">
                                <b-form-select id="auth" class="form-select" v-model="dataFormAdd.role_id" required>
                                    <b-form-select-option v-for="item in listRole" :key="item.role_id" :value="item.role_id">{{ item.role_name }}</b-form-select-option>
                                </b-form-select>
                            </b-col>
                            <!-- <b-col cols="lg-5"><p style="font-size: 14px; margin: 0;">Tên đăng nhập hệ thống:</p></b-col> -->
                            <!-- <b-col cols="lg-7"><p style="font-weight: 500;  margin: 0;">MNV0254</p></b-col> -->
                            <b-col cols="lg-5"><label for="branch">Chi nhánh làm việc:</label></b-col>
                            <b-col cols="lg-7">
                                <b-form-select id="branch" class="form-select" v-model="dataFormAdd.agency_id" required>
                                    <b-form-select-option v-for="item in listAgency" :key="item.agency_id" :value="item.agency_id">{{ item.agency_name }}</b-form-select-option>
                                </b-form-select>
                            </b-col>
                            <b-col cols="lg-5"><label for="id_num">Số CMT/CCCD/Hộ chiếu:</label></b-col>
                            <b-col cols="lg-7"><input id="id_num" v-model="dataFormAdd.cccd" @input="checkInputNumber('cccd')" class="form-control" required></b-col>
                            <b-col cols="lg-5"><label for="day_res">Ngày cấp:</label></b-col>
                            <b-col cols="lg-7"><b-form-datepicker v-model="dataFormAdd.ngayCapCC" id="day_res" :show-decade-nav="true" :max="max" start-weekday=1 locale="vi"></b-form-datepicker></b-col>
                            <b-col cols="lg-5"> <label for="place_res">Nơi cấp:</label></b-col>
                            <b-col cols="lg-7"><b-form-input id="place_res" v-model="dataFormAdd.noiCapCC" required @input="formatTxt('noiCapCC')"></b-form-input></b-col>
                            <p style="font-size: 14px;">Ảnh CMT / Thẻ căn cước / Hộ chiếu</p>
                            <b-col cols="lg-6">
                                <b-form-file class="mt-3 d-none" id="front_side" ref="front_side" @change="handleFrontImgChange" accept="image/png, image/gif, image/jpeg, image/jpg"></b-form-file>
                                <img src="@/assets/admin/icon/Group 808.png" alt="" onclick="$('#front_side').click()" v-if="!front_img">
                                <img :src="front_img" onclick="$('#front_side').click()" v-else>
                            </b-col>
                            <b-col cols="lg-6">
                                <b-form-file class="mt-3 d-none" id="back_side" ref="back_side" @change="handleBackImgChange" accept="image/png, image/gif, image/jpeg, image/jpg"></b-form-file>
                                <img src="@/assets/admin/icon/Group 809.png" alt="" onclick="$('#back_side').click()" v-if="!back_img">
                                <img :src="back_img" onclick="$('#back_side').click()" v-else>
                            </b-col>
                            
                            <p style="font-weight: 500;">Giấy tờ liên quan</p>
                            <div>
                                <b-row>
                                    <b-col>
                                        <label for="paper">Tải lên giấy tờ ( Đinh dạng .pdf ) </label>
                                        <input class="custom-file-input mb-2" id="paper" accept="application/pdf,application/vnd.ms-excel" multiple type="file" @change="changeFile('file')">
                                    </b-col>
                                </b-row>
                            </div>

                            <div v-if="dataFormAdd.role_id == 3">
                                <b-row>
                                    <b-col cols="lg-5"><label for="dgv_num">Số thẻ đấu giá viên:</label></b-col>
                                    <b-col cols="lg-7"><input id="dgv_num" v-model="dataFormAdd.soTheDGV" @input="checkInputNumber('soTheDGV')" class="form-control"></b-col>
                                    <b-col cols="lg-5"><label for="dgv_date">Ngày cấp:</label></b-col>
                                    <b-col cols="lg-7"><b-form-datepicker v-model="dataFormAdd.ngayCap" id="dgv_date" :show-decade-nav="true" :max="max" start-weekday=1 locale="vi"></b-form-datepicker></b-col>
                                    <b-col cols="lg-5"><label for="dgv_place">Nơi cấp:</label></b-col>
                                    <b-col cols="lg-7"><b-form-input id="dgv_place" v-model="dataFormAdd.noiCap" @input="formatTxt('noiCap')"></b-form-input></b-col>
                                    <b-col cols="lg-5"><label for="cc_num">Số chứng chỉ hành nghề:</label></b-col>
                                    <b-col cols="lg-7"><input id="cc_num" v-model="dataFormAdd.cchn" @input="checkInputNumber('cchn')" class="form-control"></b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <label for="dgv_card">Thẻ đấu giá viên</label>
                                        <input class="custom-file-input mb-2" id="dgv_card" accept="application/pdf,application/vnd.ms-excel" type="file" @change="changeFile('theDGV')" required>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <label for="certificate">Chứng chỉ hành nghề đấu giá</label>
                                        <input class="custom-file-input mb-2" id="certificate" accept="application/pdf,application/vnd.ms-excel" type="file" @change="changeFile('ccHanhNghe')" required>
                                    </b-col>
                                </b-row>
                            </div>

                        </b-row>
                        <div class="text-right mt-3">
                            <button type="button" class="btn-cancel" :disabled="isLoading">Hủy bỏ</button>
                            <button type="submit" :disabled="isLoading">Thêm nhân viên</button>
                        </div>
                    </b-form>
                </b-col>
               </b-row>
            </div>
            <div class="loader" v-if="isLoading"></div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            avatar: '',
            front_img: '',
            back_img: '',
            max: new Date(),
            dataFormAdd: {
                full_name: '',
                gender: 0,
                birthday: null,
                phone: '',
                email: '',
                avatar: '',
                address: '',
                position: '',
                role_id: '',
                agency_id: '',
                cccd: '',
                ngayCapCC: null,
                noiCapCC: '',
                id_back_side:'',
                id_front_face:'',
                noiCap:'',
                ngayCap: null,
                soTheDGV:'',
                ccHanhNghe: '',
                theDGV: '',
                file: [],
                cchn: ''
            },
            listRole: [],
            listAgency: [],
            token: '',
            isLoading: false
        }
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
        this.getListRole()
        this.getListAgency()
    },
    methods: {
        show() {
            this.$refs['add-member'].show()
        },
        hideModal() {
            this.$refs['add-member'].hide()
        },
        checkInputNumber(type) {
            if(type == 'phone'){
                this.dataFormAdd.phone = this.formatNumber(event.target.value)
            } else if (type == 'cccd') {
               this.dataFormAdd.cccd = this.formatNumber(event.target.value)
            } else if (type == 'cchn') {
                this.dataFormAdd.cchn = this.formatNumber(event.target.value)
            } else {
                this.dataFormAdd.soTheDGV = this.formatNumber(event.target.value)
            }
        },
        handleImgChange(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    this.avatar = reader.result;
                    this.upload('avatar', file)
                };
                reader.readAsDataURL(file);
            }
        },
        handleFrontImgChange(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    this.front_img = reader.result;
                    this.upload('front', file)
                };
                reader.readAsDataURL(file);
            }
        },
        handleBackImgChange(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    this.back_img = reader.result;
                    this.upload('back', file)
                };
                reader.readAsDataURL(file);
            }
        },
        async changeFile(type) {
            const file = event.target.files[0];
            const files = Array.from(event.target.files);

            if (file || files) {
                const reader = new FileReader();
                if (type == 'file') {
                    reader.onload = () => {
                        files.forEach(element => {
                            this.upload('file', element)
                        });
                    };
                } else if (type == 'theDGV') {
                    reader.onload = () => {
                        this.upload('theDGV', file)
                    };
                } else {
                    reader.onload = () => {
                        this.upload('ccHanhNghe', file)
                    }; 
                }
                reader.readAsDataURL(file);
            }
        },
        async addMember() {
            event.preventDefault();
            if(this.dataFormAdd.birthday == null || this.dataFormAdd.ngayCapCC == null) {
                this.$toast.error('Vui lòng điền đầy đủ thông tin')
            } else {
                this.isLoading = true
                await this.$axios.post('/auth/createUser', this.dataFormAdd, {
                    headers: {
                        'x-access-token': this.token,
                    } 
                }).then(res => {
                    this.$toast.success('Tạo tài khoản nhân viên thành công!')
                    this.$refs['add-member'].hide()
                    this.emitModalVisible(false)
                    this.isLoading = false
                }).catch(err => {
                    this.$toast.error(err.response.data.error)
                    this.isLoading = false
                })
            }
        },
        clearData() {
            this.avatar = ''
            this.front_img = ''
            this.back_img = ''

            this.dataFormAdd = {
                full_name: '',
                gender: '',
                birthday: null,
                phone: '',
                email: '',
                avatar: '',
                address: '',
                position: '',
                role_id: '',
                agency_id: '',
                cccd: '',
                ngayCapCC: null,
                noiCapCC: '',
                id_back_side:'',
                id_front_face:'',
                noiCap:'',
                ngayCap: null,
                soTheDGV:'',
                ccHanhNghe: '',
                theDGV: '',
                file: [],
            }
        },
        async getListRole() {
            const res = await this.$axios.get('/permission/getListRole', {
                headers: {
                    'x-access-token': this.token
                }
            })

            if(res.status == 200) {
                this.listRole = res.data;
            }
        },
        async getListAgency() {
            await this.$axios.get('/agency/getListAgency', {
                headers: {
                    'x-access-token': this.token
                }
            }).then(res=> {
                this.listAgency = res.data;
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if(err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                }
            })
        },
        async upload(type, file) {
            if (type == 'avatar') {
                const data = {
                    'file': file,
                    'path': 'user/avatar/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    } 
                }).then(res => {
                    this.dataFormAdd.avatar = res.data.list_result[0].path
                })
            } else if(type == 'front') {
                const data = {
                    'file': file,
                    'path': 'user/id_front_face/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    } 
                }).then(res => {
                    this.dataFormAdd.id_front_face = res.data.list_result[0].path
                })
            } else if (type == 'back') {
                const data = {
                    'file': file,
                    'path': 'user/id_back_side/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    } 
                }).then(res => {
                    this.dataFormAdd.id_back_side = res.data.list_result[0].path
                })
            } else if (type == 'file') {
                const data = {
                    'file': file,
                    'path': 'user/file/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    } 
                }).then(res => {
                    this.dataFormAdd.file.push(res.data.list_result[0].path)
                })
            } else if (type == 'theDGV') {
                const data = {
                    'file': file,
                    'path': 'user/theDGV/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    } 
                }).then(res => {
                    this.dataFormAdd.theDGV = res.data.list_result[0].path
                })
            } else {
                const data = {
                    'file': file,
                    'path': 'user/chungChiHanhNghe/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    } 
                }).then(res => {
                    this.dataFormAdd.ccHanhNghe = res.data.list_result[0].path
                })
            }
        },
        emitModalVisible(isClose = false) {
            this.$emit("handleCloseModal", isClose);
        },
        formatTxt(item) {
            this.dataFormAdd[item] = this.dataFormAdd[item].replace(/[<>\[\]]/g, '')
        },
    }
}
</script>

<style lang="css" scoped>
    .add-member {
        color: #515151;
    }
    .add-member h5 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .add-member .form-control,
    .add-member .form-select {
        margin-bottom: 10px;
    }

    .add-member .form-control:focus,
    .add-member .form-select:focus {
        box-shadow: none;
    }

    .add-member .form-control {
        border: none;
        border-bottom: 1px solid #B6B6B6;
        border-radius: 0;
        padding-bottom: 0;
    }

    label {
        font-size: 14px;
    }

    .add-member .form-select {
        background-color: #EAEAEA;
        border: none;
        border-radius: 38px;
    }
    button {
        border: none;
        background: #EE3439;
        color: #FFFFFF;
        font-weight: 500;
        border-radius: 20px;
        padding: 5px 31px;
    }

    .btn-cancel {
        background-color: #FFFFFF;
        border: 1px solid #EE3439;
        color: #EE3439;
        margin-right: 10px;
    }
    .add_success button {
        background: #EE3439;
        border-radius: 10px;
        padding: 9px 40px;
    }
    .add_success button:active {
        background: #EE3439;
    }

    .custom-file-input::-webkit-file-upload-button {
        visibility: hidden;
    }

    .custom-file-input {
        color: #515151;
        margin-left: 40px;
        background: #EEEEEE;
        border-radius: 15px;
        padding: 3px 0;
    }

    .custom-file-input::after {
        position: absolute;
        right: 80px;
        content: 'Chọn file';
        display: inline-block;
        background: #444444;
        border-radius: 15px;
        padding: 6px 13px;
        outline: none;
        white-space: nowrap;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
    }

    .custom-file-input::before {
        position: absolute;
        content: '';
        background-image: url('../../../../assets/admin/icon/Import Pdf.png');
        width: 32px;
        height: 32px;
        left: 10px;
    }
</style>

<style>
    .wp-add-member {
        border-radius: 20px;
        box-shadow: 2px 2px 6px 0px #00000026;
    }

    .add_success .modal-width {
        width: 445px;
    }

    .add-member button.h-auto {
        opacity: 0;
    }

    .add-member .no-gutters {
        margin: 0;
    }

    .add-member .b-calendar-grid-help {
        display: none;
    }

    .add-member .b-calendar .form-control:focus,
    .add-member .b-form-datepicker.form-control.focus,
    .add-member .form-control.focus {
        box-shadow: none;
        border: 1px solid #ced4da6e;
    }

    .add-member .text-truncate {
        padding: 0;
    }

    .add-member .b-form-datepicker,
    .add-member .b-form-timepicker {
        border-radius: 12px;
        padding: 2px 12px;
    }

    .add-member .text-muted {
        color: #B4B4B4 !important;
    }

    .add-member .custom-radio {
        display: flex;
        align-items: center;
        margin-right: 50px;
    }

    .add-member .custom-radio label {
        margin-left: 10px;
    }
</style>