<template>
    <div class="au-auction">
        <b-tabs content-class="mt-3">
            <b-tab title="Cuộc đấu giá đang diễn ra" active>
                <auHappenning />
            </b-tab>
            <b-tab title="Cuộc đấu giá sắp diễn ra">
                <auDelivery />
            </b-tab>
            <b-tab title="Cuộc đấu giá đã kết thúc">
                <auFinished />
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import auHappenning from "./components/auHappenning.vue";
import auDelivery from "./components/auDelivery.vue";
import auFinished from "./components/auFinished.vue";
import listAsset from "./components/listasset.vue";
export default {
    components: {
        auHappenning,
        auDelivery,
        auFinished,
        listAsset
    }
}
</script>
<style lang="css">
.au-auction .nav-tabs {
    justify-content: space-around;
    box-shadow: 0px 2px 2px 0px #0000002B;
    min-height: 85px;
    align-items: end;
}

.au-auction .nav-link {
    border: none;
    font-size: 20px;
    font-weight: 600;
    color: #A6A6A6 !important;
}

.au-auction .nav-link.active {
    color: #EE3439 !important;
    border-bottom: 2px solid #EE3439;
    background-color: #EEEEEE;
}

@media (max-width: 1024px) {
    .au-auction .nav-link {
        font-size: 17px;
    }
}
</style>