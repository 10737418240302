<template>
    <div class="wp-statis">
      <h5>Trang chủ</h5>
        <!-- <b-row class="mb-4">
            <b-col cols="lg-8">
                <div class="statis">
                    <div class="d-flex align-items-center justify-content-between flex-wrap mb-4">
                        <p class="statis_ttl">Thống kê kinh doanh</p>
                        <div class="d-flex align-items-center">
                            <p>Thống kê:</p>
                            <button class="active">Tháng</button>
                            <button>Quý</button>
                            <button>Năm</button>
                        </div>
                    </div>

                    <b-row class="mb-4">
                        <b-col cols="lg-3">
                            <p style="font-size: 18px;">Doanh thu: <span style="font-size: 20px; font-weight: 700;">Tháng 05/2023</span></p>
                            <p style="color: #EE3439; font-size: 11.5px; font-weight: 500;">Dữ liệu thống kê được tính từ:
                                <span style="display: block; font-weight: 700; font-size: 12.46px;"> 01/05/2023 đến ngày 31/05/2023</span>
                            </p>
                        </b-col>
                        <b-col cols="lg-3">
                            <div class="statis-item">
                                <p class="percent"><img src="@/assets/admin/icon/growup.png" alt="" style="width: 14px;"> 32.5%</p> -->
                                <!-- <p class="percent" style="color: #EE3439;"><img src="@/assets/admin/icon/growdown.png" alt="" style="width: 14px;"> 32.5%</p> -->
                                <!-- <p style="font-size: 14px;">So với tháng trước </p>
                            </div>
                        </b-col>
                        <b-col cols="lg-3">
                            <div class="statis-item">
                                <p>Số cuộc đấu giá đã diễn ra </p>
                                <p>126</p>
                            </div>
                        </b-col>
                        <b-col cols="lg-3">
                            <div class="statis-item">
                                <p>Doanh thu </p>
                                <p style="color: #EE3439; font-weight: 800;">2.057.265.000 VNĐ</p>
                            </div>
                        </b-col>
                    </b-row>
                    <BarChart />
                </div>
            </b-col>
            <b-col cols="lg-4">
                <div class="doughnut" style="height: 100%;">
                    <p class="statis_ttl mb-5">Tỉ trọng doanh thu chi nhánh</p>
                    <Doughnut />
                </div>
            </b-col>
        </b-row> -->

        <b-row class="mb-4">
            <b-col cols="lg-12">
                <div class="wp-bg">
                    <img src="@/assets/images/Logo.png" alt="">
                    <p>{{ currentTime }} &nbsp;&nbsp;&nbsp;&nbsp; {{ this.$moment().format('l') }}</p>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="lg-8">
                <b-row>
                    <b-col cols="lg-7">
                        <div class="list-statis mb-3">
                            <div class="list-statis-item">
                                <img src="@/assets/admin/icon/statis-1.png" alt="">
                                <p>Đang đấu giá</p>
                                <p>{{ totalAuHappening }}</p>
                            </div>

                            <div class="list-statis-item">
                                <img src="@/assets/admin/icon/statis-2.png" alt="">
                                <p>Sắp đấu giá</p>
                                <p>{{ totalAuUpcoming }}</p>
                            </div>

                            <div class="list-statis-item">
                                <img src="@/assets/admin/icon/statis.png" alt="">
                                <p>Tổng số tài sản</p>
                                <p>{{ totalAsset }}</p>
                            </div>
                        </div>
                        <!-- <div class="line-chart">
                            <p class="statis_ttl mb-3">Cuộc đấu giá đã hoàn thành</p>
                            <LineChart />
                        </div> -->
                        <div class="list-item" @click="$router.push('/admin/app-news')">
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <p class="num">{{ totalNewsApprove }}</p>
                                    <p class="txt">Tin tức cần duyệt</p>
                                </div>
                                <img src="@/assets/admin/icon/statis-3.png" alt="">
                            </div>
                            <p class="btn-approve" style="background-color: #00B569;">Duyệt tin tức mới <img src="@/assets/admin/icon/Back.png" alt=""></p>
                        </div>

                        <div class="list-item" @click="$router.push('/admin/list-customer-new')">
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <p class="num">{{ totalCustomerApprove }}</p>
                                    <p class="txt">User đăng ký mới</p>
                                </div>
                                <img src="@/assets/admin/icon/statis-4.png" alt="">
                            </div>
                            <p class="btn-approve new-user">Duyệt User mới <img src="@/assets/admin/icon/Back.png" alt=""></p>
                        </div>
                    </b-col>
                    <b-col cols="lg-5">
                        <div class="list" style="height: 100%;">
                            <div class="list-item" @click="$router.push('/admin/approve-asset')">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p class="num">{{ totalAssetApprove }}</p>
                                        <p class="txt">Tài sản mới đăng chờ duyệt</p>
                                    </div>
                                    <img src="@/assets/admin/icon/statis-3.png" alt="">
                                </div>
                                <p class="btn-approve">Duyệt đăng tài sản <img src="@/assets/admin/icon/Back.png" alt=""></p>
                            </div>

                            <div class="list-item" @click="$router.push('/admin/list-auction')">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p class="num">{{ totalAuUpcoming }}</p>
                                        <p class="txt">Cuộc đấu giá cần duyệt đăng ký</p>
                                    </div>
                                    <img src="@/assets/admin/icon/statis-5.png" alt="">
                                </div>
                                <p class="btn-approve new-regis">Xem cuộc đấu giá sắp diễn ra <img src="@/assets/admin/icon/Back.png" alt=""></p>
                            </div>

                            <div class="list-item" @click="$router.push('/admin/approve-auction')">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p class="num">{{ totalAuctionApprove }}</p>
                                        <p class="txt">Cuộc đấu giá chờ duyệt</p>
                                    </div>
                                    <img src="@/assets/admin/icon/statis-5.png" alt="">
                                </div>
                                <p class="btn-approve file-app">Duyệt cuộc đấu giá mới <img src="@/assets/admin/icon/Back.png" alt=""></p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="lg-4">
                <div class="new-act" style="height: 100%;">
                    <div class="d-flex align-items-center justify-content-between" style="margin-bottom: 20px;">
                        <p class="statis_ttl">Hoạt động mới nhất</p>
                        <p style="font-size: 14px; color: #F1786E; cursor: pointer;" @click="getListNotification(paramsNoitifi)"><img src="@/assets/admin/icon/Delivery Time.png" alt=""> Làm mới</p>
                    </div>
                    <div class="over-item" @scrollend="loadData()">
                        <div class="item" v-for="(item, index) in listNotification.result" :key="index">
                            <img src="@/assets/admin/icon/auction_success.png" alt="" v-if="item.type == 3">
                            <img src="@/assets/admin/icon/Group 1556.png" alt="" v-if="item.type == 7 || item.type == 10">
                            <img src="@/assets/admin/icon/Group 1181.png" alt="" v-if="item.type == 8">
                            <img src="@/assets/admin/icon/Group 1179.png" alt="" v-if="item.type == 9">
                            <img src="@/assets/admin/icon/Group 1185.png" alt="" v-if="item.type == 11">
                            <div>
                                <p>{{ item.type_name }}</p>
                                <p>{{ formateTime(item.push_at) }}</p>
                            </div>
                        </div>
                        <div class="loader-notifi" v-if="listNotification.pagination && listNotification.pagination.per_page <= listNotification.pagination.total_element"></div>
                        <div v-else class="text-center" style="font-size: 14px; color: #EE3439; font-weight: 500;">Đã hết thông báo</div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
  
<script>
import BarChart from './components/barChart.vue'
import Doughnut from './components/doughnut.vue'
import LineChart from './components/lineChart.vue'
export default {
    components: {
        BarChart,
        Doughnut,
        LineChart
    },
    data() {
        return{
            currentTime: '',
            token: '',
            params_agency: '',
            totalAuHappening: '',
            totalAuUpcoming: '',
            totalAsset: '',
            totalAssetApprove: '',
            totalAuctionApprove: '',
            totalCustomerApprove: '',
            totalNewsApprove: '',
            paramsNoitifi:{
                page: 1,
                per_page: 10,
            },
            listNotification: []
        }
    },
    created() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
            if(info.roles.role_id == 1) {
                this.params_agency = ''
            } else {
                this.params_agency = info.agency_id
            }
        }
        setInterval(() => {
            this.currentTime = this.$moment().format('LTS');
        });
        this.getTotalData()
        this.getListNotification(this.paramsNoitifi)
    },
    methods: {
        async getTotalData() {
            await this.$axios.get('/auction/getListAuctionHappenningForAdmin', {
                params: {
                    page: '1',
                    per_page: '10000',
                    approve_status: '1',
                    publish_status: '1',
                    agency_id: this.params_agency
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res=> {
                this.totalAuHappening = res.data.re.length
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if(err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                } else {
                    this.$toast.error('Vui lòng thử lại!')
                }
            })

            await this.$axios.get('/auction/getListAuctionUpcomingForAdmin', {
                params: {
                    page: '1',
                    per_page: '10000',
                    approve_status: '1',
                    publish_status: '1',
                    agency_id: this.params_agency
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.totalAuUpcoming = res.data.re.length

            })

            await this.$axios.get('/asset/getListAsset', {
                params: {
                    asset_name: '',
                    asset_code: '',
                    category_id: '',
                    owner: '',
                    from_price: '',
                    to_price: '',
                    from_step_price: '',
                    to_step_price: '',
                    order: 'latest',
                    page: '1',
                    per_page: '1000',
                    winner: '',
                    status: '',
                    description: '',
                    auction_id: '',
                    publish_status: '',
                    asset_status: '',
                    agency_id: this.params_agency
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.totalAsset = res.data.result.length
            })

            await this.$axios.get('/asset/getListAssetToApprove', {
                params: {
                    asset_name: '',
                    asset_code: '',
                    category_id: '',
                    owner: '',
                    from_price: '',
                    to_price: '',
                    from_step_price: '',
                    to_step_price: '',
                    order: 'latest',
                    page: '1',
                    per_page: '1000',
                    winner: '',
                    status: '',
                    description: '',
                    auction_id: '',
                    publish_status: '1',
                    agency_id: this.params_agency
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.totalAssetApprove = res.data.result.length
            })

            await this.$axios.get('/auction/getListAuctionToApprove', {
                params: {
                    page: '1',
                    per_page: '100',
                    approve_status: '0',
                    publish_status: '1',
                    agency_id: this.params_agency
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res=> {
                this.totalAuctionApprove = res.data.re.length
            })

            await this.$axios.get('/member/getListMemberRegister',{
                params: {
                    type: '',
                    member_name: '',
                    member_code: '',
                    phone: '',
                    address: '',
                    email: '',
                    gender: '',
                    birthday: '',
                    page: '1',
                    per_page: '1000',
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res=> {
                this.totalCustomerApprove = res.data.result.length
            })

            await this.$axios.get('/news/getListNewsForAdmin', {
                params: {
                    page: 1,
                    per_page: 10000,
                    approve_status: 0,
                    publish_status: 1
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.totalNewsApprove = res.data.result.length
            })
        },
        getListNotification(params) {
            this.$axios.get('/common/getListNotificationForAdmin', {
                params: params,
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.listNotification = res.data
            }).catch(err => {
                if (err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if (err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                }
            })
        },
        loadData(){
            this.paramsNoitifi.per_page += 10;
            this.getListNotification(this.paramsNoitifi)
        },
    }
};
</script>
  
<style lang="css" scoped>
    h5 {
        font-family: Roboto;
        color: #515151;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .wp-statis {
        font-family: Roboto;
        color: #515151;
    }

    .statis, .doughnut, .new-act, .line-chart {
        background-color: #FFFFFF;
        box-shadow: 0px 0px 6px 0px #00000026;
        padding: 14px 13px 13px 16px;
        border-radius: 10px;
    }

    p {
        margin-bottom: 0;
    }
    button {
        border: none;
        background: #EBEBEB;
        border-radius: 10px;
        width: 90px;
        height: 29px;
        margin: 0 5px;
        color: #929292;
        font-weight: 500;
    }
    button.active {
        color: #FFFFFF;
        background: #EE3439;
    }
    .statis_ttl {
        font-size: 20px;
        font-weight: 700;
        line-height: 36px;
    }

    .statis-item {
        border: 1px solid #C1C1C1;
        border-radius: 17px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .statis-item p:first-child {
        font-size: 13.32px;
        font-weight: 500;
        text-align: center;
    }
    .statis-item p:last-child {
        font-weight: 700;
        font-size: 18.32px;
        text-align: center;
    }

    .statis-item p.percent {
        font-weight: 700;
        font-size: 18.32px;
        color: #00B569;
    }

    .list-statis {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .list-statis-item {
        width: 31%;
        height: 113px;
        max-height: 113px;
        text-align: center;
        box-shadow: 1.6054688692092896px 1.6054688692092896px 4.81640625px 0px #0000001C;
        background: #FFFFFF;
        border-radius: 7.52px;
        padding-top: 14px;
        padding-bottom: 8px;
    }

    .list-statis-item p {
        font-size: 11.24px;
        font-weight: 500;
        line-height: 20.23px;
    }

    .list-statis-item p:last-child {
        color: #EE3439;
        font-size: 36px;
        margin-top: 5px;
    }

    .list-item {
        cursor: pointer;
        margin-bottom: 13px;
    }

    .list-item > div {
        background-color: #FFFFFF;
        box-shadow: 2px 2px 6px 0px #0000001C;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 11px 19px 5px 14px;
    }

    p.num {
        font-size: 32px;
        font-weight: 500;
    }
    p.txt {
        font-size: 14px;
        font-weight: 500;
    }

    p.btn-approve {
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 500;
        background-color: #3FC53C;
        padding: 4px 10px 5px 14px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 2px 2px 6px 0px #0000001C;
    }

    p.new-regis {
        background-color: #EE3439;
    }
    p.file-app {
        background-color: #EE7734;
    }
    p.new-user {
        background-color: #3C8BC5;
    }

    .item {
        display: flex;
        align-items: center;
        margin-bottom: 27px;
    }

    .item > div {
        margin-left: 15px;
    }

    .item p {
        font-size: 14px;
    }
    .item p:first-child {
        font-size: 15px;
        font-weight: 500;
    }

    .over-item {
        max-height: 290px;
        overflow-y: auto;
        position: relative;
    }

    .over-item::-webkit-scrollbar {
        width: 0;
    }

    .wp-bg {
        background-image: url('@/assets/images/background.png');
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 25px 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .wp-bg p {
        color: #D6B887;
        font-size: 48px;
        font-weight: 700;
        font-family: Libre Bodoni;
        margin-top: 20px;
    }
    @media (max-width: 992px) {
        .statis, .line-chart {
            margin-bottom: 15px;
        }
        .statis-item {
            margin-bottom: 15px;
            height: unset;
        }
    }
</style>