<template>
    <div class="auth">
        <div class="bg-transparent login">
            <div class="banner-main first">
                <div class="content">
                    <div class="container d-md-flex py-5">
                        <div class="col-md-6 logo-login">
                            <div style="text-align: center;">
                                <img src="@/assets/front-end/img/Group 874.png" alt="">
                            </div>
                        </div>
                        <div class="col-md-6 ">
                            <div style="max-width: 423px; margin: 0 auto;">
                                <b-form class="mt-2" id="form-login-member" @submit="login(formData)">
                                    <div class="d-flex login_tab mb-3">
                                        <label class="login-tab mr-3 active"><a href="#">Đăng nhập</a></Label>
                                        <label class="login-tab"><b-link to="/register">Đăng ký</b-link></Label>
                                    </div>
                                    <div class="form-group mt-3">
                                        <label for="si-email" type="email">Số điện thoại / Email</label>
                                        <input class="form-control" type="text" name="email" id="si-email"
                                            placeholder="Nhập Email hoặc số điện thoại" required
                                            v-model="formData.email">
                                        <div class="invalid-feedback">vui lòng nhập email hợp lệ </div>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="si-password">Mật khẩu</label>
                                        <div class="password-toggle">
                                            <input class="form-control" name="password" type="password" id="si-password"
                                                required placeholder="Nhập mật khẩu" ref="inputPass" autocomplete
                                                v-model="formData.password">
                                            <a href="#" class="toggel-password" @click="showPass">
                                                <b-icon icon="eye" font-scale="0.5" v-if="iconStt"></b-icon>
                                                <b-icon icon="eye-slash" font-scale="0.5" v-else></b-icon>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-wrap justify-content-between">
                                        <div class="form-group mb-3 d-flex align-item-center">
                                            <input type="checkbox" name="remember" id="remember"
                                                v-model="formData.remember">
                                            <div class="remember ml-1" for="remember">Ghi nhớ đăng nhập </div>
                                        </div>
                                        <b-link class="forgot_password" to="/forget-password">Quên mật khẩu</b-link>
                                    </div>
                                    <button class="btn btn-gold" type="submit">
                                        ĐĂNG NHẬP
                                    </button>
                                </b-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import { notification } from '@/service/fireBase'

export default {
    data() {
        return {
            iconStt: true,
            formData: {
                email: localStorage.getItem('memberEmail'),
                password: localStorage.getItem('memberPassword'),
                remember: localStorage.getItem('remember'),
                accessToken: "",
                deviceToken: null,
                device_name: "",
            },
            publicKey: 'MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHvcIWyQskFcrDyzUuVML4OgVCrbOS+g0K5B5ngrMK1623+eBYBVegi6f0fe15xNatSphlBNjaTezEyZVYORd9lRfxMglWXeUtDTQhqmzBU4653USZZqdMGBdPMZRpDXO4+T+WEEXLDA/bW1MZ8E8LIKof2edlwAGX2JBKDqwPfhAgMBAAE=',
            encryptedData:''
        };
    },
    computed: {
        getDeviceToken() {
            return notification
        }
    },
    mounted() {
        if (window.innerWidth < 768) {
            this.formData.device_name = "Mobile"
        } else if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
            this.formData.device_name = "Tablet"
        } else if (window.innerWidth >= 1024 && window.innerWidth <= 1600) {
            this.formData.device_name = "Laptop"
        } else {
            this.formData.device_name = "PC"
        }
    },
    methods: {
        ...mapActions(['loginUser']),
        showPass() {
            this.iconStt = !this.iconStt
            this.$refs.inputPass.type = this.showPassword(this.$refs.inputPass.type)
        },
        async login(params) {
            event.preventDefault()
            if (this.getDeviceToken.token) {
                params.deviceToken = this.getDeviceToken.token
            }
            if (this.formData.remember) {
                localStorage.setItem('memberEmail', this.formData.email);
                localStorage.setItem('memberPassword', this.formData.password);
                localStorage.setItem('remember', this.formData.remember);
            }
            await this.$axios.post('/member/signinMember', {
                email: params.email,
                password: this.encryptData(params.password),
                deviceToken: params.deviceToken,
                device_name: params.device_name
            }).then((res) => {
                let token = res.data.userData.accessToken
                localStorage.setItem('member', token);
                this.loginUser(res.data.userData);
                this.$router.push('/about')
                window.location.reload();
            }).catch((err) => {
                if (err.code == "ERR_NETWORK") {
                    this.$toast.error('Lỗi hệ thống')
                }else{
                    this.$toast.error(err.response.data.error)
                }
            })
        },
        
    },
}

</script>

<style lang="css" scoped>
.login .password-toggle {
    position: relative;
}

.login .password-toggle .toggel-password {
    top: 28%;
}
</style>

