<template>
    <div class="">
        <div class="header-home mt-3 w-100">
            <div>
                Tài sản đấu giá
            </div>
            <div class="view-more">
                <b-link to="product-auction">Xem thêm >></b-link>
            </div>
        </div>
        <div class="content-home" v-if="listAsset.result">
            <carousel :responsive="{
                0: {
                    nav: false,
                    dots: false,
                    items: 1
                },
                675:{
                    items: 2,
                    nav: false,
                    dots: false,
                },
                768: {
                    items: 2,
                    nav: false,
                    dots: false,
                },
                992: {
                    items: 3,
                    nav: false,
                    dots: false,
                },
                1280: {
                    items: 4,
                    nav: false,
                    dots: false,
                },
            }" :autoplay="true" :margin="10">
                <b-link v-for="(item, index) in listAsset.result" :key="index">
                    <div class="scroll-mobie d-flex justify-content-center" @click="$router.push('/product-detail/' + encode(item.asset_id))" >
                        <cardAsset :item="item"/>
                    </div>
                </b-link>
            </carousel>
        </div>
    </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import cardAsset from "../components/card-asset.vue";
export default {
    components: {
        carousel,
        cardAsset
    },
    mounted() {
        this.getListAssetForMember(this.params)
    },
    data() {
        return {
            params: {
                asset_name: "",
                asset_code: "",
                asset_status: "",
                category_id: "",
                owner: "",
                from_price: "",
                to_price: "",
                from_step_price: "",
                to_step_price: "",
                page: 1,
                per_page: 10,
                order: "latest",
                winner: "",
                status: "",
                description: "",
                auction_id: "",
            },
            listAsset: {
                pagination: {},
                result: ['', '', '', '', '', '', '', '', '', '']
            },
        }
    }
}
</script>