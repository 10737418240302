<template>
    <div class="wp-asset_detail">
        <b-row class="align-items-center">
            <b-col cols="lg-5">
                <div class="mb-3 d-flex align-items-center justify-content-between wp-btn-lnk">
                    <h5 style="font-size: 22px; font-weight: 700; color: #515151;" class="m-0">Chi tiết tài sản</h5>
                    <div class="d-flex align-items-center" v-if="dataDetail.asset_status == 2 && intListRole.includes(27) || dataDetail.asset_status == 3 && intListRole.includes(27) || dataDetail.asset_status == 4 && intListRole.includes(27)">
                        <p class="m-0" style="font-size: 15px; font-weight: 500; color: #515151;">Trạng thái tài sản:</p>
                        <b-dropdown :text="txt_status" class="m-md-2" 
                            :class="{'failed': txt_status == 'Đã đấu giá - Không thành',
                                     'success': txt_status == 'Đã đấu giá - Thành'
                                    }">
                            <b-dropdown-item @click="updateStatusAsset(4, dataDetail.asset_id)">Đã đấu giá - Thành</b-dropdown-item>
                            <b-dropdown-item @click="updateStatusAsset(3, dataDetail.asset_id)">Đã đấu giá - Không thành</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </b-col>

            <b-col cols="lg-7" v-if="dataDetail.asset_status == 2 && intListRole.includes(27) || dataDetail.asset_status == 3 && intListRole.includes(27) || dataDetail.asset_status == 4 && intListRole.includes(27)">
                <div class="mb-3 d-flex align-items-center justify-content-end wp-btn-lnk">
                    <b-link :href="'/admin/list-joined/' + encode(dataDetail.asset_id)" target="_blank" class="asset_detail_lnk mr-2">Danh sách tham gia đấu giá</b-link>
                    <b-link :href="'/admin/list-history/' + encode(dataDetail.asset_id)" target="_blank" class="asset_detail_lnk mr-2">Lịch sử trả giá</b-link>
                    <b-link @click="getReport(dataDetail.asset_id)" class="asset_detail_lnk">Biên bản đấu giá</b-link>
                </div>
            </b-col>
        </b-row>
        <div class="asset_detail">
            <div class="text-right" v-if="action == 'edit'">
                <button class="mr-2" @click="$router.push('/admin/edit-asset/' + encode(dataDetail.asset_id))" v-if="intListRole.includes(24)">Chỉnh sửa thông tin tài sản</button>
                <button @click="deleteAssset('one', dataDetail.asset_id)" v-if="intListRole.includes(25)">Xóa tài sản</button>
            </div>
            <div class="text-right btn-group-approve" v-if="action == 'approve'">
                <button class="mr-2" @click="approveAsset('cancel', dataDetail.asset_id, dataDetail.asset_code)">Từ chối</button>
                <button class="mr-4 btn-approve" @click="approveAsset('approve', dataDetail.asset_id, dataDetail.asset_code)">Phê duyệt</button>
                <button class="btn-img" @click="$router.push('/admin/edit-asset/' + encode(dataDetail.asset_id))"><img src="@/assets/admin/icon/Edit.png" alt=""></button>
                <button class="btn-img" @click="deleteAssset('one', dataDetail.asset_id)"><img src="@/assets/admin/icon/Trash.png" alt=""></button>
            </div>
            <h5 class="title mt-3">{{ dataDetail.asset_name }}</h5>
            <b-row>
                <b-col cols="lg-5">
                    <slideImage :list_images=dataDetail.image />
                </b-col>
                <b-col cols="lg-7">
                    <p v-if="dataDetail.auction_status == 0">Cuộc đấu giá sẽ bắt đầu lúc {{ formateTime(dataDetail.start_time) }}</p>
                    <div class="status_au status_au_success" :class="{'status_au_fail' : dataDetail.auction_status == 3, 'status_au_dif' : dataDetail.auction_status == 4}" v-if="dataDetail.auction_status == 2 || dataDetail.auction_status == 3 || dataDetail.auction_status == 4">
                        <p style="padding-top: 5px;">Cuộc đấu giá kết thúc </p>
                        <p v-if="dataDetail.auction_status == 2">Kết thúc thành</p>
                        <p v-if="dataDetail.auction_status == 3" style="color: #EE3439;">Kết thúc không thành</p>
                        <p v-if="dataDetail.auction_status == 4" style="color: #EE3439;">Kết thúc</p>
                    </div>

                    <div class="show-time" v-if="dataDetail.auction_status == 1">
                        <p>Cuộc đấu giá đang diễn ra</p>
                        <p>Thời gian trả giá còn: </p>
                        <p>{{ countdown }}</p>
                    </div>
                    <b-row class="info-asset">
                        
                        <b-col cols="md-4 col-xs-4"><p>Giá khởi điểm:</p></b-col>
                        <b-col cols="md-7 col-xs-7"><p class="text-price">{{ parseInt(dataDetail.price).toLocaleString("vi-VN") }} VNĐ</p></b-col>
                        
                        <b-col cols="md-4 col-xs-4"><p>Mã tài sản đấu giá:</p></b-col>
                        <b-col cols="md-7 col-xs-7"><p>{{ dataDetail.asset_code }}</p></b-col>

                        <b-col cols="md-4 col-xs-4"><p>Thời gian bắt đầu đăng ký tham gia đấu giá:</p></b-col>
                        <b-col cols="md-7 col-xs-7"><p>{{ formateTime(dataDetail.register_start_time) }}</p></b-col>

                        <b-col cols="md-4 col-xs-4"><p>Thời gian kết thúc đăng ký:</p></b-col>
                        <b-col cols="md-7 col-xs-7"><p>{{ formateTime(dataDetail.register_end_time) }}</p></b-col>

                        <b-col cols="md-4 col-xs-4"><p>Tiền mua hồ sơ:</p></b-col>
                        <b-col cols="md-7 col-xs-7"><p>{{ parseInt(dataDetail.record_fee).toLocaleString("vi-VN")}} VNĐ</p></b-col>

                        <b-col cols="md-4 col-xs-4"><p>Bước giá:</p></b-col>
                        <b-col cols="md-7 col-xs-7"><p>{{ parseInt(dataDetail.step_price).toLocaleString("vi-VN") }} VNĐ</p></b-col>

                        <b-col cols="md-4 col-xs-4"><p>Tiền đặt trước:</p></b-col>
                        <b-col cols="md-7 col-xs-7"><p>{{ parseInt(dataDetail.down_payment).toLocaleString("vi-VN") }} VNĐ</p></b-col>

                        <b-col cols="md-4 col-xs-4"><p>Tên chủ tài sản:</p></b-col>
                        <b-col cols="md-7 col-xs-7"><p>{{ dataDetail.owner }}</p></b-col>

                        <b-col cols="md-4 col-xs-4"><p>Nơi xem tài sản:</p></b-col>
                        <b-col cols="md-7 col-xs-7"><p>{{ dataDetail.view_address }}</p></b-col>

                        <b-col cols="md-4 col-xs-4"><p>Thời gian bắt đầu trả giá:</p></b-col>
                        <b-col cols="md-7 col-xs-7"><p>{{ formateTime(dataDetail.start_time) }}</p></b-col>

                        <b-col cols="md-4 col-xs-4"><p>Thời gian kết thúc trả giá:</p></b-col>
                        <b-col cols="md-7 col-xs-7"><p>{{ formateTime(dataDetail.end_time) }}</p></b-col>

                        <b-col cols="md-4 col-xs-4"><p>Danh mục tài sản:</p></b-col>
                        <b-col cols="md-7 col-xs-7"><p>{{ dataDetail.category_name }}</p></b-col>

                        <b-col cols="md-4 col-xs-4"><p>Chi nhánh tổ chức đấu giá:</p></b-col>
                        <b-col cols="md-7 col-xs-7"><p>{{ dataDetail.agency_name }}</p></b-col>
                    </b-row>
                </b-col>
            </b-row>

            <b-row class="mt-3">
                <b-col cols="lg-8">
                    <h5 class="text-center" style="font-size: 20px; font-weight: 600;">THÔNG BÁO ĐẤU GIÁ</h5>
                    <p style="font: 18px;" v-html="dataDetail.auction_noti"></p>
                </b-col>
                <b-col cols="lg-4">
                    <div class="same-file" v-if="dataDetail.file && dataDetail.file.length !== 0">
                        <h5>Tài liệu liên quan </h5>
                        <b-link v-for="(file, index) in dataDetail.file" :key="index" :href="$storage + file.url" target="_blank">{{ file.file_name }}</b-link>
                    </div>
                    <div class="info-orig mt-4">
                        <h5>Thông tin tổ chức đấu giá </h5>
                        <p>
                            <img src="@/assets/icon/Company.png" alt="">
                            Đơn vị tổ chức:
                            <span>{{ dataDetail.org }}</span>
                        </p>
                        <p>
                            <img src="@/assets/icon/Customer.png" alt="">
                            Đấu giá viên:
                            <span>{{ dataDetail.DGV }}</span>
                        </p>
                        <p>
                            <img src="@/assets/icon/Location.png" alt="">
                            Địa điểm:
                            <span>{{ dataDetail.auction_address }}</span>
                        </p>
                    </div>

                    <div class="info-orig mt-4" v-if="dataDetail.asset_status == 2 || dataDetail.asset_status == 3 || dataDetail.asset_status == 4">
                        <div v-if="dataDetail.complain.length !== 0" v-for="complain in dataDetail.complain">
                            <h5>Khiếu nại của khách mời</h5>
                            <p>
                                <img src="@/assets/icon/Customer.png" alt="">
                                Người khiếu nại:
                                <span>{{ complain.name }}</span>
                            </p>
                            <p>
                                <img src="@/assets/admin/icon/Error.png" alt="">
                                Nội dung khiếu nại:
                                <span>{{ complain.complain }}</span>
                            </p>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <b-row class="mt-3">
                <h5 class="text-center mb-4">Mẫu đăng ký và quy chế đấu giá tài sản</h5>
                <b-col cols="lg-6">
                    <div class="wp-pdf">
                        <p>Quy chế tham gia đấu giá</p>
                        <div class="pdfvuer">
                            <pdf :src="pdfdata" v-for="i in numPages" :key="i" :id="i" :page="i" :scale.sync="scale"></pdf>
                        </div>
                    </div>
                </b-col>
                <b-col cols="lg-6">
                    <p style="font-size: 18px; font-weight: 500;">Quy chế riêng:</p>
                    <ul class="list-rule" v-if="dataDetail.own_regulation !== null">
                        <li>{{ dataDetail.own_regulation }}</li>
                    </ul>
                    <p style="font-size: 18px; font-weight: 500;">Hồ sơ yêu cầu khi đăng ký tham gia đấu giá ( PDF , Docx , Word ,...):</p>
                    <ul class="list-rule">
                        <li v-for="(required, index) in dataDetail.required_file" :key="index">{{ required.required_file_name }}</li>
                    </ul>
                </b-col>
            </b-row>
        </div>
        <Approve ref="approve" @handleCloseModal="listenDialog"/>
        <Delete ref="delete" @handleCloseModal="listenDialog"/>
    </div>
</template>
<script>
import slideImage from "../components/slideImage.vue";
import pdfvuer from 'pdfvuer'
import Approve from './modal/approve.vue'
import Delete from './modal/delete.vue'
export default {
    components: {
        slideImage,
        pdf: pdfvuer,
        Approve,
        Delete
    },
    data() {
        return {
            action: '',
            page: 1,
            numPages: 0,
            pdfdata: '',
            scale: 'page-width',
            register: '',
            proof: '',
            other: '',
            id: '',
            token: '',
            dataDetail: [],
            timeLeft: 0,
            txt_status: '',
            listRole: [],
            intListRole: [],
        }
    },
    computed: {
        countdown() {
            return this.timeLeft ? this.formatTime(this.timeLeft, 'hour') : '00 giờ      00 phút      00 giây';
        },
    },
    mounted(){
        const currentPath = this.$route.path;
        if (currentPath.includes('list-asset') == true) {
            this.action = 'edit'
        } else if(currentPath.includes('approve-asset')) {
            this.action = 'approve'
        } else {
            this.action = ''
        }
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
            info.roles.arr_resource.forEach(element => {
                this.listRole.push(element.resource_id)
            });
            this.intListRole = this.listRole.map(Number)
        }
        this.id = this.decode(this.$route.params.asset_id)
        this.getDataDetail()
    },
    methods: {
        async getDataDetail() {
            await this.$axios.get('/asset/getAssetDetail', {
                params: {
                    asset_id: this.id,
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.dataDetail = res.data
                if(res.data.asset_status == 3) {
                    this.txt_status = "Đã đấu giá - Không thành"
                } else if(res.data.asset_status == 4) {
                    this.txt_status = "Đã đấu giá - Thành"
                } else {
                    this.txt_status = "Đã đấu giá - Chờ xử lý"
                }
                this.pdfdata = this.$storage + res.data.regulation
                this.getPdf()

                // --
                let startTime = new Date(res.data.auction_start_time).getTime();
                let endTime = new Date(res.data.end_time).getTime();
                let now = new Date().getTime();
                let countdown = startTime - now
                if (countdown - 25200000 !== 0 && countdown - 25200000 > 0) {
                    this.timeLeft = parseInt(countdown);
                    this.startCountdown(parseInt(countdown));
                    this.timeStart = true
                } else {
                    countdown = endTime - now
                    this.timeLeft = parseInt(countdown);
                    this.startCountdown(parseInt(countdown));
                    this.timeStart = false
                }
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if(err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                }
            })
        },
        getPdf() {
            var self = this;
            self.pdfdata = pdfvuer.createLoadingTask(this.pdfdata);
            self.pdfdata.then(pdf => {
                self.numPages = pdf.numPages;
            });
        },
        formateTime(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY HH:mm:ss")
        },
        approveAsset(type, id, code) {
            this.$refs.approve.show(type, id, code)
        },
        listenDialog(isClose = false) {
            if(!isClose) {
                this.$router.go(-1)
            }
        },
        deleteAssset(type, id) {
            this.$refs.delete.show(type, id)
        },
        getReport(id) {
            const links = [
                this.$report + "/report/exportPDF?path=template/bien_ban_dau_gia&asset_id=" + id,
                this.$report + "/report/exportPDF?path=template/danh_sach_diem_danh&asset_id=" + id,
                this.$report + "/report/exportPDF?path=template/dien_bien_tra_gia&asset_id=" + id,
                this.$report + '/report/exportPDF?path=template/trich_xuat_dau_gia&asset_id=' + id,
                this.$report + '/report/exportPDF?path=template/danh_sach_dang_ky&asset_id=' + id
            ];

            links.forEach((link) => {
                window.open(link, '_blank');
            });
        },
        async updateStatusAsset(asset_status, id) {
            const data = {
                asset_status : asset_status,
                asset_id : id
            }
            this.txt_status = event.target.innerText
            await this.$axios.put('/asset/updateAssetStatus', data, {
                headers: {
                    'x-access-token': this.token,
                } 
            }).then(res => {
                this.$toast.success('Sửa trạng thái tài sản thành công!')
                // this.$router.go(-1)
                this.getDataDetail()
            }).catch(err => {
                this.$toast.error('Sửa trạng thái tài sản không thành công!')
            })        
        }
    }
}
</script>
<style lang="css" scoped>
    .asset_detail {
        box-shadow: 2px 2px 6px 0px #00000026;
        background: #FFFFFF;
        border-radius: 15px;
        padding: 13px 12px 20px 23px;
        color: #515151;
    }

    button {
        background: none;
        border: 1px solid #EE3439;
        border-radius: 20px;
        color: #EE3439;
        font-weight: 500;
        padding: 6px 10px;
    }

    .btn-group-approve button.btn-img {
        border: none;
        filter: grayscale(1);
        width: 40px;
    }
    .btn-group-approve button.btn-approve {
        border: none;
        background-color: #EE3439;
        color: #FFFFFF;
    }
    .btn-img img {
        width: 20px;
        height: 20px;
    }
    .info-asset .col-md-7 p {
        font-weight: 500;
        color: #444444;
    }

    .btn-group-approve button{
        width: 139px;
        height: 36px;
    }
    .text-price {
        color: #EE3439 !important;
        font-size: 24px;
    }

    .same-file h5, .info-orig h5 {
        font-size: 22px;
        font-weight: 500;
    }

    .same-file a {
        position: relative;
        padding-left: 30px;
        display: block;
        color: #515151;
        margin-bottom: 10px;
    }
    .same-file a::before {
        position: absolute;
        content: '';
        background-image: url('@/assets/icon/Note.png');
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        left: 0;
    }
    .info-orig span {
        color: #EE3439;
        font-weight: 500;
    }

    .wp-pdf {
        background-color: #BEBEBE;
        border-radius: 10px;
        padding: 13px 79px 24px 85px;
    }
    .wp-pdf p {
        font-size: 22px;
        font-weight: 600;
        text-align: center;
    }

    ul.list-rule {
        list-style: disc;
        padding-left: 2rem;
    }

    .asset_detail_lnk {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        border: 1px solid #515151;
        border-radius: 20px;
        height: 30px;
        width: 215px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .status_au {
        box-shadow: 0px 2px 10px 0px #0000001A;
        border-radius: 40px;
        text-align: center;
        position: relative;
        margin-bottom: 15px;
        width: 75%;
    }

    .status_au_success::before {
        content: '';
        position: absolute;
        background-image: url('@/assets/icon/Ok.png');
        background-repeat: no-repeat;
        width: 41px;
        height: 41px;
        left: 3%;
        top: 25%;
    }

    .status_au_fail::before {
        content: '';
        position: absolute;
        background-image: url('@/assets/front-end/icon/Group 831.png');
        background-repeat: no-repeat;
        width: 52px;
        height: 51px;
        left: 3%;
        top: 25%;
    }

    .status_au_dif::before {
        content: '';
        position: absolute;
        background-image: url('@/assets/icon/Auction_modal.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: 52px;
        height: 51px;
        left: 3%;
        top: 25%;
    }

    .status_au p {
        color: #515151;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .status_au p:last-child {
        font-family: Libre Bodoni;
        font-weight: 700;
        font-size: 26px;
        padding-bottom: 10px;
        color: #00B569;
    }

    .show-time {
        position: relative;
        text-align: center;
        font-weight: 500;
        color: #EE3439;
        border: none;
        box-shadow: 2px 2px 2px 0px #00000040;
        background-color: #F7F7F7;
        border-radius: 40px;
        margin-bottom: 15px;
        width: 75%;
    }

    .show-time::before {
        content: "";
        position: absolute;
        background-image: url('@/assets/icon/clock.png');
        width: 75px;
        height: 61px;
        background-repeat: no-repeat;
        left: 3%;
        top: 21%;
    }

    .show-time p:first-child {
        font-size: 22px;
    }

    .show-time p:last-child {
        font-size: 30px;
        font-family: Libre Bodoni;
        white-space: break-spaces;
    }

    .show-time p:nth-child(2) {
        font-size: 18px;
    }

    .show-time p {
        margin: 0;
    }

    @media (max-width: 992px) {
        .status_au {
            width: 100%;
        }
    }
    @media (max-width: 768px) {
        .wp-pdf {
            padding: 13px 0 24px 0;
        }

        .wp-btn-lnk {
            flex-wrap: wrap;
        }

        .asset_detail_lnk {
            margin: 5px 0 0 5px !important;
        }

        .wp-btn-lnk h5 {
            margin-bottom: 10px !important;
        }
    }
</style>

<style>
    .wp-asset_detail button.btn.dropdown-toggle {
        background-color: transparent;
        border-radius: 20px;
        padding-top: 2px;
        padding-bottom: 2px;
        color: #FF9838;
        font-weight: 500;
    }

    .wp-asset_detail .failed button.btn.dropdown-toggle {
        color: #EE3439;
    }

    .wp-asset_detail .success button.btn.dropdown-toggle {
        color: #00B569;
    }

    .wp-asset_detail .dropdown-toggle::after {
        border-right-color: #666666 !important;
        border-bottom-color: #666666 !important;
        margin-left: 25px;
    }

    .wp-asset_detail .dropdown-menu {
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    }

    .wp-asset_detail .dropdown-item:hover, .wp-asset_detail .dropdown-item:focus {
        background-color: transparent;
    }

    .wp-asset_detail .dropdown-menu li:first-child {
        border-bottom: 1px solid #E9E9E9;
    }

    .wp-asset_detail .dropdown-menu li .dropdown-item {
        font-weight: 500;
        color: #EE3439;
    }
    .wp-asset_detail .dropdown-menu li:first-child .dropdown-item {
        color: #00B569;
    }

    .wp-asset_detail .table {
        overflow: auto;
    }

    .wp-asset_detail table, .wp-asset_detail tr, .wp-asset_detail td {
        border: 1px solid #515151;
    }
</style>