<template>
    <div>
        <div class="begin-header" :class="{ 'auction_room': auctionRoom() }" v-if="!hideHeader"></div>
        <div class="header" :class="{ 'auction_room': auctionRoom() }">
            <div class="header-content">
                <div class="container">
                    <b-navbar toggleable="lg" type="dark">
                        <b-navbar-toggle target="nav-collapse" id="toggel-button"></b-navbar-toggle>
                        <b-navbar-brand to="/">
                            <img src="@/assets/images/logo 4.png" alt="" class="header-logo">
                            <p class="logo_ttl">DGV AUCTION <br> PARTNERSHIP COMPANY</p>
                        </b-navbar-brand>
                        <b-collapse id="nav-collapse" is-nav ref="navCollapse">
                            <b-navbar-nav class="ml-auto" >
                                <b-nav-item to="/" class="header_sub">
                                    <img src="@/assets/images/logo 4.png" alt="">
                                </b-nav-item>
                                <div class="d-flex product-auction">
                                    <b-nav-item to="/product-auction" class="head-link">Tài sản đấu giá</b-nav-item>
                                    <b-nav-item-dropdown>
                                        <b-dropdown-item :to="'/cate-detail/'+category.category_id" v-for="(category, index) in listCategory" :key="index">
                                            <img class="dropdown-ico" :src="$storage + category.icon" alt="" @error="handleError">
                                            {{ category.name}}</b-dropdown-item>                                       
                                    </b-nav-item-dropdown>
                                </div>
                                <div class="d-flex auction">
                                    <b-nav-item @click="routeAuction('')" class="head-link head-link_auction">
                                        Cuộc đấu giá
                                    </b-nav-item>
                                    <b-nav-item-dropdown>
                                        <b-dropdown-item @click="routeAuction('0')">
                                            Cuộc đấu giá sắp diễn ra
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="routeAuction('1')">
                                            Cuộc đấu giá đang diễn ra
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="routeAuction('2,3')">
                                            Cuộc đấu giá đã kết thúc
                                        </b-dropdown-item>
                                    </b-nav-item-dropdown>
                                </div>
                                <!-- <div class="d-flex news">
                                    <router-link to="/news" class="head-link head-link_news">Tin tức</router-link>
                                    <b-nav-item-dropdown>
                                        <b-dropdown-item to="/news">Tin tức chung</b-dropdown-item>
                                        <b-dropdown-item to="/news">Thông tin đấu giá</b-dropdown-item>
                                        <b-dropdown-item to="/news">Kết quả đấu giá</b-dropdown-item>
                                    </b-nav-item-dropdown>
                                </div> -->

                                <b-nav-item to="/news" class="d-flex align-items-center">Tin tức</b-nav-item>
                                <b-nav-item to="/about" class="d-flex align-items-center">Giới thiệu</b-nav-item>
                                <b-nav-item to="/contact" class="d-flex align-items-center">Liên hệ</b-nav-item>
                                <b-nav-item to="/login" class="lg-none" v-if="!isLoggedIn">Đăng ký / Đăng nhập</b-nav-item>
                                <b-nav-item to="/countdown" class="lg-none" v-if="!isLoggedIn">
                                    <img src="@/assets/icon/Time.png" alt="">Time
                                </b-nav-item>
                                <!-- <b-nav-item  v-if="isLoggedIn" @message="loadData()">
                                    <span class="count-noti" v-if="listNotification.count_new_noti">{{ formartPreCountNoti(listNotification.count_new_noti) }}</span>
                                    <b-dropdown class="notificate" right >
                                        <div class="drodown-content" >
                                            <div class="col-12 header pb-2">
                                                Thông báo
                                            </div>
                                            <div class="col-12 p-2 pt-0  body" @scrollend="loadData()">
                                                <div v-for="(notificate, index) in listNotification.result" @click="viewNotificate(notificate)" :class="{ 'unseen': !notificate.view_status }">
                                                    <div class="col-12 p-2 content d-flex"  v-if="notificate.type == 1">
                                                        <div class="col-auto pl-0 pr-2">
                                                            <img :src="$storage + notificate.image" @error="handleError"
                                                                class="image-noti" alt="">
                                                        </div>
                                                        <div>
                                                            <h6>{{ notificate.notification }} </h6>
                                                            <p>Công ty đấu giá hợp danh DGV thông báo: {{ notificate.notification }} </p>
                                                            <div class="button">
                                                                <button class="btn btn-outline" @click="$router.push('/news-detail/' + notificate.news_id)">Xem chi tiết</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-2 content" @click="redirectRouter(notificate)"
                                                    v-if="notificate.type == 3 || notificate.type == 4 || notificate.type == 5 || notificate.type == 13 || notificate.type == 16 ">
                                                        <h6>{{ notificate.type_name }} </h6>
                                                        <p>
                                                            {{ notificate.notification }} </p>
                                                    </div>
                                                    <div class="col-12 p-2 content" v-if="notificate.type == 6">
                                                        <h6>{{ notificate.type_name }} </h6>
                                                        <p>{{ notificate.content }}
                                                            <strong> {{ notificate.notification }}</strong>
                                                        </p>
                                                        <div class="button">
                                                            <button class="btn btn-outline" @click="$router.push('/account/auction')">Tham gia</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="loader-notifi" v-if="listNotification.pagination && listNotification.pagination.per_page <= listNotification.pagination.total_element"></div>
                                                <div v-else class="text-center" style="font-size: 14px; color: #EE3439; font-weight: 500;">Đã hết thông báo</div>
                                            </div>
                                        </div>
                                    </b-dropdown>
                                </b-nav-item>
                                <b-nav-item to="/account" class="d-flex align-items-center" v-if="isLoggedIn">
                                    <img class="head-avatar"
                                        :src="$storage + dataMember.avatar" alt=""
                                        v-if="dataMember.avatar" @error="handleError">
                                    <img src="@/assets/front-end/img/blank-avatar-icon-26.png" alt="" @error="handleError"
                                        class="head-avatar" v-else>
                                </b-nav-item> -->
                            </b-navbar-nav>
                        </b-collapse>
                        <div class="d-flex align-items-center" v-if="isLoggedIn">
                            <b-nav-item @message="loadData()" style="list-style: none;">
                                <span class="count-noti" v-if="listNotification.count_new_noti"
                                onclick="$('.header .notificate button.dropdown-toggle').click()">
                                    {{ formartPreCountNoti(listNotification.count_new_noti) }}
                                </span>
                                <b-dropdown class="notificate" right >
                                    <div class="drodown-content" >
                                        <div class="col-12 header pb-2">
                                            Thông báo
                                        </div>
                                        <div class="col-12 p-2 pt-0  body" @scrollend="loadData()">
                                            <div v-for="(notificate, index) in listNotification.result" @mousedown="viewNotificate(notificate)" :class="{ 'unseen': !notificate.view_status }">
                                                <div class="col-12 p-2 content d-flex"  v-if="notificate.type == 1">
                                                    <div class="col-auto pl-0 pr-2">
                                                        <img :src="$storage + notificate.image" @error="handleError"
                                                            class="image-noti" alt="">
                                                    </div>
                                                    <div>
                                                        <h6>{{ notificate.notification }} </h6>
                                                        <p>Công ty đấu giá hợp danh DGV thông báo: {{ notificate.notification }} </p>
                                                        <div class="button">
                                                            <button class="btn btn-outline" @mousedown="$router.push('/news-detail/' + notificate.news_id)">Xem chi tiết</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-12 p-2 content" @mousedown="redirectRouter(notificate)" v-else-if="notificate.type == 6">
                                                    <h6>{{ notificate.type_name }} </h6>
                                                    <p>{{ notificate.content }}
                                                        <strong> {{ notificate.notification }}</strong>
                                                    </p>
                                                    <div class="button">
                                                        <button class="btn btn-outline">Tham gia</button>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-2 content" @mousedown="redirectRouter(notificate)" v-else>
                                                    <h6>{{ notificate.type_name }} </h6>
                                                    <p>
                                                        {{ notificate.notification }} </p>
                                                </div>
                                            </div>
                                            <div class="loader-notifi" v-if="listNotification.pagination && listNotification.pagination.per_page <= listNotification.pagination.total_element"></div>
                                            <div v-else class="text-center" style="font-size: 14px; color: #EE3439; font-weight: 500;">Đã hết thông báo</div>
                                        </div>
                                    </div>
                                </b-dropdown>
                            </b-nav-item>
                            <div class="mb-border" v-if="isLoggedIn"></div>
                            <b-nav-item to="/account" class="d-flex align-items-center" v-if="isLoggedIn">
                                <div class="head-avatar">
                                    <img class=""
                                        :src="$storage + dataMember.avatar" alt=""
                                        v-if="dataMember.avatar" @error="handleErrorAvatar">
                                    <img src="@/assets/front-end/img/blank-avatar-icon-26.png" alt="" @error="handleError"
                                        class="" v-else>
                                </div>
                            </b-nav-item>
                        </div>
                    </b-navbar>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { notification } from '@/service/fireBase'

export default {

    computed: {
        ...mapGetters(['isLoggedIn']),
        getDeviceToken() {
            return notification
        },
    },
    data() {
        return {
            params: {
                code: "",
                name: "",
                status: "",
                parent_id: "",
                page: 1,
                per_page: 10,
            },
            paramsNoitifi:{
                page: 1,
                per_page: 5,
            },
            listCategory:[],
            dataMember: [],
            storedData: localStorage.getItem('userInfo'),
            listNotification:{},
            hideHeader : false
        }
    },
    mounted() {
        this.getState()
        if (localStorage.getItem('member')) {
            setTimeout(() => {
                this.showNotification()
            }, 200);
            this.getDataMember()
            this.getListNotificationForMember(this.paramsNoitifi)
        }
        this.getListCategoryForMember(this.params)
        
        if (this.$route.path == '/countdown') {
            this.hideHeader = true
        }
    },
    watch: {
        "$route.path": function (url) {
            this.hideHeader = false
            if (url == '/countdown') {
                this.hideHeader = true
            }
        }
    },
    methods: {
        
        getState(){
            let element = this
            document.addEventListener("click", function (event) {
                if (event.target.id != 'toggel-button' && event.target.className != 'navbar-toggler-icon') {
                    if (element.$refs.navCollapse.show) {
                        element.$refs.navCollapse.show = false
                    }
                }
            });
        },
        redirectRouter(noti){
            if (noti.type == 3|| noti.type == 2 || noti.type == 4 || noti.type == 6) {
                this.$router.push({
                    path:'/product-detail/'+ this.encode(noti.asset_id),
                    query:{
                        approve_status : noti.approve_status,
                        is_client:noti.is_client,
                    }
                })
            } else if (noti.type == 5|| noti.type == 16) {
                this.$router.push('/result-auction/' + this.encode(noti.asset_id))
            } else {
                this.$router.push('/account/auction/delivery')
            }
        },
        async getDataMember() {
            if (this.storedData) {
                var info = JSON.parse(this.storedData)
                await this.$axios.get('/member/getMemberDetail', {
                    params: {
                        member_id: info.id
                    },
                    headers: {
                        'x-access-token': info.accessToken
                    }
                }).then(res => {
                    this.dataMember = res.data
                }).catch(err => {
                    this.checkAuthenticate(err)
                })
            }
        },
        auctionRoom() {
            let setPath = '/auction-room/' + this.$route.params.id
            if (this.$router.currentRoute.path == setPath) {
                return true
            }
        },
        routeAuction(type){
            this.$router.push({
                path: "/auction",
                query: {
                    type: type,
                },
                // preserve existing query and hash if any
            })
        },
        formartPreCountNoti(count){
            if (count > 9) {
                return '9+'
            }else{
                return count
            }
        },
        getListNotificationForMember(params) {
            this.$axios.get('/common/getListNotificationForMember', {
                params: params,
                headers: {
                    'x-access-token': localStorage.getItem('member')
                }
            }).then(res => {
                this.listNotification = res.data
                if (this.page) {
                    this.page.totalData = res.data.pagination.total_element
                    this.page.totalPage = res.data.pagination.total_page
                    this.page.currentPage = res.data.pagination.page
                    this.page.totalPage = res.data.pagination.total_page
                }
            }).catch(err => {
                this.checkAuthenticate(err)
            })
        },
        showNotification(){
            let mouse = this
            var noticeMe = this.getDeviceToken.notificate
            if (noticeMe) {
                noticeMe.onMessageHandler = function (payload) {
                    if (payload.data.logout_status == 1 && payload.data.role == 3) {
                        mouse.logout()
                    }
                    else {
                        mouse.getListNotificationForMember(mouse.paramsNoitifi)
                    }
                    const notificationOptions = {
                        body: payload.notification.body,
                        icon: '/dgv_logo.png'
                    };
                    new Notification(payload.notification.title, notificationOptions)
                }
            }
        },
        loadData(){
            this.paramsNoitifi.per_page += 5;
            this.getListNotificationForMember(this.paramsNoitifi)
        },
        async viewNotificate(notificate){
            if (!notificate.view_status ) {
                await this.$axios.post('/common/viewNotification', {
                    notification_id: notificate.id,
                }, {
                    headers: {
                        'x-access-token': localStorage.getItem('member'),
                        'Content-Type': 'application/json',
                    }
                }).then((res) => {
                    this.getListNotificationForMember(this.paramsNoitifi)
                }).catch((err) => {
                    this.checkAuthenticate(err)
                })
            }
        }
    },

}
</script>

<style lang="css" >
.begin-header {
    height: 99px;
    background-color: #080808;
}
body{
    font-family: Roboto;
}
.image-noti{
    width: 120px;
    height: 120px;
}
#end-scroll{
    height: 1px;
    overflow: auto;
}
.header .notificate .unseen .content{
    background-color: #FFd8d8;
}
.header .notificate button.dropdown-toggle {
    background-image: url('@/assets/icon/Alarm.png');
    background-color: #00000000;
    border: unset;
}

.header .notificate .dropdown-menu.show {
    width: 392px;
    right: 0%;
    left: unset;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.header .content h6 {
    font-size: 15px;
    font-weight: 600;
    font-family: Roboto;
    color: #515151;
}

.header .button {
    display: flex;
    align-items: center;
    margin-top: 0.25rem;
    justify-content: flex-end;
}

.header .button .btn {
    border: #515151 1px solid;
    font-weight: 500;
    font-size: 14px;
    color: #515151;
    width: 136px;
    height: 30px;
    border-radius: 30px;
    padding: 0;

}

.header .content p {
    font-size: 13px;
    font-weight: 400;
    font-family: Roboto;
    color: #515151;
}

.header .notificate ul.dropdown-menu {
    background-color: #00000000;
    border: unset;
}

.header .notificate ul {
    margin-top: 100%;
}

.header .notificate .header {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    color: #515151;
    padding: 1rem 1rem 0 1rem;
}

.header .notificate .footer {
    background-color: #00000000;
    font-family: Roboto;
    color: #EE3439;
    font-weight: 500;
    font-size: 16px;
    padding: 0.5rem;

}

.header .notificate .body {
    height: 500px;
    overflow: auto;
    border-radius: 0 0 0 20px;
}

.header .notificate .body::-webkit-scrollbar {
    width: 0;
}

.header .notificate .content {
    background-color: #EEEEEE;
    border-radius: 10px;
    margin: 0.5rem 0;
}

.header .notificate .drodown-content {
    background-color: #ffffff;
    border-radius: 20px;
    overflow: hidden;
    padding-bottom: 10px;
}

body::-webkit-scrollbar {
    width: 6px;
}

body::-webkit-scrollbar-thumb {
    background: #D6B877;
}

.header .nav-link:focus-visible {
    border: none;
    outline: none;
}

.header .logo_ttl {
    margin: 0;
    color: #DEDEDE;
    font-family: Libre Bodoni;
    padding-left: 9px;
    line-height: 1;
}

.header .navbar-brand {
    display: flex;
    align-items: center;
}

.header #nav-collapse {
    justify-content: flex-end;
}

.header ul.navbar-nav {
    justify-content: center;
    align-items: center;
}

.header .dropdown-ico {
    margin-right: 14px;
    width: 24px;
    height: 24px;
}

.header .nav-item a {
    color: #DEDEDE;
    font-size: 18px;
    line-height: 21px;
    margin-right: 15px;
    font-weight: 500;
}

.header .head-link a {
    margin-right: 0;
}

.header .nav-item a span {
    margin-right: 8px;
    font-size: 12px;
    color: #515151;
}

.dropdown-toggle::after {
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    border-top: 0 !important;
    border-right: 2px solid #DEDEDE !important;
    border-bottom: 2px solid #DEDEDE !important;
    border-left: 0 !important;
}

.header ul.dropdown-menu {
    background-color: #080808;
}

.header ul.dropdown-menu li {
    border-bottom: 0.89px solid #4B4B4B;
}

.header a.dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px;
}

.header ul.dropdown-menu li:last-child {
    border: none;
}

.header .dropdown-item:hover,
.header .dropdown-item:focus {
    background-color: #080808 !important;
    color: #FF0000 !important;
}

.header button.navbar-toggler {
    border: none;
    outline: none;
    margin-right: 20px;

}

.header button.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

.header .lg-none {
    display: none;
}

.header .username {
    font-weight: 700;
    font-family: Libre Bodoni;
    font-size: 16px;
    line-height: 20px;
    margin-left: 20px;
}

.header .head-link {
    font-size: 18px;
    line-height: 21.09px;
    color: #DEDEDE;
    text-decoration: none;
    font-weight: 500;
    width: 138px;
}

.header .head-link_news {
    width: 59px;
}

.header .head-link_auction {
    width: 123px;
}

.header .head-link:hover {
    color: #DEDEDE;
}

.product-auction,
.auction,
.news {
    align-items: center;
}

.header .dropdown-menu.show {
    left: -130px;
    width: 246px;
}

.news .dropdown-menu.show {
    left: -65px;
}

.auction .dropdown-menu.show {
    left: -112px;
}

.header .header-content {
    background-color: #080808;
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 1000;
    min-height: 99px;
    align-items: center;
    top: 0;
}

.header-room {
    position: relative;
}

.header_sub {
    display: none;
}

.mb-border {
    height: 60px;
    border: 1px solid #727272;
    margin-right: 10px;
    display: none;
}
@media (max-width: 1200px) {
    .header .logo_ttl {
        display: none;
    }
}

@media (max-width: 1400px) {


    .begin-header.auction_room {
        height: auto;
    }

    .header.auction_room .header-content {
        position: relative;
    }

    .container {
        max-width: 1280px;
    }
}

@media (max-width: 1024px) {
    .header .username {
        display: none;
    }

    .header .logo_ttl {
        display: none;
    }
}
@media (max-width: 991px) {
    .header .navbar-collapse {
        position: absolute;
        top: 70px;
        left: -2%;
        z-index: 99;
        background: #080808;
        width: 290.58px;
    }

    .navbar-nav .dropdown-menu {
        position: relative;
    }

    .header-logo {
        display: none;
    }

    .header ul.navbar-nav {
        align-items: start;
        padding: 0 0 0 20px;
        height: 850px;
        justify-content: flex-start;
    }
    .header .notificate .btn-group{
        position: absolute;
    }

    .header_sub, .mb-border {
        display: block;
    }
}
@media (max-width: 992px) {
    .header .notificate ul{
        margin-top: 5%;
    }
    .header .notificate .dropdown-menu.show{
        width: 392px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        position: fixed;
        right: 10px;
    }

    .product-auction,
    .auction,
    .news {
        align-items: unset;
    }

    .header .lg-none {
        display: block;
    }

}
@media (max-width: 768px) {

    .header .username {
        display: inline-block;
    }  

    .header .b-nav-dropdown.dropdown {
        /* top: -7px; */
    }
}

@media (max-width: 576px) {

    .header .navbar-collapse {
        left: -12px;
    }

    .header .logo_ttl {
        font-size: 14px;
    }

    .header button.navbar-toggler {
        margin-right: 0;
    }

    .header .navbar-brand {
        margin-right: 0;
    }
}

@media (max-width: 335px) {
    .header .logo_ttl {
        font-size: 10px;
    }

    .header .navbar-brand {
        margin-right: 0 !important;
    }
}

@media (max-width: 285px) {
    .header .logo_ttl {
        display: none;
    }

    .header .navbar {
        justify-content: space-around;
    }
}
</style>
<style lang="css" scoped>
    .count-noti{
        position: absolute;
        background-color: #FBE3B0;
        z-index: 1000;
        color: #FFFFFF;
        font-size: 15px;
        border-radius: 12px;
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 18px;
    }
    .head-avatar{
        width: 45px;
        height: 45px;
        border-radius: 31px;
        margin: auto;
        object-fit: cover;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .head-avatar img{
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .notificate .loader-notifi {
        right: 47%;
    }
</style>