<template>
    <div>
        <h5 style="font-size: 22px;" class="mb-3">Phân quyền</h5>
        <b-row>
            <b-col cols="xl-5">
                <div class="list-auth">
                    <div v-if="intListRole.includes(12)">
                        <div class="d-flex justify-content-between align-items-center mb-4">
                            <p class="m-0" style="font-size: 20px;">Phân quyền</p>
                            <button class="add_auth" @click="addAuth" v-if="intListRole.includes(13)">+ Tạo phân quyền mới</button>
                        </div>
                        <div class="item-auth mb-3" v-for="(data, index) in dataListRole" :key="index" :class="{'active' : active == index}">
                            <p @click="showDetailAuth(index, data.role_id)" style="width: 85%;">{{ data.role_name }}</p>
                            <div>
                                <button v-if="intListRole.includes(14)"><img src="@/assets/admin/icon/Edit.png" alt="" @click="editRole('edit', index, data.role_id)"></button>
                                <button v-if="intListRole.includes(15)" :class="{'d-none' : data.role_id == 1 || data.role_id == 2 || data.role_id == 3}"><img src="@/assets/admin/icon/Trash.png" alt="" @click="showModalDel(data.role_name, data.role_id )"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col cols="xl-7" v-if="showDetail">
                <div class="menu-auth">
                    <p style="font-size: 20px;">Chức năng</p>
                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                        <label class="container-label mb-0">
                            <h5 style="font-size: 18px;" class="mb-0">Chọn tất cả chức năng</h5>
                            <input type="checkbox" class="input-style" @change="toggleAll" :disabled="!statusEdit">
                        <span class="checkmark"></span>
                        </label>
                        <b-form class="search-form">
                            <b-form-group>
                                <b-form-input v-model="keySearch" type="text" placeholder="Tìm kiếm chức năng" required></b-form-input>
                            </b-form-group>
                            <button style="background-color: #EE3439;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                        </b-form>
                    </div>
                    <!-- ----ITEM---- -->
                    <div class="wp-item">
                        <div v-for="(resource, index) in filteredItems" :key="index">
                            <div class="item-func" v-if="!resource.parent_id">
                                <label class="container-label mb-0">
                                    <p style="font-size: 18px;" class="mb-0">{{ resource.resource_name }}</p>
                                    <input type="checkbox" class="input-style" v-model="dataDetailRole" :value="resource.resource_id" 
                                        :checked="dataDetailRole.includes(resource.resource_id)"
                                        :disabled="!statusEdit" @change="selectResouce">
                                    <span class="checkmark"></span>
                                </label>
                                <div class="list-fucn d-flex">
                                    <p v-for="action in resource.action" style="margin: 0;">{{ action.action_name }}</p>
                                </div>
                            </div>
                            <div class="item-func-item" v-if="resource.parent_id">
                                <label class="container-label">
                                    <p style="font-size: 18px;">{{ resource.resource_name }}</p>
                                    <input type="checkbox" class="input-style" v-model="dataDetailRole" :value="resource.resource_id" :disabled="!statusEdit">
                                    <span class="checkmark"></span>
                                </label>
                                <div class="list-fucn list-fucn--sub d-flex">
                                    <p v-for="action in resource.action"
                                        :class="{'active' : action.status == 1}">
                                        {{ action.action_name }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-right" v-if="statusEdit">
                        <button class="cancel-edit" @click="editRole('cancel')">Hủy bỏ thay đổi</button>
                        <button class="accept-edit" @click="updateRole">Xác nhận phân quyền</button>
                    </div>
                </div>
            </b-col>
        </b-row>
        <addAuth ref="addAuth" @handleCloseModal="listenDialog"/>
        <Delete ref="delete" :del="del" @handleCloseModal="listenDialog"/>
    </div>
</template>
<script>
import search from '../components/search_form.vue'
import addAuth from './modal/addAuth.vue'
import Delete from './modal/deleteAuth.vue'
export default {
    components: {
        search,
        addAuth,
        Delete
    },
    data() {
        return {
            del: {name: '', id: ''},
            keySearch: '',
            dataListRole: [],
            // dataListAction: [],
            dataDetailRole: [],
            dataListResource: [],
            showDetail: false,
            active: -1,
            token: '',
            statusEdit: false,
            dataSubmit: [],
            role_id: '',
            listRole: [],
            intListRole: [],
        }
    },
    computed: {
        filteredItems() {
            return this.dataListResource.filter(resource => resource.resource_name.toLowerCase().includes(this.keySearch.toLowerCase()));
        },
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
            info.roles.arr_resource.forEach(element => {
                this.listRole.push(element.resource_id)
            });
            this.intListRole = this.listRole.map(Number)
        }
        this.getListRole()
        // this.getListAction()
        this.getListResource()
    },
    methods: {
        toggleAll(event) {
            this.dataDetailRole = []
            this.dataSubmit = event.target.checked ? this.dataListResource.slice() : []
            if(this.dataSubmit.length !== 0) {
                this.dataSubmit.forEach(element => {
                    this.dataDetailRole.push(element.resource_id)
                });
            } else {
                this.dataDetailRole = []
            }
        },
        selectResouce(event) {
            this.dataListResource.forEach(element => {
                if(event.target.checked) {
                    if(element.parent_id == event.target.value) {
                        this.dataDetailRole.push(element.resource_id)
                    }
                } else {
                    if(element.parent_id == event.target.value) {
                        this.dataDetailRole = this.dataDetailRole.filter(item => item !== element.resource_id)
                    }
                }
            });
        },
        addAuth() {
            this.$refs.addAuth.show()
        },
        showModalDel(name, id) {
            this.del.name = name
            this.del.id = id
            this.$refs.delete.show()
        },
        editRole(type, key, id) {
            this.role_id = id
            this.active = key
            this.showDetail = true
            if(type == 'edit') {
                this.statusEdit = true
                this.showDetailAuth(key, id)
            } else {
                this.statusEdit = this.showDetail = false
            }
        },
        async updateRole () {
            const data = {
                "role_id": this.role_id,
                "resource": this.dataDetailRole
            }
            await this.$axios.put('/permission/updateRole', data, {
                headers: {
                    'x-access-token': this.token
                } 
            }).then((res) => {
                this.$toast.success('Sửa phân quyền thành công!')
                this.statusEdit = false
                this.getListResource()
            }).catch((err) => {
                this.$toast.error('Sửa phân quyền không thành công!')
            })
        },
        async showDetailAuth(key, id) {
            this.showDetail = true
            this.active = key
            this.dataDetailRole = []
            const res = await this.$axios.get('/permission/getRoleDetail?role_id=' + id, {
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                if(res.data.resource.length != 0) {
                    res.data.resource.forEach(element => {
                        this.dataDetailRole.push(element.resource_id)
                    });
                } else {
                    this.dataDetailRole = []
                }
            }
        },
        listenDialog(isClose = false) {
            if(!isClose) {
                this.getListRole()
                this.showDetail = false
            }
        },
        async getListRole() {
            const res = await this.$axios.get('/permission/getListRole', {
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.dataListRole = res.data;
            }
        },
        // async getListAction() {
        //     const res = await this.$axios.get('/permission/getListAction', {
        //         headers: {
        //             'x-access-token': this.token
        //         }
        //     })
        //     if(res.status == 200) {
        //         this.dataListAction = res.data;
        //     }
        // },
        async getListResource() {
            const res = await this.$axios.get('/permission/getListResource?resource_name', {
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.dataListResource = res.data;
            }
        }
    }
}
</script>
<style lang="css" scoped>
    .container-label {
        color: #515151;
        display: block;
        position: relative;
        padding-left: 25px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
    }

    .container-label input.input-style {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #FFFFFF;
        border: 1px solid #B4B4B4;
        border-radius: 5px;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .container-label input:checked ~ .checkmark:after {
        display: block;
    }

    .container-label .checkmark:after {
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        background-image: url('@/assets/admin/icon/Done_check.png');
    }

    .list-auth,
    .menu-auth {
        box-shadow: 2px 2px 6px 0px #00000026;
        background: #FFFFFF;
        padding: 10px 14px 27px 12px;
        border-radius: 10px;
        color: #515151;
        font-weight: 500;
        height: 100%;
    }
    button {
        background: none;
        border: none;
    }

    .add_auth {
        color: #EE3439;
        font-weight: 500;
    }

    .item-auth {
        display: flex;
        justify-content: space-between;
        border: 1px solid #B4B4B4;
        border-radius: 10px;
        padding: 7px 15px 6px 24px;
        cursor: pointer;
    }

    .item-auth p {
        margin: 0;
    }

    .item-auth img {
        filter: grayscale(1);
        width: 20px;
        height: 20px;
    }
    .item-auth.active {
        color: #FFFFFF;
        background: #EE3439;
        border: none;
    }

    .item-auth.active img{
        filter: brightness(10);
    }

    .item-func {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        border: 1px solid #B4B4B4;
        border-radius: 10px;
        padding: 7px 57px 7px 12px;
        margin: 15px 0;
    }

    .list-fucn p {
        position: relative;
        padding-left: 30px;
        display: flex;
        align-items: center;
        margin-right: 20px !important;
    }
    .list-fucn p::before {
        content: '';
        background: #515151;
        width: 16px;
        height: 16px;
        position: absolute;
        border-radius: 5px;
        left: 0;
    }
    .list-fucn--sub p {
        margin-bottom: 0;
    }
    .list-fucn--sub p::before  {
        content: '';
        background: #FFFFFF;
        border: 1px solid #B4B4B4;
        width: 16px;
        height: 16px;
        position: absolute;
        border-radius: 5px;
        left: 0;
    }

    .list-fucn--sub p.active::before {
        background: #515151;
        border: none;
    }

    .item-func-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        border: 1px solid #B4B4B4;
        border-radius: 10px;
        padding: 7px 57px 7px 12px;
        margin: 15px 0 15px 40px;
    }

    .item-func-item .container-label {
        margin-bottom: 0;
    }
    .item-func-item .container-label p {
        margin-bottom: 0;
    }

    .cancel-edit {
        border: 1px solid #EE3439;
        border-radius: 20px;
        padding: 4px 31px;
        color: #EE3439;
        font-weight: 500;
        margin-right: 20px;
    }
    .accept-edit {
        border-radius: 20px;
        padding: 4px 20px;
        color: #FFFFFF;
        background-color: #EE3439;
        font-weight: 500;
    }

    .wp-item {
        max-height: 650px;
        overflow-y: auto;
    }

    .wp-item::-webkit-scrollbar {
        width: 0;
    }

    @media (max-width:1024px) {
        .menu-auth {
            margin-top: 30px;
        }
    }
    @media (max-width:768px) {
        .list-fucn {
            justify-content: flex-end;
        }
        .item-func, .item-func-item {
            display: block;
        }
    }

    @media (max-width:425px) {
        .list-fucn {
            flex-wrap: wrap;
        }
        .item-func-item {
            padding-right: 0;
        }
    }
</style>

<style>
    .menu-auth .search-form {
        width: 300px;
    }
</style>