<template>
    <div>
        <b-modal id="" ref="delete-auction" centered hide-footer hide-header modal-class="del-auction" content-class="modal-width" @hide="clearData">
            <div class="delete-auction text-center" :class="{'overlay' : isLoading}">
                <img src="@/assets/admin/icon/warning.png" alt="">
                <h5>Xóa cuộc đấu giá</h5>
                <p>Bạn đang yêu cầu xóa bỏ cuộc đấu giá <br/>
                    Sau khi xác nhận xóa bỏ sẽ không thể hoàn tác
                </p>
                <!-- <p style="text-align: left; font-weight: 500;">Lý do: </p>
                <b-form-textarea
                    placeholder="Nhập lí do"
                    rows="3"
                    max-rows="6"
                    v-model="note"
                ></b-form-textarea> -->
                <p style="font-size: 14px; color: #EE3439; font-weight: 500; margin-top: 10px;" v-if="showError">Vui lòng nhập lý do!</p>
                <b-button class="mt-3 mr-3 btn-cancel" @click="hideModal()" block :disabled="isLoading">Hủy bỏ</b-button>
                <b-button class="mt-3" block @click="deleteAuction()" :disabled="isLoading">Xác nhận</b-button>
            </div>
            <div class="loader" v-if="isLoading"></div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            id: '',
            // note: '',
            showError: false,
            isLoading: false
        }
    },
    computed: {

    },
    methods: {
        show(id) {
            this.id = id
            this.$refs['delete-auction'].show()
        },
        hideModal() {
            this.$refs['delete-auction'].hide()
        },
        emitModalVisible(isClose = false) {
            this.$emit("handleCloseModal", isClose);
        },
        async deleteAuction(type) {
            const storedData = localStorage.getItem('admInfo')
            if (storedData) {
                var info = JSON.parse(storedData)
            }
            const data = {
                "auction_id" : this.id,
                // "note" : this.note
            }
            if(this.note == '') {
                this.showError = true
            } else {
                this.isLoading = true
                this.showError = false
                await this.$axios.put('/auction/destroyAuction', data, {
                    headers: {
                        'x-access-token': info.accessToken,
                    } 
                }).then(res => {
                    this.$toast.success('Xóa cuộc đấu giá thành công!')
                    this.$refs['delete-auction'].hide()
                    this.emitModalVisible(false)
                    this.isLoading = false
                }).catch(err => {
                    this.$toast.error('Xóa cuộc đấu giá không thành công!')
                    this.isLoading = false
                })
            }
        },
        clearData() {
            this.showError = false
        }
    }
}
</script>

<style>
    .del-auction .modal-width {
        width: 380px;
    }
</style>
<style lang="css" scoped>

    .delete-auction h5,
    .delete-auction p {
        color: #444444;
    }
    .delete-auction button {
        background-color: #EE3439;
        color: #ffffff;
        font-weight: 500;
        border-radius: 10px;
        border: none;
        min-width: 163px;
        min-height: 42px;
    }

    .delete-auction button:active,
    .delete-auction button:focus {
        background-color: #EE3439 !important;
    }

    .delete-auction button:hover {
        background-color: #EE3439;
    }

    .delete-auction .btn-cancel {
        color: #EE3439;
        border: 1px solid #EE3439;
        background: #ffffff;
    }

    .delete-auction .btn-cancel:hover,
    .delete-auction .btn-cancel:focus,
    .delete-auction .btn-cancel:active {
        background: #ffffff !important;
        color: #EE3439;
        border: 1px solid #EE3439;
    }

    textarea {
        background: #E6E6E6;
        border: 1px solid #CCCCCC;
        border-radius: 10px;
        overflow-y: auto !important;
    }

    textarea:focus {
        box-shadow: none;
        background: #E6E6E6;
        border: 1px solid #CCCCCC;
    }

    @media (max-width: 375px) {
        .delete-auction .btn-cancel {
            margin-right: 0 !important;
        }

        .delete-auction p {
            font-size: 15px;
        }
    }
</style>