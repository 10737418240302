<template>
    <div class="list_auction">
        <h5 style="font-weight: 700; font-size: 22px; color: #515151;" class="mb-4">Danh sách cuộc đấu giá</h5>
        <b-tabs content-class="mt-3">
            <b-tab title="Cuộc đấu giá đang diễn ra"><p><auHappenning /></p></b-tab>
            <b-tab title="Cuộc đấu giá sắp diễn ra" active><auDelivery /></b-tab>
            <b-tab title="Cuộc đấu giá đã diễn ra"><p><auFinished/></p></b-tab>
        </b-tabs>
    </div>
</template>
<script>
import auHappenning from "./components/auction_happenning.vue"
import auDelivery from "./components/auction_upcoming.vue"
import auFinished from "./components/auction_finished.vue"
export default {
    components: {
        auHappenning,
        auDelivery,
        auFinished
    }
}
</script>
<style lang="css">
    .list_auction .nav-item {
        width: 33.33%;
        text-align: center;
    }

    .list_auction .nav-item a {
        color: #929292;
        font-size: 20px;
        font-weight: 500;
    }

    .list_auction .nav-item a.active {
        color: #FFFFFF !important;
        background: #515151;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
</style>