<template>
    <div>
        <div class="cz-product-gallery">
            <div class="cz-preview">
                <div v-for="(photo, key) in list_images" class="cz-preview-item" :class="{ 'active': key == 0 }">
                    <b-link href="#">
                        <img class="show-imag img-responsive" v-if="selectedImage" :src="selectedImage" alt="Product image"
                            @click="showModal(selectedImage)" @error="handleError">
                        <img class="show-imag img-responsive" v-else :src="$storage + photo"
                            alt="Product image" @click="showModal($storage + photo)" @error="handleError">
                    </b-link>
                </div>
            </div>
            <div class="row m-0">
                <div class="table-responsive mt-md-3" style="padding: 0px;">
                    <carousel :responsive="{
                        0: {
                            nav: false,
                            dots: false,
                            items: 1
                        },

                        374: {
                            items: 2,
                            nav: false,
                            dots: false,
                        },
                        500: {
                            items: 3,
                            nav: false,
                            dots: false,
                        },
                        768: {
                            items: 4,
                            nav: false,
                            dots: false,
                        },
                        991: {
                            items: 3,
                            nav: false,
                            dots: false,
                        },
                        1200: {
                            items: 3,
                            nav: false,
                            dots: false,
                        },
                        1399: {
                            items: 4,
                            nav: false,
                            dots: false,
                        },
                    }" :autoplay="true" :margin="10" v-if="list_images" :key="carouselKey">
                        <div class="cz-thumblist align-self-center" v-for="(photo, key) in  list_images" :key="key">
                            <a class=" cz-thumblist-item ">
                                <img class="click-img" :src="$storage + photo" alt=""
                                    @click="showImage($storage + photo)" @error="handleError">
                            </a>
                        </div>
                    </carousel>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <b-modal id="" ref="imgModal" centered hide-footer hide-header size="lg" content-class="modal-img image-auction">
            <img :src="this.imgModal" alt="" style="border-radius: 15px;">
        </b-modal>
    </div>
</template>
<script>
import carousel from "vue-owl-carousel";
export default {
    props: ["list_images", "carouselKey"],
    components: {
        carousel,
    },
    data() {
        return {
            images: ['','','','','','',''],
            imgModal: '',
            selectedImage: null,
        }
    },
    watch: {
        "$route.params": function() {
            this.selectedImage = null
        }
    },
    methods: {
        showImage(image) {
            this.selectedImage = image;
        },
        showModal(url) {
            this.$refs.imgModal.show()
            this.imgModal = url;
        }
    }
}
</script>
<style scoped lang="css">
.cz-product-gallery {
    display: block;
}

.show-imag {
    width: -webkit-fill-available;
    max-height: 415px;
    border-radius: 15px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.cz-preview {
    width: 100%;
    max-height: 100% !important;
    margin-left: 0;
}

img,
figure {
    max-width: 100%;
    vertical-align: middle;
}

.cz-thumblist-item {
    display: block;
    position: relative;
    margin: 0.8rem;
    transition: border-color 0.2s ease-in-out;
    border-radius: .3125rem;
    text-decoration: none !important;
    overflow: hidden;
    width: auto;
    height: auto;
    min-width: 147px;
}

.cz-thumblist-item>img {
    display: block;
    width: 100%;
    height: 98px;
    object-fit: cover;
}


@media (max-width: 768px) and (min-width: 576px) {
    .cz-preview-item>img {
        width: 100%;
    }

    .show-imag {
        height: auto;
    }

}

@media (max-width: 575px) {
    .cz-thumblist {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: center;
        justify-content: center;
        padding-top: 1rem;
        padding-right: 22px;
        padding-bottom: 10px;
        padding-top: 8px !important;
    }

    .show-imag {
        height: auto;
    }

    .cz-thumblist-item {
        margin: 0px;
    }

    .cz-preview-item>img {
        width: 100%;
    }
}
</style>