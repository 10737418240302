<template>
    <div class="wp-reports">
        <h5 style="font-size: 22px; font-weight: 700;">Báo cáo chung</h5>
        <b-row class="reports">
            <b-col cols="lg-5">
                <div class="type_report">
                    <h5>Loại báo cáo</h5>
                    <p class="m-0">Hãy chọn loại báo cáo</p>
                    <ul class="list_report mt-3">
                        <li v-for="(item, index) in list_report" :key="index" :class="{active: active_el == item.id}" @click="selectItem(item.id)">{{ item.name }}</li>
                    </ul>
                </div>
            </b-col>
            <b-col cols="lg-7">
                <div class="sel_report mt-3 mt-lg-0" style="position: relative;">
                    <form @submit="getReport(active_el)">
                        <h5>Thuộc tính của báo cáo</h5>
                        <p>Hãy chọn các thuộc tính của báo cáo</p>
                        <div class="d-flex justify-content-between flex-wrap" v-if="this.active_el !== 6 && this.active_el !== 2">
                            <div style="width: 49%;">
                                <label for="agency" style="font-size: 20px;">Chọn chi nhánh</label>
                                <b-form-select id="agency" class="form-control form-select" v-model="dataAgency">
                                    <b-form-select-option value="0">Tất cả chi nhánh</b-form-select-option>
                                    <b-form-select-option v-for="item in listAgency" :key="item.agency_id" :value="item.agency_id">{{ item.agency_name }}</b-form-select-option>
                                </b-form-select>
                            </div>
                            <div style="width: 49%;" v-if="this.active_el == 3 && this.active_el !== 4">
                                <label for="category" style="font-size: 20px;">Danh mục tài sản</label>
                                <b-form-select id="category" class="form-control form-select" v-model="dataCate">
                                    <b-form-select-option value="0">Tất cả danh mục</b-form-select-option>
                                    <b-form-select-option v-for="item in listCate" :key="item.category_id" :value="item.category_id">{{ item.name }}</b-form-select-option>
                                </b-form-select>
                            </div>
                            <div style="width: 49%;" v-if="this.active_el == 1">
                                <label for="type" style="font-size: 20px;">Thuộc tính cuộc đấu giá</label>
                                <b-form-select id="type" class="form-control form-select" v-model="dataAuctionAttr">
                                    <b-form-select-option value="0">Tất cả cuộc đấu giá</b-form-select-option>
                                    <b-form-select-option value="1">Cuộc đấu giá đang diễn ra</b-form-select-option>
                                    <b-form-select-option value="2">Cuộc đấu giá sắp diễn ra</b-form-select-option>
                                    <b-form-select-option value="3">Cuộc đấu giá đã diễn ra</b-form-select-option>
                                    <b-form-select-option value="4">Cuộc đấu giá chưa được duyệt</b-form-select-option>
                                </b-form-select>
                            </div>
                            <div style="width: 49%;" :class="{'mt-2' : this.active_el == 3}" v-if="this.active_el == 3">
                                <label for="type" style="font-size: 20px;">Thuộc tính tài sản</label>
                                <b-form-select id="type" class="form-control form-select" v-model="dataAssetAttr">
                                    <b-form-select-option value="0">Tất cả</b-form-select-option>
                                    <b-form-select-option value="1">Chưa đấu giá</b-form-select-option>
                                    <b-form-select-option value="2">Đang đấu giá</b-form-select-option>
                                    <b-form-select-option value="3">Đã đấu giá - Chờ xử lý</b-form-select-option>
                                    <b-form-select-option value="4">Đã đấu giá - Thành</b-form-select-option>
                                    <b-form-select-option value="5">Đã đấu giá - Không thành</b-form-select-option>
                                </b-form-select>
                            </div>
                            <div style="width: 49%;" v-if="this.active_el == 5">
                                <label for="auth" style="font-size: 20px;">Phân quyền</label>
                                <b-form-select id="auth" class="form-control form-select" v-model="dataRole">
                                    <b-form-select-option value="0">Tất cả phân quyền</b-form-select-option>
                                    <b-form-select-option v-for="item in listRole" :key="item.role_id" :value="item.role_id">{{ item.role_name }}</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>
                        <div v-if="this.active_el == 2" style="position: relative;">
                            <label for="auction" style="font-size: 20px;">Chọn cuộc đấu giá</label>
                            <div style="position: relative; margin-top: 10px;">
                                <b-form-input id="auction" class="form-control" v-model="keyAuction" placeholder="Nhập tên cuộc đấu giá" @input="showMenu = true"></b-form-input>
                                <button class="btn-arrow" type="button" @click="showMenu = !showMenu"><img src="@/assets/admin/icon/Expand Arrow.png" alt=""></button>
                            </div>
                            <ul class="wp-item" v-if="showMenu">
                                <li style="box-shadow: 0px 0px 15px 0px #00000021; border-radius: 20px; margin-bottom: 15px;" v-for="(data, index) in filteredItems" :key="index"  @click="selectedAuction(data.auction_id, data.auction_name)">
                                    <div class="d-flex">
                                        <img :src="$storage + data.thumbnail" alt="" style="min-width: 100px; width: 100px; height: 100px; border-radius: 20px;" @error="handleError">
                                        <div class="wp-txt" style="margin-left: 31px;">
                                            <p style="font-size: 18px; font-weight: 600; line-height: 180%; margin-bottom: 0;">{{ data.auction_name }}</p>
                                            <p style="color: #929292; margin-bottom: 0;">Mã cuộc đấu giá: <span style="color: #515151; font-weight: 500;">{{ data.auction_code }}</span></p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div v-if="this.active_el !== 2">
                            <p style="font-size: 20px; font-weight: 500; margin-top: 15px;">Chọn khoảng thời gian</p>
                            <div class="d-flex justify-content-between">
                                <div class="d-flex align-items-center" style="width: 45%;">
                                    <label for="date_start">Từ</label>
                                    <input id="date_start" class="form-control" type="date" v-model="start_time" required max="9999-12-31">
                                </div>
                                <div class="d-flex align-items-center" style="width: 45%;">
                                    <label for="date_end">Đến</label>
                                    <input id="date_end" class="form-control" type="date" v-model="end_time" required max="9999-12-31">
                                </div>
                            </div>
                        </div>
                        <div class="text-right wp-btn">
                            <!-- <button class="btn-cancel" >Hủy bỏ</button> -->
                            <button type="submit">Xuất file</button>
                        </div>
                    </form>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            active_el: 1,
            list_report: [
                {'id': 1, 'name': 'Báo cáo cuộc đấu giá'},
                {'id': 2, 'name': 'Danh sách khách hàng tham gia cuộc đấu giá'},
                {'id': 3, 'name': 'Báo cáo tài sản'},
                {'id': 4, 'name': 'Báo cáo tài chính'},
                {'id': 5, 'name': 'Báo cáo nhân sự'},
                {'id': 6, 'name': 'Báo cáo khách hàng'},
            ],
            keyAuction: '',
            listAgency: [],
            listCate: [],
            listRole: [],
            token: '',
            showMenu: false,
            dataAgency: 0,
            dataAuctionAttr: 0,
            dataAssetAttr: 0,
            dataCate: 0,
            dataRole: 0,
            dataAuction: [],
            start_time: null,
            end_time: null,
            auction_id: ''
        }
    },
    computed: {
        filteredItems() {
            return this.dataAuction.filter(data => data.auction_name.toLowerCase().includes(this.keyAuction.toLowerCase()));
        },
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
        this.getListAgency()
        this.getListCate()
        this.getListRole()
        this.getDataAuction()
    },
    methods: {
        selectItem(id) {
            this.active_el = id
            this.start_time = null
            this.end_time = null
            this.dataAgency = 0
            this.dataAuctionAttr = 0
            this.dataAssetAttr = 0
            this.dataCate = 0
            this.dataRole = 0
            this.auction_id = ''
        },
        selectedAuction(id, name) {
            this.keyAuction = name
            this.auction_id = id
            this.showMenu = false
        },
        async getListAgency() {
            await this.$axios.get('/agency/getListAgency', {
                headers: {
                    'x-access-token': this.token
                }
            }).then(res=> {
                this.listAgency = res.data
            })
        },
        async getListCate() {
            const res = await this.$axios.get('/category/getListCategory', {
                params: {
                    code: '',
                    name: '',
                    status: '',
                    parent_id: '',
                    page: '1',
                    per_page: '10000',
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.listCate = res.data.result
            }
        },
        async getListRole() {
            const res = await this.$axios.get('/permission/getListRole', {
                headers: {
                    'x-access-token': this.token
                }
            })

            if(res.status == 200) {
                this.listRole = res.data;
            }
        },
        async getDataAuction() {
            await this.$axios.get('/auction/getListAuctionForReport', {
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.dataAuction = res.data;
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if(err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                }
            })
        },
        getReport(data) {
            event.preventDefault()
            var param_report = "/reportx/GetReportExcel?"
            if(data == 1) {
                const link = this.$report + param_report +"path=template/AuctionX&Agency_id=" + this.dataAgency +"&Auction_status="+ this.dataAuctionAttr +"&Start_time=" + this.start_time+"&End_time=" + this.end_time
                window.open(link, '_blank');
            } else if(data == 2) {
                const link = this.$report + param_report +"path=template/MemberByAuctionX&Auction_id=" + this.auction_id
                window.open(link, '_blank');
            } else if(data == 3) {
                const link = this.$report + param_report +"path=template/AssetX&Agency_id=" + this.dataAgency +"&Asset_status="+ this.dataAssetAttr +"&Start_time=" + this.start_time+"&End_time=" + this.end_time + "&Category_id=" + this.dataCate
                window.open(link, '_blank');
            } else if(data == 4) {
                const link = this.$report + param_report +"path=template/FinancialManagementX&Agency_id=" + this.dataAgency +"&Start_time=" + this.start_time+"&End_time=" + this.end_time
                window.open(link, '_blank');
            } else if(data == 5) {
                const link = this.$report + param_report +"path=template/UserX&Agency_id=" + this.dataAgency +"&Role_id="+ this.dataRole +"&Start_time=" + this.start_time+"&End_time=" + this.end_time
                window.open(link, '_blank');
            } else {
                const link = this.$report + param_report +"path=template/MemberX&Start_time=" + this.start_time+"&End_time=" + this.end_time
                window.open(link, '_blank');
            }
            this.start_time = null
            this.end_time = null
            this.dataAgency = 0
            this.dataAuctionAttr = 0
            this.dataAssetAttr = 0
            this.dataCate = 0
            this.dataRole = 0
            this.auction_id = ''
        }
    }
}
</script>
<style lang="css" scoped>
    .wp-reports {
        color: #515151;
    }

    .reports h5 {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0;
    }

    .type_report, .sel_report {
        background-color: #FFF;
        border-radius: 20px;
        padding: 15px 18px 20px 18px;
        height: 100%;
    }

    ul.list_report li{
        border-radius: 20px;
        background: #EFEFEF;
        margin-bottom: 8px;
        padding: 13px 25px;
        font-weight: 500;
        cursor: pointer;
    }
    
    ul.list_report li.active {
        background: #EE3439;
        color: #FFF;
    }

    .form-select {
        background-color: #EEEEEE;
        border: none;
        border-radius: 15px;
        padding: 11px 12px;
    }

    input.form-control {
        background-color: #FFF;
        border-radius: 20px;
        border: 1px solid #515151;
        padding: 6px 12px;
    }

    input.form-control:focus,
    .form-select:focus {
        box-shadow: none;
    }

    label {
        padding-right: 20px;
        font-size: 15px;
        font-weight: 500;
    }
    button {
        background-color: #EE3439;
        border: none;
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        width: 206px;
        height: 35px;
        border-radius: 20px;
    }

    button.btn-cancel {
        background: #FFF;
        color: #EE3439;
        border: 1px solid #EE3439;
        margin-right: 10px;
    }

    ul.wp-item {
        max-height: 400px;
        overflow-y: auto;
        margin-top: 10px;
        position: absolute;
        cursor: pointer;
        background: #FFF;
        width: 100%;
        border-radius: 28px;
        box-shadow: rgba(0, 0, 0, 0.13) 0px 0px 15px;
        padding: 0 10px;
    }

    ul.wp-item::-webkit-scrollbar {
        width: 0;
    }

    .wp-btn {
        height: 200px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .btn-arrow {
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        width: 51px;
    }
</style>