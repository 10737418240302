<template>
    <div>
        <b-modal id="" ref="infoUser" centered hide-footer hide-header size="lg" content-class="modal-style" modal-class="modal-infoUser">
            <b-row class="info-user">
                <h5>Thông tin khách hàng</h5>
                <b-col cols="lg-4">
                    <div class="text-center">
                        <img :src="$storage + dataUser.avatar" alt="" width="182" height="182"
                            style="border-radius: 93px;" v-if="dataUser.avatar">
                            <img src="@/assets/front-end/img/blank-avatar-icon-26.png" alt="" width="182" height="182"
                            style="border-radius: 93px;" v-else="dataUser.avatar">
                    </div>
                    <div class="user_code">
                        <p>Mã khách hàng</p>
                        <p>{{ dataUser.member_code }}</p>
                    </div>
                    <div class="paper">
                        <p>Giấy tờ liên quan:</p>
                        <b-link v-for="(datafile, index) in dataUser.file"
                            :href="$storage + datafile.url" target="_blank" :key="index">{{
                                datafile.file_name }}</b-link>
                    </div>
                    <div class="status" v-if="this.type == 'member'">
                        <p>Đã đăng ký </p>
                        <p>Đã nộp phí mua hồ sơ</p>
                        <p>Đã nộp phí đặt cọc</p>
                    </div>
                </b-col>

                <b-col cols="lg-8">
                    <h5>Thông tin cá nhân</h5>
                    <div class="info" v-if="dataUser.type == 2">
                        <p>Họ và tên </p> <span>{{ dataUser.rep_name }}</span>
                    </div>
                    <div class="info" v-else>
                        <p>Họ và tên </p> <span>{{ dataUser.member_name }}</span>
                    </div>
                    <div class="info">
                        <p>Loại tài khoản </p> <span v-if="dataUser.type == 2">Tổ chức</span>
                        <span v-else>Cá nhân</span>
                    </div>
                    <div class="info">
                        <p>Số điện thoại </p> <span>{{ dataUser.phone }}</span>
                    </div>
                    <div class="info">
                        <p>Ngày sinh </p> <span>{{ formateTime(dataUser.birthday) }}</span>
                    </div>
                    <div class="info">
                        <p>Email </p> <span>{{ dataUser.email }}</span>
                    </div>
                    <div class="info">
                        <p>Địa chỉ </p> <span>{{ dataUser.member_address }}</span>
                    </div>
                    <div class="info">
                        <p>Giới tính</p> <span v-if="dataUser.gender == 0">Nam</span>
                        <span v-else>Nữ</span>
                    </div>
                    <div class="info">
                        <p>Số CMT / Thẻ căn cước / Hộ chiếu</p> <span>{{ dataUser.id_number }}</span>
                    </div>
                    <div class="info">
                        <p>Ngày cấp</p> <span>{{ formateTime(dataUser.id_date) }}</span>
                    </div>
                    <div class="info">
                        <p>Nơi cấp</p> <span>{{ dataUser.id_place }}</span>
                    </div>
                    <div class="info" style="display: block;">
                        <p class="mb-3">Ảnh CMT / Thẻ căn cước / Hộ chiếu</p>
                        <div class="d-flex img_card">
                            <div v-if="dataUser.id_front_face">
                                <img :src="$storage + dataUser.id_front_face" alt=""
                                    style="max-width: 216px; height: auto;">
                                <span>Mặt trước</span>
                            </div>
                            <div v-if="dataUser.id_back_side">
                                <img :src="$storage + dataUser.id_back_side" alt=""
                                    style="max-width: 216px; height: auto;">
                                <span>Mặt sau</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="dataUser.type == 2">
                        <h5 style="margin-top: 15px;">Thông tin doanh nghiệp</h5>
                        <div class="info">
                            <p>Tên doanh nghiệp</p> <span>{{ dataUser.member_name }}</span>
                        </div>
                        <div class="info">
                            <p>Mã số doanh nghiệp</p> <span>{{ dataUser.tax_code }}</span>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            code: '',
            dataUser: [],
            id: '',
            type: '',
            info: null
        }
    },
    methods: {
        async show(id, type) {
            this.id = id
            this.type = type
            const storedData = localStorage.getItem('auInfo')
            if (storedData) {
                this.info = JSON.parse(storedData)
            }

            const res = await this.$axios.get('member/getMemberDetail?member_id=' + this.id, {
                headers: {
                    'x-access-token': this.info.accessToken
                }
            })

            if (res.status == 200) {
                this.dataUser = res.data
            }

            this.$refs['infoUser'].show()
        },
        hideModal() {
            this.$refs['infoUser'].hide()
        },
        formateTime(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY")
        },
    }
}
</script>

<style>
    .modal-infoUser .modal-style {
        border-radius: 17.47px;
        box-shadow: 0px 0px 38px 0px #00000040;
    }
</style>

<style lang="css" scoped>
.info-user {
    color: #515151;
}

.info-user h5 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}

.user_code p {
    margin: 0;
    text-align: center;
}

.user_code p:first-child {
    font-size: 13px;
}

.user_code p:last-child {
    color: #515151;
    font-weight: 500;
}

.paper p {
    font-size: 14px;
    margin: 11px 0;
    font-weight: 500;
}

.paper a {
    position: relative;
    color: #515151;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 15px;
    padding-left: 30px;
}

.paper a::before {
    content: '';
    position: absolute;
    background-image: url('../../../../assets/icon/Note.png');
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    left: 0;
}

.status p {
    position: relative;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 500;
    color: #00B569;
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.status p::before {
    content: '';
    position: absolute;
    background-image: url('../../../../assets/icon/Ok.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 12px;
    height: 12px;
    left: 0;
}

.info {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    margin-bottom: 10px;
}

.info p {
    width: 60%;
    margin-bottom: 0;
    color: #757575;
}

.info span {
    font-weight: 500;
    text-align: right;
}

.img_card span {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #757575;
    font-size: 12px;
}
</style>