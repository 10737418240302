<template>
    <div>
        <b-modal id="change_password" ref="changePassword" size="lg" centered hide-footer hide-header @hide="clearData">
            <form action="" class="change-pass" autocomplete="off" @submit="changePass">
                <div class="d-flex align-items-center justify-content-between">
                    <h5>Đổi mật khẩu</h5>
                    <!-- <a class="show-pass d-flex" @click="togglePasswordVisibility">
                        <b-icon icon="eye" font-scale="0.5" v-if="iconStt"></b-icon>
                        <b-icon icon="eye-slash" font-scale="0.5" v-else></b-icon>
                        <span style="font-size: 16px; color: #515151; padding-left: 5px; font-weight: 500;">Hiển thị</span>
                    </a> -->
                </div>
                <div class="row justify-content-between" style="margin-bottom: 21px;">
                    <label for="currentPass" class="col-lg-3 my-2">Mật khẩu hiện tại</label>
                    <div class="col-lg-9 form-group" style="position: relative;">
                        <b-form-input id="currentPass" :type="showPass[0] ? 'text' : 'password'" v-model="oldPass"
                            autocomplete="off"></b-form-input>
                        <a class="show-pass" @click="togglePasswordVisibility(0, 'iconStt')">
                            <b-icon icon="eye" font-scale="0.5" v-if="iconStt"></b-icon>
                            <b-icon icon="eye-slash" font-scale="0.5" v-else></b-icon>
                        </a>
                    </div>
                    <label for="newPass" class="col-lg-3 my-2">Mật khẩu mới</label>
                    <div class="col-lg-9" style="position: relative;">
                        <b-form-input id="newPass" :type="showPass[1] ? 'text' : 'password'" v-model="newPass"
                            :state="validateNewPass" autocomplete="off">
                        </b-form-input>
                        <a class="show-pass" @click="togglePasswordVisibility(1, 'iconStt1')">
                            <b-icon icon="eye" font-scale="0.5" v-if="iconStt1"></b-icon>
                            <b-icon icon="eye-slash" font-scale="0.5" v-else></b-icon>
                        </a>
                        <b-form-invalid-feedback :state="validateNewPass">
                            Vui lòng nhập mật khẩu đúng yêu cầu
                        </b-form-invalid-feedback>
                        <b-form-text class="change-note">
                            ( Mật khẩu tối thiểu 8 ký tự, gồm chữ hoa, chữ thường, số và ký tự đặc biệt )
                        </b-form-text>
                    </div>
                    <label for="confirmPass" class="col-lg-3 my-2">Nhập lại mật khẩu mới</label>
                    <div class="col-lg-9" style="position: relative;">
                        <b-form-input id="confirmPass" :type="showPass[2] ? 'text' : 'password'" v-model="confirmPass"
                            :state="validateConfirmPass" autocomplete="off">
                        </b-form-input>
                        <a class="show-pass" @click="togglePasswordVisibility(2, 'iconStt2')">
                            <b-icon icon="eye" font-scale="0.5" v-if="iconStt2"></b-icon>
                            <b-icon icon="eye-slash" font-scale="0.5" v-else></b-icon>
                        </a>
                        <b-form-invalid-feedback :state="validateConfirmPass">
                            Mật khẩu không trùng khớp
                        </b-form-invalid-feedback>
                    </div>
                </div>

                <div class="text-sm-right" style="text-align: center;">
                    <b-button class="mt-3 btn-cancel mx-2" block @click="hideModal()">Hủy bỏ</b-button>
                    <b-button class="mt-3" block @click="changePass">Thay đổi</b-button>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            oldPass: '',
            newPass: '',
            confirmPass: '',
            token: JSON.parse(localStorage.getItem('userInfo')).accessToken,
            showPass: [],
            iconStt: true,
            iconStt1: true,
            iconStt2: true,
            publicKey: 'MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHvcIWyQskFcrDyzUuVML4OgVCrbOS+g0K5B5ngrMK1623+eBYBVegi6f0fe15xNatSphlBNjaTezEyZVYORd9lRfxMglWXeUtDTQhqmzBU4653USZZqdMGBdPMZRpDXO4+T+WEEXLDA/bW1MZ8E8LIKof2edlwAGX2JBKDqwPfhAgMBAAE=',
            encryptedData: '',
        }
    },
    computed: {
        validateNewPass() {
            if (this.newPass != '') {
                return (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(this.newPass))
            }
        },
        validateConfirmPass() {
            if (this.newPass != '') {
                return this.confirmPass == this.newPass
            }
        }
    },
    methods: {
        togglePasswordVisibility(param, icon) {
            this[icon] = !this[icon]
            this.showPass[param] = !this.showPass[param];
        },
        show(token) {
            this.$refs['changePassword'].show()
        },
        hideModal() {
            this.$refs['changePassword'].hide()
            this.newPass = ''
            this.confirmPass = ''
        },
        clearData() {
            this.oldPass = this.newPass = this.confirmPass = ''
        },
        async changePass() {
            const data = {
                oldpassword: this.encryptData(this.oldPass),
                newpassword: this.encryptData(this.newPass)
            }
            if (this.validateNewPass && this.validateConfirmPass) {
                await this.$axios.put('/member/changePass', data, {
                    headers: {
                        'x-access-token': this.token
                    }
                }).then((res) => {
                    this.$toast.success('Đổi mật khẩu thành công!')
                    this.$refs['changePassword'].hide()
                }).catch((err) => {
                    this.$toast.error('Đổi mật khẩu không thành công!')
                })
            }
        }
    },
}
</script>

<style lang="css">
.change-pass {
    color: #515151;
}

.change-pass h5 {
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 15px;
}

.change-pass input {
    background: #EAEAEA;
    border: none;
    border-radius: 91px;
    height: 43px;
    color: #515151;
}

.change-pass input:focus {
    background: #EAEAEA;
    color: #515151;
    box-shadow: none !important;
}

.change-pass .change-note {
    color: #757575 !important;
    font-weight: 300;
    line-height: 28.8px;
    font-size: 16px;
    margin-bottom: 18px;
    display: block;
}


.change-pass button {
    background: #C22924;
    border: none;
    font-weight: 700;
    font-size: 20px;
    width: 165px;
    height: 42px;
    font-family: Roboto;
    border-radius: 16.02px;
}

.change-pass .btn-cancel,
.change-pass .btn-cancel:active {
    color: #C22924;
    background: transparent !important;
    border: 1px solid #C22924;
    margin-right: 10px;
}

.change-pass button:hover,
.change-pass button:active {
    background: #C22924 !important;
}

.change-pass .btn-cancel:hover {
    background: transparent !important;
}

.change-pass .invalid-feedback {
    padding-left: 10px;
}

.show-pass {
    color: #D6B877;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    right: 40px;
    top: 8px;
}

.show-pass:hover {
    color: #D6B877;
}

.change-pass .show-pass svg {
    font-size: 24px !important;
}</style>