<template>
    <div>
        <b-container class="cate-detail">
            <b-breadcrumb :items="items"></b-breadcrumb>
            <b-row>
                <h5 class="title">Danh mục: <span > {{categoryDetail.name}}</span></h5>

            </b-row>
            <div class="row">
                <div class="col-xl-3">
                    <b-form class="form-search" @submit.prevent="setPage(1)">
                        <b-form-group id="input-group-1" label-for="input-1" >
                            <b-form-input id="input-1" placeholder="Tìm kiếm tài sản đấu giá"
                                class="input-search" v-model="paramsAsset.asset_name"></b-form-input>
                            <img class="search_ico" src="@/assets/icon/Search.png" alt="" @click="setPage(1)">
                        </b-form-group >
                    </b-form>
                    <div class="cate-img">
                        <img class="img-cate" :src="$storage+categoryDetail.image" alt="" @error="handleError">
                    </div>
                    <div class="filter">
                        <h6>Lọc tài sản theo giá</h6>
                        <b-form @submit.prevent="paramsAsset.page = 1, getListAssetForMember(paramsAsset)">
                            <b-form-group id="input-group-1" label="Từ:" label-for="input-1">
                                <input type="text"  id="input-1" placeholder="200.000.000" v-model="paramsAsset.from_price"
                                @input="formatInput('from_price')" name="from_price" class="form-control"/>
                            </b-form-group>

                            <b-form-group id="input-group-2 " label="Đến:" label-for="input-2">
                                <input id="input-2" placeholder="50.000.000.000" v-model="paramsAsset.to_price"
                                @input="formatInput('to_price')" name="to_price" class="form-control"/>
                            </b-form-group>
                            <b-button type="submit" class="btn-filter" >Lọc theo giá</b-button>
                        </b-form>
                    </div>
                    <div>
                        <h6 class="list_ttl">Danh mục đấu giá</h6>
                        <ul class="list-cate">
                            <li v-for="(cate, index) in listCategory" :key="index">
                                <b-link :to="'/cate-detail/'+cate.category_id" :data-name="cate.name">
                                    {{ cate.name }}
                                </b-link>
                            </li>
                        </ul>
                    </div>
 
                    <div class="show-news d-flex d-xl-block flex-wrap">
                        <h5 class="col-12">Tin tức và thông báo mới</h5>
                        <b-card v-for="(item, index) in listNews.result" :key="index"
                        class="mb-2 col-xl-12 col-md-6">
                            <cardNews :item="item"/>
                        </b-card>
                    </div>
                </div>
                <div class="col-xl-9">
                    <div class="data-cate" v-if="listAsset.result != []">
                        <div class="col-12 flex-between align-items-center mb-3 p-1">
                            <p class="total">Tổng số tài sản trong danh mục : <span>{{listAsset.pagination.total_element}}</span></p>
                            <select class="form-visible" id="" v-model="paramsAsset.order" @change="paramsAsset.page = 1, getListAssetForMember(paramsAsset)">
                                <option value="lowest">Giá tăng dần</option>
                                <option value="highest">Giá giảm dần</option>
                                <option value="latest">Mới nhất</option>
                                <option value="oldest">Cũ nhất</option>
                            </select>
                        </div>
                        <b-row>
                            <div class="col-xl-4 col-lg-4 col-sm-6 d-flex justify-content-center" v-for="(data_cate, index) in listAsset.result"
                                @click="$router.push('/product-detail/' + encode(data_cate.asset_id))" :key="index">
                                <cardAsset :item="data_cate"/>
                            </div>
                        </b-row>
                        <Pagination :page="pageAsset" @set-page="setPage"/>
                    </div>
                    <div class="empty-data" v-else>
                        <p class="no-data">Không có kết quả nào phù hợp</p>
                    </div>
                </div>
            </div>
        </b-container>
    </div>
</template>
<script>
import Pagination from "../web-view/components/paginate.vue";
import cardAsset from "./components/card-asset.vue";
import cardNews from "./components/card-news.vue";
export default {
    components: {
        Pagination,
        cardAsset,
        cardNews
    },
    data() {
        return {
            items: [
                {
                    text: 'Trang chủ',
                    to: '/',
                },
                {
                    text: 'Bất động sản và quyền sử dụng đất',
                    active: true
                },
            ],
            categoryDetail:[],
            listCategory:[],
            listAsset: {
                pagination: { },
                result: []
            },
            params:{
                category_id: this.$route.params.category_id,
                name: "",
                code: "",
                status: 1,
                parent_id: null,
                page:1,
                per_page:10,
            },
            paramsNews:{
                page: 1,
                per_page: 2,
            },
            paramsAsset:{
                asset_name: "",
                asset_code: "",
                asset_status: "",
                category_id: this.$route.params.category_id,
                owner: "",
                from_price: "",
                to_price: "",
                from_step_price: "",
                to_step_price: "",
                page: 1,
                per_page: 15,
                order: "latest",
                winner: "",
                status: "",
                description: "",
                auction_id: "",
            },
            isShow: true,
            pagination:{},
            pageAsset: {
                totalData: 0,
                totalPage: 3,
                currentPage: this.$route.query.page || 1,
                perPage: 10,
                classPrev: 'd-none',
                classNext: 'd-none'
            },
            listNews:[],
            url: ''
        }
    },
    mounted() {
        this.getListCategoryForMember(this.params)
        this.getListAssetForMember(this.paramsAsset)
        this.getListNews(this.paramsNews)
    },
    watch: {
        "$route.params": function(url) {
            this.url = url.category_id
            this.paramsAsset = {
                asset_name: "",
                asset_code: "",
                asset_status: "",
                category_id: url.category_id,
                owner: "",
                from_price: "",
                to_price: "",
                from_step_price: "",
                to_step_price: "",
                page: 1,
                per_page: 15,
                order: "latest",
                winner: "",
                status: "",
                description: "",
                auction_id: "",
            }
            this.getListAssetForMember(this.paramsAsset);
        }
    },
    methods:{
        setPage(val) {
            this.paramsAsset.page = val;
            this.getListAssetForMember(this.paramsAsset);
        },
        checkNumber() {
            const value = event.target.value
            const name = event.target.name
            this.paramsAsset[name] = this.formatNumber(value)
        },
        formatInput(field) {
            if (this.paramsAsset[field]) {
                const cleanedValue = parseInt(this.paramsAsset[field].replace(/[^\d]/g, ''));
                this.paramsAsset[field] = cleanedValue.toLocaleString();
            }
        },
    },

    beforeUpdate(){
        if(this.url) {
            this.params.category_id = this.url
        }
        this.getCategoryDetail(this.params)
    },

}
</script>
<style lang="css">
.cate-detail .title {
    padding-left: 0.25rem;
}
.form-visible{
    border: unset;
}
h5.title {
    font-size: 24px;
    font-family: Libre Bodoni;
    font-weight: 500;
    color: #515151;
    line-height: 30px;
    margin-bottom: 17px;
    padding: 0 0 11px;
    width: 97%;
    border-bottom: 1px solid #E1E1E1;
}

.form-search {
    position: relative;
}

.search_ico {
    position: absolute;
    background-color: #515151;
    top: 2px;
    right: 2px;
    border-radius: 39px;
    padding: 2px 16px;
    cursor: pointer;
}

.input-search {
    border: 1px solid #B3B3B3;
    border-radius: 39px;
    width: 100%;
    padding-right: 64px;
    height: 37px;
}

.input-search:focus {
    box-shadow: none;
    border-color: #B3B3B3;
}

.total {
    margin: 0 0 0 15px;
    font-size: 16px;
    line-height: 28.8px;
    color: #515151;
    font-family: Roboto;
}


.cate-img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.cate-name {
    margin: 0;
    position: absolute;
    font-size: 32px;
    color: #FFFFFF;
    font-family: Libre Bodoni;
    font-weight: 500;
    line-height: 40px;
    width: 100%;
}

.filter h6 {
    margin: 16px 0 5px;
    color: #515151;
    font-size: 22px;
    font-family: Roboto;
    line-height: 39.6px;
}

.filter .form-group {
    display: flex;
    justify-content: space-between;
}

.filter .form-group label {
    width: 50px;
}

.filter .form-group::after {
    content: "VNĐ";
    color: #515151;
    font-size: 18px;
    line-height: 32.4px;
}

.filter .form-control {
    border: none;
    background: #E4E4E4;
    border-radius: 91px;
    margin-bottom: 6px;
    color: #929292;
    text-align: right;
}

.filter .form-control:focus {
    box-shadow: none;
}

.btn-filter {
    color: #EE3439;
    background: none;
    border: 1px solid #EE3439;
    border-radius: 91px;
    width: 100%;
    height: 38px;
    margin: 10px 0 18px;
}

.btn-filter:active,
.btn-filter:hover {
    color: #EE3439 !important;
    background: none !important;
    border-color: #EE3439 !important;
}

.list_ttl {
    color: #515151;
    font-size: 22px;
    font-weight: 600;
    line-height: 39.6px;
    font-family: Roboto;
    border-bottom: 1px solid #E7E7E7;
}

.list-cate {
    list-style: disc;
    padding-left: 2rem;
    color: #515151;
}

.list-cate li a {
    font-size: 20px;
    color: #515151;
    text-decoration: none;
}

.empty-data {
    background-image: url('@/assets/images/logo_moreinfo.png');
    background-repeat: no-repeat;
    background-position: top;
    height: 100%;
}

.no-data {
    color: #9E9E9E;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    font-family: Roboto;
}

.show-news h5 {
    font-size: 24px;
    line-height: 30px;
    font-family: Libre Bodoni;
    color: #515151;
    font-weight: 500;
    margin: 16px 0 17px;
}

.show-news .card,
.data-cate .card {
    border: none;
}

.data-cate .card {
    text-align: center;
}

.show-news .card-body,
.data-cate .card-body {
    border-radius: 7px;
    padding: 0;
}

.show-news .card-body .card-text {
    font-size: 18px;
    color: #515151;
    line-height: 32.4px;
    font-weight: 600;
    margin: 8px 0 0;
    padding-left: 9px;
}

.show-news .card-body .text-detail,
.data-cate .card-body .text {
    font-size: 15px;
    font-weight: 400;
    line-height: 27px;
    color: #515151;
    margin-bottom: 10px;
    padding-left: 9px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.data-cate .card-body .text {
    text-align: left;
}

.data-cate .card-body .btn {
    background: none;
    color: #515151;
    font-size: 14.05px;
    border: 1px solid #515151;
    font-weight: 500;
    line-height: 25.29px;
    border-radius: 20px;
    justify-content: center;
}

.data-cate .list-btn {
    margin: 0;
    text-align: left;
    padding-left: 0;
}

.data-cate .list-btn span {
    font-weight: 400;
}

.show-news .btn-viewmore {
    background: none;
    color: #515151;
    font-size: 14.05px;
    text-align: center;
    border: 1px solid #515151;
    border-radius: 18px;
    width: 136px;
    margin-left: 9px;
    height: 35px;
}

.show-news .btn-viewmore:active {
    background: none;
    color: #515151;
}

.none {
    display: none;
}

@media (min-width: 1200px) {
    .col-xxl-12{
        flex: 0 0 100%;
        width: 100% !important;
        max-width: 100%;
    }
}

@media (max-width: 1024px) {

    .btn-filter {
        width: 100%;
    }

    .filter .form-group::after {
        font-size: 14px;
    }

    .list-cate li a {
        font-size: 18px;
    }

    .show-news h5 {
        font-size: 21px;
    }

    .show-news .card-body .card-text {
        font-size: 15px;
    }

    .show-news .card-body .text-detail {
        font-size: 14px;
    }

    .data-cate .col-md-4 {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .wp-sort {
        display: flex;
        align-items: center;
        height: 37px;
    }

    .col-100 {
        width: 100%;
    }

    .col-100 .cate-img {
        text-align: center;
        margin-top: 20px;
    }

    .total {
        font-size: 14px;
    }

    .img-cate {
        width: 50%;
    }


    .show-news {
        display: none;
    }

    .cate-detail .cate-name {
        width: 50%;
    }
}

@media (max-width: 425px) {
    h5.title {
        padding-left: 20px;
    }

    .total,
    .wp-sort {
        display: none;
    }

    .data-cate .col-md-4 {
        width: 100%;
    }

    .img-cate {
        width: 70%;
    }


    .none {
        display: block;
    }
}

@media (max-width: 375px) {
    .none {
        display: none;
    }

    .wp-sort {
        display: block;
        text-align: left !important;
    }

    .cate-name {
        font-size: 25px;
    }

}

@media (max-width: 320px) {
    .cate-name {
        font-size: 23px;
    }
}

.sort .btn {
    background: none;
    color: #9C9C9C;
    border: none;
}

.sort .btn:active,
.sort .btn:focus {
    background: none;
    color: #9C9C9C;
    box-shadow: none;
}

.sort .btn:focus-visible {
    box-shadow: none !important;
}

.sort .dropdown-toggle::after {
    border-color: #515151 !important;
    margin-left: 10px;
}

.sort .dropdown-menu {
    background-color: #FFFFFF;
    padding: 0;
    width: 100%;
}

.sort .dropdown-item {
    color: #9C9C9C;
}

.sort .dropdown-item:hover {
    background-color: transparent !important;
    color: #9C9C9C !important;
}

.sort .dropdown-menu li {
    border-color: #9C9C9C;
}
</style>
