<template>
    <div class="d-none d-xl-block">
        <section class="call-buton">
            <a class="cc-calto-action-ripple" href="tel:"><img class="image-icon" src="@/assets/icon/zalo-icon-circle.png" alt=""></a>
        </section>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="css" scoped>
.call-buton .cc-calto-action-ripple {
    z-index: 99999;
    position: fixed;
    right: 3%;
    bottom: 8rem;
    border-radius: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #ffffff;
    -webkit-animation: cc-calto-action-ripple 0.6s linear infinite;
    animation: cc-calto-action-ripple 0.6s linear infinite;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
}
.image-icon{
    width: 62px;
    height: 61px;
}
@media (max-width: 768px) {
    .call-buton .cc-calto-action-ripple {
        left: 5px;
        bottom: 5px;
    }
}

@keyframes cc-calto-action-ripple {
    0% {
        -webkit-box-shadow: 0 4px 10px rgba(0, 63, 236, 0.2), 0 0 0 0 rgba(0, 63, 236, 0.2), 0 0 0 5px rgba(0, 63, 236, 0.2), 0 0 0 10px rgba(0, 63, 236, 0.2);
        box-shadow: 0 4px 10px rgba(0, 63, 236, 0.2), 0 0 0 0 rgba(0, 63, 236, 0.2), 0 0 0 5px rgba(0, 63, 236, 0.2), 0 0 0 10px rgba(0, 63, 236, 0.2);
    }

    100% {
        -webkit-box-shadow: 0 4px 10px rgba(0, 63, 236, 0.2), 0 0 0 5px rgba(0, 63, 236, 0.2), 0 0 0 10px rgba(0, 63, 236, 0.2), 0 0 0 20px rgba(236, 139, 0, 0);
        box-shadow: 0 4px 10px rgba(0, 63, 236, 0.2), 0 0 0 5px rgba(0, 63, 236, 0.2), 0 0 0 10px rgba(0, 63, 236, 0.2), 0 0 0 20px rgba(236, 139, 0, 0);
    }
}
</style>
