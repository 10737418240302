<template>
    <div class="sidebar-admin">
        <b-button v-b-toggle.sidebar-admin class="d-none btn-show-bar">>></b-button>
        <b-sidebar id="sidebar-admin" backdrop shadow>
            <h5>Danh mục quản lý</h5>
            <ul class="list-cate-admin">
                <li v-for="item in items" :key="item.id" :class="{'none' : intListRole.includes(item.id) == false, 'd-block':item.id >=100}">
                    <b-link :to="item.to" v-b-toggle="'collapse-' + item.id"
                        :class="{ active: active_el == item.id, 'item-sub': item.sub }" @click="selectItem(item.id)">
                        <img :src="item.icon" alt="">{{ item.name }}
                    </b-link>
                    <b-collapse :id="'collapse-' + item.id" v-if="item.sub" accordion="my-accordion">
                        <ul class="sub-menu">
                            <li v-for="list in item.list" :class="{'none' : intListRole.includes(list.id) == false, 'd-block':list.id >=100}">
                                <b-link :to="list.to" @click="selectItem(list.id)"
                                    :class="{ active: active_el == list.id }">{{ list.txt }}</b-link>
                            </li>
                        </ul>
                    </b-collapse>
                </li>
            </ul>
        </b-sidebar>
        <div class="sidebar-pc">
            <h5>Danh mục quản lý</h5>
            <ul class="list-cate-admin">
                <li v-for="item in items" :key="item.id" :class="{'none' : intListRole.includes(item.id) == false, 'd-block':item.id >=100}">
                    <b-link :to="item.to" v-b-toggle="'collapse-' + item.id"
                        :class="{ active: active_el == item.id, 'item-sub': item.sub }" @click="selectItem(item.id)">
                        <img :src="item.icon" alt="">{{ item.name }}
                    </b-link>
                    <b-collapse :id="'collapse-' + item.id" v-if="item.sub" accordion="my-accordion">
                        <ul class="sub-menu">
                            <li v-for="list in item.list" :class="{'none' : intListRole.includes(list.id) == false, 'd-block':list.id >=100}">
                                <b-link :to="list.to" @click="selectItem(list.id)"
                                    :class="{ active: active_el == list.id }">{{ list.txt }}</b-link>
                            </li>
                        </ul>
                    </b-collapse>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            items: [
                { id: 1, name: 'Trang chủ', to: '/admin', icon: require('@/assets/admin/icon/Home.png'), sub: false },
                { id: 2, name: 'Quản lý chi nhánh', to: '/admin/branch', icon: require('@/assets/admin/icon/maps.png'), sub: false },
                {
                    id: 100, name: 'Quản lý cuộc đấu giá', icon: require('@/assets/admin/icon/auction.png'),
                    sub: true, list: [
                        { id: 32, txt: 'Duyệt cuộc đấu giá mới', to: '/admin/approve-auction' },
                        { id: 28, txt: 'Danh sách cuộc đấu giá', to: '/admin/list-auction' },
                    ]
                },
                {
                    id: 101, name: 'Quản lý tài sản đấu giá', icon: require('@/assets/admin/icon/list.png'),
                    sub: true, list: [
                        { id: 26, txt: 'Duyệt tài sản mới', to: '/admin/approve-asset' },
                        { id: 23, txt: 'Tạo tài sản mới', to: '/admin/add-asset' },
                        { id: 22, txt: 'Danh sách tài sản', to: '/admin/list-asset' },
                    ]
                },
                {
                    id: 102, name: 'Quản lý nhân viên', icon: require('@/assets/admin/icon/People.png'),
                    sub: true, list: [
                        { id: 12, txt: 'Phân quyền', to: '/admin/employee-auth' },
                        { id: 17, txt: 'Danh sách nhân viên', to: '/admin/list-employee' }
                    ]
                },
                {
                    id: 103, name: 'Quản lý khách hàng', icon: require('@/assets/admin/icon/customer.png'),
                    sub: true, list: [
                        { id: 35, txt: 'Duyệt khách hàng mới', to: '/admin/list-customer-new' },
                        { id: 34, txt: 'Danh sách khách hàng', to: '/admin/list-customer' },
                        { id: 106, txt: 'Tài khoản khách mời', to: '/admin/list-guest' }
                    ]
                },
                { id: 104, name: 'Quản lý tin tức', icon: require('@/assets/admin/icon/News.png'), sub: true,
                     list: [
                        { id: 42, txt: 'Duyệt tin tức mới', to: '/admin/app-news' },
                        { id: 38, txt: 'Danh sách tin tức', to: '/admin/list-news' },
                        // { id: 107, txt: 'Quảng cáo', to: '/admin/list-ads' },
                    ]},
                // { id: 105, name: 'Quản lý tài chính', to: '/admin', icon: require('@/assets/admin/icon/Increase.png'), sub: false },
                { id: 46, name: 'Báo cáo chung', to: '/admin/reports', icon: require('@/assets/admin/icon/Health Graph.png'), sub: false },
                { id: 44, name: 'Hệ thống', to: '/admin/settings', icon: require('@/assets/admin/icon/Settings.png'), sub: false },
            ],
            active_el: 1,
            listRole: [],
            intListRole: []
        }
    },
    created() {
        const currentPath = this.$route.path;
        if (currentPath.includes('branch')) {
            this.active_el = 2
        } else if (currentPath.includes('auction')) {
            this.active_el = 3
        } else if (currentPath.includes('asset')) {
            this.active_el = 4
        } else if (currentPath.includes('employee')) {
            this.active_el = 5
        } else if (currentPath.includes('customer') || currentPath.includes('list-guest')) {
            this.active_el = 6
        } else if (currentPath.includes('news') || currentPath.includes('list-ads')) {
            this.active_el = 7
        } else if (currentPath.includes('settings')) {
            this.active_el = 10
        }

        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            info.roles.arr_resource.forEach(element => {
                this.listRole.push(element.resource_id)
            });
            this.intListRole = this.listRole.map(Number)
        }
    },
    methods: {
        selectItem(id) {
            this.active_el = id
        }
    }
}
</script>

<style lang="css" scoped>
.active {
    color: #EE3439 !important;
}

.sidebar-admin {
    font-family: Roboto;
    position: sticky;
    top: 70px;
}

.sidebar-admin h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    color: #515151;
    margin: 0;
    border-bottom: 1px solid #E5E5E5;
    text-align: center;
    padding: 8px 0;
}

.list-cate-admin li {
    padding: 10px 0;
}

.list-cate-admin li a {
    color: #515151;
    font-weight: 500;
    font-size: 17px;
}

.list-cate-admin li a img {
    padding-right: 10px;
}

.item-sub {
    position: relative;
    display: flex;
    align-items: center;
}

.item-sub::after {
    position: absolute;
    content: "";
    border: solid #666666;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    right: 0;
}

.sub-menu li {
    padding-left: 50px;
}

.sub-menu li::before {
    content: "\2022";
    color: #515151;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

@media (max-width: 1500px) {
    .sidebar-admin .sidebar-pc {
        display: none;
    }

    .sidebar-admin {
        position: unset;
    }

    .btn-show-bar {
        display: block !important;
        position: fixed;
        top: 50%;
        z-index: 99;
    }
}
</style>

<style lang="css">
.sidebar-admin .b-sidebar>.b-sidebar-body {
    padding: 0 20px;
}

.sidebar-admin .b-sidebar:not(.b-sidebar-right)>.b-sidebar-header .close {
    background: none;
    border: none;
}</style>