<template>
    <div :class="{'overlay' : isLoading}">
        <div>
            <h5>Chi tiết tin tức mới</h5>
            <div class="wp-formAdd">
                <div class="d-flex align-items-center justify-content-between">
                    <p style="font-size: 20px; font-weight: 500;">Viết tin tức</p>
                    <div class="d-flex">
                        <span style="margin-right: 20px;">Gửi thông báo đến tất cả khách hàng:</span>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" v-model="dataDetail.noti_status" disabled>
                        </div>
                        <button class="btn-reject" @click="approveNews('reject',dataDetail.news_id)">Từ chối</button>
                        <button class="btn-approve" @click="approveNews('approve',dataDetail.news_id)">Phê duyệt</button>
                    </div>
                </div>
                    <b-row class="align-items-center mb-3">
                        <b-col cols="md-2">
                            <p>Hình ảnh hiển thị</p>
                        </b-col>
                        <b-col cols="md-10">
                            <img :src="$storage + dataDetail.image" style="max-width: 177px; border-radius: 10px; margin-right: 20px;" @error="handleError">
                        </b-col>
                    </b-row>
    
                    <b-row>
                        <b-col cols="md-2">
                            <label for="ttl">Tiêu đề tin tức</label>
                        </b-col>
                        <b-col cols="md-10">
                            <b-input type="text" id="ttl" placeholder="Nhập tiêu đề của tin tức và thông báo" v-model="dataDetail.title" disabled></b-input>
                        </b-col>
                    </b-row>
                    
                    <p class="mt-4" style="font-size: 20px; font-weight: 600;">Nội dung tin tức và thông báo</p>
                    <div v-html="dataDetail.description"></div>
            </div>
        </div>
        <div class="loader" v-if="isLoading"></div>
    </div>
</template>
<script>
import '../../../service/customQuillModule';
export default {
    data() {
        return {
            dataImg: '',
            token: '',
            dataDetail: [],
            isLoading: false
        }
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
        this.getNewsDetail()
    },
    methods: {
        async getNewsDetail(){
            await this.$axios.get('/news/getNewsDetailForAdmin', {
                params: {
                    news_id: this.decode(this.$route.params.news_id)
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.dataDetail = res.data
                this.dataImg = this.$storage + res.data.image
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if(err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                }
            })
        },
        async approveNews(type, id) {
            this.isLoading = true
            const data = {
                news_id: id
            }
            if(type == 'reject') {
                await this.$axios.put('/news/rejectNews', data, {
                    headers: {
                        'x-access-token': this.token
                    }
                }).then(res => {
                    this.$toast.success('Từ chối phê duyệt thành công!')
                    this.$router.go(-1)
                    this.isLoading = false
                }).catch(err => {
                    this.$toast.error('Từ chối phê duyệt không thành công!')
                    this.isLoading = false
                })
            } else {
                await this.$axios.put('/news/approveNews', data, {
                    headers: {
                        'x-access-token': this.token
                    }
                }).then(res => {
                    this.$toast.success('Phê duyệt thành công!')
                    this.$router.go(-1)
                    this.isLoading = false
                }).catch(err => {
                    this.$toast.error('Phê duyệt không thành công!')
                    this.isLoading = false
                })
            }
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        font-family: Roboto;
        color: #515151;
        font-size: 22px;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 15px;
    }

    .wp-formAdd {
        font-family: Roboto;
        box-shadow: 2px 2px 6px 0px #00000026;
        background-color: #FFFFFF;
        border-radius: 15px;
        padding: 18px 29px 23px 29px;
        color: #515151;
    }

    input.form-control {
        background-color: #EEEEEE;
        border-radius: 15px;
        height: 68px;
        border: none;
    }

    input.form-control:focus {
        box-shadow: none;
        border: none;
    }

    input.form-control::placeholder {
        font-weight: 500;
        color: #929292;
    }

    button {
        color: #EE3439;
        border: 1px solid #EE3439;
        border-radius: 20px;
        background: none;
        font-size: 18px;
        font-weight: 500;
        width: 160px;
        height: 42px;
        margin-right: 10px;
        margin-top: 15px;
    }

    button.send {
        margin-right: 0;
        color: #FFFFFF;
        background-color: #EE3439;
        width: 203px;
    }
    
    .btn-reject, .btn-approve {
        margin: 0;
        height: 27px;
        font-size: 14px;
        width: 120px;
        margin-left: 15px;
    }

    .btn-approve {
        background: #EE3439;
        color: #FFF;
    }

    .form-switch .form-check-input:checked {
        background-color: #EE3439;
        border-color: #EE3439;
    }

    .form-switch .form-check-input:focus {
        box-shadow: none;
    }
</style>

<style>
    .ql-container {
        height: unset !important;
        min-height: 400px;
        max-height: 1000px;
        overflow-y: auto;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }

    .ql-container::-webkit-scrollbar {
        width: 0;
    }

    .ql-snow .ql-tooltip {
        left: 0 !important;
    }

    .ql-snow .ql-tooltip input[type=text] {
        color: #515151;
    }

    .ql-toolbar {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .ql-editor.ql-blank::before {
        color: #929292;
        font-size: 20.62px;
        font-weight: 500;
        font-style: normal;
        text-align: center;
    }

    .wp-formAdd .table, .auction_detail .table{
        overflow: auto;
    }

    .wp-formAdd table,
    .wp-formAdd tr,
    .wp-formAdd td,
    .auction_detail table,
    .auction_detail tr,
    .auction_detail td {
        border: 1px solid #515151;
    }
</style>