<template>
<div>
    <h5>Thành viên đã duyệt tham gia đấu giá</h5>
    <div class="d-flex align-items-center auction_search">
        <b-form class="search-form" style="width: 100%;">
            <b-form-group>
                <b-form-input v-model="keySearch" type="text" placeholder="Tìm kiếm thành viên" required></b-form-input>
            </b-form-group>
            <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
        </b-form>
        <button><img src="@/assets/admin/icon/Adjust.png"></button>
    </div>
    <div class="btn-add-member">
        <p>Số thành viên tham gia: <span>{{ getDataJoined.length }}</span></p>
        <button class="btn-add" @click="addGuest" v-if="showButton && this.room_status == 0">+ Thêm thành viên</button>
    </div>
    <div class="user-join">
        <div class="info-user info-user--user" v-for="user in filteredItems">
            <div class="d-flex justify-content-between flex-wrap">
                <p class="user-code" @click="showInfoUser(user.member_id, 'member')">{{ user.member_code }}</p>
                <p class="user-name">{{ user.member_name }}</p>
                <span :class="[user.is_online == 1 ? 'status--onl' : 'status--off', 'status']"></span>
            </div>
            <div class="text-right">
                <button @click="handleKick(user.member_code, user.member_id, user.member_socket_id, dataAsset.auction_key)">Kick khỏi phòng</button>
            </div>
        </div>
        <p class="no-data" v-if="getDataJoined.length == 0">Chưa có thành viên tham gia</p>
    </div>
    <div class="user-join">
        <p class="guest">Khách mời tham gia phòng đấu giá</p>
        <div class="info-user" v-for="client in getDataClientJoined" :style="{ backgroundColor: getRandomColor() }">
            <div class="d-flex justify-content-between flex-wrap">
                <p class="user-code">{{ client.member_code }}</p>
                <p class="user-name">{{ client.member_name }}</p>
                <span :class="[client.is_online == 1 ? 'status--onl' : 'status--off', 'status']"></span>
            </div>
            <div class="d-flex justify-content-between">
                <p>{{ client.client_role }}</p>
                <button @click="handleKick(client.member_code, client.member_id, client.member_socket_id, dataAsset.auction_key)">Kick khỏi phòng</button>
            </div>
        </div>
        <p class="no-data" v-if="getDataClientJoined.length == 0">Chưa có khách mời tham gia</p>
    </div>
    <modalKick ref="modalKick" @kickMember="kickMember" />
    <modalAddGuest ref="modalAddGuest"/>
    <infoUser ref="infoUser" />
</div>
</template>

<script>
import modalKick from '../modal/kick-user.vue';
import modalAddGuest from '../modal/add_guest.vue';
import infoUser from '../modal/info_user.vue';
import { state } from "@/service/socket";
export default {
    components: {
        modalKick,
        modalAddGuest,
        infoUser,
    },
    data() {
        return{
            dataSearch: {
                text: 'Tìm kiếm thành viên',
                color: '#515151'
            },
            count: [],
            no_guest: true,
            totalMemberJoined: 0,
            dataAsset: [],
            keySearch: '',
            showButton: true,
            room_status: '',
            colors: [
                '#84D677;',
                '#D67777',
                '#3FA5FF',
                '#16a085',
                '#3498db',
                '#6ab04c',
                '#f0932b',
                '#30336b',
                '#A3CB38',
                '#9980FA',
            ],
            token: '',
            asset_id: '',
            socket: null
        }
    },
    computed: {
        getDataJoined() {
            return state.dataJoined
        },
        getDataClientJoined() {
            return state.listClientJoined
        },
        filteredItems() {
            return this.getDataJoined.filter(user => user.member_name.toLowerCase().includes(this.keySearch.toLowerCase()));
        }
    },
    created() {
        const storedData = localStorage.getItem('auInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
        this.auctionId = this.decode(this.$route.params.auction_id)
        this.getListAsset();
    },
    methods: {
        getRandomColor() {
            const randomIndex = Math.floor(Math.random() * this.colors.length);
            return this.colors[randomIndex];
        },
        handleKick(code, id, socket_id, auction_key) {
            this.$refs.modalKick.show(code, id, socket_id, auction_key)
        },
        addGuest() {
            this.$refs.modalAddGuest.show()
        },
        showInfoUser(id, type) {
            this.$refs.infoUser.show(id, type)
        },
        async getData() {
            const res = await this.$axios.get('/asset/getAssetDetail?asset_id=' + this.asset_id, {
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.dataAsset = res.data
                const key = '';
                const token = JSON.parse(localStorage.getItem('auInfo')).accessToken;
                this.socket = this.$socket(this.dataAsset.auction_key ,key, token)
                this.socket.on('countdownStart', (res) => {
                    if (res <= 0) {
                        this.showButton = false
                    }
                })
                this.socket.on('room_status', (res) => {
                    this.room_status = res.room_status
                })
            }
        },
        async getListAsset() {
            await this.$axios.get('/asset/getListAsset', {
                params: {
                    asset_name: '',
                    asset_code: '',
                    category_id: '',
                    owner: '',
                    from_price: '',
                    to_price: '',
                    from_step_price: '',
                    to_step_price: '',
                    order: 'latest',
                    page: '1',
                    per_page: '100000',
                    winner: '',
                    status: '',
                    description: '',
                    auction_id: this.auctionId,
                    publish_status: '',
                    asset_status: ''
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.asset_id = res.data.result[0].asset_id
                this.getData()
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAuctioneer')
                } else if (err.response.status == 401) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAuctioneer')
                }
            })
        },
        kickMember(id, socket_id, note) {
            this.socket.emit("kickMember", {member_id: id, client_id: socket_id, note: note})
        },
    }
}
</script>

<style lang="css" scoped>

    .auctioneer_screen .user h5 {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .auction_search {
        margin-bottom: 20px;
    }
    .auction_search > div {
        width: 85%;
        margin-right: 20px;
    }

    .btn-add-member {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 15px;
    }

    .btn-add-member p {
        margin: 0;
    }

    .btn-add-member p span {
        font-weight: 500;
    }

    .btn-add-member button {
        color: #FFFFFF;
        font-size: 13px;
        font-weight: 500;
        line-height: 23.4px;
        border-radius: 14px;
        margin: 0 10px 10px 0;
        background: #F8991E;
        padding: 7px 15px; 
    }

    .user-join {
        overflow-y: scroll;
        max-height: 410px;
    }
    .info-user {
        font-size: 14.92px;
        font-weight: 500;
        align-items: center;
        border-radius: 14px;
        background-color: #F8F8F8;
        padding: 10px 15px;
        margin-bottom: 10px;
        color: #FFFFFF;
    }

    .info-user--adm {
        background: #84D677;
    }
    .info-user--owner {
        background: #D67777;
    }
    .info-user--tk {
        background: #3FA5FF;
    }
    .info-user p {
        margin-bottom: 7px;
    }
    .info-user .user-code {
        color: #FFFFFF;
        text-decoration: underline;
        cursor: pointer;
        margin-right: 20px;
    }
    .info-user--user .user-code {
        color: #3FA5FF;
    }

    .info-user .status {
        height: 8px;
        width: 8px;
        border-radius: 20px;
        border: 1px solid;
    }
    .info-user button {
        font-size: 13px;
        font-weight: 500;
        border-radius: 14px;
        height: 30px;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
    }

    .info-user--user button{
        color: #EE3439;
        border: 1px solid #EE3439;
    }

    .info-user--user {
        color: #515151;
    }

    .info-user--user .status {
        border: none;
    }

    .status--onl {
        background-color: #00B569;
    }
    .status--off {
        background-color: #EE3439;
    }

    .auctioneer_screen .user-join::-webkit-scrollbar {
        width: 2px;
    }
    .auctioneer_screen .user-join::-webkit-scrollbar-thumb {
        background: #929292;
    }
    
    p.guest {
        font-size: 18px;
        font-weight: 500;
        position: sticky;
        top: 0;
        background: #FFFFFF;
        padding-top: 20px;
    }
    .no-data {
        font-size: 18px;
        color: #A3A3A3;
        font-weight: 400;
        text-align: center;
        margin-top: 30px;
    }
</style>
