<template>
    <div>
        <b-container class="news">
            <b-breadcrumb :items="items"></b-breadcrumb>
            <b-row>
                <h5 class="title">Tin tức và thông báo</h5>
                <div class="col-xxl-3 col-md-6 col-lg-4 pr-2" v-for="(item, index) in listNews.result" :key="index">
                    <cardNew :item="item"></cardNew>
                </div>
                <Pagination :page="pageNews" @set-page="setPage" />
            </b-row>
        </b-container>
    </div>
</template>
<script>
import Pagination from "../web-view/components/paginate.vue";
import cardNew from "@/pages/web-view/components/card-news.vue"
export default {
    components: {
        Pagination,
        cardNew,
    },
    data() {
        return {
            items: [
                {
                    text: 'Trang chủ',
                    to: '/',
                },
                {
                    text: 'Tin tức chung',
                    active: true
                },
            ],
            dataNews: [],
            pageNews: {
                perPage: 5,
                totalPage: 0,
                totalData: 0,
                currentPage: 1,
                classPrev: 'd-none',
                classNext: 'd-none'
            },
            listNews: {},
            params: {
                page: 1,
                per_page: 12,
            }
        }
    },
    mounted() {
        this.getListNews(this.params);
    },
    methods: {
        setPage(val) {
            this.params.page = val;
            this.getListNews(this.params);
        },
    },
}
</script>

<style lang="css" scoped>
.news .sold-out {
    position: absolute;
    left: 0;
}
[class*="col-"] {
    margin-bottom: 30px;
}
@media (min-width: 1280px) {
    .col-xxl-3{
        flex: 0 0 auto;
        width: 25%;
    }
}
@media (max-width: 1024px) {
    .news .col-md-3 {
        width: 50%;
    }
}

@media (max-width: 768px) {

    .news .card-body .btn {
        display: block;
    }
}

@media (max-width: 425px) {
    .news .d-flex h5 {
        font-size: 21px;
    }

    .news .col-md-3 {
        width: 100%;
    }

    .card {
        height: unset;
    }
}
</style>
