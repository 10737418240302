<template>
  <div>
    <b-container class="auction_detail">
      <b-breadcrumb :items="items"></b-breadcrumb>
      <b-row>
        <h5 class="title">{{ dataAsset.asset_name }}</h5>
        <b-col cols="md-8">
          <h5 class="noti">THÔNG BÁO ĐẤU GIÁ</h5>
          <p class="info" v-html="dataAsset.auction_noti"></p>
        </b-col>
        <b-col>
          <div class="more-info">
            <h5 class="more-info__ttl">Tài liệu liên quan</h5>
            <ul class="list-info">
              <li @click="toPdf(file.url)" v-for="file in dataAsset.file">
                {{ file.file_name }}</li>
            </ul>
          </div>
          <div class="more-info">
            <h5 class="more-info__ttl">Thông tin tổ chức đấu giá</h5>
            <ul class="list-info-cus">
              <li><img src="@/assets/icon/Company.png" alt="">Đơn vị tổ chức: <span class="text-red">{{ dataAsset.org
              }}</span></li>
              <li><img src="@/assets/icon/Customer.png" alt="">Đấu giá viên: <span class="text-red">{{ dataAsset.DGV
              }}</span></li>
              <li><img src="@/assets/icon/Location.png" alt="">Địa điểm: <span class="text-red">{{ dataAsset.view_address
              }}</span></li>
            </ul>
          </div>
        </b-col>
      </b-row>
      <SameProduct :category_id="dataAsset.category_id" :listAsset="listAsset" @getData="getData"/>
    </b-container>
  </div>
</template>
<script>
import SameProduct from "../components/sameproduct.vue";

export default {
  components: {
    SameProduct,
  },
  data() {
    return {
      items: [
        {
          text: 'Trang chủ',
          to: '/',
        },
        {
          text: 'Thông tin chi tiết đấu giá',
          active: true
        },
      ],
      asset_id: '',
      dataAuctions: [],
      params:{
        asset_name: "",
        asset_code: "",
        asset_status: "",
        category_id: '',
        owner: "",
        from_price: "",
        to_price: "",
        from_step_price: "",
        to_step_price: "",
        page: 1,
        per_page: 10,
        order: "latest",
        winner: "",
        status: "",
        description: "",
        auction_id: "",
      },
      dataAsset:{},
      listAsset:[],
    }
  },
  created() {
    this.asset_id = this.decode(this.$route.params.asset_id);
    this.getData()
    this.getListAssetForMember(this.params)
  },
  methods: {
    async getData() {
      await this.$axios.get('/asset/getAssetDetail', {
        params: {
          asset_id: this.decode(this.$route.params.asset_id)
        },
        headers: {
          'x-access-token': '',
        }
      }).then((res) => {
        this.dataAsset = res.data
        this.params.category_id = this.dataAsset.category_id
        if (this.dataAsset.asset_status == 3 || this.dataAsset.asset_status == 4) {
          this.endStatus = true
        }
      })
    },
    
  }
}
</script>
<style lang="css" scoped>
.auction_detail {
  color: #515151;
  background-image: url('@/assets/images/logo_moreinfo.png');
  background-repeat: no-repeat;
  background-position: top;
}

.auction_detail .title {
  font-size: 24px;
  line-height: 30px;
  font-family: Libre Bodoni;
  color: #515151;
  padding-bottom: 16px;
  border-bottom: 1px solid #E1E1E1;
}

.auction_detail .noti {
  text-align: center;
  font-weight: 500;
  padding: 15px 0;
}

.auction_detail .info {
  font-size: 18px;
  font-family: Roboto;
  line-height: 32.4px;
}

.auction_detail .more-info__ttl {
  font-weight: 500;
  font-size: 22px;
  line-height: 39.6px;
}

.auction_detail li {
  padding: 5px 0;
}

.auction_detail .list-info li {
  position: relative;
  margin-left: 33px;
  cursor: pointer;
}

.auction_detail .list-info li::before {
  content: " ";
  background: url('@/assets/icon/Note.png');
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  position: absolute;
  left: -33px;
  top: 3px;
}

.auction_detail .list-info-cus img {
  padding-right: 14px;
}

.auction_detail .text-red {
  color: #EE3439;
  font-size: 16px;
  font-weight: 500;
  line-height: 28.8px;
  font-family: Roboto;
}

@media (max-width: 768px) {

  .auction_detail .title {
    font-size: 20px;
  }

  .auction_detail .info {
    font-size: 16px;
  }

  .auction_detail .noti {
    font-size: 18px;
  }

  .auction_detail .more-info,
  .auction_detail .text-red {
    font-size: 14px;
  }

  .auction_detail .more-info__ttl {
    font-size: 16px;
  }
}
</style>