<template>
    <div class="form-branch">
        <b-modal id="" ref="edit" centered hide-footer hide-header @hide="clearData">
            <form action="" class="edit-branch" @submit="editBranch" autocomplete="off" :class="{'overlay' : isLoading}">
               <h5>Chỉnh sửa chi nhánh</h5>
               <p style="font-weight: 500; margin-bottom: 7px;">Ảnh đại diện chi nhánh</p>
               <img :src="this.dataImg" v-if="this.dataImg" class="mb-3" style="max-width: 177px; border-radius: 10px; margin-right: 20px; cursor: pointer;" onclick="$('#file_img').click()">
               <img :src="$storage + this.dataForm.image" alt="" class="mb-3" onclick="$('#file_img').click()" v-else style="cursor: pointer;" @error="handleError">
               <b-form-file class="mt-3 d-none"  id="file_img" ref="fileInput" @change="handleImgChange" accept="image/png, image/gif, image/jpeg, image/jpg"></b-form-file>
               <p style="font-weight: 500; color: #515151;">Mã chi nhánh: <span style="font-weight: 600;">{{ this.dataForm.agency_code }}</span></p>
               <b-form-group
                    label="Tên chi nhánh:"
                    label-for="name"
                >
                    <b-form-input
                        placeholder="Nhập tên chi nhánh"
                        id="name"
                        type="text"
                        v-model="dataForm.agency_name"
                        @input="formatTxt('agency_name')"
                        required
                    ></b-form-input>
                </b-form-group>
               <b-form-group
                    label="Email:"
                    label-for="email"
                >
                    <b-form-input
                        placeholder="Nhập email chi nhánh"
                        id="email"
                        type="email"
                        v-model="dataForm.email"
                        @input="formatTxt('email')"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Số điện thoại:"
                    label-for="phone_number"
                >
                    <input
                        placeholder="Nhập số điện thoại chi nhánh"
                        id="phone_number"
                        type="text"
                        v-model="dataForm.phone"
                        required
                        @input="checkInputPhone"
                        class="form-control"
                        maxlength="12"
                    >
                </b-form-group>
               <b-form-group
                    label="Địa chỉ:"
                >
                    <b-form-select v-model="provinceSelect" class="form-control" @change="changeCity" required>
                        <template #first>
                            <b-form-select-option :value="provinceSelect" disabled>{{ dataForm.province_name }}</b-form-select-option>
                            <b-form-select-option v-for="item in optionCitys" :key="item.code" :value="item">{{ item.name }}</b-form-select-option>
                        </template>
                    </b-form-select>

                    <b-form-select v-model="districtSelect" class="form-control" @change="changeDistrict" required>
                        <template #first>
                            <b-form-select-option :value="districtSelect" disabled>{{ dataForm.district_name }}</b-form-select-option>
                            <b-form-select-option v-for="item in optionDistricts" :key="item.code" :value="item">{{ item.name }}</b-form-select-option>
                        </template>
                    </b-form-select>
                  
                    <b-form-select v-model="wardSelect" class="form-control" required @change="changeWard">
                        <template #first>
                            <b-form-select-option :value="wardSelect" disabled>{{ dataForm.ward_name }}</b-form-select-option>
                            <b-form-select-option v-for="item in optionWards" :key="item.code" :value="item">{{ item.name }}</b-form-select-option>
                        </template>
                    </b-form-select>

                    <b-form-textarea
                        placeholder="Địa chỉ chi tiết"
                        rows="5"
                        max-rows="6"
                        v-model="dataForm.address"
                        @input="formatTxt('address')"
                        required
                    ></b-form-textarea>
                </b-form-group>
               <div class="text-right">
                <b-button class="mt-3 mr-3 btn-cancel" @click="hideModal()" block :disabled="isLoading">Hủy bỏ</b-button>
                <b-button class="mt-3" type="submit" block :disabled="isLoading">Xác nhận chỉnh sửa</b-button>
               </div>
            </form>
            <div class="loader" v-if="isLoading"></div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            agency_id: '',
            optionCitys: [],
            optionDistricts: [],
            optionWards: [],
            provinceSelect: '',
            districtSelect: '',
            wardSelect: '',
            dataImg: '',
            img: '',
            dataForm: {
                image: '',
                agency_name: '',
                agency_code: '',
                email: '',
                phone: '',
                province_id: '',
                province_name: '',
                district_id: '',
                district_name: '',
                ward_id: '',
                ward_name: '',
                address: ''
            },
            isLoading: false
        }
    },
    mounted() {
        this.$axios.get('/common/getListProvince')
        .then(res => {
            this.optionCitys = res.data.province
        })
        .catch(error => {
            console.error(error);
        });
    },
    methods: {
        show(id) {
            this.agency_id = id
            this.$refs['edit'].show()
            this.getDataAgency();
        },
        checkInputPhone(event) {
            this.dataForm.phone = this.formatNumber(event.target.value)
        },
        formatTxt(item) {
            this.dataForm[item] = this.dataForm[item].replace(/[<>\[\]]/g, '')
        },
        hideModal() {
            this.$refs['edit'].hide()
        },
        handleImgChange(event) {
            const file = event.target.files[0];
            this.img = file;
                if (file) {
                    const reader = new FileReader();
                    reader.onload = () => {
                    this.dataImg = reader.result
                };
                reader.readAsDataURL(file);
            }
            this.upload()
        },

        async upload() {
            const data = {
                'file': this.img,
                'path': 'agency/image/'
            }
            const storedData = localStorage.getItem('admInfo')
            if (storedData) {
                var info = JSON.parse(storedData)
            }

            await this.$axios.post('/common/uploadFile', data, {
                headers: {
                    'x-access-token': info.accessToken,
                    'Content-Type': 'multipart/form-data'
                } 
            }).then(res => {
                this.dataForm.image = res.data.list_result[0].path
            })
        },

        async changeCity(event) {
            const id = event.code
            this.dataForm.province_id = id
            this.dataForm.province_name = event.name
            this.optionDistricts = await this.callApiDistrict(id)
        },

        async changeDistrict(event) {
            const id = event.code
            this.dataForm.district_id = id
            this.dataForm.district_name = event.name
            this.optionWards = await this.callApiWard(id)
        },
        
        changeWard(event) {
            this.dataForm.ward_id = event.code
            this.dataForm.ward_name = event.name
        },

        async getDataAgency() {
            const storedData = localStorage.getItem('admInfo')
            if (storedData) {
                var info = JSON.parse(storedData)
            }
            const res = await this.$axios.get('/agency/getAgencyDetail?agency_id=' + this.agency_id, {
                headers: {
                    'x-access-token': info.accessToken
                }
            })

            if(res.status == 200) {
                this.dataForm = res.data.data
                this.provinceSelect = this.dataForm.province_id
                this.districtSelect = this.dataForm.district_id
                this.wardSelect = this.dataForm.ward_id
                this.optionDistricts = await this.callApiDistrict(this.dataForm.province_id)
                this.optionWards = await this.callApiWard(this.dataForm.district_id)
            }
        },

        async editBranch () {
            event.preventDefault();
            const storedData = localStorage.getItem('admInfo')
            if (storedData) {
                var info = JSON.parse(storedData)
            }

            if(this.dataForm.image == "") {
                this.errorImg = true
            } else {
                this.errorImg = false
                this.isLoading = true
                await this.$axios.put('/agency/updateAgency', this.dataForm, {
                    headers: {
                        'x-access-token': info.accessToken,
                    } 
                }).then(res => {
                    this.$toast.success('Chỉnh sửa chi nhánh thành công!')
                    this.$refs['edit'].hide()
                    this.emitModalVisible(false)
                    this.isLoading = false
                }).catch(err => {
                    this.$toast.error(err.response.data.error)
                    this.isLoading = false
                })
            }
        },

        emitModalVisible(isClose = false) {
            this.$emit("handleCloseModal", isClose);
        },

        clearData() {
            this.dataImg = ''
            this.provinceSelect = null
            this.districtSelect = null
            this.wardSelect = null
            this.dataForm = {
                image: '',
                agency_name: '',
                email: '',
                phone: '',
                province_id: '',
                province_name: '',
                district_id: '',
                district_name: '',
                ward_id: '',
                ward_name: '',
                address: ''
            }
        }
    }
}
</script>

<style lang="css">
    .edit-branch label, .edit-branch legend {
        font-weight: 500;
    }
</style>
<style lang="css" scoped>

    .edit-branch h5 {
        color: #515151;
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 15px;
    }

    .edit-branch .form-control::placeholder {
        font-weight: 400;
        color: #929292;
    }

    .edit-branch select.form-control{
        background-image: url(@/assets/admin/icon/Expand\ Arrow.png);
        background-repeat: no-repeat;
        background-position: right;
    }

    select.form-control * {
        color: black;
    }
    .edit-branch .form-control {
        background: #E1E1E1;
        border: none;
        color: #515151;
        font-weight: 500;
        border-radius: 20px;
        min-height: 47px;
        margin-top: 10px;
        overflow-y: unset !important;
    }

    .edit-branch .form-control:focus {
        box-shadow: none;
        border: none;
        background-color: #E1E1E1;
    }

    .edit-branch button {
        background-color: #EE3439;
        color: #ffffff;
        font-weight: 500;
        border-radius: 10px;
        border: none;
        min-height: 41px;
        min-width: 194px;
    }

    .edit-branch button:active,
    .edit-branch button:focus {
        background-color: #EE3439 !important;
    }

    .edit-branch button:hover {
        background-color: #EE3439;
    }

    .edit-branch .btn-cancel {
        color: #EE3439;
        border: 1px solid #EE3439;
        background: #ffffff;
        min-width: 108px;
    }

    .edit-branch .btn-cancel:hover,
    .edit-branch .btn-cancel:focus,
    .edit-branch .btn-cancel:active {
        background: #ffffff !important;
        color: #EE3439;
        border: 1px solid #EE3439;
    }

    .custom-select {
        color: #929292 !important;
        font-weight: 400 !important;
    }
</style>