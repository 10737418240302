import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging"
import { reactive } from 'vue';

export let notification = reactive({
    token: "",
    noti: "",
    error: "",
    notificate:null,
})
const firebaseConfig = {
    apiKey: "AIzaSyCk51Z49MPK-I2mI9fUmyQSob6G-X-gE8o",
    authDomain: "dgv-2023.firebaseapp.com",
    projectId: "dgv-2023",
    storageBucket: "dgv-2023.appspot.com",
    messagingSenderId: "566071453240",
    appId: "1:566071453240:web:6c679563804c32201e28cd",
    measurementId: "G-11SSKHPYPE"
};

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app)

export default function requestPermission() {
    if ("Notification" in window) {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                const app = initializeApp(firebaseConfig)
                const messaging = getMessaging(app)
                getToken(messaging, {
                    vapidKey: 'BAIRJW3QSBVda-vE8WlG_wyy8lAWHyiHfWGIn-BdBOom-WtTWP2x2nEbYqMO1oU47G4_iP5y_3C-9rY15_VGIG4'
                }).then((currentToken) => {
                    if (currentToken) {
                        notification.token = currentToken
                    } else {
                        const notificationOptions = {
                            body: payload.notification.body,
                            icon: '/dgv_logo.png'
                        };
                        new Notification('cannot get token', notificationOptions)
                        notification.error = 'cannot get token'
                    }
                }).catch((err) => {
                    if (err) {
                        notification.error = err
                    }
                });
                notification.notificate = messaging
                onMessage(messaging,(payload)=>{
                    notification.noti = payload
                    const notificationOptions = {
                        body: payload.notification.body,
                        icon: '/dgv_logo.png'
                    };
                    notification.notificate = new Notification(payload.notification.title, notificationOptions)
                    
                })
            }
        })
    }
}
requestPermission()