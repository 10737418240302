<template>
    <div>
        <b-modal id="" ref="listFile" centered hide-footer hide-header modal-class="list-file" content-class="modal-width">
            <h5>Hồ sơ yêu cầu đăng ký tham gia</h5>
            <b-form class="list-file" @submit="emitData">
                <b-form-checkbox-group v-model="dataForm.checked">
                    <b-form-checkbox :value="item" v-for="(item, index) in listFile" :key="index">{{ item.required_file_name }}</b-form-checkbox>
                </b-form-checkbox-group>
                <b-form-checkbox value="add" @input="checkAdd" v-model="addFile" :disabled="dataForm.checked.length !== 0">Thêm yêu cầu hồ sơ mới:</b-form-checkbox>
                <b-form-textarea
                    placeholder="Nhập yêu cầu"
                    rows="3"
                    max-rows="5"
                    :disabled="checkDisable"
                    v-model="name"
                    required
                ></b-form-textarea>
                <b-button class="mt-3 mr-2 btn-cancel" @click="hideModal()" block type="submit">Hủy bỏ</b-button>
                <b-button class="mt-3" block type="submit">Xác nhận</b-button>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dataForm: {
                checked: [],
            },
            token: '',
            listFile: [],
            checkDisable: true,
            addFile: '',
            name: '',
        }
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
        this.getListFile()
    },
    methods: {
        show() {
            this.$refs['listFile'].show()
        },
        hideModal() {
            this.$refs['listFile'].hide()
        },
        emitData() {
            event.preventDefault()
            if(this.addFile !== '' && this.addFile && this.dataForm.checked.length == 0) {
                this.createRequiredFile()
            } else if (this.dataForm.checked && this.dataForm.checked.length !== 0){
                this.$emit('sendDataList', this.dataForm.checked);
                this.$refs['listFile'].hide()
            }
        },
        checkAdd() {
            this.checkDisable = !this.checkDisable
            this.name = ''
        },
        async getListFile() {
            const res = await this.$axios.get('/asset/getListRequiredFile', {
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.listFile = res.data
            }
        },
        async createRequiredFile() {
            const data = {
                "name": this.name
            }
            await this.$axios.post('/asset/createRequiredFile', data, {
                headers: {
                    'x-access-token': this.token,
                } 
            }).then(res => {
                this.$toast.success('Thêm yêu cầu hồ sơ mới thành công!')
                this.getListFile()
            }).catch(err => {
                this.$toast.error('Thêm yêu cầu hồ sơ mới không thành công!')
            })
        }
    }
}
</script>

<style lang="css" scoped>

    .list-file h5{
        color: #515151;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .list-file button {
        background-color: #EE3439;
        color: #ffffff;
        font-weight: 500;
        border-radius: 20px;
        border: none;
        min-width: 220px;
        min-height: 44px;
    }

    .list-file button:active,
    .list-file button:focus {
        background-color: #EE3439 !important;
    }

    .list-file button:hover {
        background-color: #EE3439;
    }

    .list-file .btn-cancel {
        color: #EE3439;
        border: 1px solid #EE3439;
        background: #ffffff;
    }

    .list-file .btn-cancel:hover,
    .list-file .btn-cancel:focus,
    .list-file .btn-cancel:active {
        background: #ffffff !important;
        color: #EE3439;
        border: 1px solid #EE3439;
    }

    textarea {
        background: #EEEEEE;
        border-radius: 15px;
        border: none;
        overflow-y: auto !important;
    }

    textarea:focus {
        box-shadow: none;
        border: none;
        background: #EEEEEE;
    }
</style>

<style>
    .list-file .custom-checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .list-file .custom-control-input {
        margin-right: 15px;
        width: 20px;
        height: 20px;
    }
</style>