<template>
    <div class="tab-pane fade" :class="{ 'show active': this.$router.currentRoute.path == '/account/auction/finished' }"
        id="nav-history" role="tabpanel" aria-labelledby="nav-history-tab">
        <div class="d-flex mt-3 mb-xl-4">
            <img class="mx-2" style="width: 24px;height: 20px;" src="@/assets/front-end/icon/Important.png" alt="">
            <h6 class="warning" style="font-size: 19px;font-weight: 500;font-family: Libre Bodoni;">Lưu ý: Danh sách bao gồm
                những cuộc đấu giá bạn đã tham gia</h6>
        </div>
        <div class="row justify-content-around">
            <div class="col-xl-12 col-md-6 col-xs-12 p-2 d-xl-flex xl-border" v-for="(item, index) in dataAuction"
                :key="index" style="cursor: pointer;">
                <div class="col-xl-12 card" style="border-radius: 30px; box-shadow: 2px 2px 4px 0px #00000021;">
                    <img :src="$storage + item.thumbnail" alt="" style="border-radius: 30px;"
                            @click="getListAssetAuction(item)" @error="handleError">
                        <div class="row" @click="getListAssetAuction(item)">
                        <div>
                            <label class="col-12">{{ item.auction_name }}</label>
                        </div>
                        <div class="col-xl-auto">
                            <div class="col-12 d-flex align-items-center">
                                <h6>Mã cuộc đấu giá:</h6>
                                <span>{{ item.auction_code }}</span>
                            </div>
                            <div class="col-12 d-flex align-items-center">
                                <h6>Số lượng tài sản:</h6>
                                <span>{{ item.count_asset }}</span>
                            </div>
                            <div class="col-12 d-flex align-items-center">
                                <h6>Đấu giá viên:</h6>
                                <span>{{ item.full_name }}</span>
                            </div>
                        </div>
                        <div class="col-xl-auto">
                            <div class="col-12 d-flex align-items-center">
                                <h6>Thời gian bắt đầu:</h6>
                                <span>{{ formateTime(item.start_time) }}</span>
                            </div>
                            <div class="col-12 d-flex align-items-center">
                                <h6>Thời gian kết thúc:</h6>
                                <span>{{ formateTime(item.end_time) }}</span>
                            </div>
                            <div class="col-12 align-items-center">
                                <h6></h6>
                                <span style="margin-left: 0; display: inline-block;">{{ item.value }} </span>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-xl-2 col-xlg-auto" style="text-align: center;">
                        <span class="warning" style="margin: 0; display: block;" v-if="item.auction_status_id == 4">
                            <img src="@/assets/icon/Auction_modal.png" alt="" style="width: 50px; height: 50px">
                            <p style="font-weight: 500;font-size: 18px; display: block; margin: 0;">
                                Cuộc đấu giá <br> kết thúc
                            </p>
                        </span>
                        <span class="warning" style="margin: 0; display: block;" v-if="item.auction_status_id == 3">
                            <img src="@/assets/front-end/icon/Group 831.png" alt="" style="width: 50px; height: 50px">
                            <p style="font-weight: 500;font-size: 18px; display: block; margin: 0;">
                                Cuộc đấu giá <br> không thành
                            </p>
                        </span>
                        <span class="success" style="margin: 0; display: block;" v-if="item.auction_status_id == 2">
                            <img src="@/assets/front-end/icon/Ok.png" alt="" style="width: 50px; height: 50px">
                            <p style="font-weight: 500;font-size: 18px; display: block; margin: 0;">
                                Cuộc đấu giá <br> thành
                            </p>
                        </span>
                    </div>
                </div>
            </div>
            <Paginate :page=page @set-page="setPage" class="mt-2"/>
        </div>
    </div>
</template>
<script>
import Paginate from '../components/paginate.vue'
export default {
    components: {
        Paginate
    },
    data() {
        return {
            dataAuction: [],
            page: {
                totalData: 0,
                totalPage: 0,
                currentPage: this.$route.query.page || 1,
                perPage: 4,
                classPrev: 'd-none',
                classNext: 'd-none'
            },
        }
    },
    created() {
        this.getDataAuction()
    },
    methods: {
        formateTime(time) {
            return this.$moment.utc(time).format("HH:mm:ss DD/MM/YYYY")
        },
        async getDataAuction() {
            const data = {
                page: this.page.currentPage,
            };
            const res = await this.$axios.get('/auction/getListAuctionEnding?page=' + data.page + '&per_page=4', {
                headers: {
                    'x-access-token': localStorage.getItem('member')
                }
            })
            if (res.status == 200) {
                this.dataAuction = res.data.re
                this.page.totalData = res.data.pagination.total_element
                this.page.totalPage = res.data.pagination.total_page
            }
        },
        setPage(val) {
            this.page.currentPage = val;
            this.getDataAuction();
        },
        getListAssetAuction(item) {
            this.$router.push({
                path: '/auction/auction_asset/'+item.auction_id,
            })
        }
    },

}
</script>

<style lang="css">
    #nav-history span.page-link, #nav-happenning span.page-link, #nav-delivery span.page-link {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
</style>