<template>
    <div>
        <b-table 
            responsive="md"
            :fields="data.fields" 
            :items="data.items"
            tbody-tr-class="my-2"
        >
            <template #cell(status)="row">
                <span :class="{
                    'ok-img': row.item.status === '0',
                }" v-show="row.item.status == 0">
                    Giá cao nhất
                </span>
                <span :class="{
                    'cancel': row.item.status === '1'
                }" v-show="row.item.status == 1">
                    Giá thấp hơn
                </span>
            </template>
        </b-table>
    </div>
</template>
<script>
export default {
    props:["tab"],
    data() {
        return {
            data: this.tab
        }
    }
}
</script>

<style lang="css">

.tab-product-detail table {
    color: #515151;
}
.tab-product-detail thead tr {
    background-color: #E5E5E5;
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
}

.tab-product-detail tbody tr {
    font-weight: 500;
}

.tab-product-detail .table > :not(caption) > * > * {
    border: none;
}

.tab-product-detail  tr {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 53px;
}

.tab-product-detail tbody tr {
    border: 1px solid #ABABAB;
    border-radius: 22px;
}

.tab-product-detail .text-red {
    color: #EE3439;
}

.ok-img, .cancel {
    position: relative;
    padding-left: 25px;
    display: flex;
    align-items: center;
}

.ok-img::before {
    content: '';
    position: absolute;
    background-image: url(@/assets/icon/Ok.png);
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
}

.cancel::before {
    content: '';
    position: absolute;
    background-image: url(@/assets/icon/close.png);
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
}

@media (max-width: 768px) {
    table {
        font-size: 13px;
    }

    .tab-product-detail thead tr {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    table {
        font-size: 11px;
    }

    .tab-product-detail thead tr {
        font-size: 12px;
    }
    
}
@media (max-width: 425px) {
    table .col-md-1 {
        width: 15%;
    }
    .tab-product-detail .text button, .tab-product-detail .user_join {
        font-size: 13px !important;
    }
}
@media (max-width: 320px) {
    table {
        font-size: 10px;
    }

    .tab-product-detail thead tr {
        font-size: 10px;
    }

    .tab-product-detail .nav-tabs .nav-link {
        font-size: 13px !important;
    }
    .tab-product-detail .text button, .tab-product-detail .user_join {
        font-size: 11px !important;
    }
}
</style>