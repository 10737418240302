<template>
    <div class="sameproduct">
        <div class="d-flex same_ttl">
            <h5>Tài sản tương tự</h5>
            <b-link :to="'/cate-detail/'+category_id">Xem thêm >></b-link>
        </div>
        <b-row v-if="listAsset.result">
            <div class="slide">
                <carousel :responsive="{
                    0: {
                        nav: false,
                        dots: false,
                        items: 1
                    },
                    575: {
                        items: 2,
                        nav: false,
                        dots: false,
                    },
                    768: {
                        items: 2,
                        nav: false,
                        dots: false,
                    },
                    992: {
                        items: 3,
                        nav: false,
                        dots: false,
                    },
                    1200: {
                        items: 4,
                        nav: false,
                        dots: false,
                    },
                }" :autoplay="true" :margin="10">
                    <b-link class="slide-item text-center" v-for="(itemcarousel, index) in Array.from(listAsset.result)" :key="index"
                    @click="$router.push('/product-detail/'+ encode(itemcarousel.asset_id))">
                        <cardAsset :item="itemcarousel" />
                    </b-link>
                </carousel>
            </div>
        </b-row>
    </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import cardAsset from "./card-asset.vue";
export default {
    components: {
        carousel,
        cardAsset
    },
    props:['category_id', 'listAsset'],
}
</script>
<style lang="css" scoped>
.sameproduct {
    margin: 29px 0 31px;
}

.sameproduct .same_ttl {
    justify-content: space-between;
    color: #515151;
    font-weight: 500;
    margin-bottom: 24px;
    font-family: Libre Bodoni;
    align-items: center;
}

.sameproduct .same_ttl h5 {
    font-size: 29.97px;
    line-height: 37.46px;
    font-weight: 600;
}

.sameproduct .same_ttl a {
    font-size: 16px;
    color: #515151;
    text-decoration: none;
    line-height: 20px;
}

.sameproduct .slide .slide-item {
    border: none;
    padding: 0;
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
}


.sameproduct .slide .slide-item .btn {
    background-color: transparent;
    border-radius: 19px;
    color: #929292;
    text-align: left;
    font-size: 14px;
}

.sameproduct .slide .slide-item__ttl {
    text-align: left;
    margin: 0;
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.sameproduct .slide .slide-item .list-btn {
    margin: 0;
    padding-left: 0;
}

.sameproduct .slide .slide-item .list-btn span {
    color: #515151;
    font-weight: 500;
}

@media (max-width: 375px) {
    .sameproduct .d-flex h5 {
        font-size: 22px;
    }
}
</style>