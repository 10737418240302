<template>
    <div>
        <b-modal id="return_price" ref="return_price" centered hide-footer hide-header @hide="hideModal">
            <div class="return_price">
                <img src="@/assets/icon/warning_ico.png" alt="">
                <h5>Xác nhận rút lại giá đã trả</h5>
                <p>Nếu rút lại giá đã trả bạn sẽ bị truất quyền trả giá! Nhập mã xác nhận để rút lại
                    giá đã trả:
                    <span style="color: #EE3439; font-weight: 600;">{{ random }}</span>
                </p>
                <b-form-input type="text" required v-model="inputValue" maxlength="5"></b-form-input>
                <p v-show="!correct_code" style="color: #EE3439; font-weight: 600; margin: 10px 0 0 ;">Vui lòng nhập đúng mã
                    xác nhận</p>
                <b-button class="mt-3 cancel_btn" block @click="$bvModal.hide('return_price')">Hủy bỏ</b-button>
                <b-button class="mt-3 confirm" block @click="check_code">Xác nhận</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    data() {
        return {
            inputValue: "",
            correct_code: true,
            return_price: false,
            random: ""
        }
    },

    methods: {
        check_code() {
            if (this.inputValue == this.random) {
                this.$refs['return_price'].hide();
                this.$emit('save-data', this.return_price = true);
            } else {
                this.correct_code = false;
            }
        },
        show() {
            this.$refs['return_price'].show()
            this.randomString()
        },
        randomString() {
            let randomString = '';

            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

            for (let i = 0; i < 5; i++) {
                const randomChar = characters.charAt(Math.floor(Math.random() * characters.length));
                randomString += randomChar;
            }
            this.random = randomString
        },
        hideModal() {
            this.inputValue = ''
        }
    }
}
</script>
<style lang="css" scoped>
.modal-content {
    border-radius: 20px;
    box-shadow: 0px 0px 38px 0px #00000040;
}

.return_price {
    text-align: center;
    color: #444444;
    text-decoration: none;
}

.return_price h5 {
    font-weight: 500;
    font-size: 20.96px;
    line-height: 37.73px;
}

.return_price button {
    width: 165.06px;
    height: 41.9px;
    text-transform: uppercase;
    border-radius: 9.65px;
    font-weight: 700;
    font-size: 14.47px;
    border: none;
}

.return_price .confirm,
.return_price .confirm:active {
    background: #EE3439;
}

.return_price input {
    border-radius: 9.65px;
    background: #EEEEEE;
    border: none;
}

.return_price input:focus {
    box-shadow: none;
    background: #EEEEEE;
}

.return_price .cancel_btn,
.return_price .cancel_btn:active {
    background: #FFFFFF;
    border: 1px solid #EE3439;
    color: #EE3439;
    margin-right: 10px;
}
</style>