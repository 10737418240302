<template>
    <div>
        <b-container class="wait_room">
            <div class="d-flex justify-content-between">
                <b-breadcrumb :items="items"></b-breadcrumb>
            </div>
            <b-row>
                <h5 class="title">{{ dataAsset.asset_name }}</h5>
                <b-col cols="lg-6">
                    <slideImage :list_images=dataAsset.image />
                    <div v-if="dataAsset.asset_status !== 0">
                        <img src="@/assets/icon/Auction2.png" alt="" class="image-status"
                            v-if="dataAsset.asset_status == 1">
                        <img src="@/assets/icon/golden sold out.png" alt="" class="image-status" v-else>
                    </div>
                </b-col>
                <b-col cols="lg-6">
                    <div class="status_au" :class="{
                        'status_au--success': dataAsset.auction_status == 2,
                        'status_au--fail': dataAsset.auction_status == 3,
                        'status_au--dif': dataAsset.auction_status == 4,
                    }">
                        <p style="padding-top: 5px;">Cuộc đấu giá kết thúc </p>
                        <p v-if="dataAsset.auction_status == 3">Kết thúc không thành</p>
                        <p v-else-if="dataAsset.auction_status == 4" style="color: #ee3439;">Kết thúc</p>
                        <p v-else>Kết thúc thành</p>
                    </div>
                    <div class="info">
                        <p>Trạng thái tài sản:</p>
                        <span class="highlight" style="color: #FF9838;" v-if="dataAsset.asset_status == 2">Đã đấu giá - Đang
                            xử lý</span>
                        <span class="highlight" v-if="dataAsset.asset_status == 3">Đấu giá không thành</span>
                        <span class="highlight" style="color: #00B569;" v-if="dataAsset.asset_status == 4">Đấu giá thành</span>
                    </div>
                    <div class="info" v-if="dataAsset.highestBid && dataAsset.asset_status !== 3">
                        <p>Giá trúng đấu giá:</p> <span class="highlight">{{
                            parseInt(dataAsset.highestBid).toLocaleString("vi-VN") }} VNĐ</span>
                    </div>
                    <div class="info" v-else>
                        <p>Giá trúng đấu giá:</p> <span class="highlight">0 VNĐ</span>
                    </div>
                    <div class="info">
                        <p>Giá khởi điểm:</p> <span class="highlight">{{ parseInt(dataAsset.price).toLocaleString("vi-VN")
                        }} VNĐ</span>
                    </div>
                    <div class="info">
                        <p>Mã tài sản đấu giá:</p> <span>{{ dataAsset.asset_code }}</span>
                    </div>
                    <div class="info" v-if="dataAsset.winner_name && dataAsset.asset_status == 4">
                        <p>Người trúng đấu giá:</p> <span class="auction--success">{{ dataAsset.winner_name }}</span>
                    </div>
                    <!-- <div class="info" v-if="dataAsset.winner_code">
                        <p>Mã người trúng:</p> <span>{{ dataAsset.winner_code }}</span>
                    </div> -->
                    <div class="info">
                        <p>Số người tham gia đấu giá:</p> <span>{{ this.user_join.length }}</span>
                    </div>
                    <div class="info">
                        <p>Số lượt trả giá:</p> <span>{{ countBid }}</span>
                    </div>
                    <div class="info">
                        <p>Thời gian bắt đầu trả giá:</p> <span>{{ formateTime(dataAsset.auction_start_time) }}</span>
                    </div>
                    <div class="info">
                        <p>Thời gian kết thúc trả giá:</p> <span>{{ formateTime(dataAsset.end_time) }}</span>
                    </div>
                    <div class="group-btn">
                        <b-link @click="$router.push('/auctioneer/asset-detail/' + encode(dataAsset.asset_id))" class="btn-link">Chi tiết tài
                            sản đấu giá</b-link>
                        <b-link class="btn-link btn-report" target="_blank" @click="getReport">Biên bản đấu giá</b-link>
                    </div>
                </b-col>
            </b-row>

            <b-row style="margin-top: 25px;" class="file">
                <b-col cols="md-6 col-lg-4">
                    <div class="more-info">
                        <h5 class="more-info__ttl">Tài liệu liên quan</h5>
                        <ul class="list-info">
                            <li v-for="(datafile, index) in dataAsset.file" :key="index"><b-link
                                    :href="$storage + datafile.url" target="_blank">{{
                                        datafile.file_name }}</b-link></li>
                        </ul>
                    </div>
                </b-col>
                <b-col cols="md-6 col-lg-4">
                    <div class="more-info">
                        <h5 class="more-info__ttl">Thông tin tổ chức đấu giá</h5>
                        <ul class="list-info-cus">
                            <li><img src="@/assets/icon/Company.png" alt="">Đơn vị tổ chức: <span class="text-red">{{
                                dataAsset.org }}</span></li>
                            <li><img src="@/assets/icon/Customer.png" alt="">Đấu giá viên: <span class="text-red">{{
                                dataAsset.DGV }}
                                </span></li>
                            <li><img src="@/assets/icon/Location.png" alt="">Địa điểm: <span class="text-red">{{
                                dataAsset.auction_address }}
                                </span></li>
                        </ul>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <detailAu />
            </b-row>
        </b-container>
    </div>
</template>
<script>
import slideImage from "../../web-view/components/slideImage.vue"
import detailAu from './components/detail-auction.vue'
export default {
    components: {
        slideImage,
        detailAu
    },
    data() {
        return {
            items: [
                {
                    text: 'Trang chủ',
                    to: '/',
                },
                {
                    text: 'Chi tiết tài sản đấu giá',
                    active: true
                },
            ],
            asset_id: '',
            dataAsset: [],
            countBid: '',
            user_join: [],
        }
    },
    methods: {
        formateTime(time) {
            return this.$moment.utc(time).format("HH:mm:ss DD/MM/YYYY")
        },
        async getDataAsset() {
            const storedData = localStorage.getItem('auInfo')
            if (storedData) {
                var info = JSON.parse(storedData)
            }

            const res = await this.$axios.get('/asset/getAssetDetail?asset_id=' + this.asset_id, {
                headers: {
                    'x-access-token': info.accessToken
                }
            })

            if (res.status == 200) {
                this.dataAsset = res.data
            }
        },
        async getAuHistory() {
            const storedData = localStorage.getItem('auInfo')
            if (storedData) {
                var info = JSON.parse(storedData)
            }

            await this.$axios.get('/auction/getAuctionHistory?page=1&per_page=1000&asset_id=' + this.asset_id, {
                headers: {
                    'x-access-token': info.accessToken
                }
            }).then(res => {
                this.countBid = res.data.re.length
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAuctioneer')
                } else if (err.response.status == 401) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAuctioneer')
                }
            })
        },
        async getMemberJoined() {
            const storedData = localStorage.getItem('auInfo')
            if (storedData) {
                var info = JSON.parse(storedData)
            }

            const res = await this.$axios.get('/auction/getListMemberJoin?asset_id=' + this.asset_id, {
                headers: {
                    'x-access-token': info.accessToken
                }
            })

            if (res.status == 200) {
                this.user_join = res.data
            }
        },
        getReport() {
            const links = [
                this.$report + "/report/exportPDF?path=template/bien_ban_dau_gia&asset_id=" + this.asset_id,
                this.$report + "/report/exportPDF?path=template/danh_sach_diem_danh&asset_id=" + this.asset_id,
                this.$report + "/report/exportPDF?path=template/dien_bien_tra_gia&asset_id=" + this.asset_id,
                this.$report + '/report/exportPDF?path=template/trich_xuat_dau_gia&asset_id=' + this.asset_id,
                this.$report + '/report/exportPDF?path=template/danh_sach_dang_ky&asset_id=' + this.asset_id
            ];

            links.forEach((link) => {
                window.open(link, '_blank');
            });
        },
    },
    mounted() {
        this.asset_id = this.decode(this.$route.params.asset_id);
        this.getDataAsset();
        this.getAuHistory();
        this.getMemberJoined();
        setTimeout(() => {
            this.getDataAsset();
        }, 1000);
    },
}
</script>
<style lang="css" scoped>
.wait_room {
    color: #515151;
    background-image: url('@/assets/images/logo_moreinfo.png');
    background-repeat: no-repeat;
    background-position: top;
}

.info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.info p {
    min-width: 330px;
    margin: 0;
}

.info span {
    font-weight: 500;
}

.info span.highlight {
    color: #EE3439;
    font-size: 20px;
}

span.auction--success {
    color: #00B569;
    font-size: 20px;
    font-weight: 500;
}

.group-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.btn-link {
    border: 1px solid #515151;
    color: #515151;
    text-decoration: none;
    border-radius: 16px;
    font-weight: 500;
    height: 46px;
    width: 246px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wait_room .more-info__ttl {
    font-weight: 500;
    font-size: 22px;
    line-height: 39.6px;
}

.wait_room .more-info li {
    padding: 5px 0;
}

.wait_room .list-info li {
    position: relative;
    margin-left: 33px;
}

.wait_room .list-info li::before {
    content: " ";
    background: url('@/assets/icon/Note.png');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    position: absolute;
    left: -33px;
    top: 3px;
}

.wait_room .list-info li a {
    text-decoration: none;
    color: #515151;
}

.list-info,
.list-info-cus {
    padding-left: 5px;
}

.wait_room .list-info-cus img {
    padding-right: 14px;
}

.wait_room .text-red {
    color: #EE3439;
    font-size: 16px;
    font-weight: 500;
    line-height: 28.8px;
    font-family: Roboto;
}


.status_au {
    box-shadow: 0px 2px 10px 0px #0000001A;
    border-radius: 40px;
    text-align: center;
    position: relative;
    margin-bottom: 15px;
}

.status_au::before {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    width: 41px;
    height: 41px;
    left: 3%;
    top: 23%;
}

.status_au p {
    color: #515151;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0;
}

.status_au p:last-child {
    font-family: Libre Bodoni;
    font-weight: 700;
    font-size: 26px;
    padding-bottom: 10px;
}

.status_au--success::before {
    background-image: url('@/assets/icon/Ok.png');
}

.status_au--fail::before {
    background-image: url('@/assets/front-end/icon/Group\ 831.png');
    width: 52px;
    height: 51px;
}
.status_au--dif::before {
    background-image: url('@/assets/icon/Auction_modal.png');
    width: 52px;
    height: 51px;
    background-size: cover;
}

.status_au--success p:last-child {
    color: #00B569;
}

.status_au--fail p:last-child {
    color: #EE3439;
}

.btn-report {
    border-color: #EE3439;
    color: #EE3439;
    margin-left: 15px;
}

.image-status {
    position: absolute;
    z-index: 90;
    top: 5%;
    left: 5%;
}

@media (max-width: 1200px) {
    .info span.highlight {
        font-size: 17px;
    }
}

@media (max-width: 1024px) {

    .btn-link {
        padding: 12px 16px;
    }
}

@media (max-width: 768px) {

    .wait_room .title {
        font-size: 20px;
    }

    .wait_room .more-info,
    .wait_room .text-red {
        font-size: 14px;
    }

    .wait_room .more-info__ttl {
        font-size: 16px;
    }

    .info p {
        min-width: 330px;
    }

    .info span.highlight {
        font-size: 16px;
    }

    .btn-link {
        width: 100%;
    }

    .file .col-md-4 {
        width: 50%;
    }
}

@media (max-width: 575px) {
    .info p {
        min-width: 210px;
    }
}
@media (max-width: 425px) {
    .file .col-md-4 {
        width: 100%;
    }

    .info span.highlight {
        font-size: 18px;
    }

    .info {
        font-size: 14px;
    }

    .show-time::before {
        display: none;
    }

    .show-time p:first-child {
        font-size: 18px;
    }

    .show-time p:nth-child(2) {
        font-size: 16px;
    }

    .show-time p:last-child {
        font-size: 22px;
    }

    .wait_room .title {
        font-size: 18px;
    }

    .btn-link {
        padding: 12px 8px;
    }
}

@media (max-width: 375px) {

    .info span.highlight {
        font-size: 16px;
    }

    .status_au::before {
        display: none;
    }
}

@media (max-width: 320px) {
    .info p {
        min-width: 200px;
    }

    .info span.highlight,

    .info,
    .auction-box,
    .wait_room .more-info,
    .wait_room .text-red {
        font-size: 14px;
    }

    span.auction--success {
        font-size: 16px;
    }
}
</style>

<style lang="css">
.auction-box .form-group>div {
    display: flex;
    margin-top: 13px;
    justify-content: space-around;
}
</style>