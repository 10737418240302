<template>
    <div :class="{'overlay' : isLoading}">
        <div>
            <div class="d-flex justify-content-between align-items-center">
                <h5 style="font-size: 22px; font-weight: 700; margin: 0;">Tạo cuộc đấu giá mới</h5>
                <button class="draft" @click="$router.push('/admin/draft-auction')">Bản nháp đã lưu</button>
            </div>
            <div class="form-auction">
                <b-form @submit="addAuction('add')">
                <b-row>
                    <b-col cols="lg-7">
                        <!-- <div class="d-flex justify-content-between align-items-center mb-3"> -->
                            <h5 class="mb-3">Thông tin chi tiết cuộc đấu giá</h5>
                        <!-- </div> -->
                            <b-row>
                                <b-col cols="lg-4">
                                    <label for="auction_name">Tên cuộc đấu giá:</label>
                                </b-col>
                                <b-col cols="lg-8" class="input-form">
                                    <b-form-input
                                        id="auction_name"
                                        placeholder="Nhập tên, mô tả cuộc đấu giá"
                                        v-model="dataAdd.auction_name"
                                        @input="formatTxt('auction_name')"
                                        required
                                    ></b-form-input>
                                </b-col>
    
                                <b-col cols="md-4">
                                    <p>Mã cuộc đấu giá:</p>
                                </b-col>
                                <b-col cols="md-8" class="input-form">
                                    <p style="font-weight: 500; color: #444444;">Hệ thống tự tạo mã</p>
                                </b-col>
    
                                <b-col cols="lg-4">
                                    <label for="time_start_regis">Thời gian bắt đầu đấu giá:</label>
                                </b-col>
                                <b-col cols="lg-8" class="input-form">
                                    <b-row>
                                        <b-col cols="lg-6">
                                            <div>
                                                <b-form-datepicker id="time_start_regis" start-weekday=1 placeholder="Chọn ngày" locale="vi" v-model="date_start_time"></b-form-datepicker>
                                            </div>
                                        </b-col>
                                        <b-col cols="lg-6">
                                            <!-- <b-form-timepicker locale="vi" placeholder="Chọn thời gian" v-model="time_start_time"></b-form-timepicker> -->
                                            <b-form-input v-model="time_start_time" type="time" locale="vi" required></b-form-input>
                                        </b-col>
                                    </b-row>
                                </b-col>
    
                                <b-col cols="lg-4">
                                    <label for="time_end_regis">Thời gian kết thúc đấu giá:</label>
                                </b-col>
                                <b-col cols="lg-8" class="input-form">
                                    <b-row>
                                        <b-col cols="lg-6">
                                            <div>
                                                <b-form-datepicker id="time_end_regis" start-weekday=1 placeholder="Chọn ngày" locale="vi" v-model="date_end_time"></b-form-datepicker>
                                            </div>
                                        </b-col>
                                        <b-col cols="lg-6">
                                            <!-- <b-form-timepicker locale="vi" placeholder="Chọn thời gian" v-model="time_end_time"></b-form-timepicker> -->
                                            <b-form-input v-model="time_end_time" type="time" locale="vi" required></b-form-input>
                                        </b-col>
                                    </b-row>
                                </b-col>
    
                                <b-col cols="lg-4">
                                    <label for="type">Phương thức đấu giá:</label>
                                </b-col>
                                <b-col cols="lg-8" class="input-form">
                                    <b-form-select id="type" class="form-control form-select" v-model="dataAdd.type" required>
                                        <b-form-select-option :value="null">Chọn Phương thức đấu giá</b-form-select-option>
                                        <b-form-select-option value="1">Đấu giá lên</b-form-select-option>
                                        <!-- <b-form-select-option value="2">Đấu giá xuống</b-form-select-option> -->
                                    </b-form-select>
                                </b-col>

                                <b-col cols="lg-4">
                                    <label for="type">Thời gian đếm ngược vào phòng:</label>
                                </b-col>
                                <b-col cols="lg-8" class="input-form" style="position: relative;">
                                    <b-form-input
                                        placeholder="Nhập phút"
                                        v-model="dataAdd.countdown_time"
                                        required
                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                    ></b-form-input>
                                    <span class="sp-min">Phút</span>
                                </b-col>
                                
                                <h5>Tài sản trong cuộc đấu giá</h5>
                                <b-col cols="lg-4">
                                    <label for="asset">Tài sản 1:</label>
                                </b-col>
                                <b-col cols="lg-8" class="input-form">
                                    <b-form-select id="asset" class="form-control form-select" v-model="dataAdd.asset[0]" required>
                                        <b-form-select-option :value="null">Chọn tài sản</b-form-select-option>
                                        <b-form-select-option v-for="item in listAsset" :key="item.asset_id" :value="item.asset_id">{{ item.asset_code +' - '+item.asset_name }}</b-form-select-option>
                                    </b-form-select>
                                    <div class="text-right">
                                        <button class="mb-3" type="button">+ Thêm tài sản</button>
                                    </div>
                                </b-col>
    
                            </b-row>
                        </b-col>
                        <b-col cols="lg-5">
                            <h5>Hình ảnh đại diện cuộc đấu giá:</h5>
                            <b-form-file class="mt-3 d-none" id="file_img" ref="fileInput"  @change="handleImgChange" accept="image/png, image/gif, image/jpeg, image/jpg"></b-form-file>
                            <img :src="this.dataImg" v-if="this.dataImg" style="max-width: 177px; border-radius: 10px; margin-right: 20px; cursor: pointer;" onclick="$('#file_img').click()">
                            <img src="@/assets/admin/icon/icon_add.png" onclick="$('#file_img').click()" v-else style="cursor: pointer;">
                            <h5 class="mt-3">Thông tin tổ chức đấu giá </h5>
                            <b-row>
                                <b-col cols="lg-4">
                                    <label for="unit"><img src="@/assets/icon/Company.png" alt="" style="padding-right: 14px;">Đơn vị tổ chức:</label>
                                </b-col>
                                <b-col cols="lg-8" class="input-form">
                                    <b-form-input
                                        id="unit"
                                        placeholder="Nhập đơn vị tổ chức đấu giá"
                                        v-model="dataAdd.org"
                                        @input="formatTxt('org')"
                                        required
                                    ></b-form-input>
                                </b-col>
                                
                                <b-col cols="lg-4">
                                    <label><img src="@/assets/icon/Customer.png" alt="" style="padding-right: 14px;">Đấu giá viên:</label>
                                </b-col>
                                <b-col cols="lg-8" class="input-form">
                                    <b-dropdown :text="text_dgv" class="button-list form-control p-0">
                                        <b-dropdown-item v-for="(dgv, index) in listDGV" :key="index" @click="getNameDGV(dgv.full_name, dgv.user_id)">
                                            <div class="d-flex align-items-center">
                                                <img :src="$storage + dgv.avatar" alt="" style="width: 45px; height: 45px; border-radius: 31px;" @error="handleError">
                                                <div class="ml-3">
                                                    <p style="font-weight: 500; margin-bottom: 0;">{{ dgv.full_name }}</p>
                                                    <p class="m-0">Đấu giá viên - {{ dgv.agency_name }}</p>
                                                </div>
                                            </div>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-col>
    
                                <b-col cols="lg-4">
                                    <label for="secretary"><img src="@/assets/icon/Location.png" alt="" style="padding-right: 14px;">Thư ký đấu giá:</label>
                                </b-col>
                                <b-col cols="lg-8" class="input-form">
                                    <b-form-input
                                        id="secretary"
                                        placeholder="Nhập thư ký đấu giá"
                                        v-model="dataAdd.secretary"
                                        @input="formatTxt('secretary')"
                                        required
                                    ></b-form-input>
                                </b-col>
    
                                <b-col cols="lg-4">
                                    <label for="add"><img src="@/assets/icon/Location.png" alt="" style="padding-right: 14px;">Địa điểm:</label>
                                </b-col>
                                <b-col cols="lg-8" class="input-form">
                                    <b-form-input
                                        id="add"
                                        placeholder="Nhập địa điểm"
                                        v-model="dataAdd.address"
                                        @input="formatTxt('address')"
                                        required
                                    ></b-form-input>
                                </b-col>
                            </b-row>
                            <div class="d-flex mt-2">
                                <div class="form-check form-switch">
                                    <input id="bid_public" class="form-check-input" type="checkbox" role="switch" v-model="status_public">
                                </div>
                                <label for="bid_public">Công khai danh sách trả giá</label>
                            </div>
                            <div class="d-flex">
                                <div class="form-check form-switch">
                                    <input id="attend_status" class="form-check-input" type="checkbox" role="switch" v-model="dataAdd.auction_attendance_status">
                                </div>
                                <label for="attend_status">Điểm danh vào cuộc đấu giá</label>
                            </div>
                            <div class="d-flex">
                                <div class="form-check form-switch">
                                    <input id="public_list" class="form-check-input" type="checkbox" role="switch" v-model="dataAdd.auction_public_status">
                                </div>
                                <label for="public_list">Công khai lịch sử trả giá với cả người không tham gia đấu giá </label>
                            </div>
                            <button class="mt-3 mb-1" type="button" @click="$refs.authen_modal.show()">Thiết lập ủy quyền</button>
                            <div class="group-button">
                                <button type="button" @click="$router.go(-1)" :disabled="isLoading">Hủy bỏ</button>
                                <button type="button" @click="addAuction('draft')" :disabled="isLoading">Lưu bản nháp</button>
                                <button type="submit" :disabled="isLoading">Tạo cuộc đấu giá</button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </div>
        <div class="loader" v-if="isLoading"></div>

        <b-modal id="" ref="authen_modal" centered hide-footer hide-header modal-class="authen_modal" content-class="modal-width">
            <div class="authen-modal">
                <h5 style="margin-bottom: 16px;">Chọn thời gian được phép ủy quyền</h5>
                <div class="d-flex align-items-center justify-content-between">
                    <span>Thời gian bắt đầu ủy quyền:</span>
                    <div class="d-flex align-items-center justify-content-between" style="flex-basis: 70%;">
                        <b-form-datepicker start-weekday=1 placeholder="Chọn ngày" locale="vi" style="flex-basis: 47%;" v-model="start_date_authen"></b-form-datepicker>
                        <b-form-input style="flex-basis: 47%; height: 42px; background: #EEE;" type="time" locale="vi" v-model="start_time_authen"></b-form-input>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between mt-3">
                    <span>Thời gian kết thúc ủy quyền:</span>
                    <div class="d-flex align-items-center justify-content-between" style="flex-basis: 70%;">
                        <b-form-datepicker start-weekday=1 placeholder="Chọn ngày" locale="vi" style="flex-basis: 47%;" v-model="end_date_authen"></b-form-datepicker>
                        <b-form-input style="flex-basis: 47%; height: 42px; background: #EEE;" type="time" locale="vi" v-model="end_time_authen"></b-form-input>
                    </div>
                </div>
                <div class="d-flex mt-4">
                    <div class="form-check form-switch">
                        <input id="authen" class="form-check-input" type="checkbox" role="switch" v-model="dataAdd.auction_authority_status">
                    </div>
                    <label for="authen">Yêu cầu duyệt ủy quyền</label>
                </div>
                <div class="text-right wp-btn">
                    <b-button class="mt-3 mr-3 btn-cancel" block @click="cancelAuthen">Hủy bỏ</b-button>
                    <b-button class="mt-3" block @click="$refs.authen_modal.hide()">Xác nhận</b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    data() {
        return {
            date_start_time: '',
            time_start_time: '',
            date_end_time: '',
            time_end_time: '',
            status_public: '',
            dataImg: '',
            customers: [''],
            dataAdd: {
                auction_name: '',
                start_time: null,
                end_time: null,
                type: null,
                thumbnail: '',
                org: '',
                public_status: '',
                publish_status: '',
                view_status: 0,
                address: '',
                user_id: '',
                asset: [null],
                auction_public_status: '',
                auction_attendance_status: '',
                auction_authority_status: '',
                countdown_time: '',
                authority_start_time: null,
                authority_end_time: null,
                secretary: ''
            },
            listDGV: [],
            text_dgv: 'Tên đấu giá viên',
            token: '',
            listAsset: [],
            isLoading: false,
            start_date_authen: '',
            start_time_authen: '',
            end_date_authen: '',
            end_time_authen: ''
        }
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
        this.getListDGV()
    },
    methods: {
        handleImgChange(event) {
            const file = event.target.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        this.dataImg = reader.result
                        this.upload(file)
                };
                reader.readAsDataURL(file);
            }
        },
        async upload(file) {
            const data = {
                'file': file,
                'path': 'auction/thumbnail/'
            }
            await this.$axios.post('/common/uploadFile', data, {
                headers: {
                    'x-access-token': this.token,
                    'Content-Type': 'multipart/form-data'
                } 
            }).then(res => {
                this.dataAdd.thumbnail = res.data.list_result[0].path
            })
        },
        async addAuction(type) {
            event.preventDefault();
            if(this.date_start_time !== '' && this.time_start_time !== ''){
                this.dataAdd.start_time = this.date_start_time + " " + this.time_start_time
            }

            if(this.date_end_time !== '' && this.time_end_time !== ''){
                this.dataAdd.end_time = this.date_end_time + " " + this.time_end_time
            }

            if(this.start_date_authen !== '' && this.start_time_authen !== ''){
                this.dataAdd.authority_start_time = this.start_date_authen + " " + this.start_time_authen
            }
            if(this.end_date_authen !== '' && this.end_time_authen !== ''){
                this.dataAdd.authority_end_time = this.end_date_authen + " " + this.end_time_authen
            }

            if(this.status_public) {
                this.dataAdd.public_status = 1
            } else {
                this.dataAdd.public_status = 0
            }

            if(this.dataAdd.auction_authority_status) {
                this.dataAdd.auction_authority_status = 1
            } else {
                this.dataAdd.auction_authority_status = 0
            }
            if(this.dataAdd.auction_attendance_status) {
                this.dataAdd.auction_attendance_status = 1
            } else {
                this.dataAdd.auction_attendance_status = 0
            }
            if(this.dataAdd.auction_public_status) {
                this.dataAdd.auction_public_status = 1
            } else {
                this.dataAdd.auction_public_status = 0
            }

            if(type == 'add') {
                if(!this.dataAdd.start_time || !this.dataAdd.end_time || this.dataAdd.thumbnail == '') {
                    this.$toast.error('Vui lòng nhập đầy đủ thông tin!')
                } else {
                    this.dataAdd.publish_status = 1
                    this.isLoading = true
                    await this.$axios.post('/auction/createAuction', this.dataAdd, {
                        headers: {
                            'x-access-token': this.token,
                        } 
                    }).then(res => {
                        this.$toast.success('Thêm cuộc đấu giá thành công!')
                        this.$router.push('/admin/approve-auction')
                        this.isLoading = false
                    }).catch(err => {
                        this.$toast.error('Thêm cuộc đấu giá không thành công!')
                        this.isLoading = false
                    })
                }
            } else {
                this.dataAdd.publish_status = 0
                this.isLoading = true
                await this.$axios.post('/auction/createAuction', this.dataAdd, {
                    headers: {
                        'x-access-token': this.token,
                    } 
                }).then(res => {
                    this.$toast.success('Lưu bản nháp thành công!')
                    this.$router.push('/admin/draft-auction')
                    this.isLoading = false
                }).catch(err => {
                    this.$toast.error('Lưu bản nháp không thành công!')
                    this.isLoading = false
                })
            }
        },
        async getListDGV() {
            const res = await this.$axios.get('/auth/getListUser', {
                params: {
                    user_name: '',
                    user_code: '',
                    role_id: '3',
                    agency_id: '',
                    phone: '',
                    address: '',
                    email: '',
                    full_name: '',
                    gender: '',
                    birthday: '',
                    soTheDGV: '',
                    cccd: '',
                    page: '1',
                    per_page: '10000',
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.listDGV = res.data.result
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if(err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                }
            })
        },
        async getNameDGV(name, id) {
            this.text_dgv = name
            this.dataAdd.user_id = id
            const res = await this.$axios.get('/asset/getAssetByDGV', {
                params: {
                    dgv: id,
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.listAsset = res.data.data
            }
        },
        cancelAuthen() {
            this.$refs.authen_modal.hide()
            this.start_date_authen = this.start_time_authen = this.end_date_authen = this.end_time_authen = ''
            this.dataAdd.authority_start_time = this.dataAdd.authority_end_time = null
            this.dataAdd.auction_authority_status = false
        },
        formatTxt(item) {
            this.dataAdd[item] = this.dataAdd[item].replace(/[<>\[\]]/g, '')
        }
    }
}
</script>
<style lang="css" scoped>
    .form-auction {
        background: #FFFFFF;
        box-shadow: 2px 2px 6px 0px #00000026;
        border-radius: 15px;
        padding: 19px 10px 40px 30px;
        margin-top: 20px;
        color: #515151;
        height: 100%;
    }

    .form-auction h5 {
        font-size: 20px;
        font-weight: 500;
    }

    .form-auction .form-control {
        background-color: #EEEEEE;
        border-radius: 15px;
        border: none;
        margin-bottom: 15px;
        padding-top: 11px;
        padding-bottom: 11px;
    }

    .form-auction .form-control:focus {
        box-shadow: none;
    }

    .form-auction .form-control::placeholder {
        color: #B4B4B4;
    }

    button {
        background: none;
        border: none;
        font-weight: 500;
        color: #EE3439;
    }

    .group-button button {
        border: 1px solid #EE3439;
        border-radius: 20px;
        padding: 8px 50px;
        margin-right: 10px;
        margin-top: 10px;
    }

    .group-button button:last-child {
        color: #FFFFFF;
        background: #EE3439;
    }

    .form-check {
        margin: 0 15px 12px 0;
    }

    .form-switch .form-check-input:checked {
        background-color: #EE3439;
        border-color: #EE3439;
    }

    .form-switch .form-check-input:focus {
        box-shadow: none;
    }

    .draft {
        color: #EE3439;
        border: 1px solid #EE3439 !important;
        border-radius: 20px;
        font-weight: 500;
        padding: 4px 38px;
    }

    .sp-min {
        position: absolute;
        right: 30px;
        top: 12px;
        font-weight: 500;
        color: #515151;
    }
</style>

<style>
    .form-auction button.h-auto {
        opacity: 0;
    }

    .form-auction .no-gutters {
        margin: 0;
    }

    .form-auction .b-calendar-grid-help {
        display: none;
    }

    .form-auction .b-calendar .form-control:focus,
    .form-auction .b-form-datepicker.form-control.focus,
    .form-auction .form-control.focus {
        box-shadow: none;
        border: 1px solid #ced4da6e;
    }

    .form-auction .text-truncate {
        padding: 0;
    }

    .form-auction .b-form-datepicker,
    .form-auction .b-form-timepicker {
        background-color: #EEEEEE;
        border-radius: 12px;
        padding: 2px 12px;
    }

    .form-auction .text-muted {
        color: #B4B4B4 !important;
    }

    .form-auction .button-list .dropdown-toggle {
        justify-content: flex-start;
        padding-top: 12.5px;
        padding-bottom: 12.5px;
    }

    .form-auction .button-list .dropdown-toggle:focus-visible {
        box-shadow: none;
    }

    .authen_modal .modal-dialog {
        max-width: 858px !important;
    }

    .authen_modal .modal-width {
        width: 858px;
    }

    .authen_modal button.h-auto {
        opacity: 0;
    }

    .authen_modal .no-gutters {
        margin: 0;
    }

    .authen_modal .b-calendar-grid-help {
        display: none;
    }

    .authen_modal .b-calendar .form-control:focus,
    .authen_modal .b-form-datepicker.form-control.focus,
    .authen_modal .form-control.focus {
        box-shadow: none;
        border: 1px solid #ced4da6e;
    }

    .authen_modal .text-truncate {
        padding: 0;
    }

    .authen_modal .b-form-datepicker,
    .authen_modal .b-form-timepicker {
        background-color: #EEEEEE;
        border-radius: 12px;
        padding: 2px 12px;
    }

    .authen_modal .text-muted {
        color: #B4B4B4 !important;
    }

    .authen_modal .wp-btn button {
        color: #FFF;
        font-size: 16px;
        font-weight: 700;
        background-color: #ee3439;
        border-radius: 32px;
        width: 163px;
        height: 42px;
    }

    .authen_modal .wp-btn button:hover, .authen_modal .wp-btn button:active {
        background-color: #ee3439 !important;
    }

    .authen_modal button.btn-cancel {
        color: #ee3439;
        font-size: 16px;
        font-weight: 700;
        border: 1px solid #EE3439;
        background: #FFF;
    }

    .authen_modal button.btn-cancel:hover {
        color: #EE3439;
        background-color: #fff !important;
        border-color: #EE3439;
    }
</style>