<template>
    <div>
        <b-modal ref="formAddGuest" centered hide-footer hide-header content-class="modal-width" modal-class="modal-add-guest">
        <div class="add-guest-modal text-center" :class="{'overlay' : isLoading}">
            <img src="@/assets/icon/icon_auction.png" alt="">
            <h5 style="margin-top: 10px;">Thêm người vào phòng đấu giá</h5>
            <p>Bạn đang muốn thêm người vào phòng đấu giá , hãy nhập đủ thông tin bên dưới, hệ thống sẽ gửi key cho khách mời vào phòng </p>
            <b-form :model="dataForm">
                <b-form-group
                    label="Họ và tên khách mời"
                    label-for="name"
                >
                    <b-form-input
                    id="name"
                    type="text"
                    placeholder="Nhập họ và tên khách mời"
                    required
                    v-model="dataForm.member_name"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Số điện thoại khách mời"
                    label-for="phone"
                >
                    <b-form-input
                        id="phone"
                        type="text"
                        placeholder="Nhập số điện thoại khách mời"
                        required
                        v-model="dataForm.phone"
                        @input="isValidPhoneNumber(dataForm.phone)"
                        :disabled="phone != '' && phone != null"
                        maxlength="10"
                    ></b-form-input>
                    <span style="font-size: 14px; color: #ee3439; display: block; text-align: left;" v-if="dataForm.phone && !isValidPhoneNumber(dataForm.phone)">Vui lòng nhập số điện thoại hợp lệ </span>
                </b-form-group>
                <b-form-group
                    label="Chức danh"
                    label-for="duty"
                >
                    <b-form-input
                    id="duty"
                    type="text"
                    placeholder="Nhập chức danh"
                    required
                    v-model="dataForm.role_name"
                    ></b-form-input>
                </b-form-group>
                <p v-if="error1" style="font-size: 14px; color: #EE3439; font-weight: 500;">Vui lòng nhập đủ thông tin</p>
                <p class="mt-3" style="font-size: 15px; text-align: left;">Chọn quyền</p>
                <div class="d-flex">
                    <label class="container-label">
                        <h5 style="font-size: 15px; font-weight: 400;">Xem</h5>
                        <input type="checkbox" class="input-style" checked v-model="dataForm.view">
                        <span class="checkmark"></span>
                    </label>
                    <label class="container-label">
                        <h5 style="font-size: 15px; font-weight: 400;">Khiếu nại</h5>
                        <input type="checkbox" class="input-style" v-model="dataForm.complain">
                        <span class="checkmark"></span>
                    </label>
                    <label class="container-label">
                        <h5 style="font-size: 15px; font-weight: 400;">Báo cáo</h5>
                        <input type="checkbox" class="input-style" v-model="dataForm.report">
                        <span class="checkmark"></span>
                    </label>
                    <label class="container-label">
                        <h5 style="font-size: 15px; font-weight: 400;">Xuất file</h5>
                        <input type="checkbox" class="input-style" v-model="dataForm.export">
                        <span class="checkmark"></span>
                    </label>
                </div>
            </b-form>
            <b-button class="mt-3 mr-3 btn-cancel" @click="hideFormAddGuest()" block :disabled="isLoading">Quay lại</b-button>
            <b-button class="mt-3" block @click="handleAdd" :disabled="isLoading">Mời vào phòng</b-button>
        </div>
        <div class="loader" v-if="isLoading"></div>
    </b-modal>

    <b-modal ref="modalAddGuest" centered hide-footer hide-header content-class="modal-width" modal-class="modal-add-guest" @hide="clearEmail">
        <div class="add-guest-modal text-center">
            <img src="@/assets/icon/icon_auction.png" alt="">
            <h5 style="margin-top: 10px;">Thêm người vào phòng đấu giá</h5>
            <p>Bạn đang muốn thêm người vào phòng đấu giá , hãy nhập đủ thông tin bên dưới, hệ thống sẽ gửi key cho khách mời vào phòng </p>
            <b-form v-on:submit.prevent="showFormAdd">
                <b-form-group
                    label="Email khách mời"
                    label-for="email"
                >
                    <b-form-input
                    id="email"
                    type="text"
                    placeholder="Nhập email khách mời"
                    v-model="email"
                    ></b-form-input>
                </b-form-group>
                <p v-if="error" style="font-size: 14px; color: #EE3439; font-weight: 500;">Vui lòng nhập đúng Email</p>
                <b-button class="mt-2 mr-3 btn-cancel" @click="hidemodalAddGuest()" block>Hủy bỏ</b-button>
                <b-button class="mt-2" block @click="showFormAdd">Tiếp theo</b-button>
            </b-form>
        </div>
    </b-modal>

    <b-modal ref="statusInvite" centered hide-footer hide-header content-class="modal-width" modal-class="statusInvite">
        <div class="statusInvite text-center">
            <div v-if="this.inviteStatus == 1">
                <img src="@/assets/icon/Ok.png" alt="">
                <p style="font-weight: 500; margin-top: 15px;" class="success">{{ this.message }}</p>
            </div>
            <div v-else>
                <img src="@/assets/front-end/icon/Group 831.png" alt="">
                <p style="font-weight: 500; margin-top: 15px; color: #EE3439;">{{ this.message }}</p>
            </div>
        </div>
    </b-modal>
    </div>
</template>
<script>
export default {
    data() {
        return {
            email: '',
            error: false,
            error1: false,
            dataForm: {
                member_name: '',
                phone: '',
                role_name: '',
                view: true,
                complain: false,
                report: false,
                export: false,
            },
            member_id: '',
            member_name: '',
            phone: '',
            role: [],
            inviteStatus: '',
            message: '',
            dataEmail: '',
            token: '',
            isLoading: false
        }
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
    },
    methods: {
        validateEmail(email) {
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(email);
        },
        show(){
            this.$refs.modalAddGuest.show()
        },
        hidemodalAddGuest() {
            this.$refs.modalAddGuest.hide()
        },
        hideFormAddGuest() {
            this.$refs.modalAddGuest.show()
            this.$refs.formAddGuest.hide()
        },
        async showFormAdd () {
            this.dataEmail = this.email
            if(this.isValidEmail = this.validateEmail(this.dataEmail)) {
                const data = {
                    "email": this.dataEmail,
                    "auction_id": this.decode(this.$route.params.auction_id)
                }
                await this.$axios.post('/auction/checkEmail', data, {
                    headers: {
                        'x-access-token': this.token,
                    } 
                }).then((res) => {
                    if(res.data.message) {
                        this.message = res.data.message
                        this.$refs.statusInvite.show()
                    }else {
                        this.member_id = res.data.member_id
                        this.member_name = this.dataForm.member_name = res.data.member_name
                        this.phone = this.dataForm.phone = res.data.phone
                        this.$refs.modalAddGuest.hide()
                        this.$refs.formAddGuest.show()
                        this.error = false;
                    }
                })
            } else {
                this.error = true;
            }
        },
        async handleAdd() {
            this.role = []
            if (this.dataForm.view == true) {
                this.role.push(1)
            }
            if (this.dataForm.complain == true) {
                this.role.push(2)
            }
            if (this.dataForm.report == true) {
                this.role.push(3)
            }
            if (this.dataForm.export == true) {
                this.role.push(4)
            }
            const dataInvite = {
                "member_id": this.member_id,
                "email": this.dataEmail,
                "phone": this.dataForm.phone,
                "auction_id": this.decode(this.$route.params.auction_id),
                "role": this.role,
                "role_name": this.dataForm.role_name,
                "member_name": this.dataForm.member_name
            }
            if(this.dataForm.role_name == ''|| this.dataForm.member_name == '' || this.dataForm.phone == '') {
                this.error1 = true;
            } else {
                this.error1 = false;
                this.isLoading = true
                await this.$axios.post('/auction/inviteMember', dataInvite, {
                    headers: {
                        'x-access-token': this.token,
                    } 
                }).then((res) => {
                    this.inviteStatus = res.data.result_status
                    this.message = res.data.message
                    this.$refs.statusInvite.show()
                    this.$refs.formAddGuest.hide()
                    this.emitModalVisible(false)
                    this.isLoading = false
                    if(res.data.result_status == 1) {
                        this.email = ''
                        this.dataForm.member_name = ''
                        this.dataForm.role_name = ''
                        this.role = []
                    }
                })
            }
        },
        emitModalVisible(isClose = false) {
            this.$emit("handleCloseModal", isClose);
        },
        clearEmail () {
            this.email = ''
        }
    }
}
</script>
<style lang="css">
    .modal-add-guest .modal-width {
        max-width: 421px;
    }
    .add-guest-modal label {
        text-align: left;
        width: 100%;
        font-size: 15px;
        color: #515151;
    }

    .add-guest-modal .form-control {
        background-color: #E5E5E5;
        border-radius: 20px;
        height: 44px;
        border: none;
    }

    .add-guest-modal .form-control:focus {
        border: none;
        box-shadow: none;
    }

    .add-guest-modal select.form-control {
        color: #B4B4B4;
    }

    .add-guest-modal .form-control::placeholder {
        color: #B4B4B4;
    }

    .statusInvite .modal-width {
        width: 350px;
    }
</style>
<style lang="css" scoped>
    .container-label {
        color: #515151;
        display: block;
        position: relative;
        padding-left: 25px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
    }

    .container-label input.input-style {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #FFFFFF;
        border: 1px solid #B4B4B4;
        border-radius: 5px;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .container-label input:checked ~ .checkmark:after {
        display: block;
    }

    .container-label .checkmark:after {
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        background-image: url('@/assets/admin/icon/Done_check.png');
    }

    .add-guest-modal {
        color: #444444;
    }

    .add-guest-modal h5 {
        font-size: 21px;
    }
    button {
        background-color: #EE3439;
        color: #ffffff;
        font-weight: 700;
        border-radius: 32px;
        border: none;
        min-width: 185px;
        min-height: 42px;
    }

    button:active,
    button:focus {
        background-color: #EE3439 !important;
    }

    button:hover {
        background-color: #EE3439;
    }

    .btn-cancel {
        color: #EE3439;
        border: 1px solid #EE3439;
        background: #ffffff;
    }

    .btn-cancel:hover,
    .btn-cancel:focus,
    .btn-cancel:active {
        background: #ffffff !important;
        color: #EE3439;
        border: 1px solid #EE3439;
    }
</style>