<template>
    <div class="container-fluid">
        <div class="row menu_sticky">
            <Header/>
        </div>
        <div class="row">
            <div class="col-md-2">
                <Sidebar/>
            </div>
            <div class="col-md-10 content pt-3 pb-3">
                <router-view></router-view>
            </div>
        </div>
        <div class="row">
            <Footer/>
        </div>
    </div>
</template>

<script>
import Header from '@/pages/admin-view/components/header.vue';
import Sidebar from '@/pages/admin-view/components/sidebar.vue';
import Footer from '@/pages/admin-view/components/copyright.vue';

export default {
    components: {
        Header,
        Sidebar,
        Footer
    },
    name: "App",
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || "Admin-DGV";
            }
        },
    },
};
</script>

<style lang="css" scoped>

    .menu_sticky {
        position: sticky;
        top: 0;
        z-index: 100;
    }
    .content {
        background-color: #eeeeee;
        background-image: url(../assets/images/logo_moreinfo.png);
        background-repeat: no-repeat;
        background-position: center;
        min-height: 868px;
    }

    @media (max-width: 1500px) {
        .content{
            width: 100%;
        }
    }
</style>