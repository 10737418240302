<template>
    <div>
        <b-modal ref="modalApprove" centered hide-footer hide-header content-class="modal-width" :modal-class="{'modal-approve' : status == 1}">
        <div class="approve-modal text-center">
            <img src="@/assets/icon/icon_auction.png" alt="">
            <h5 style="margin-top: 10px;" v-if="status == 1">Phê duyệt thành viên tham gia</h5>
            <h5 style="margin-top: 10px;" v-else>Từ chối yêu cầu tham gia đấu giá</h5>
            <p v-if="status == 1">Bạn muốn phê duyệt thành viên <span style="font-weight: 500;">MKH-124568</span> được quyền tham gia đấu giá tài sản ? </p>
            <p v-else>Bạn đã từ chối yêu cầu tham gia đấu giá tài sản của thành viên <span style="font-weight: 500;">MKH-124568</span></p>
            <b-form v-if="status == 0" class="text-left form-denial">
                <p>Lí do từ chối</p>
                    <b-form-group >
                        <b-form-radio-group v-model="selected">
                            <b-form-radio v-model="value_stop" value="Vi phạm quy chế đấu giá">Vi phạm quy chế đấu giá</b-form-radio>
                            <b-form-radio v-model="value_stop" value="Sai thông tin cá nhân">Sai thông tin cá nhân</b-form-radio>
                            <b-form-radio v-model="value_stop" value="Thiếu giấy tờ liên quan">Thiếu giấy tờ liên quan</b-form-radio>
                            <b-form-radio v-model="value_stop" value="Chưa nộp đủ các khoản phí">Chưa nộp đủ các khoản phí</b-form-radio>
                            <b-form-radio v-model="value_stop" value="Không online">Không online</b-form-radio>
                            <b-form-radio v-model="value_stop" value="Lý do khác">Lý do khác</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                    <b-form-textarea
                        placeholder="Nhập lí do"
                        rows="3"
                        max-rows="6"
                    ></b-form-textarea>
            </b-form>
            <b-button class="mt-3 mr-2 btn-cancel" @click="hidemodalApprove()" block>Hủy bỏ</b-button>
            <b-button class="mt-3" block @click="refuseMember" v-if="status == 0">Xác nhận</b-button>
            <b-button class="mt-3" block @click="confirmMember" v-else>Xác nhận</b-button>
        </div>
    </b-modal>
    </div>
</template>
<script>
export default {
    data() {
        return {
            status: '',
            member_id: '',
            member_socket_id: '',
            auction_key: '',
            value_stop: '',
            selected: 'Vi phạm quy chế đấu giá'
        }
    },
    methods: {
        show(status, member_id, member_socket_id, auction_key){
            this.status = status
            this.member_id = member_id
            this.member_socket_id = member_socket_id
            this.auction_key = auction_key
            this.$refs.modalApprove.show()
        },
        hidemodalApprove() {
            this.$refs.modalApprove.hide()
        },
        refuseMember() {
            //SOCKET
            const key = ''
            const token = JSON.parse(localStorage.getItem('auInfo')).accessToken;
            // this.$socket(this.auction_key, key, token).emit("refuseMember", {member_id: this.member_id, member_socket_id: this.member_socket_id})
            this.$refs['modalApprove'].hide()
        },
        confirmMember() {
            //SOCKET
            const key = ''
            const token = JSON.parse(localStorage.getItem('auInfo')).accessToken;
            // this.$socket(this.auction_key, key, token).emit("approveMember", {member_id: this.member_id, member_socket_id: this.member_socket_id})
            this.$refs['modalApprove'].hide()
        },
    }
}
</script>
<style lang="css">
    .modal-approve .modal-width {
        max-width: 373px;
        border-radius: 17.47px;
        box-shadow: 0px 0px 38px 0px #00000040;
    }

    .approve-modal .custom-radio {
        align-items: center;
        display: flex; 
    }

    .approve-modal .custom-control-label {
        margin-left: 10px;
    }

    .approve-modal .form-denial textarea {
        overflow-y: auto !important;
        background: #E6E6E6;
        border-radius: 10px;
        border: 1px solid #CCCCCC;
    }

    .approve-modal .form-denial textarea:focus {
        box-shadow: none;
    }
</style>
<style lang="css" scoped>

    .approve-modal {
        color: #444444;
    }

    .approve-modal h5 {
        font-size: 20px;
        font-weight: 500;
    }

    .approve-modal .form-denial {
        color: #515151;
    }

    .approve-modal .form-denial p {
        margin-bottom: 0;
        font-weight: 500;
    }
    button {
        background-color: #EE3439;
        color: #ffffff;
        font-weight: 700;
        border-radius: 10px;
        border: none;
        min-width: 163px;
        min-height: 42px;
    }

    button:active,
    button:focus {
        background-color: #EE3439 !important;
    }

    button:hover {
        background-color: #EE3439;
    }

    .btn-cancel {
        color: #EE3439;
        border: 1px solid #EE3439;
        background: #ffffff;
    }

    .btn-cancel:hover,
    .btn-cancel:focus,
    .btn-cancel:active {
        background: #ffffff !important;
        color: #EE3439;
        border: 1px solid #EE3439;
    }
</style>