<template>
    <div>
        <b-modal id="" ref="delete-asset" centered hide-footer hide-header modal-class="del-asset" content-class="modal-width">
            <div class="delete-asset text-center">
                <img src="@/assets/admin/icon/warning.png" alt="">
                <div v-if="this.type == 'all'">
                    <h5>Xóa tất cả tài sản</h5>
                    <p>Bạn đang yêu cầu xóa bỏ tất cả tài sản <br/>
                        Sau khi xác nhận xóa bỏ sẽ không thể hoàn tác
                    </p>
                    <b-button class="mt-3 mr-3 btn-cancel" @click="hideModal()" block>Hủy bỏ</b-button>
                    <b-button class="mt-3" block @click="deleteAsset('all')">Xác nhận</b-button>
                </div>
                <div v-else>
                    <h5>Xóa tài sản</h5>
                    <p>Bạn đang yêu cầu xóa bỏ tài sản <br/>
                        Sau khi xác nhận xóa bỏ sẽ không thể hoàn tác
                    </p>
                    <b-button class="mt-3 mr-3 btn-cancel" @click="hideModal()" block>Hủy bỏ</b-button>
                    <b-button class="mt-3" block @click="deleteAsset('one')">Xác nhận</b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            id: '',
            idArr: [],
            type: ''
        }
    },
    computed: {

    },
    methods: {
        show(type, id) {
            this.type = type
            if(type == 'one') {
                this.id = id
            } else {
                id.forEach(element => {
                    this.idArr.push(element.asset_id)
                });
            }
            this.$refs['delete-asset'].show()
        },
        hideModal() {
            this.$refs['delete-asset'].hide()
        },
        emitModalVisible(isClose = false) {
            this.$emit("handleCloseModal", isClose);
        },
        async deleteAsset(type) {
            const storedData = localStorage.getItem('admInfo')
            if (storedData) {
                var info = JSON.parse(storedData)
            }
            if(type == 'one') {
                var data = {
                    "asset_id": [this.id]
                }
            } else {
                var data = {
                    "asset_id": this.idArr
                }
            }
            await this.$axios.put('/asset/deleteAsset', data, {
                headers: {
                    'x-access-token': info.accessToken,
                } 
            }).then(res => {
                this.$toast.success('Xóa tài sản thành công!')
                this.$refs['delete-asset'].hide()
                this.emitModalVisible(false)
                this.idArr = []
            }).catch(err => {
                this.$toast.error('Xóa tài sản không thành công!')
            })
        }
    }
}
</script>

<style>
    .del-asset .modal-width {
        width: 380px;
    }
</style>
<style lang="css" scoped>

    .delete-asset h5,
    .delete-asset p {
        color: #444444;
    }
    .delete-asset button {
        background-color: #EE3439;
        color: #ffffff;
        font-weight: 500;
        border-radius: 10px;
        border: none;
        min-width: 163px;
        min-height: 42px;
    }

    .delete-asset button:active,
    .delete-asset button:focus {
        background-color: #EE3439 !important;
    }

    .delete-asset button:hover {
        background-color: #EE3439;
    }

    .delete-asset .btn-cancel {
        color: #EE3439;
        border: 1px solid #EE3439;
        background: #ffffff;
    }

    .delete-asset .btn-cancel:hover,
    .delete-asset .btn-cancel:focus,
    .delete-asset .btn-cancel:active {
        background: #ffffff !important;
        color: #EE3439;
        border: 1px solid #EE3439;
    }
    @media (max-width: 375px) {
        .delete-asset .btn-cancel {
            margin-right: 0 !important;
        }

        .delete-asset p {
            font-size: 15px;
        }
    }
</style>