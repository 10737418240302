<template>
    <div>
        <b-modal id="" ref="approve-auction" centered hide-footer hide-header modal-class="approve-auction" content-class="modal-width" @hide="clearData">
            <div class="approve-auction text-center" :class="{'overlay' : isLoading}">
                <div v-if="this.type == 'approve'">
                    <img src="@/assets/admin/icon/Ok.png" alt="" class="mb-3">
                    <h5>Phê duyệt đăng cuộc đấu giá thành công</h5>
                    <p>Bạn đã phê duyệt đăng cuộc đấu giá mới <br/>
                        Mã cuộc đấu giá: <span style="font-weight: 500;">{{ code }}</span>
                    </p>
                    <b-button class="mt-3" block @click="hideApprove">Xác nhận</b-button>
                </div>
                
                <div v-else>
                    <img src="@/assets/admin/icon/warning.png" alt="" class="mb-3">
                    <h5>Từ chối yêu cầu phê duyệt cuộc đấu giá</h5>
                    <p>Bạn đã từ chối yêu cầu duyệt đăng cuộc đấu giá mới <br/>
                        Hãy tích chọn hoặc nhập lý do từ chối để gửi thông báo đến nhân viên
                    </p>
                    <b-form class="text-left form-cancel-asset">
                        <p>Lý do từ chối: </p>
                        <b-form-group>
                            <b-form-radio-group v-model="selected" @input="checkDisabled">
                                <b-form-radio value="Tài sản đấu giá không đúng">Tài sản đấu giá không đúng</b-form-radio>
                                <b-form-radio value="Sai thông tin cuộc đấu giá">Sai thông tin cuộc đấu giá</b-form-radio>
                                <b-form-radio value="Thiếu thông tin cá nhân">Thiếu thông tin cá nhân</b-form-radio>
                                <b-form-radio value="Sai chính tả">Sai chính tả</b-form-radio>
                                <b-form-radio value="Lý do khác">Lý do khác</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-textarea
                            placeholder="Nhập lí do"
                            rows="3"
                            max-rows="6"
                            v-model="text_note"
                            :disabled="disabled"
                        ></b-form-textarea>
                        <div class="text-center">
                            <b-button class="mt-3" block @click="rejectAuction" :disabled="isLoading">Xác nhận</b-button>
                        </div>
                    </b-form>
                </div>
            </div>
            <div class="loader" v-if="isLoading"></div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            type: '',
            id: '',
            selected: 'Tài sản đấu giá không đúng',
            text_note: '',
            token: '',
            code: '',
            disabled: true,
            isLoading: false
        }
    },
    methods: {
        show(type, id, token, code) {
            this.type = type
            this.id = id
            this.token = token
            this.code = code
            this.$refs['approve-auction'].show()
        },
        hideModal() {
            this.$refs['approve-auction'].hide()
        },
        checkDisabled(event) {
            if(event == "Lý do khác") {
                this.disabled = false
            } else {
                this.disabled = true
            }
        },
        clearData() {
            this.selected = 'Tài sản đấu giá không đúng'
            this.text_note = ''
        },
        emitModalVisible(isClose = false) {
            this.$emit("handleCloseModal", isClose);
        },
        hideApprove() {
            this.emitModalVisible(false)
            this.$refs['approve-auction'].hide()
        },
        async rejectAuction(){
            this.isLoading = true
            if(this.selected == 'Lý do khác'){
                this.selected = this.text_note
            }
            const data = {
                auction_id: this.id,
                note: this.selected
            }

            await this.$axios.put('/auction/rejectAuction', data, {
                headers: {
                    'x-access-token': this.token,
                } 
            }).then(res => {
                this.$toast.success('Từ chối duyệt thành công!')
                this.emitModalVisible(false)
                this.$refs['approve-auction'].hide()
                this.isLoading = false
            }).catch(err => {
                this.$toast.error('Từ chối duyệt không thành công!')
                this.isLoading = false
            })
        }
    }
}
</script>

<style>
    .approve-auction .modal-width {
        width: 455px;
    }

    .approve-auction .form-cancel-asset .custom-radio {
        display: flex;
    }

    .approve-auction .form-cancel-asset .custom-radio label{
        margin-left: 10px;
    }

    .approve-auction .form-cancel-asset textarea {
        background: #E6E6E6;
        border: 1px solid #CCCCCC;
        border-radius: 10px;
        overflow-y: auto !important;
    }

    .approve-auction .form-cancel-asset textarea:focus {
        box-shadow: none;
    }
</style>
<style lang="css" scoped>

    .approve-auction h5,
    .approve-auction p {
        color: #444444;
    }
    .approve-auction button {
        background-color: #EE3439;
        color: #ffffff;
        font-weight: 500;
        border-radius: 10px;
        border: none;
        min-width: 163px;
        min-height: 42px;
    }

    .approve-auction button:active,
    .approve-auction button:focus {
        background-color: #EE3439 !important;
    }

    .approve-auction button:hover {
        background-color: #EE3439;
    }

    .form-cancel-asset p {
        font-size: 15px;
        font-weight: 500;
    }

    @media (max-width: 375px) {
        .approve-auction .btn-cancel {
            margin-right: 0 !important;
        }

        .approve-auction p {
            font-size: 15px;
        }
    }
</style>