<template>
    <div v-if="dataNews">
        <b-container class="news-detail">
            <b-breadcrumb :items="items"></b-breadcrumb>
            <b-row>
                <h5 class="title">{{ dataNews.title }}</h5>
                <b-col cols="xl-9">
                    <div v-html=" dataNews.description"></div>
                </b-col>
                <b-col cols="xl-3">
                    <h5 class="newsmore">Tin tức và thông báo khác</h5>
                    <div class="respon-new d-flex flex-wrap">
                        <div v-for="item in listNews.result" 
                        class="mb-2 d-flex justify-content-center col-xxl-12 col-md-6 col-12">
                            <cardNews :item="item"/>
                        </div>
                    </div>
                    <!-- <h5 class="newsmore">Quảng cáo</h5> -->
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import Pagination from "../web-view/components/paginate.vue";
import cardNews from "./components/card-news.vue";
export default {
    components: {
        Pagination,
        cardNews
    },
    data() {
        return {
            items: [
                {
                    text: 'Trang chủ',
                    to: '/',
                },
                {
                    text: 'Tin tức',
                    to: '/news',
                },
                {
                    text: '',
                    active: true
                },
            ],
            dataNews:{},
            params:{
                page:1,
                per_page:2
            },
            listNews:{},
        }
    },
    mounted(){
        this.getNewsDetail(this.$route.params.news_id)
        this.getListNews(this.params)
    },
    watch: {
        "$route.params": function (url) {
            this.getNewsDetail(url.news_id)
        }
    },
    methods:{
        async getNewsDetail(news_id) {
            await this.$axios.get('/news/getNewsDetail?news_id=' + news_id, {
                headers: {
                    'x-access-token': ''
                }
            }).then((res) => {
                this.dataNews = res.data
                this.items[2].text = res.data.title;
            }).catch((err) => {
                this.checkAuthenticate(err)
            })
        },
    }
}
</script>
<style lang="css" scoped>
.news-detail {
    color: #515151;
    font-family: Roboto;
    margin-bottom: 30px;
}

.news-detail .title {
    font-size: 24px;
    line-height: 30px;
    font-family: Libre Bodoni;
    padding-bottom: 16px;
    border-bottom: 1px solid #E1E1E1;
    margin-bottom: 11px;
}

.news-detail .news_ttl {
    font-size: 32px;
    font-weight: 600;
    line-height: 57.6px;
    text-transform: uppercase;
    margin: 0;
}

.news-detail .news_txt {
    font-size: 24px;
    font-weight: 500;
    line-height: 43.2px;
    text-transform: uppercase;
    margin-bottom: 6px;
}

.news-detail .text {
    text-align: justify;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 36px;
    font-weight: 400;
}

.news-detail .text-center {
    background-image: url('@/assets/images/logo_moreinfo.png');
    background-repeat: no-repeat;
    background-position: top;
}

.news-detail .card {
    border: none;
}

.news-detail .card-body {
    padding: 0;
    margin: 0 0 30px 15px;
    background-color: #F8F8F8;
    border-radius: 10px;
    padding-bottom: 15px;
}

.news-detail .card-body .card-text {
    font-size: 18px;
    font-weight: 600;
    line-height: 32.4px;
    padding: 8px;
    margin: 0;
}

.news-detail .card-body .text-detail {
    font-size: 14.05px;
    font-weight: 400;
    line-height: 25.29px;
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.news-detail .card-body .btn {
    background: none;
    color: #515151;
    border-color: #515151;
    margin-left: 20px;
    border-radius: 20px;
    padding: 5px 28px;
}

.newsmore {
    color: #515151;
    font-family: Libre Bodoni;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 16px;
}
@media (max-width: 1024px) {
    .news-detail .news_img {
        width: 100%;
    }

    .news-detail .card-body .card-text {
        font-size: 16px;
        line-height: 22.4px;
    }

    .newsmore {
        font-size: 18px;
    }
}

@media (max-width: 768px) {
    [class*="col-"] {
        width: 100%;
    }

    .news-detail .card-body,
    .newsmore {
        margin-left: 0;
    }

    .newsmore {
        margin-top: 40px;
        font-size: 23px;
        font-weight: 600;
    }

    .respon-new {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

}

@media (max-width: 425px) {
    .news-detail .news_ttl {
        font-size: 22px;
    }

    .news-detail .news_txt {
        font-size: 18px;
    }

    .news-detail .text {
        font-size: 16px;
    }

    .newsmore {
        font-size: 20px;
    }
    .respon-new {
        display: block;
    }

    .respon-new > div {
        width: 100%;
    }
}
</style>

<style>
    .ql-align-center {
        text-align: center;
    }

    .ql-align-right {
        text-align: right;
    }

    .ql-align-justify {
        text-align: justify;
    }
</style>