<template>
    <div>
        <div class="about-us">
            <b-container class="bg-2">
                <b-row>
                    <b-col cols="md-6 flex-colum justify-content-center align-items-center">
                        <img class="logo" src="@/assets/images/Logo.png" alt="">
                        <p class="quote">Lấy chữ tín làm đầu , sự hài lòng, tín nhiệm của quý khách hàng chính là sự thành
                            công của chúng tôi .</p>
                    </b-col>
                    <b-col cols="md-6 mt-3 mb-5">
                        <b-card class="mt-3 mb-5">
                            <b-card-text>
                                <h3>VỀ CHÚNG TÔI</h3>
                                <div v-html="infomation.infomation.value"></div>
                            </b-card-text>
                            <div class="d-flex justify-content-between">
                                <b-button to="/detail/company_introduction" class="viewmore">Xem thêm</b-button>
                                <p class="sign text-center">{{ infomation.position.value }}<span class="sign--name">{{ infomation.repre.value }}</span></p>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            infomation: {}
        }
    },
    created() {
        this.infomation = this.getInformation()
    }
}
</script>
<style lang="css">
.about-us {
    background-image: url('@/assets/images/Vector.png');
    background-repeat: no-repeat;
    background-color: #080808;
    background-position-x: right;
    text-align: center;
    font-family: Roboto;
    background-position-y: top;
    min-height: 90vh;
    display: flex;
    align-items: center;
}
.flex-colum{
    display: flex;
    flex-direction: column;
}
.about-us .bg-2 {
    background-image: url('@/assets/images/vòng\ nguyệt\ quế\ 1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: top;
}

.about-us .card {
    background: rgba(1, 1, 1, 0.5);
    color: #FFFFFF;
    border-radius: 24px;
    text-align: left;
    font-size: 18px;
    line-height: 21.09px;
    font-weight: 500;
}

.about-us .card-body {
    padding: 25px 32px 40px;
}

.about-us .card-text {
    font-weight: 400;
    text-align: justify;
    margin-bottom: 45px
}

.about-us .card-text h3 {
    margin-bottom: 26px;
    font-weight: 700;
}

.about-us .quote {
    color: #9E9E9E;
    max-width: 555px;
    margin: 32px auto 0;
    font-size: 19.02px;
    line-height: 22.29px;
}

.about-us .viewmore {
    background: none;
    border-radius: 30px;
    padding-left: 25px;
    padding-right: 25px;
    max-height: 41px;
    font-weight: 500;
}

.about-us .viewmore:hover,
.about-us .viewmore:active {
    background-color: unset !important;
}

.about-us .sign--name {
    display: block;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .about-us .bg-2 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .about-us .logo {
        max-width: 275px;
    }

    .about-us .card {
        font-size: 15px;
        line-height: 20.09px;
    }

    .about-us .card-body {
        padding: 15px;
    }
}

@media (max-width: 425px) {
    .about-us {
        background-image: url('@/assets/images/Vector\ mobile.png');
        background-repeat: no-repeat;
        background-position: top;
        background-size: 100%;
    }

    .about-us .bg-2 {
        padding-bottom: 0;
        background-position: top;
    }

    .about-us .card {
        font-size: 14px;
        line-height: 19.09px;
        margin-top: 30px;
    }
}
</style>