import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loggedIn: false, 
    userInfo: null,
    auInfo: null,
		admInfo: null
  },
  mutations: {
    login(state, userInfo) {
      state.loggedIn = true;
      state.userInfo = userInfo;
    },
    logout(state) {
      state.loggedIn = false;
      state.userInfo = null;
      localStorage.removeItem('userInfo');
    },
    loginAu( state, auInfo) {
      state.auInfo = auInfo;
    },
    logoutAu(state) {
      state.auInfo = null;
      localStorage.removeItem('auInfo');
    },
    loginAdm(state, admInfo) {
      state.admInfo = admInfo;
    },
    logoutAdm(state) {
      state.admInfo = null;
      localStorage.removeItem('admInfo');
    },
  },
  actions: {
    loginUser({ commit }, userInfo) {
      commit('login', userInfo);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    },
    logoutUser({ commit }) {
      commit('logout');
    },
    loginAu({ commit }, auInfo) {
      commit('loginAu', auInfo);
      localStorage.setItem('auInfo', JSON.stringify(auInfo));
    },
    logoutAu({ commit }) {
      commit('logoutAu');
    },
    loginAdm({ commit }, admInfo) {
      commit('loginAdm', admInfo);
      localStorage.setItem('admInfo', JSON.stringify(admInfo));
    },
    logoutAdm({ commit }) {
      commit('logoutAdm');
    },
  },
  getters: {
    isLoggedIn: state => {
      // Kiểm tra trạng thái từ Vuex
      if (state.loggedIn) {
        return true;
      }
      
      // Kiểm tra dữ liệu từ localStorage
      const userInfo = localStorage.getItem('userInfo');
      return !!userInfo;
    },
    getUserInfo: state => state.userInfo
  }
});