<template>
    <div>
        <b-modal id="" ref="approve-cus" centered hide-footer hide-header modal-class="approve-cus" content-class="modal-width">
            <div class="approve-cus text-center">
                <div v-if="this.type == 'approve'">
                    <img src="@/assets/admin/icon/Ok.png" alt="" class="mb-3">
                    <h5>Phê duyệt thành công</h5>
                    <p>Bạn đã phê duyệt thành công khách hàng mới <br/>
                        <span style="font-weight: 500;">{{ this.code }}</span>
                    </p>
                    <b-button class="mt-3" block @click="hideModal">Xác nhận</b-button>
                </div>
                
                <div v-else>
                    <img src="@/assets/admin/icon/warning.png" alt="" class="mb-3">
                    <h5>Từ chối yêu cầu phê duyệt</h5>
                    <p>Bạn đã từ chối yêu cầu duyệt khách hàng mới <br/>
                        Hãy tích chọn hoặc nhập lý do từ chối để gửi thông báo đến khách hàng
                    </p>
                    <b-form class="text-left form-cancel-asset" @submit="rejectMember">
                        <p>Lý do từ chối: </p>
                        <b-form-group >
                            <b-form-radio-group v-model="selected">
                                <b-form-radio value="Thông tin nhập không giống trong CCCD/HC">Thông tin nhập không giống trong CCCD/HC</b-form-radio>
                                <b-form-radio value="Sai thông tin">Sai thông tin</b-form-radio>
                                <b-form-radio value="Thiếu giấy tờ">Thiếu giấy tờ</b-form-radio>
                                <b-form-radio value="Nhập thiếu thông tin">Nhập thiếu thông tin</b-form-radio>
                                <b-form-radio value="Đăng ảnh đại diện không phù hợp">Đăng ảnh đại diện không phù hợp</b-form-radio>
                                <b-form-radio value="Lý do khác" @input="submitError">Lý do khác</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-textarea
                            placeholder="Nhập lí do"
                            rows="3"
                            max-rows="6"
                            style="overflow-y: auto !important;"
                            v-model="text"
                            :disabled="isDisabled"
                            required
                        ></b-form-textarea>
                        <div class="text-center">
                            <b-button class="mt-3" block type="submit">Xác nhận</b-button>
                        </div>
                    </b-form>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            type: '',
            selected: 'Thông tin nhập không giống trong CCCD/HC',
            id: '',
            code: '',
            text: '',
            isDisabled: true,
        }
    },
    computed: {

    },
    methods: {
        show(type, id, code) {
            this.type = type
            this.id = id
            this.code = code
            this.$refs['approve-cus'].show()
        },
        submitError(event) {
            if(event == "Lý do khác"){
                this.isDisabled = false
            } else {
                this.isDisabled = true
            }
            this.text = ''
        },
        hideModal() {
            this.$refs['approve-cus'].hide()
        },
        emitModalVisible(isClose = false) {
            this.$emit("handleCloseModal", isClose);
        },
        async rejectMember() {
            this.emitModalVisible(true)
            this.$refs['approve-cus'].hide()
            event.preventDefault()
            if(this.selected == "Lý do khác") {
                var data = {
                    'member_id': this.id,
                    'note': this.text
                }
            } else {
                var data = {
                    'member_id': this.id,
                    'note': this.selected
                }
            }

            const storedData = localStorage.getItem('admInfo')
            if (storedData) {
                var info = JSON.parse(storedData)
            }

            await this.$axios.put('/member/rejectMember', data, {
                headers: {
                    'x-access-token': info.accessToken
                } 
            }).then((res) => {
                this.emitModalVisible(false)
                this.$toast.success('Từ chối duyệt thành công!')
            }).catch((err) => {
                this.$toast.error('Từ chối duyệt không thành công!')
            })
        }
    }
}
</script>

<style>
    .approve-cus .modal-width {
        width: 455px;
    }

    .approve-cus .form-cancel-asset .custom-radio {
        display: flex;
    }

    .approve-cus .form-cancel-asset .custom-radio label{
        margin-left: 10px;
    }

    .approve-cus .form-cancel-asset textarea {
        background: #E6E6E6;
        border: 1px solid #CCCCCC;
        border-radius: 10px;
    }

    .approve-cus .form-cancel-asset textarea:focus {
        box-shadow: none;
    }
</style>
<style lang="css" scoped>

    .approve-cus h5,
    .approve-cus p {
        color: #444444;
    }
    .approve-cus button {
        background-color: #EE3439;
        color: #ffffff;
        font-weight: 500;
        border-radius: 10px;
        border: none;
        min-width: 163px;
        min-height: 42px;
    }

    .approve-cus button:active,
    .approve-cus button:focus {
        background-color: #EE3439 !important;
    }

    .approve-cus button:hover {
        background-color: #EE3439;
    }

    .form-cancel-asset p {
        font-size: 15px;
        font-weight: 500;
    }

    @media (max-width: 375px) {
        .approve-cus .btn-cancel {
            margin-right: 0 !important;
        }

        .approve-cus p {
            font-size: 15px;
        }
    }
</style>