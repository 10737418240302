<template>
    <div>
        <div class="list_cus_new container mt-4">
            <h5 style="font-weight: 700; font-size: 22px; color: #515151; margin-bottom: 20px; font-family: Libre Bodoni;" >Duyệt yêu cầu ủy quyền tham gia đấu giá</h5>
            <b-row>
                <b-col cols="lg-5">
                    <div class="list">
                        <h5 class="mb-3">Danh sách yêu cầu ủy quyền</h5>
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex">
                                <b-form class="search-form" style="width: 100%;">
                                    <b-form-group>
                                        <b-form-input v-model="keySearch" type="text" placeholder="Tìm kiếm" required></b-form-input>
                                    </b-form-group>
                                    <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                                </b-form>
                                <!-- <button><img src="@/assets/admin/icon/Adjust.png" alt=""></button> -->
                            </div>
                        </div>
                        <div class="cus-item" v-for="(item, index) in filteredItems" :key="index" style="cursor: pointer;" @click="getDetail(item.member_id, item.authorized_person)">
                            <div class="cus-info d-flex">
                                <img :src="$storage + item.avatar" alt="avatar" style="width: 70px; height: 70px; border-radius: 36px; margin-right: 15px;" @error="handleError">
                                <div>
                                    <p style="font-size: 18px; font-weight: 500; min-height: 21px;">
                                        <span v-if="item.type == 1">{{ item.member_name }}</span>
                                        <span v-else>{{ item.rep_name }}</span>
                                    </p>
                                    <p>Mã khách hàng: <span>{{ item.member_code }}</span></p>
                                    <p v-if="item.type == 2">Tổ chức: <span>{{ item.member_name }}</span></p>
                                    <p v-else>Tài khoản cá nhân</p>
                                </div>
                            </div>
                        </div>
                        <!-- <Paginate :page=page @set-page="setPage" style="margin-top: 10px;"/> -->
                    </div>
                </b-col>
                <b-col cols="lg-7" v-if="showDetail">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h5>Thông tin khách hàng yêu cầu ủy quyền</h5>
                        <button class="btn-accept" @click="approveMemberAuthorization(authorized_person, au_id)">Phê duyệt ủy quyền</button>
                    </div>
                    <div class="detail-cus" v-for="(item, index) in filteredItems" :key="index" v-if="item.member_id == au_id" style="margin-bottom: 20px;">
                        <b-row>
                            <b-col cols="lg-4">
                                <div class="text-center">
                                    <img :src="$storage + item.avatar" @error="handleError" alt="avatar" style=" border-radius: 36px;" class="mb-2">
                                    <p class="mb-0" style="font-size: 13px;">Người ủy quyền</p>
                                    <p style="font-weight: 500;">{{ item.member_code }}</p>
                                </div>
                                <div class="file">
                                    <b-link :href="$storage + item.authorization_file" target="_blank" v-if="item.authorization_file !== ''">Giấy tờ ủy quyền</b-link>
                                </div>
                            </b-col>
                            <b-col cols="lg-8">
                                <p style="font-size: 18px; font-weight: 600;">Thông tin cá nhân</p>
                                <div class="info"><p>Họ và tên </p>
                                    <p v-if="item.type == 1">{{ item.member_name }}</p>
                                    <p v-else>{{ item.rep_name }}</p>
                                </div>
                                <div class="info"><p>Loại tài khoản </p>
                                    <p v-if="item.type == 2">Tổ chức</p>
                                    <p v-else>Cá nhân</p>
                                </div>
                                <div class="info"><p>Số điện thoại </p><p>{{ item.phone }}</p></div>
                                <div class="info"><p>Email </p><p>{{ item.email }}</p></div>
                                <div class="info"><p>Số CMT / Thẻ căn cước / Hộ chiếu</p><p>{{ item.id_number }}</p></div>
                                <div v-if="item.type == 2">
                                    <p style="font-size: 18px; font-weight: 600;">Thông tin doanh nghiệp</p>
                                    <div class="info"><p>Tên doanh nghiệp</p><p>{{ item.member_name }}</p></div>
                                    <div class="info"><p>Mã số thuế</p><p>{{ item.tax_code }}</p></div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="detail-cus">
                        <b-row>
                            <b-col cols="lg-4">
                                <div class="text-center">
                                    <img :src="$storage + dataDetail.avatar" @error="handleError" alt="avatar" style=" border-radius: 36px;" class="mb-2">
                                    <p class="mb-0" style="font-size: 13px;">Người được ủy quyền</p>
                                    <p style="font-weight: 500;">{{ dataDetail.member_code }}</p>
                                </div>
                            </b-col>
                            <b-col cols="lg-8">
                                <p style="font-size: 18px; font-weight: 600;">Thông tin cá nhân</p>
                                <div class="info"><p>Họ và tên </p>
                                    <p v-if="dataDetail.type == 1">{{ dataDetail.member_name }}</p>
                                    <p v-else>{{ dataDetail.rep_name }}</p>
                                </div>
                                <div class="info"><p>Loại tài khoản </p>
                                    <p v-if="dataDetail.type == 2">Tổ chức</p>
                                    <p v-else>Cá nhân</p>
                                </div>
                                <div class="info"><p>Số điện thoại </p><p>{{ dataDetail.phone }}</p></div>
                                <div class="info"><p>Email </p><p>{{ dataDetail.email }}</p></div>
                                <div class="info"><p>Số CMT / Thẻ căn cước / Hộ chiếu</p><p>{{ dataDetail.id_number }}</p></div>
                                <div v-if="dataDetail.type == 2">
                                    <p style="font-size: 18px; font-weight: 600;">Thông tin doanh nghiệp</p>
                                    <div class="info"><p>Tên doanh nghiệp</p><p>{{ dataDetail.member_name }}</p></div>
                                    <div class="info"><p>Mã số thuế</p><p>{{ dataDetail.tax_code }}</p></div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
   
</template>
<script>
import Paginate from '../../../web-view/components/paginate.vue'
export default {
    components: {
        Paginate
    },
    data() {
        return {
            keySearch: '',
            dataCustomer: [],
            dataDetail: [],
            showDetail: false,
            token: '',
            page: {
                totalData: 0,
                totalPage: 0,
                currentPage: this.$route.query.page || 1,
                perPage: 1000,
                classPrev: 'd-none',
                classNext: 'd-none'
            },
            member_required_file_status: [],
            checkDisabled: [],
            au_id: '',
            authorized_person: ''
        }
    },
    computed: {
        filteredItems() {
            return this.dataCustomer.filter(member => {
                const name = member.member_name ? member.member_name.toLowerCase() : member.rep_name.toLowerCase();
                return name.includes(this.keySearch.toLowerCase());
            });
        }
    },
    mounted() {
        const storedData = localStorage.getItem('auInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
            this.id = info.id
        }
        this.getData()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.showDetail = false
            this.getData();
        },
        formateTime(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY")
        },
        async getDetail(authen_id, member_id) {
            this.au_id = authen_id
            this.authorized_person = member_id
            this.showDetail = true
            const res = await this.$axios.get('/member/getMemberDetail', {
                params: {
                    member_id: member_id
                },
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.dataDetail = res.data
            }
        },
        async getData() {
            const res = await this.$axios.get('/auction/getListMemberAuthorityAuction', {
                params: {
                    auction_id: this.decode(this.$route.params.auction_id),
                    page: this.page.currentPage,
                    per_page: this.page.perPage,
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.page.totalData = res.data.pagination.total_element
                this.page.totalPage = res.data.pagination.total_page
                this.dataCustomer = res.data.re
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAuctioneer')
                } else if (err.response.status == 401) {
                    localStorage.removeItem('auInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAuctioneer')
                }
            })
        },
        async approveMemberAuthorization(id, au_id) {
            const data = {
                auction_id: this.decode(this.$route.params.auction_id),
                authorized_person: id,
                member_id: au_id
            }
            await this.$axios.post('/auction/approveMemberAuthorization', data, {
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.$toast.success('Phê duyệt thành công!')
                this.showDetail = false
                this.getData();
            }).catch(err => {
                this.$toast.error('Phê duyệt không thành công!')
            })
        }
    }
}
</script>
<style lang="css" scoped>

    .list_cus_new {
        background-image: url('@/assets/images/logo_moreinfo.png');
        background-repeat: no-repeat;
        background-position: center;
        min-height: 868px;
    }
    .list, .detail-cus {
        box-shadow: 2px 2px 6px 0px #00000026;
        background: #FFFFFF;
        padding: 10px 19px 24px 14px;
        color: #515151;
    }

    .list {
        border-radius: 15px;
    }

    .detail-cus {
        border-radius: 20px;
    }

    button {
        background: none;
        border: none;
    }

    .btn-approve {
        font-size: 15px;
        font-weight: 500;
        color: #EE3439;
        border: 1px solid #EE3439;
        border-radius: 20px;
        max-height: 32px;
        margin-left: 20px;
        padding: 10px 20px;
        align-items: center;
        display: flex;
    }

    .btn-accept {
        font-size: 15px;
        font-weight: 500;
        color: #FFFFFF;
        background-color: #EE3439;
        border-radius: 20px;
        padding: 4px 29px;
    }

    .list-access p {
        font-weight: 500;
        margin-bottom: 10px;
    }

    .cus-item {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #B4B4B4;
        border-radius: 10px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }

    .cus-item p {
        margin: 0;
        font-size: 13px;
    }

    .cus-item span {
        font-size: 14px;
        font-weight: 500;
    }

    .btn-del img {
        width: 15px;
        height: 15px;
        filter: grayscale(1);
        margin-right: 20px;
    }

    .detail-cus h5 {
        font-size: 20px;
        font-weight: 500;
    }

    .file p {
        font-size: 14px;
        font-weight: 500;
    }

    .file a {
        position: relative;
        display: block;
        color: #515151;
        font-size: 14px;
        margin-bottom: 10px;
        padding-left: 30px;
    }

    .file a::before {
        position: absolute;
        content: '';
        background-image: url('@/assets/icon/Note.png');
        width: 25px;
        height: 25px;
        left: 0;
    }

    .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .info p {
        font-size: 14px;
        color: #757575;
    }

    .info p:last-child {
        font-weight: 500;
        font-size: 15px;
        color: #515151;
        text-align: right;
        width: 50%;
    }

    .container-label {
        color: #515151;
        display: block;
        position: relative;
        padding-left: 25px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
    }

    .container-label input.input-style {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #FFFFFF;
        border: 1px solid #B4B4B4;
        border-radius: 5px;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .container-label input:checked ~ .checkmark:after {
        display: block;
    }

    .container-label .checkmark:after {
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        background-image: url('@/assets/admin/icon/Done_check.png');
    }

    .btn-confirm {
        background: #EE3439;
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 16.019px;
        width: 140px;
        height: 32px;
    }

    @media (max-width: 992px) {
        .detail-cus {
            margin-top: 20px;
        }
    }
</style>

<style>
    @media (max-width:425px) {
        .list .search-form {
            width: 100%;
        }
    }
</style>