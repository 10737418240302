<template>
    <div>
        <b-modal id="" ref="editCate" centered hide-footer hide-header modal-class="wp-edit-cate" content-class="modal-width" @hide="clearForm">
            <div class="edit-cate">
                <h5>Chỉnh sửa danh mục</h5>
                <b-form @submit="editCategory">
                    <p style="font-weight: 500;">Ảnh đại diện danh mục</p>
                    <div class="d-flex mb-3">
                        <div>
                            <img :src="this.imgShow" v-if="this.imgShow" style="max-width: 95px; border-radius: 10px; margin-right: 20px; cursor: pointer;" onclick="$('#file_img').click()">
                            <img :src="$storage + dataForm.image" alt="" style="max-width: 95px; border-radius: 10px; margin-right: 20px; cursor: pointer;" onclick="$('#file_img').click()" v-else @error="handleError">
                            <span style="display: block; font-size: 13px; color: #515151; text-align: center;">Web 2.5/1</span>
                            <p style="font-size: 14px; font-weight: 500; color: #EE3439;" v-if="errorImg">Vui lòng thêm hình ảnh danh mục</p>
                            <b-form-file class="mt-3 d-none" accept="image/png, image/gif, image/jpeg, image/jpg" id="file_img" ref="fileInput" @change="handleImgChange('image')"></b-form-file>
                        </div>

                        <div class="ml-5">
                            <img :src="this.imgMobShow" v-if="this.imgMobShow" style="max-width: 95px; border-radius: 10px; margin-right: 20px; cursor: pointer;" onclick="$('#file_mobile').click()">
                            <img :src="$storage + dataForm.mobile_image" alt="" style="max-width: 95px; border-radius: 10px; margin-right: 20px; cursor: pointer;" onclick="$('#file_mobile').click()" v-else @error="handleError">
                            <span style="display: block; font-size: 13px; color: #515151; text-align: center;">App 0.7/1</span>
                            <p style="font-size: 14px; font-weight: 500; color: #EE3439;" v-if="errorImg">Vui lòng thêm hình ảnh danh mục</p>
                            <b-form-file class="mt-3 d-none" accept="image/png, image/gif, image/jpeg, image/jpg" id="file_mobile" ref="fileInput" @change="handleImgChange('mobile')"></b-form-file>
                        </div>
                    </div>

                    <p style="font-weight: 500;">Icon hiển thị</p>
                    <img :src="this.icoShow" v-if="this.icoShow" class="mb-3" style="max-width: 56px; height: 56px; border-radius: 10px; margin-right: 20px; cursor: pointer;" onclick="$('#file_ico').click()">
                    <img :src="$storage + dataForm.icon" alt="" class="mb-3" style="cursor: pointer; width: 56px; height: 56px;" onclick="$('#file_ico').click()" v-else @error="handleError">
                    <p style="font-size: 14px; font-weight: 500; color: #EE3439;" v-if="errorIco">Vui lòng thêm icon danh mục</p>
                    <b-form-file class="mt-3 d-none" accept="image/png, image/gif, image/jpeg, image/jpg" id="file_ico" ref="fileInput" @change="handleImgChange('icon')"></b-form-file>

                    <label for="name" class="mb-2">Tên danh mục</label>
                    <b-form-input id="name" placeholder="Nhập tên danh mục" required v-model="dataForm.name"></b-form-input>
                    <div class="text-right">
                        <b-button class="mt-3 mr-3 btn-cancel" type="button" @click="hideModal()" block>Hủy bỏ</b-button>
                        <b-button class="mt-3" block type="submit">Sửa danh mục</b-button>
                    </div>
                </b-form>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgShow: '',
            img: '',
            imgMobShow: '',
            imgMob: '',
            icoShow: '',
            ico: '',
            errorImg: false,
            errorIco: false,
            dataForm: {
                image: '',
                mobile_image: '',
                icon: '',
                name: '',
                parent_id: '',
                category_id: '',
            },
            token: ''
        }
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
    },
    methods: {
        show(data) {
            this.dataForm.image = data.image
            this.dataForm.icon = data.icon
            this.dataForm.name = data.name
            this.dataForm.mobile_image = data.mobile_image
            this.dataForm.category_id = data.category_id
            this.$refs['editCate'].show()
        },
        hideModal() {
            this.$refs['editCate'].hide()
        },
        clearForm() {
            this.imgShow = ''
            this.imgMobShow = ''
            this.icoShow = ''
            this.dataForm.name = ''
        },
        emitModalVisible(isClose = false) {
            this.$emit("handleCloseModal", isClose);
        },
        async handleImgChange(type) {
            const file = event.target.files[0];
            if (file && type == 'image') {
                this.img = file;
                const reader = new FileReader();
                reader.onload = () => {
                    this.imgShow = reader.result
                };
                reader.readAsDataURL(file);
                this.upload('image')
            } else if(file && type == 'icon') {
                this.ico = file;
                const reader = new FileReader();
                reader.onload = () => {
                    this.icoShow = reader.result
                };
                reader.readAsDataURL(file);
                this.upload('icon')
            } else {
                this.imgMob = file;
                const reader = new FileReader();
                reader.onload = () => {
                    this.imgMobShow = reader.result
                };
                reader.readAsDataURL(file);
                this.upload('mobile')
            }
        },
        async upload(type) {
            if(type == 'image') {
                var data = {
                    'file': this.img,
                    'path': 'category/image/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    } 
                }).then(res => {
                    this.dataForm.image = res.data.list_result[0].path
                })
            }else if (type == 'mobile') {
                var data = {
                    'file': this.imgMob,
                    'path': 'category/image/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    } 
                }).then(res => {
                    this.dataForm.mobile_image = res.data.list_result[0].path
                })
            } else {
                var data = {
                    'file': this.ico,
                    'path': 'category/icon/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    } 
                }).then(res => {
                    this.dataForm.icon = res.data.list_result[0].path
                })
            }
        },
        async editCategory() {
            event.preventDefault();
            if(this.dataForm.image == "") {
                this.errorImg = true
            } else if(this.dataForm.icon == "") {
                this.errorIco = true
            } else {
                this.errorImg = false
                this.errorIco = false
                await this.$axios.put('/category/updateCategory', this.dataForm, {
                    headers: {
                        'x-access-token': this.token,
                    } 
                }).then(res => {
                    this.$toast.success('Sửa danh mục thành công!')
                    this.$refs['editCate'].hide()
                    this.emitModalVisible(false)
                }).catch(err => {
                    this.$toast.error('Sửa danh mục không thành công!')
                })
            }
        }
    }
}
</script>

<style>
    .wp-edit-cate .modal-width {
        width: 582px;
        border-radius: 20px;
    }
</style>
<style lang="css" scoped>
    .edit-cate {
        color: #515151;
    }
    .edit-cate h5 {
        font-size: 22px;
        margin-bottom: 15px;
    }

    label {
        font-weight: 500;
    }
    input {
        background-color: #E1E1E1;
        border-radius: 20px;
        padding: 10px 0.75rem;
        border: none;
    }

    input:focus {
        background-color: #E1E1E1;
        box-shadow: none;
        border: none;
    }

    input::placeholder {
        color: #929292;
    }

    button {
        background-color: #EE3439;
        border: none;
        border-radius: 10px;
        padding: 8px 43px;
        font-weight: 500;
    }

    button:hover,
    button:active {
        background: #EE3439 !important;
    }

    button.btn-cancel {
        border: 1px solid #EE3439;
        background: #FFFFFF;
        color: #EE3439;
    }

    button.btn-cancel:hover {
        border: 1px solid #EE3439;
        background: #FFFFFF !important;
        color: #EE3439;
    }
</style>