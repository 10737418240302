<template>
    <span class="warning" style="font-weight: 600;font-size: 20px; display: block; margin: 0;">
        {{ countdown }}
    </span>
</template>
<script>
export default {
    props: ['duration'],
    data() {
        return {
            timeLeft: 0
        }
    },
    mounted() {
        this.setTime()
    },
    computed: {
        countdown() {
            return this.timeLeft ? this.formatTime(this.timeLeft) : '00:00:00';
        },
    },
    methods: {
        setTime() {
            let startTime = this.$moment.utc(this.duration).format("x")
            let now = this.$moment.utc().format("x")
            let countdown = startTime - now
            if (countdown - 25200000 !== 0 && countdown - 25200000 > 0) {
                this.timeLeft = parseInt(countdown);
                this.startCountdown(this.timeLeft);
            }
        }
    }
}
</script>