<template>
    <div>
        <b-modal id="" ref="addAtuh" centered hide-footer hide-header modal-class="wp-add-auth" content-class="modal-width" @hide="clearData">
            <div class="add-auth" :class="{'overlay' : isLoading}">
                <h5>Thêm phân quyền mới</h5>
                <b-form @submit="createRole">
                    <label for="role_name">Tên phân quyền</label>
                    <b-form-input id="role_name" v-model="dataForm.role_name" placeholder="Nhập tên phân quyền" required @input="formatTxt('role_name')"></b-form-input>
                    <div class="text-right">
                        <b-button class="mt-3 mr-3 btn-cancel" @click="hideModal()" block :disabled="isLoading">Hủy bỏ</b-button>
                        <b-button class="mt-3" block type="submit" :disabled="isLoading">Thêm phân quyền</b-button>
                    </div>
                </b-form>
            </div>
            <div class="loader" v-if="isLoading"></div>
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dataForm: {
                role_name: ''
            },
            token: '',
            isLoading: false
        }
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
    },
    methods: {
        show() {
            this.$refs['addAtuh'].show()
        },
        hideModal() {
            this.$refs['addAtuh'].hide()
        },
        clearData() {
            this.dataForm = {
                role_name : ''
            }
        },
        emitModalVisible(isClose = false) {
            this.$emit("handleCloseModal", isClose);
        },
        async createRole() {
            event.preventDefault();
            this.isLoading = true
            await this.$axios.post('/permission/createRole', this.dataForm, {
                headers: {
                    'x-access-token': this.token,
                } 
            }).then(res => {
                this.$toast.success('Thêm phân quyền thành công!')
                this.$refs['addAtuh'].hide()
                this.emitModalVisible(false)
                this.isLoading = false
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if(err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                } else {
                    this.isLoading = false
                    this.$toast.error('Vui lòng thử lại!')
                }
            })
        },
        formatTxt(item) {
            this.dataForm[item] = this.dataForm[item].replace(/[<>\[\]]/g, '')
        },
    }
}
</script>

<style>
    .wp-add-auth .modal-width {
        width: 582px;
        border-radius: 20px;
    }
</style>
<style lang="css" scoped>
    .add-auth {
        color: #515151;
    }
    .add-auth h5 {
        font-size: 22px;
        margin-bottom: 15px;
    }

    label {
        font-weight: 500;
    }
    input {
        background-color: #E1E1E1;
        border-radius: 20px;
        padding: 10px;
        border: none;
    }

    input:focus {
        background-color: #E1E1E1;
        box-shadow: none;
        border: none;
    }

    input::placeholder {
        color: #929292;
    }

    button {
        background-color: #EE3439;
        border: none;
        border-radius: 10px;
        padding: 8px 43px;
        font-weight: 500;
    }

    button:hover,
    button:active {
        background: #EE3439 !important;
    }

    button.btn-cancel {
        border: 1px solid #EE3439;
        background: #FFFFFF;
        color: #EE3439;
    }

    button.btn-cancel:hover {
        border: 1px solid #EE3439;
        background: #FFFFFF !important;
        color: #EE3439;
    }
</style>