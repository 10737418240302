<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "App",
    created() {
        window.addEventListener("online", this.handleOnline);
        window.addEventListener("offline", this.handleOffline);
        
        // Kiểm tra kết nối ngay khi tải trang
        this.checkInternetConnection();
    },
    beforeDestroy() {
        window.removeEventListener("online", this.handleOnline);
        window.removeEventListener("offline", this.handleOffline);
    },
    methods: {
        handleOnline() {
        // Kiểm tra lại kết nối thực tế khi có sự kiện 'online'
        this.checkInternetConnection();
        },
        handleOffline() {
        // Khi mất kết nối, điều hướng đến trang thông báo
        this.$router.push("/networkError");
        },
        async checkInternetConnection() {
        try {
            // Thử gửi yêu cầu đến một URL để kiểm tra kết nối thực tế
            await fetch("https://www.google.com", {
            method: "HEAD",
            mode: "no-cors",
            });
            // Nếu kết nối thành công, quay lại trang trước đó (nếu cần)
            // if (this.$route.path === "/networkError") {
            //     this.$router.push("/");
            // }            
        } catch (e) {
            // Nếu không có kết nối, điều hướng đến trang "networkError"
            this.$router.push("/networkError");
        }
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                this.checkInternetConnection();
                document.title = to.meta.title || "DGV";
            }
        },
    }
};
</script>
