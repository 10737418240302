<template>
    <div class="list_cus_new">
        <h5 style="font-weight: 700; font-size: 22px; color: #515151; margin-bottom: 20px;">Quản lý khách hàng</h5>
        <b-row>
            <b-col cols="lg-5">
                <div class="list">
                    <h5>Danh sách khách hàng</h5>
                    <div class="d-flex flex-wrap">
                        <b-form class="search-form" @submit.prevent="setPage(1)">
                            <b-form-group>
                                <b-form-input v-model="keySearch" type="text" placeholder="Tìm kiếm"></b-form-input>
                            </b-form-group>
                            <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                        </b-form>
                        <!-- <button><img src="@/assets/admin/icon/Adjust.png" alt=""></button> -->
                    </div>
                    <div class="wp-cus-item">
                        <div class="cus-item" v-for="(item, index) in dataCustomer" :key="index" style="cursor: pointer;">
                            <div class="cus-info d-flex" @click="getDetail(item.member_id)">
                                <img :src="$storage + item.avatar" v-if="item.avatar" alt="avatar" style="width: 70px; height: 70px; border-radius: 36px; margin-right: 15px; object-fit: cover;" @error="handleError">
                                <img src="@/assets/front-end/img/blank-avatar-icon-26.png" v-else alt="avatar" style="width: 70px; height: auto; border-radius: 36px; margin-right: 15px;">
                                <div>
                                    <p style="font-size: 18px; font-weight: 500;" v-if="item.type == 1">{{ item.member_name }}</p>
                                    <p style="font-size: 18px; font-weight: 500;" v-else>{{ item.rep_name }}</p>
                                    <p>Mã khách hàng: <span>{{ item.member_code }}</span></p>
                                    <p v-if="item.type == 1">Tổ chức: <span>Khách hàng cá nhân</span></p>
                                    <p v-else>Tổ chức: <span>{{ item.rep_name }}</span></p>
                                </div>
                            </div>
                            <div class="text-right">
                                <button class="btn-del" @click="deleteMember(item.member_id)" v-if="intListRole.includes(36)"><img src="@/assets/admin/icon/Trash.png" alt=""></button>
                            </div>
                        </div>
                        <Paginate :page=page @set-page="setPage" style="margin-top: 10px;"/>
                    </div>
                </div>
            </b-col>
            <b-col cols="lg-7" v-if="showDetail">
                <div class="detail-cus">
                    <h5 class="mb-3">Thông tin khách hàng</h5>
                    <b-row>
                        <b-col cols="lg-4">
                            <div class="text-center">
                                <img :src="$storage + dataDetail.avatar" v-if="dataDetail.avatar" alt="avatar" style=" border-radius: 36px;" class="mb-2" @error="handleError">
                                <p class="mb-0" style="font-size: 13px;">Mã khách hàng</p>
                                <p style="font-weight: 500;">{{ dataDetail.member_code }}</p>
                            </div>
                            <div class="file">
                                <p>Giấy tờ liên quan:</p>
                                <b-link v-for="(file, index) in dataDetail.file" :key="index" :href="$storage + file.url" target="_blank">{{ file.file_name }}</b-link>
                                <b-link :href="$storage + dataDetail.business_registration" target="_blank" v-if="dataDetail.business_registration">Giấy chứng nhận đăng ký kinh doanh</b-link>
                            </div>
                        </b-col>
                        <b-col cols="lg-8">
                            <p style="font-size: 18px; font-weight: 600;">Thông tin cá nhân</p>
                            <div class="info"><p>Họ và tên </p>
                                <p v-if="dataDetail.type == 1">{{ dataDetail.member_name }}</p>
                                <p v-else>{{ dataDetail.rep_name }}</p>
                            </div>
                            <div class="info"><p>Loại tài khoản </p>
                                <p v-if="dataDetail.type == 1">Cá nhân</p>
                                <p v-else>Tổ chức</p>
                            </div>
                            <div class="info"><p>Số điện thoại </p><p>{{ dataDetail.phone }}</p></div>
                            <div class="info"><p>Ngày sinh </p><p>{{ formateTime(dataDetail.birthday) }}</p></div>
                            <div class="info"><p>Email </p><p>{{ dataDetail.email }}</p></div>
                            <div class="info"><p>Địa chỉ </p>
                                <p v-if="dataDetail.type == 1">{{ dataDetail.member_address +' - '+ dataDetail.ward_name +' - '+ dataDetail.district_name +' - '+ dataDetail.province_name }}</p>
                                <p v-else>{{ dataDetail.rep_address +' - '+ dataDetail.ward_name +' - '+ dataDetail.district_name +' - '+ dataDetail.province_name }}</p>
                            </div>
                            <div class="info"><p>Giới tính</p>
                                <p v-if="dataDetail.gender == 0">Nam</p>
                                <p v-else>Nữ</p>
                            </div>
                            <div class="info"><p>Số CMT / Thẻ căn cước / Hộ chiếu</p><p>{{ dataDetail.id_number }}</p></div>
                            <div class="info"><p>Ngày cấp</p><p>{{ formateTime(dataDetail.id_date) }}</p></div>
                            <div class="info"><p>Nơi cấp</p><p>{{ dataDetail.id_place }}</p></div>
                            <p>Ảnh CMT / Thẻ căn cước / Hộ chiếu</p>
                            <div class="info">
                                <div class="text-center col-lg-6">
                                    <img :src="$storage + dataDetail.id_front_face" alt="" v-if="dataDetail.id_front_face" @error="handleError" style="border-radius: 20px;">
                                    <img v-else src="@/assets/images/th.jpg" alt="">
                                    <p style="color: #757575; font-size: 12px; text-align: center; width: 100%;">Mặt trước</p>
                                </div>
                                <div class="text-center col-lg-6">
                                    <img :src="$storage + dataDetail.id_back_side" alt="" v-if="dataDetail.id_back_side" @error="handleError" style="border-radius: 20px;">
                                    <img v-else src="@/assets/images/th.jpg" alt="">
                                    <p style="color: #757575; font-size: 12px; text-align: center; width: 100%;">Mặt sau</p>
                                </div>
                            </div>
                            <div v-if="dataDetail.type == 2">
                                <p style="font-size: 18px; font-weight: 600;">Thông tin doanh nghiệp</p>
                                <div class="info"><p>Tên doanh nghiệp</p><p>{{ dataDetail.member_name }}</p></div>
                                <div class="info"><p>Mã số doanh nghiệp</p><p>{{ dataDetail.tax_code }}</p></div>
                                <div class="info"><p>Ngày cấp mã số thuế</p><p>{{ formateTime(dataDetail.tax_date) }}</p></div>
                                <div class="info"><p>Nơi cấp</p><p>{{ dataDetail.tax_place }}</p></div>
                                <div class="info"><p>Địa chỉ</p><p>{{ dataDetail.member_address }}</p></div>
                            </div>
                            <p style="font-size: 18px; font-weight: 600;">Tài khoản ngân hàng</p>
                            <div class="info"><p>Họ và tên chủ tài khoản</p><p>{{ dataDetail.bank_account_name }}</p></div>
                            <div class="info"><p>Ngân hàng</p><p>{{ dataDetail.bank_name }}</p></div>
                            <div class="info"><p>Số tài khoản</p><p>{{ dataDetail.bank_account_number }}</p></div>
                            <div class="info"><p>Chi nhánh</p><p>{{ dataDetail.branch }}</p></div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import search from '../components/search_form.vue'
import Paginate from '../../web-view/components/paginate.vue'
export default {
    components: {
        search,
        Paginate
    },
    data() {
        return {
            keySearch: '',
            page: {
                totalData: 0,
                totalPage: 0,
                currentPage: this.$route.query.page || 1,
                perPage: 10,
                classPrev: 'd-none',
                classNext: 'd-none'
            },
            dataCustomer: [],
            showDetail: false,
            token: '',
            dataDetail: [],
            listRole: [],
            intListRole: [],
        }
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
            info.roles.arr_resource.forEach(element => {
                this.listRole.push(element.resource_id)
            });
            this.intListRole = this.listRole.map(Number)
        }
        this.getListCus()
    },
    methods: {
        formateTime(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY")
        },
        async getDetail(id) {
            this.showDetail= true
            const res = await this.$axios.get('/member/getMemberDetail?member_id=' + id,{
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
                this.dataDetail = res.data
            }
        },
        setPage(val) {
            this.page.currentPage = val;
            this.getListCus();
        },
        async getListCus() {
            const data = {
                page: this.page.currentPage,
                per_page: this.page.perPage
            };
            await this.$axios.get('/member/getListMember',{
                params: {
                    type: '',
                    member_name: this.keySearch,
                    member_code: '',
                    phone: '',
                    address: '',
                    email: '',
                    gender: '',
                    birthday: '',
                    page: data.page,
                    per_page: data.per_page,
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res=> {
                this.page.totalData = res.data.pagination.total_element
                this.page.totalPage = res.data.pagination.total_page
                this.dataCustomer = res.data.result;
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if(err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                } else {
                    this.$toast.error('Vui lòng thử lại!')
                }
            })
        },
        async deleteMember(id) {
            const data = {
                "member_id": id
            }    
            await this.$axios.put('/member/deleteMember', data, {
                headers: {
                    'x-access-token': this.token,
                } 
            }).then(res => {
                this.$toast.success("Xóa tài khoản khách hàng thành công")
                this.getListCus()
                this.showDetail = false
            }).catch(res => {
                this.$toast.success("Xóa tài khoản khách hàng không thành công")
            })
        }
    }
}
</script>
<style lang="css" scoped>
    .list, .detail-cus {
        box-shadow: 2px 2px 6px 0px #00000026;
        background: #FFFFFF;
        padding: 10px 19px 24px 14px;
        color: #515151;
    }

    .list {
        border-radius: 15px;
    }

    .detail-cus {
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    button {
        background: none;
        border: none;
    }

    .cus-item {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #B4B4B4;
        border-radius: 10px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }

    .cus-info {
        flex-basis: 100%;
    }
    .cus-item p {
        margin: 0;
        font-size: 13px;
    }

    .cus-item span {
        font-size: 14px;
        font-weight: 500;
    }

    .btn-del img {
        width: 15px;
        height: 15px;
        filter: grayscale(1);
        margin-right: 20px;
    }

    .detail-cus h5 {
        font-size: 20px;
        font-weight: 500;
    }

    .file p {
        font-size: 14px;
        font-weight: 500;
    }

    .file a {
        position: relative;
        display: block;
        color: #515151;
        font-size: 14px;
        margin-bottom: 10px;
        padding-left: 30px;
    }

    .file a::before {
        position: absolute;
        content: '';
        background-image: url('@/assets/icon/Note.png');
        width: 25px;
        height: 25px;
        left: 0;
    }

    .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .info p {
        font-size: 14px;
        color: #757575;
    }

    .info p:last-child {
        font-weight: 500;
        font-size: 15px;
        color: #515151;
        text-align: right;
        width: 50%;
    }

    .wp-cus-item {
        max-height: 650px;
        overflow-y: auto;
    }
    .wp-cus-item::-webkit-scrollbar {
        width: 0;
    }

    @media (max-width:992px) {
        .detail-cus {
            margin-top: 20px;
        }
    }
</style>

<style>
    /* .list .search-form {
        width: 363px;
    } */

    @media (max-width:425px) {
        .list .search-form {
            width: 100%;
        }
    }
</style>