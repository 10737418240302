<template>
    <b-link @click="$router.push('/news-detail/' + item.news_id)" v-if="item" class="d-flex justify-content-center">
        <b-card class="card-new">
            <img class="card-img" v-bind:src="this.$storage + item.image" alt="" @error="handleError">
            <div :class="{ 'gr-text': item.type == 1 }">
                <label class="col-12 news_ttl" v-if="item.title">{{ item.title }}</label>
                <p class="slide-item__ttl">
                    Công ty đấu giá hợp danh DGV thông báo: <br>
                    {{ item.title }}
                </p>
            </div>
            <a class="infor" href="#" v-if="item.cate == 'auction'">
                <div class="d-flex" style="align-items: center; justify-content: center;">
                    <img src="@/assets/icon/Auction1.png" alt="" style="width: unset;">
                    <ul class="list-btn">
                        <li>Thời gian đấu giá: <span>{{ item.time }}</span></li>
                        <li>Giá khởi điểm: <span>
                                {{ item.price.toLocaleString("vi-VN") }} VNĐ
                            </span></li>
                        <li>Bước giá : <span>{{ item.step_price.toLocaleString("vi-VN") }} VNĐ</span>
                        </li>
                    </ul>
                </div>
            </a>
            <div class="d-flex justify-content-between align-items-end pl-2" v-if="item.type == 1">
                <b-button :to="'/news-detail/' + item.news_id" class="mb-2">
                    Xem chi tiết
                </b-button>
                <div class="count-view">
                    {{ formatCount(item.count_view) }}
                    <b-icon icon="eye-fill"></b-icon>
                </div>
            </div>
        </b-card>
    </b-link>
</template>
<script>
export default {
    props: ['item'],
    data() {
        return {

        }
    },
    methods: {
        redirect() {
            this.$router.push({
                path: '/news-detail/' + this.item.news_id,
                params: {
                    news_id: this.item.news_id
                }
            })

        }
    }
}
</script>
<style lang="css" scoped>
.card {
    background: #F8F8F8;
    color: #515151;
    font-size: 14.05px;
    border: none;
    height: 100%;
    width: 308px;
}

.card-img {
    width: 308px;
    height: 205px;
    object-fit: cover;
}

.news_ttl {
    color: #515151;
    font-size: 18px;
    font-weight: 600;
    line-height: 160%;
}

.count-view {
    color: #9e9e9e;
    padding: 0 0.5rem;
    text-align: right;
}

.gr-text {
    min-height: 125px;
}

.gr-text p {
    -webkit-line-clamp: 2;
    line-height: 26px;
    margin: 0 0.5rem;
}

.card-body {
    padding: 0;
}

.news label {
    text-align: start;
    margin: 0 0.25rem;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    cursor: pointer;
}

.card-body .btn {
    background: none;
    color: #515151;
    font-size: 14.05px;
    border: 1px solid #515151;
    font-weight: 500;
    border-radius: 20px;
    width: 136px;
    height: 35px;
}

.news .infor div {
    background: none;
    color: #515151;
    border: 1px solid #515151;
    border-radius: 20px;
    margin: 0.5rem;
}

.slide-item__ttl {
    text-align: left;
    margin: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 15px;
    font-weight: 400;
    line-height: 25.29px;

}

.news .card-text {
    padding: 8px 7px 0;
    margin: 0;
    color: #515151;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
}

.news .text {
    margin: 15px 7px;
    font-size: 15px;
    line-height: 27px;
    font-weight: 400;
    color: #515151;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.news .list-btn {
    margin: 0.25rem;
    text-align: left;
    padding-left: 0;
}

.news .list-btn span {
    font-weight: 500;
    font-style: 14px;
}

.wp-news .card-new {
    width: 308px;
    height: 378px;
}
</style>