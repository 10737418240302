var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"cz-product-gallery"},[_c('div',{staticClass:"cz-preview"},_vm._l((_vm.list_images),function(photo,key){return _c('div',{staticClass:"cz-preview-item",class:{ 'active': key == 0 }},[_c('b-link',{attrs:{"href":"#"}},[(_vm.selectedImage)?_c('img',{staticClass:"show-imag img-responsive",attrs:{"src":_vm.selectedImage,"alt":"Product image"},on:{"click":function($event){return _vm.showModal(_vm.selectedImage)},"error":_vm.handleError}}):_c('img',{staticClass:"show-imag img-responsive",attrs:{"src":_vm.$storage + photo,"alt":"Product image"},on:{"click":function($event){return _vm.showModal(_vm.$storage + photo)},"error":_vm.handleError}})])],1)}),0),_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"table-responsive mt-md-3",staticStyle:{"padding":"0px"}},[(_vm.list_images)?_c('carousel',{key:_vm.carouselKey,attrs:{"responsive":{
                    0: {
                        nav: false,
                        dots: false,
                        items: 1
                    },

                    374: {
                        items: 2,
                        nav: false,
                        dots: false,
                    },
                    500: {
                        items: 3,
                        nav: false,
                        dots: false,
                    },
                    768: {
                        items: 4,
                        nav: false,
                        dots: false,
                    },
                    991: {
                        items: 3,
                        nav: false,
                        dots: false,
                    },
                    1200: {
                        items: 3,
                        nav: false,
                        dots: false,
                    },
                    1399: {
                        items: 4,
                        nav: false,
                        dots: false,
                    },
                },"autoplay":true,"margin":10}},_vm._l((_vm.list_images),function(photo,key){return _c('div',{key:key,staticClass:"cz-thumblist align-self-center"},[_c('a',{staticClass:"cz-thumblist-item"},[_c('img',{staticClass:"click-img",attrs:{"src":_vm.$storage + photo,"alt":""},on:{"click":function($event){return _vm.showImage(_vm.$storage + photo)},"error":_vm.handleError}})])])}),0):_vm._e()],1)])]),_c('b-modal',{ref:"imgModal",attrs:{"id":"","centered":"","hide-footer":"","hide-header":"","size":"lg","content-class":"modal-img image-auction"}},[_c('img',{staticStyle:{"border-radius":"15px"},attrs:{"src":this.imgModal,"alt":""}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }