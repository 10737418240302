import { render, staticRenderFns } from "./assetByCategory.vue?vue&type=template&id=a38f9a6e&scoped=true&"
import script from "./assetByCategory.vue?vue&type=script&lang=js&"
export * from "./assetByCategory.vue?vue&type=script&lang=js&"
import style0 from "./assetByCategory.vue?vue&type=style&index=0&id=a38f9a6e&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a38f9a6e",
  null
  
)

export default component.exports