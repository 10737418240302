<template>
    <div :class="{'overlay' : isLoading}">
        <div>
            <h5>Danh sách nhân viên</h5>
            <b-row>
                <b-col cols="lg-6">
                    <div v-if="intListRole.includes(17)">
                        <div class="list_employ" style="cursor: pointer;">
                            <div class="d-flex justify-content-between mb-3">
                                <p style="margin: 0; font-weight: 500; font-size: 20px;">Nhân viên</p>
                                <button class="btn-add-emp" @click="showModalAdd" v-if="intListRole.includes(18)">+ Thêm nhân viên mới</button>
                            </div>
                            <div class="d-flex flex-wrap justify-content-between">
                                <div class="d-flex">
                                    <b-form class="search-form">
                                        <b-form-group>
                                            <b-form-input v-model="keySearch" type="text" placeholder="Tìm kiếm nhân viên" required></b-form-input>
                                        </b-form-group>
                                        <button style="background-color: #515151;"><img src="@/assets/admin/icon/Search.png" alt=""></button>
                                    </b-form>
                                    <!-- <button><img src="@/assets/admin/icon/Adjust.png" alt=""></button> -->
                                </div>
                                <b-dropdown id="list_branch" :text="this.agency_name" class="mt-xs-2 m-md-2 button-list" v-if="role_id == 1">
                                    <b-dropdown-item @click="getListEmploy('Tất cả')">Tất cả</b-dropdown-item>
                                    <b-dropdown-item v-for="(agency, index) in listAgency" :key="index" @click="filterAgency(agency.agency_name, agency.agency_id)">{{ agency.agency_name }}</b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div class="wp-employ-item">
                                <div class="employ-item" v-for="(member, index) in filteredItems" :key="index">
                                    <div class="info-employ d-flex" @click="showDetail(member.user_id)">
                                        <img v-if="member.avatar" :src="$storage + member.avatar" alt="" style="max-width: 70px; height: 70px; width: 100%; border-radius: 36px; object-fit: cover;" @error="handleError">
                                        <img v-else src="@/assets/front-end/img/blank-avatar-icon-26.png" alt="" style="max-width: 70px; max-height: 70px; border-radius: 36px;">
                                        <div class="ml-3">
                                            <p style="font-size: 18px; font-weight: 500;">{{ member.full_name }}</p>
                                            <div class="d-flex">
                                                <p>Mã nhân viên : <span>{{ member.user_code }}</span></p>
                                                <p class="ml-4">Chi nhánh: <span>{{ member.agency_name }}</span></p>
                                            </div>
                                            <p>Chức năng: <span>{{ member.role_name }}</span></p> 
                                        </div>
                                    </div>
                                    <div>
                                        <button v-if="intListRole.includes(19)"><img src="@/assets/admin/icon/Edit.png" alt="" @click="editMember(member.user_id)"></button>
                                        <button v-if="intListRole.includes(20)"><img src="@/assets/admin/icon/Trash.png" alt="" @click="deleteMember(member.user_id)"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="lg-6" v-if="this.show_detail == true">
                    <div class="detail_employ">
                        <div class="d-flex align-items-center mb-3 justify-content-between">
                            <p style="font-size: 20px; font-weight: 500;" class="mb-0">Thông tin nhân viên</p>
                            <button class="btn-reset" v-if="this.status_edit == true" @click="resetPass(dataDetail.user_id)" :disabled="isLoading">Reset password to default</button>
                        </div>
                        <b-row>
                            <b-col cols="lg-3">
                                <div class="ava-member text-center">
                                    <div v-if="this.status_edit == false">
                                        <img :src="$storage + dataDetail.avatar" alt="" @error="handleError">
                                    </div>
                                    <div v-else>
                                        <img :src="avatar" alt="" v-if="avatar" onclick="$('#file_img').click()">
                                        <img :src="$storage + dataDetail.avatar" alt="" v-if="dataDetail.avatar && !avatar" onclick="$('#file_img').click()" @error="handleError">
                                        <input id="file_img" type="file" accept="image/png, image/gif, image/jpeg, image/jpg" class="avatar" hidden @change="uploadFile">
                                    </div>
                                    
                                    <p style="font-size: 13px;">Mã nhân viên :</p>
                                    <p style="font-weight: 500;">{{ dataDetail.user_code }}</p>
                                </div>
                            </b-col>
                            <b-col cols="lg-9">
                                <b-row class="info-detail align-items-center" v-if="this.status_edit == false">
                                    <b-col cols="md-5"><p>Tên nhân viên:</p></b-col>
                                    <b-col cols="md-7"><p>{{ dataDetail.full_name }}</p></b-col>
                                    <b-col cols="md-5"><p>Giới tính:</p></b-col>
                                    <b-col cols="md-7">
                                        <p v-if="dataDetail.gender == 0">Nam</p>
                                        <p v-else>Nữ</p>
                                    </b-col>
                                    <b-col cols="md-5"><p>Ngày sinh:</p></b-col>
                                    <b-col cols="md-7"><p>{{ formateTime(dataDetail.birthday) }}</p></b-col>
                                    <b-col cols="md-5"><p>Số điện thoại liên hệ:</p></b-col>
                                    <b-col cols="md-7"><p>{{ dataDetail.phone }}</p></b-col>
                                    <b-col cols="md-5"><p>Email:</p></b-col>
                                    <b-col cols="md-7"><p>{{ dataDetail.email }}</p></b-col>
                                    <b-col cols="md-5"><p>Địa chỉ:</p></b-col>
                                    <b-col cols="md-7"><p>{{ dataDetail.address }}</p></b-col>
                                    <b-col cols="md-5"><p>Chức danh trong công ty:</p></b-col>
                                    <b-col cols="md-7"><p>{{ dataDetail.position }}</p></b-col>
                                    <b-col cols="md-5"><p>Phân quyền chức năng:</p></b-col>
                                    <b-col cols="md-7">
                                        <p v-for="role in listRole" v-if="role.role_id == dataDetail.role_id">{{ role.role_name }}</p>
                                    </b-col>
                                    <b-col cols="md-5"><p>Tên đăng nhập hệ thống:</p></b-col>
                                    <b-col cols="md-7"><p>{{ dataDetail.user_name }}</p></b-col>
                                    <b-col cols="md-5"><p>Chi nhánh làm việc:</p></b-col>
                                    <b-col cols="md-7">
                                        <p v-for="agency in listAgency" v-if="agency.agency_id == dataDetail.agency_id">{{ agency.agency_name }}</p>
                                    </b-col>
                                    <b-col cols="md-5"><p>Số CMT/CCCD/Hộ chiếu:</p></b-col>
                                    <b-col cols="md-7"><p>{{ dataDetail.cccd }}</p></b-col>
                                    <b-row>
                                        <b-col cols="md-5" class="d-flex">
                                            <p>Ngày cấp:</p>
                                            <p style="font-weight: 500; margin-left: 25px;">{{ formateTime(dataDetail.ngayCapCC) }}</p>
                                        </b-col>
                                        <b-col cols="md-7" class="d-flex">
                                            <p style="font-weight: 400; margin-right: 25px; padding-left: 10px;">Nơi cấp:</p>
                                            <p style="font-weight: 500;">{{ dataDetail.noiCapCC }}</p>
                                        </b-col>
                                    </b-row>
                                    <b-col cols="md-12"><p>Ảnh CMT / Thẻ căn cước / Hộ chiếu:</p></b-col>
                                    <b-col cols="md-12" class="d-flex">
                                        <div class="text-center" v-if="dataDetail.id_front_face">
                                            <img class="img_id" v-if="dataDetail.id_front_face" :src="$storage + dataDetail.id_front_face" alt="" @error="handleError">
                                            <p style="font-size: 13px; color: #757575;">Mặt trước</p>
                                        </div>
                                        <div class="text-center" style="margin-left: 60px;" v-if="dataDetail.id_back_side">
                                            <img class="img_id" v-if="dataDetail.id_back_side" :src="$storage + dataDetail.id_back_side" alt="" @error="handleError">
                                            <p style="font-size: 13px; color: #757575;">Mặt sau</p>
                                        </div>
                                    </b-col>
                                    <b-row class="mt-5" v-if="dataDetail.role_id == 3">
                                        <b-col cols="md-5"><p>Số thẻ đấu giá viên:</p></b-col>
                                        <b-col cols="md-7"><p>{{ dataDetail.soTheDGV }}</p></b-col>
                                        <b-col cols="md-5"><p>Ngày cấp:</p></b-col>
                                        <b-col cols="md-7"><p>{{ formateTime(dataDetail.ngayCap) }}</p></b-col>
                                        <b-col cols="md-5"><p>Nơi cấp:</p></b-col>
                                        <b-col cols="md-7"><p>{{ dataDetail.noiCap }}</p></b-col>
                                        <b-col cols="md-5"><p>Số chứng chỉ hành nghề:</p></b-col>
                                        <b-col cols="md-7"><p>{{ dataDetail.cchn }}</p></b-col>
                                    </b-row>
                                    <ul v-if="dataDetail.role_id == 3">
                                        <li><b-link class="file_lnk" target="_blank" :href="$storage + dataDetail.ccHanhNghe" v-if="dataDetail.ccHanhNghe">Chứng chỉ hành nghề đấu giá</b-link></li>
                                        <li><b-link class="file_lnk" target="_blank" :href="$storage + dataDetail.theDGV" v-if="dataDetail.theDGV">Thẻ đấu giá viên </b-link></li>
                                    </ul>
                                </b-row>
    
                                <b-form class="form-edit" @submit="updateMember" v-else autocomplete="off">
                                    <b-row class="info-detail align-items-center">
                                        <b-col cols="md-5"><label for="name">Tên nhân viên:</label></b-col>
                                        <b-col cols="md-7"><b-form-input id="name" v-model="dataForm.full_name" required @input="formatTxt('full_name')"></b-form-input></b-col>
                                        <b-col cols="md-5"><label for="gender">Giới tính:</label></b-col>
                                        <b-col cols="md-7">
                                            <b-form-radio-group class="d-flex" style="height: 41px;" v-model="dataForm.gender" required>
                                                <b-form-radio value="0" id="gender">Nam</b-form-radio>
                                                <b-form-radio value="1">Nữ</b-form-radio>
                                            </b-form-radio-group>
                                        </b-col>
                                        <b-col cols="md-5"><label for="birthday">Ngày sinh:</label></b-col>
                                        <b-col cols="md-7"><b-form-datepicker id="birthday" v-model="dataForm.birthday" :show-decade-nav="true" :max="max" start-weekday=1 locale="vi"></b-form-datepicker></b-col>
                                        <b-col cols="md-5"><label for="phone">Số điện thoại:</label></b-col>
                                        <b-col cols="md-7"><input id="phone" v-model="dataForm.phone" @input="checkInputNumber('phone')" class="form-control" maxlength="12" required></b-col>
                                        <b-col cols="md-5"><label for="email">Email:</label></b-col>
                                        <b-col cols="md-7"><b-form-input id="email" type="email" v-model="dataForm.email" required @input="formatTxt('email')"></b-form-input></b-col>
                                        <b-col cols="md-5"><label for="address">Địa chỉ:</label></b-col>
                                        <b-col cols="md-7"><b-form-input id="address" v-model="dataForm.address" required @input="formatTxt('address')"></b-form-input></b-col>
                                        <b-col cols="md-5"><label for="position">Chức danh trong công ty:</label></b-col>
                                        <b-col cols="md-7"><b-form-input id="position" v-model="dataForm.position" required @input="formatTxt('position')"></b-form-input></b-col>
                                        <b-col cols="md-5"><label for="auth">Phân quyền chức năng:</label></b-col>
                                        <b-col cols="md-7">
                                            <b-form-select id="auth" class="form-select" v-model="dataForm.role_id" required>
                                                <b-form-select-option v-for="item in listRole" :key="item.role_id" :value="item.role_id">{{ item.role_name }}</b-form-select-option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col cols="md-5"><label>Tên đăng nhập hệ thống:</label></b-col>
                                        <b-col cols="md-7"><p style="font-weight: 500;">{{ dataForm.user_name }}</p></b-col>
                                        <b-col cols="md-5"><label for="branch">Chi nhánh làm việc:</label></b-col>
                                        <b-col cols="md-7">
                                            <b-form-select id="branch" class="form-select" v-model="dataForm.agency_id" required>
                                                <b-form-select-option v-for="item in listAgency" :key="item.agency_id" :value="item.agency_id">{{ item.agency_name }}</b-form-select-option>
                                            </b-form-select>
                                        </b-col>
                                        <b-col cols="md-5"><label for="id_num">Số CMT/CCCD/Hộ chiếu:</label></b-col>
                                        <b-col cols="md-7"><input id="id_num" v-model="dataForm.cccd" @input="checkInputNumber('cccd')" class="form-control" required></b-col>
                                        <b-col cols="md-5"><label for="id_date">Ngày cấp:</label></b-col>
                                        <b-col cols="md-7"><b-form-datepicker id="id_date" v-model="dataForm.ngayCapCC" :show-decade-nav="true" :max="max" start-weekday=1 locale="vi"></b-form-datepicker></b-col>
                                        <b-col cols="md-5"><label for="id_place">Nơi cấp:</label></b-col>
                                        <b-col cols="md-7"><b-form-input id="id_place" v-model="dataForm.noiCapCC" required @input="formatTxt('noiCapCC')"></b-form-input></b-col>
                                        <!-- <b-row>
                                            <b-col cols="md-5" class="d-flex align-items-center">
                                                <label for="id_date" style="width: 65%;">Ngày cấp:</label>
                                                <b-form-datepicker id="id_date" v-model="dataForm.ngayCapCC" :show-decade-nav="true" :max="max" start-weekday=1 locale="vi"></b-form-datepicker>
                                            </b-col>
                                            <b-col cols="md-7" class="d-flex align-items-center">
                                                <label for="id_place" style="width: 35%;">Nơi cấp:</label>
                                                <b-form-input id="id_place" v-model="dataForm.noiCapCC"></b-form-input>
                                            </b-col>
                                        </b-row> -->
                                        <b-col cols="md-12"><p>Ảnh CMT / Thẻ căn cước / Hộ chiếu:</p></b-col>
                                        <b-col cols="md-12" class="d-flex">
                                            <div class="text-center">
                                                <input type="file" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFileFront" class="uploadFront" hidden @change="uploadFile">
                                                <img class="img_id" :src="$storage + dataDetail.id_front_face" alt="" @click="openFile('front')" v-if="!imageFrontSrc && dataDetail.id_front_face" @error="handleError">
                                                <img class="review-img" @click="openFile('front')" :src="imageFrontSrc" alt="" v-else="imageFrontSrc">
                                                <p style="font-size: 13px; color: #757575;">Mặt trước</p>
                                            </div>
                                            <div class="text-center" style="margin-left: 60px;">
                                                <input type="file" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile" class="uploadBack" hidden @change="uploadFile">
                                                <img class="img_id" :src="$storage + dataDetail.id_back_side" alt="" @click="openFile('back')" v-if="!imageBackSrc && dataDetail.id_back_side" @error="handleError">
                                                <img class="review-img" @click="openFile('back')" :src="imageBackSrc" alt="" v-else="imageBackSrc">
                                                <p style="font-size: 13px; color: #757575;">Mặt sau</p>
                                            </div>
                                        </b-col>

                                        <div v-if="dataForm.role_id == 3">
                                            <b-row>
                                                <b-col cols="lg-5"><label for="dgv_num">Số thẻ đấu giá viên:</label></b-col>
                                                <b-col cols="lg-7"><input id="dgv_num" v-model="dataForm.soTheDGV" @input="checkInputNumber('soTheDGV')" class="form-control"></b-col>
                                                <b-col cols="lg-5"><label for="dgv_date">Ngày cấp:</label></b-col>
                                                <b-col cols="lg-7"><b-form-datepicker v-model="dataForm.ngayCap" id="dgv_date" :show-decade-nav="true" :max="max" start-weekday=1 locale="vi"></b-form-datepicker></b-col>
                                                <b-col cols="lg-5"><label for="dgv_place">Nơi cấp:</label></b-col>
                                                <b-col cols="lg-7"><b-form-input id="dgv_place" v-model="dataForm.noiCap" @input="formatTxt('noiCap')"></b-form-input></b-col>
                                                <b-col cols="lg-5"><label for="cchn_num">Số chứng chỉ hành nghề:</label></b-col>
                                                <b-col cols="lg-7"><input id="cchn_num" v-model="dataForm.cchn" @input="checkInputNumber('cchn')" class="form-control"></b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <label for="dgv_card">Thẻ đấu giá viên</label>
                                                    <input v-if="dataDetail.theDGV" class="custom-file-input mb-2" id="dgv_card" accept="application/pdf,application/vnd.ms-excel" type="file" @change="changeFile('theDGV')">
                                                    <input v-else class="custom-file-input mb-2" id="dgv_card" accept="application/pdf,application/vnd.ms-excel" type="file" @change="changeFile('theDGV')" required>
                                                </b-col>
                                                <a :href="$storage + dataDetail.theDGV" target="_blank" class="view-file" v-if="dataDetail.theDGV && !hideTheDGV">Xem thẻ đấu giá viên</a>
                                            </b-row>
                                            <b-row class="mt-2">
                                                <b-col>
                                                    <label for="certificate">Chứng chỉ hành nghề đấu giá</label>
                                                    <input v-if="dataDetail.ccHanhNghe" class="custom-file-input mb-2" id="certificate" accept="application/pdf,application/vnd.ms-excel" type="file" @change="changeFile('ccHanhNghe')">
                                                    <input v-else class="custom-file-input mb-2" id="certificate" accept="application/pdf,application/vnd.ms-excel" type="file" @change="changeFile('ccHanhNghe')" required>
                                                </b-col>
                                                <a :href="$storage + dataDetail.ccHanhNghe" target="_blank" class="view-file" v-if="dataDetail.ccHanhNghe && !hideCchn">Xem chứng chỉ hành nghề</a>
                                            </b-row>
                                        </div>
                                        
                                        <div class="text-right mt-3">
                                            <button class="btn-cancel" type="button" @click="status_edit = false" :disabled="isLoading">Hủy bỏ</button>
                                            <button class="btn-confirm" type="submit" :disabled="isLoading">Xác nhận chỉnh sửa</button>
                                        </div>
                                    </b-row>
                                </b-form>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
                <Add ref="Add" @handleCloseModal="listenDialog"/>
                <Delete ref="delete" :del_id="del_id" @handleCloseModal="listenDialog"/>
            </b-row>
        </div>
        <div class="loader" v-if="isLoading"></div>
    </div>
</template>
<script>
import Add from "./modal/add.vue"
import Delete from "./modal/delete.vue"
export default {
    components: {
        Add,
        Delete
    },
    data() {
        return {
            del_id: '',
            dataForm: {
                full_name: '',
                gender: '',
                birthday: null,
                phone: '',
                email: '',
                address: '',
                position: '',
                role_id: '',
                user_name: '',
                agency_id: '',
                cccd: '',
                ngayCapCC: null,
                noiCapCC: '',
                id_back_side:'',
                id_front_face:'',
                avatar: '',
                theDGV: '',
                soTheDGV: '',
                ngayCap: '',
                noiCap: '',
                cchn: ''
            },
            dataMember: [],
            show_detail: false,
            status_edit: false,
            imageFrontSrc: '',
            imageBackSrc: '',
            avatar: '',
            max: new Date(),
            dataDetail: [],
            listRole: [],
            listAgency: [],
            token: '',
            agency_name: '',
            keySearch: '',
            listRole: [],
            intListRole: [],
            role_id: '',
            params_agency: '',
            isLoading: false,
            hideTheDGV: false,
            hideCchn: false
        }
    },
    computed: {
        filteredItems() {
            return this.dataMember.filter(member => member.full_name.toLowerCase().includes(this.keySearch.toLowerCase()));
        },
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
            this.role_id = info.roles.role_id
            info.roles.arr_resource.forEach(element => {
                this.listRole.push(element.resource_id)
            });
            this.intListRole = this.listRole.map(Number)

            if(info.roles.role_id == 1) {
                this.params_agency = ''
            } else {
                this.params_agency = info.agency_id
            }
        }
        this.getListEmploy()
        this.getListRole()
        this.getListAgency()
    },
    methods: {
        formateTime(time) {
            return this.$moment.utc(time).format("DD/MM/YYYY")
        },
        showModalAdd() {
            this.$refs.Add.show();
        },
        editMember (id) {
            this.status_edit = true
            // this.showDetail(id)
        },
        deleteMember (id) {
            this.del_id = id
            this.$refs.delete.show();
        },
        checkInputNumber(type) {
            if(type == 'phone'){
                this.dataForm.phone = this.formatNumber(event.target.value)
            } else if (type == 'cccd') {
               this.dataForm.cccd = this.formatNumber(event.target.value)
            }  else if (type == 'cchn') {
               this.dataForm.cchn = this.formatNumber(event.target.value)
            } else {
                this.dataForm.soTheDGV = this.formatNumber(event.target.value)
            }
        },
        formatTxt(item) {
            this.dataForm[item] = this.dataForm[item].replace(/[<>\[\]]/g, '')
        },
        async showDetail(id) {
            this.show_detail = true
            this.status_edit = false
            const res = await this.$axios.get('/auth/getUserDetail?user_id=' + id, {
                headers: {
                    'x-access-token': this.token
                }
            })

            if(res.status == 200) {
                this.dataDetail = res.data
                this.dataForm = res.data
            }
        },
        uploadFile(event) {
            const file = event.target.files[0];
            const className = event.target.className;
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    if (className.includes('uploadFront')) {
                        this.imageFrontSrc = reader.result;
                        this.upload('id_front', file)
                    }else if(className.includes('avatar')) {
                        this.avatar = reader.result;
                        this.upload('avatar', file)
                    } else {
                        this.imageBackSrc = reader.result;
                        this.upload('id_back', file)
                    }
                };
                reader.readAsDataURL(file);
            }
        },
        async upload(type, file) {
            if(type == 'id_front') {
                const data = {
                    'file': file,
                    'path': 'user/id_front_face/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    } 
                }).then(res => {
                    this.dataForm.id_front_face = res.data.list_result[0].path
                })
            } else if (type == 'avatar') {
                const data = {
                    'file': file,
                    'path': 'user/avatar/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    } 
                }).then(res => {
                    this.dataForm.avatar = res.data.list_result[0].path
                })
            } else if (type == 'id_back') {
                const data = {
                    'file': file,
                    'path': 'user/id_back_side/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    } 
                }).then(res => {
                    this.dataForm.id_back_side = res.data.list_result[0].path
                })
            } else if (type == 'theDGV') {
                const data = {
                    'file': file,
                    'path': 'user/theDGV/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    } 
                }).then(res => {
                    this.dataForm.theDGV = res.data.list_result[0].path
                })
            } else {
                const data = {
                    'file': file,
                    'path': 'user/chungChiHanhNghe/'
                }
                await this.$axios.post('/common/uploadFile', data, {
                    headers: {
                        'x-access-token': this.token,
                        'Content-Type': 'multipart/form-data'
                    } 
                }).then(res => {
                    this.dataForm.ccHanhNghe = res.data.list_result[0].path
                })
            }
        },
        openFile(param) {
            if (param == 'front') {
                this.$refs.inputFileFront.click()
            } else {
                this.$refs.inputFile.click()
            }
        },
        listenDialog(isClose = false) {
            if(!isClose) {
                this.getListEmploy()
                this.show_detail = false
            }
        },
        async getListEmploy(text) {
            if(text) {
                this.agency_name = text
            } else {
                this.agency_name = 'Chi Nhánh'
            }

            const res = await this.$axios.get('/auth/getListUser?user_name&user_code&role_id&agency_id=' + this.params_agency+'&phone&address&email&full_name&gender&birthday&soTheDGV&cccd&page=1&per_page=10000', {
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
               this.dataMember = res.data.result;
            }
        },
        async getListRole() {
            const res = await this.$axios.get('/permission/getListRole', {
                headers: {
                    'x-access-token': this.token
                }
            })

            if(res.status == 200) {
                this.listRole = res.data;
            }
        },
        async getListAgency() {
            const res = await this.$axios.get('/agency/getListAgency', {
                headers: {
                    'x-access-token': this.token
                }
            }).then(res=> {
                this.listAgency = res.data;
            }).catch(err => {
                if(err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if(err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                } else {
                    this.$toast.error('Vui lòng thử lại!')
                }
            })
        },
        async updateMember() {
            event.preventDefault()
            this.isLoading = true
            await this.$axios.put('/auth/updateUser', this.dataForm, {
                headers: {
                    'x-access-token': this.token
                } 
            }).then((res) => {
                this.$toast.success('Sửa thông tin thành công!')
                this.status_edit = false
                this.hideTheDGV = false
                this.hideCchn = false
                this.isLoading = false
                this.getListEmploy()
            }).catch((err) => {
                this.$toast.error(err.response.data.error)
                this.isLoading = false
            })
        },
        async resetPass(id) {
            this.isLoading = true
            const data = {
                user_id: id
            }
            await this.$axios.put('/auth/resetPass', data, {
                headers: {
                    'x-access-token': this.token
                } 
            }).then((res) => {
                this.$toast.success('Reset password thành công!')
                this.status_edit = false
                this.isLoading = false
            }).catch((err) => {
                this.$toast.error('Reset password không thành công!')
                this.isLoading = false
            })
        },
        async filterAgency(text, id) {
            this.agency_name = text
            const res = await this.$axios.get('/auth/getListUser?user_name&user_code&role_id&agency_id='+ id +'&phone&address&email&full_name&gender&birthday&soTheDGV&cccd&page=1&per_page=1000000000', {
                headers: {
                    'x-access-token': this.token
                }
            })
            if(res.status == 200) {
               this.dataMember = res.data.result;
               this.show_detail = false
            }
        },
        async changeFile(type) {
            const file = event.target.files[0];
            const files = Array.from(event.target.files);

            if (file || files) {
                const reader = new FileReader();
                if (type == 'theDGV') {
                    reader.onload = () => {
                        this.upload('theDGV', file)
                    };
                    this.hideTheDGV = true
                } else {
                    reader.onload = () => {
                        this.upload('ccHanhNghe', file)
                    }; 
                    this.hideCchn = true
                }
                reader.readAsDataURL(file);
            }
        },
    }
}
</script>
<style lang="css" scoped>
    button {
        background: none;
        border: none;
    }

    .wp-employ-item {
        max-height: 815px;
        overflow-y: auto;
    }

    .wp-employ-item::-webkit-scrollbar {
        width: 0;
    }
    .list_employ, .detail_employ {
        background: #ffffff;
        box-shadow: 2px 2px 6px 0px #00000026;
        padding: 10px 18px 24px 14px;
        color: #515151;
    }
    .list_employ {
        border-radius: 15px;
    }
    .detail_employ {
        border-top-left-radius: 20px;
        border-bottom-right-radius: 20px;
        overflow: hidden;
    }

    .btn-add-emp {
        color: #EE3439;
        font-weight: 500;
    }

    .list_employ .button-list {
        width: 185px;
        height: 31px;
        border: 1px solid #515151;
        border-radius: 20px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
    }

    .employ-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #B4B4B4;
        border-radius: 10px;
        padding: 0 26px 0 0;
        border-top-left-radius: 56px;
        border-bottom-left-radius: 56px;
        margin-top: 15px;
    }

    .employ-item button img {
        width: 15px;
        height: 15px;
        filter: grayscale(1);
    }

    .info-employ {
        flex-basis: 90%;
        align-items: center;
    }

    .info-employ p {
        margin: 0;
        font-size: 13px;
    }
    .info-employ span {
        font-size: 14px;
        font-weight: 500;
    }

    .ava-member img {
        max-width: 180px;
        border-radius: 130px;
        margin-bottom: 10px;
        cursor: pointer;
        width: 100%;
        max-height: 167px;
        object-fit: cover;
    }

    .img_id, .review-img {
        border-radius: 15px;
        cursor: pointer;
    }
    .ava-member p {
        margin: 0;
    }
    .info-detail p {
        margin-bottom: 10px;
        font-size: 14px;
    }
    .info-detail .col-md-7 p {
        font-size: 15px;
        font-weight: 500;
    }

    /* ------ */
    .form-edit .form-control,
    .form-edit .form-select {
        margin-bottom: 20px;
    }

    .form-edit .form-control:focus,
    .form-edit .form-select:focus {
        box-shadow: none;
    }

    .form-edit .form-control {
        border: none;
        border-bottom: 1px solid #B6B6B6;
        border-radius: 0;
    }

    label {
        font-size: 14px;
    }

    .btn-reset {
        color: #EE3439;
        border: 1px solid #EE3439;
        border-radius: 20px;
        padding: 5px;
    }

    .form-edit .form-select {
        background-color: #EAEAEA;
        border: none;
        border-radius: 38px;
    }

    .btn-cancel {
        color: #EE3439;
        font-size: 13px;
        font-weight: 500;
        border-radius: 20px;
        border: 1px solid #EE3439;
        margin-right: 15px;
        padding: 7px 32px;
    }

    .btn-confirm {
        background: #EE3439;
        border: 1px solid #EE3439;
        color: #ffffff;
        font-size: 13px;
        font-weight: 500;
        padding: 7px 32px;
        border-radius: 20px;
    }

    .file_lnk {
        position: relative;
        display: block;
        padding-left: 35px;
        color: #515151;
        font-weight: 500;
        margin-bottom: 10px;
    }
    
    .file_lnk::before {
        content: '';
        position: absolute;
        left: 0;
        background-image: url('@/assets/icon/Note.png');
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
    }

    .review-img {
        max-width: 210px;
        border-radius: 10px;
    }

    .custom-file-input::-webkit-file-upload-button {
        visibility: hidden;
    }

    .custom-file-input {
        color: #515151;
        margin-left: 40px;
        background: #EEEEEE;
        border-radius: 15px;
        padding: 3px 0;
    }

    .custom-file-input::after {
        position: absolute;
        right: 80px;
        content: 'Chọn file';
        display: inline-block;
        background: #444444;
        border-radius: 15px;
        padding: 6px 13px;
        outline: none;
        white-space: nowrap;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
    }

    .custom-file-input::before {
        position: absolute;
        content: '';
        background-image: url('@/assets/admin/icon/Import Pdf.png');
        width: 32px;
        height: 32px;
        left: 10px;
    }

    .view-file {
        color: #515151;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        margin: 10px 0;
    }

    @media (max-width: 992px) {
        .detail_employ, .info-detail {
            margin-top: 20px;
        }
    }

    @media (max-width: 575px) {
        .employ-item {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid #B4B4B4;
        }
    }
</style>

<style>
    .list_employ .search-form {
        width: 365px;
    }

    .detail_employ .form-edit button.h-auto {
        opacity: 0;
    }

    .detail_employ .form-edit .no-gutters {
        margin: 0;
    }

    .detail_employ .form-edit .b-calendar-grid-help {
        display: none;
    }

    .detail_employ .form-edit .b-calendar .form-control:focus,
    .detail_employ .form-edit .b-form-datepicker.form-control.focus,
    .detail_employ .form-edit .form-control.focus {
        box-shadow: none;
        border: 1px solid #ced4da6e;
    }

    .detail_employ .form-edit .text-truncate {
        padding: 0;
    }

    .detail_employ .form-edit .b-form-datepicker,
    .detail_employ .form-edit .b-form-timepicker {
        border-radius: 0;
        padding: 2px 12px;
    }

    .detail_employ .form-edit .text-muted {
        color: #B4B4B4 !important;
    }

    .detail_employ .form-edit .custom-radio {
        display: flex;
        align-items: center;
        margin-right: 50px;
    }

    .detail_employ .form-edit .custom-radio label {
        margin-left: 10px;
    }
</style>