<template>
    <div>
        <b-modal v-model="showModal" ref="modalEnd" centered hide-header hide-footer content-class="modal-width" modal-class="modal-end">
        <div class="accept-modal text-center">
            <img src="@/assets/front-end/icon/Group1370.png" alt="">
            <h5 style="margin-top: 10px;">Thời gian trả giá kết thúc</h5>
            <p>Thời gian trả giá đã kết thúc , vui lòng kiểm tra thông tin và chọn kết quả cuộc đấu giá</p>
            <!-- <b-button class="mt-3" block  @click="endRoom">Xác nhận</b-button> -->
        </div>
    </b-modal>
    </div>
</template>
<script>
export default {
    props: ['dataProp'],
    data() {
        return {
            showModal: this.dataProp,
            auction_id: '',
            asset_id: '',
            auction_key: ''
        }
    },
    created() {
        // this.auctionId = this.decode(this.$route.params.auction_id);
        // this.getData()
    },
    methods: {
        // async getData(){
        //     const storedData = localStorage.getItem('auInfo')
        //     if(storedData) {
        //         var info = JSON.parse(storedData)
        //     }

        //     const res = await this.$axios.get('/asset/getAssetDetail?asset_id=' +this.auctionId, {
        //         headers: {
        //             'x-access-token': info.accessToken
        //         }
        //     })

        //     if(res.status == 200) {
        //         this.asset_id = res.data.asset_id
        //         this.auction_key = res.data.auction_key;
        //     }
        // },
        // endRoom() {
        //     //EMIT SOCKET 
        //     const key = '';
        //     const token = JSON.parse(localStorage.getItem('auInfo')).accessToken;
        //     this.$socket(this.auction_key  ,key, token).emit('endRoom')
        //     this.$refs.modalEnd.hide()
        // }
    }
}
</script>
<style>
    .modal-end .modal-width {
        width: 370px;
        border-radius: 17.47px;
        box-shadow: 0px 0px 38px 0px #00000040;
    }
    .modal-end .modal-header {
        border-bottom: none !important;
        padding-bottom: 0;
        padding-top: 0;
    }
    .modal-end .modal-header button {
        background: none;
        border: none;
        font-size: 26px;
    }
</style>
<style lang="css" scoped>
    .modal-end button {
        background-color: #EE3439;
        color: #ffffff;
        font-weight: 700;
        border-radius: 10px;
        border: none;
        min-width: 163px;
        min-height: 42px;
    }

    .modal-end button:active,
    .modal-end button:focus {
        background-color: #EE3439 !important;
    }

    .modal-end button:hover {
        background-color: #EE3439;
    }
</style>