<template>
    <div class="footer" v-if="!hideFooter">
        <b-container>
            <b-row class="">
                <b-col cols="md-6" class="col-fisrt">
                    <div class="logo-footer">
                        <img src="@/assets/images/Logo-footer.png" alt="" style="margin-bottom: 15px;">
                    </div>

                    <ul class="list-fisrt">
                        <li class="list-first__ttl">{{ infomation.company_name.value }}</li>
                        <li>Trụ sở chính : {{ infomation.address.value }}</li>
                        <li>Mã số thuế : {{infomation.tax.value }}</li>
                        <li>Người đại diện : {{ infomation.repre.value }}, chức vụ: {{ infomation.position.value }}</li>
                        <li>Số Giấy đăng ký hoạt động của doanh nghiệp đấu giá tài sản: {{ infomation.cetificate.value }}</li>
                        <li>Điện thoại: {{ infomation.phone.value }}</li>
                        <li>Email : {{ infomation.email.value }}</li>
                        <!-- <img class="logo-check" src="@/assets/images/Layer 421 1.png" alt=""> -->
                        <a href="http://online.gov.vn/Home/WebDetails/106755" target="_blank">
                            <img class="logo-check" src="@/assets/images/Layer 421 1.png" alt="">
                        </a>
                    </ul>
                </b-col>
                <b-col cols="6" class="col-md-3 ">
                    <p class="col-ttl">Về chúng tôi</p>
                    <ul class="list">
                        <li>
                            <b-link to="/about">Giới thiệu</b-link>
                        </li>
                        <li>
                            <b-link to="/detail/operating_regulations">{{ infomation.operating_regulations.title }}</b-link>
                        </li>
                        <li>
                            <b-link to="/detail/dispute_settlement_mechanism">{{ infomation.dispute_settlement_mechanism.title }}</b-link>
                        </li>
                        <li>
                            <b-link to="/detail/document_user_manual">{{ infomation.document_user_manual.title }}</b-link>
                        </li>
                        <li>
                            <b-link to="/detail/auction_guide">{{ infomation.auction_guide.title }}</b-link>
                        </li>
                    </ul>
                </b-col>
                <b-col cols="6" class="col-md-3">
                    <p class="col-ttl">Chính sách</p>
                    <ul class="list">
                        <li>
                            <b-link to="/detail/normative_documents">{{ infomation.normative_documents.title }}</b-link>
                        </li>
                        <li>
                            <b-link to="/detail/auction_rules">{{ infomation.auction_rules.title }}</b-link>
                        </li>
                        <li>
                            <b-link to="/news">Tin tức</b-link>
                        </li>
                        <li>
                            <b-link to="/detail/privacy_policy">{{ infomation.privacy_policy.title }}</b-link>
                        </li>
                    </ul>
                    <div class="download">
                        <b-link :href="infomation.google_play.url" target="_blank"><img src="@/assets/images/google_app.png" alt="" style="margin-right: 40px;"></b-link>
                        <b-link :href="infomation.app_store.url" target="_blank"><img src="@/assets/images/appstore.png" alt=""></b-link>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <p class="copyright">Copyrighted to DGV AUCTION PARTNERSHIP COMPANY</p>
    </div>
</template>
<script>
export default {
    data(){
        return{
            infomation:{},
            hideFooter:false,
        }
    },
    created(){
        this.infomation = this.getInformation()
        if (this.$route.path == '/countdown') {
            this.hideFooter = true
        }
    },
    watch: {
        "$route.path": function (url) {
            this.hideFooter = false
            if (url == '/countdown') {
                this.hideFooter = true
            }
        }
    },
}
</script>
<style lang="css" >
.footer .row {
    position: relative;
}

.footer {
    background-color: #080808;
    color: #D6B877;
    font-family: Libre Bodoni;
    padding-top: 45px;
    z-index: 1000;
}

ul {
    list-style: none;
    padding: 0;
}

.footer .list-fisrt {
    padding-left: 0;
}

.footer .list-fisrt li {
    font-size: 12.76px;
    line-height: 15.95px;
    padding: 5px 0;
}

.footer .list-fisrt .list-first__ttl {
    font-size: 15.95px;
    line-height: 19.93px;
}

.footer p.col-ttl {
    font-size: 17.54px;
    line-height: 21.93px;
    font-weight: 500;
}

.footer .list {
    padding-left: 0;
}

.footer .list li {
    position: relative;
    margin-left: 33px;
    padding-bottom: 14px;
}

.footer .list li::before {
    content: " ";
    background: url('@/assets/icon/Arrow.png');
    width: 25px;
    height: 18px;
    background-repeat: no-repeat;
    position: absolute;
    left: -33px;
    top: 3px;
}

.footer .list li a {
    color: #D6B877;
    font-family: Libre Bodoni;
    text-decoration: none;
}

.footer .download {
    position: absolute;
    right: 9%;
    top: 60%;
}

.footer .copyright {
    color: #333333;
    margin: 0;
    padding: 6px 0;
    text-align: center;
    font-size: 15.95px;
    font-weight: 400;
    background-color: #D6B877;
    font-family: Libre Bodoni;
    line-height: 19.93px;
}

.footer .col-6,
.footer .col-md-3 {
    position: unset;
}

@media (max-width: 1024px) {
    .footer .download {
        display: none;
    }
}

@media (max-width: 425px) {
    .footer .col-fisrt {
        order: 3;
        text-align: center;
        position: relative;
        margin-top: 20px;
    }

    .footer .logo-footer {
        text-align: left;
    }

    .footer .logo-check {
        position: absolute;
        top: 13%;
        width: 155px;
    }

    .footer .copyright {
        font-size: 11.7px;
    }

    .footer .list {
        font-size: 15px;
    }
}

@media (max-width: 320px) {
    .footer .logo-check {
        top: 9%;
        width: 132px;
    }

    .footer .logo-footer img {
        width: 130px;
    }
}
</style>