<template>
<div>
    <b-form class="search-form">
        <b-form-group label-for="input-search">
            <b-form-input id="input-search"  type="text" :placeholder="data.text" required></b-form-input>
        </b-form-group>
        <button :style="{ 'background-color': data.color }"><img src="@/assets/admin/icon/Search.png" alt=""></button>
    </b-form>
</div>
</template>

<script>
export default {
    props: ["data"],
    data() {
        return {

        }
    },
}
</script>

<style lang="css">
    .search-form {
        position: relative;
    }

    .search-form .form-control {
        border: 1px solid #515151;
        border-radius: 22px;
        color: #727272;
        height: 42px;
        padding-right: 80px;
    }

    .search-form .form-control:focus {
        box-shadow: none;
        border-color: #515151;
    }
    
    .search-form button {
        position: absolute;
        border: none;
        outline: none;
        border-radius: 28px;
        width: 73px;
        height: 36px;
        top: 3px;
        right: 3px;
    }

    .search-form .form-group {
        margin: 0;
    }
</style>
