<template>
    <div class="au-finished mt-5 pl-2">
        <h5 class="au_title">Lưu ý: Trước giờ đấu giá 30 phút , phải tạo phòng đấu giá để điểm danh khách hàng trước khi
            thời gian đấu giá bắt đầu .</h5>
        <b-row class="item mx-3" v-for="(data, index) in dataAuction" :key="index"
            @click="$router.push('/auctioneer/auction/list-asset/' + encode(data.auction_id))"
            style="margin-bottom: 10px; cursor: pointer;">
            <b-col cols="auto" class="p-1 wp-img">
                <img class="img_pro" :src="$storage + data.thumbnail" alt="" @error="handleError">
            </b-col>
            <b-col cols="lg-7" class="p-1">
                <p class="item_name">{{ data.auction_name }}</p>
                <div class="d-flex justify-content-between block-mobile">
                    <div class="info_pro">
                        <p>Mã cuộc đấu giá: <span>{{ data.auction_code }}</span></p>
                        <p>Số tài sản đấu: <span>{{ data.count_asset }}</span></p>
                        <p>Đấu giá viên: <span>{{ data.full_name }}</span></p>
                    </div>
                    <div class="info_pro">
                        <p>Thời gian bắt đầu: <span>{{ formateTime(data.start_time) }}</span></p>
                        <p>Thời gian kết thúc: <span>{{ formateTime(data.end_time) }}</span></p>
                        <p><span>{{ data.value }}</span></p>
                    </div>
                </div>
            </b-col>
            <b-col cols="lg-2" class="text-center au_time">
                <div v-if="data.auction_status_id == 2">
                    <img src="@/assets/front-end/icon/Ok.png" alt="" style="width: 41px; height: 41px;">
                    <p style="color: #00B569;">Cuộc đấu giá thành</p>
                </div>

                <div v-else-if="data.auction_status_id == 4">
                    <img src="@/assets/icon/Auction_modal.png" alt="" style="width: 41px; height: 41px;">
                    <p style="color: #EE3439;">Cuộc đấu giá kết thúc</p>
                </div>

                <div v-else>
                    <img src="@/assets/front-end/icon/Group 831.png" alt="" style="width: 41px; height: 41px;">
                    <p style="color: #EE3439;">Cuộc đấu giá không thành</p>
                </div>
            </b-col>
        </b-row>
        <Paginate :page=page @set-page="setPage" />
    </div>
</template>
<script>
import Paginate from '../../../../web-view/components/paginate.vue'
export default {
    components: {
        Paginate
    },
    data() {
        return {
            dataAuction: [],
            page: {
                totalData: 0,
                totalPage: 0,
                currentPage: this.$route.query.page || 1,
                perPage: 4,
                classPrev: 'd-none',
                classNext: 'd-none'
            },
        }
    },
    mounted() {
        this.getDataAuction()
    },
    methods: {
        formateTime(time) {
            return this.$moment.utc(time).format("HH:mm:ss DD/MM/YYYY")
        },
        async getDataAuction() {
            const storedData = localStorage.getItem('auInfo')
            if (storedData) {
                var info = JSON.parse(storedData)
            }
            const data = {
                page: this.page.currentPage,
            };
            const res = await this.$axios.get('/auction/getListAuctionEnding?page=' + data.page + '&per_page=4', {
                headers: {
                    'x-access-token': info.accessToken
                }
            })
            if (res.status == 200) {
                this.dataAuction = res.data.re
                this.page.totalData = res.data.pagination.total_element
                this.page.totalPage = res.data.pagination.total_page
            }
        },
        setPage(val) {
            this.page.currentPage = val;
            this.getDataAuction();
        },
    }
}
</script>
<style lang="css" scoped>
.au_title {
    color: #515151;
    font-family: Libre Bodoni;
    font-weight: 500;
    font-size: 19px;
    position: relative;
    padding-left: 25px;
    margin-bottom: 8px;
}

.au_title::before {
    content: '';
    position: absolute;
    background-image: url('@/assets/icon/Important.png');
    background-repeat: no-repeat;
    width: 24px;
    height: 20px;
    left: 0;
}

.item {
    box-shadow: 2px 2px 4px 0px #00000021;
    border: 1px solid #EAEAEA;
    border-radius: 30px;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
}

.item .img_pro {
    border-radius: 30px;
    height: 190px;
    width: 190px;
    object-fit: cover;
}

.item_name {
    color: #515151;
    font-weight: 600;
    margin: 5px 0 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.info_pro p {
    font-size: 14px;
    color: #929292;
    margin-bottom: 12px;
}

.info_pro p span {
    color: #515151;
    font-weight: 500;
}

.au_time p {
    font-size: 18.43px;
    margin: 0;
    font-weight: 500;
}


@media (max-width: 1400px) {
    .au_time p {
        font-size: 16px;
    }
    .info_pro p{
        font-size: 13px;
    }
}

@media (max-width: 1024px) {
    .au_time p {
        font-size: 16px;
    }

    .info_pro p{
        font-size: 14px;
    }
}

@media (max-width: 992px) {
    .block-mobile {
        justify-content: space-around !important;
    }
    .item_name {
        text-align: center;
    }

    .au_time {
        margin-bottom: 10px;
    }

    .wp-img {
        text-align: center;
    }
}
@media (max-width: 991px) {
    .item {
        justify-content: center;
    }
}

@media (max-width: 768px) {

    .block-mobile,
    .item_name {
        display: block !important;
        margin-left: 20px;
    }

    .item {
        padding: 10px 0;
    }
}
</style>